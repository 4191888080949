function getMatchedKeyReset(object, predicate) {
  let matchedKeyReset = {}
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'string') {
      if (object[key].includes(predicate)) {
        matchedKeyReset[key] = null
      }
    }
  })
  return matchedKeyReset
}

export default getMatchedKeyReset
