import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'

function OnExitModal({
  onConfirm,
  onCancel,
  isOpen,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <h2>{titleText}</h2>
      <p>{contentText}</p>
      <hr></hr>
      <div className="button-wrapper">
        <button className="button-secondary" onClick={onCancel}>
          {cancelButtonText}
        </button>
        <button className="button-primary" onClick={onConfirm}>
          {confirmButtonText}
        </button>
      </div>
    </Modal>
  )
}

OnExitModal.propTypes = exact({
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  titleText: PropTypes.string,
  contentText: PropTypes.string,
})

OnExitModal.defaultProps = {}

export default React.memo(OnExitModal)
