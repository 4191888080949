import React from 'react'
import exact from 'prop-types-exact'
import { Link } from 'react-router-dom'

const propTypes = {}

const defaultProps = {}

function Exploration() {
  return (
    <div className="exploration-section">
      <h4>Explore Custody Services Offerings</h4>
      <div className="exploration-subsection">
        <h5>Institutional Custody Solutions</h5>
        <p>
          Inspira provides secure solutions to help you meet the Custody Rule
          requirements, provide enhanced transparency to investors, and deliver
          necessary reporting, through our Fund Custody, Safekeeping and
          Verification Services.
        </p>
        <Link
          target="_blank"
          to={{
            pathname:
              'https://inspirafinancial.com/business/wealth-retirement/alternative-investments',
          }}
          rel="noopener noreferrer"
        >
          Learn More
        </Link>
      </div>
      <hr />
      <div className="exploration-subsection">
        <h5>Application Program Interface (API)</h5>
        <p>
          Our API offers a seamless and transparent digital interface that
          enables you to increase transaction volumes, reduce processing times
          and control the user experience.
        </p>
        <Link
          target="_blank"
          to={{
            pathname:
              'https://inspirafinancial.com/business/support/faqs/wealth-retirement/alternative-assets',
          }}
          rel="noopener noreferrer"
        >
          Learn More
        </Link>
      </div>
      <hr />
      <div className="exploration-subsection">
        <h5>Accruit 1031 Exchange</h5>
        <p>
          Accruit, an Inspira Solution, is a full-service Real Estate Qualified
          Intermediary that facilitates 1031 exchanges, as well as provides the
          industry’s only 1031 Exchange workflow technology solution.
        </p>
        <Link
          target="_blank"
          to={{ pathname: 'http://www.accruitholdings.com/' }}
          rel="noopener noreferrer"
        >
          Learn More
        </Link>
      </div>
    </div>
  )
}

Exploration.propTypes = exact(propTypes)
Exploration.defaultProps = defaultProps

export default React.memo(Exploration)
