import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  Card,
  StatusKeyModal,
  ACPTable,
  StatusHeader,
  ACPFilterPicklist,
} from 'components'
import { Spinner } from 'lp-components'
import FilterIcon from 'images/filter-icon.svg'
import { uniq, isEmpty } from 'lodash'
import {
  createStatusList,
  createStatusOptions,
  dateFormatter,
  formatNumberAsCurrency,
  formatWithNullValues,
  numberComparator,
  dateComparator,
} from 'utils'
import { ASSET_STATUSES } from 'config'
import { Link } from 'react-router-dom'
import { AssetStatusRenderer, AddNewAssetButton } from '../components'
import { Form, Formik } from 'formik'

const propTypes = {
  assets: PropTypes.array,
  showAddAssetModal: PropTypes.func.isRequired,
}

const defaultProps = {}

const loadingOverlay = `<div class="search-results-helper">
<div class="overlay-text-title med-18">No results found</div>
<span class="overlay-text reg-14">It looks like you don't have any assets yet.</span>
<span class="overlay-text reg-14">To get started, please select "Add New Asset".</span>
</div>`

function AssetTable({ assets, showAddAssetModal }) {
  const [picklistVisible, setPicklistVisible] = useState(false)
  const [filterStatuses, setFilterStatuses] = useState([])
  const [filterAssetName, setFilterAssetName] = useState('')
  const [statusOptions, setStatusOptions] = useState([])
  const [showStatusKeyModal, setShowStatusKeyModal] = useState(false)

  const filterCount = filterStatuses.length

  const columnDefs = [
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      headerTooltip: 'Status: The current status of this asset.',
      headerClass: 'asset-status-header',
      cellClass: 'asset-status-cell',
      cellRenderer: AssetStatusRenderer,
      headerComponent: (props) => (
        <StatusHeader
          props={props}
          showModal={() => setShowStatusKeyModal(true)}
        />
      ),
      tooltipValueGetter: (params) => {
        const status = ASSET_STATUSES.find(
          (status) => status.name === params.value
        )
        if (!status) {
          return params.value
        }
        return `${status.name}: ${status.description}`
      },
    },
    {
      field: 'acceptanceDate',
      headerName: 'Accepted Date',
      minWidth: 100,
      filter: false,
      sortable: true,
      headerTooltip: 'Accepted Date: The date the asset was accepted.',
      valueFormatter: dateFormatter,
      comparator: dateComparator,
    },
    {
      field: 'name',
      headerName: 'Asset Name',
      sortable: true,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellClass: 'asset-name-cell',
      headerTooltip:
        'Asset Name: The legal name as stated in the offering document.',
      cellRenderer: (params) => {
        if (
          params.data.status === 'Accepted' ||
          params.data.status === 'Review Required'
        ) {
          return (
            <Link
              className="investors-link"
              to={{
                pathname: `/assets/${params.data.assetID}/investors`,
                state: {
                  isInvestorDetails: true,
                  target: location.pathname,
                  subtitle: params.data.name,
                  breadcrumb: {
                    to: '/assets',
                    title: 'Assets',
                  },
                },
              }}
            >
              {params.data.name}
            </Link>
          )
        }
        return params.data.name
      },
    },
    {
      field: 'holdings',
      headerName: 'Investors',
      sortable: true,
      minWidth: 100,
      cellClass: 'table-right-align',
      headerTooltip: 'Investors: The number of investors in the asset.',
      valueFormatter: (params) => {
        return formatWithNullValues(params.data.holdings, (input) => {
          return parseFloat(input).toLocaleString('en')
        })
      },
      type: 'numericColumn',
      comparator: numberComparator,
    },
    {
      field: 'value',
      headerName: 'Total Value',
      sortable: true,
      minWidth: 100,
      cellClass: 'table-right-align',
      headerTooltip: 'Total Value: The total value of holdings for this asset.',
      valueFormatter: (params) => {
        return formatNumberAsCurrency(parseFloat(params.data.value), '-')
      },
      type: 'numericColumn',
      comparator: numberComparator,
    },
  ]

  useEffect(() => {
    if (!assets || !Array.isArray(assets)) return
    const uniqStatuses = uniq(
      assets?.filter((a) => a.status).map((a) => a.status)
    )
    const uniqueStatusList = createStatusList(uniqStatuses)
    setStatusOptions(createStatusOptions(uniqueStatusList))
  }, [assets])

  const filteredAssets = useMemo(() => {
    if (isEmpty(assets) || !Array.isArray(assets)) return []
    return assets?.filter((asset) => {
      return (
        (filterCount > 0
          ? filterStatuses.some((status) => asset.status === status)
          : true) &&
        (filterAssetName
          ? asset.name.toLowerCase().includes(filterAssetName.toLowerCase())
          : true)
      )
    })
  }, [assets, filterStatuses, filterAssetName])

  const onFilterTextBoxChanged = (e) => {
    setFilterAssetName(e.target.value)
  }

  const handleExternalFilterChange = (newValue) => {
    setFilterStatuses(newValue)
  }

  const applyFilters = (values) => {
    handleExternalFilterChange(values.filter)
    setPicklistVisible(false)
  }

  const assetTableActions = (
    <div className="table-actions">
      <div className="table-actions-left">
        <input
          type="text"
          id="filter-text-box"
          placeholder="Search Asset Name"
          onInput={onFilterTextBoxChanged}
        />
      </div>
      <div className="table-actions-right">
        <AddNewAssetButton showAddAssetModal={showAddAssetModal} />
      </div>
      <div className="table-actions-right">
        <button
          onClick={() => {
            setPicklistVisible(!picklistVisible)
          }}
        >
          <img src={FilterIcon} alt="" />
          Filters: {filterCount > 0 ? 'Statuses ' + filterCount : 'None'}
        </button>
      </div>
    </div>
  )

  if (!assets) {
    return (
      <Card label="Assets">
        <Spinner />
      </Card>
    )
  }

  const onGridReady = (params) => {
    const defaultSortModel = [{ colId: 'holdings', sort: 'desc', sortIndex: 0 }]
    params.columnApi.applyColumnState({ state: defaultSortModel })
    if (!filteredAssets.length) {
      params.api.showLoadingOverlay()
    }
  }

  return (
    <>
      <Card label="Assets" actions={assetTableActions} className="asset-card">
        {filteredAssets && (
          <ACPTable
            columnDefs={columnDefs}
            rowData={filteredAssets}
            overrideOnGridReady={onGridReady}
            overlayLoadingTemplate={loadingOverlay}
            overrideDefColWidth={50}
          />
        )}
      </Card>
      {showStatusKeyModal && (
        <StatusKeyModal
          onClose={() => setShowStatusKeyModal(false)}
          title="Asset Status Key"
          statuses={ASSET_STATUSES}
        />
      )}
      <Formik
        onSubmit={applyFilters}
        initialValues={{
          filter: [],
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            {picklistVisible && (
              <Form>
                <ACPFilterPicklist
                  handleExternalFilterChange={handleExternalFilterChange}
                  setPicklistVisible={setPicklistVisible}
                  setFieldValue={setFieldValue}
                  values={values}
                  currentFilter={filterStatuses}
                  options={statusOptions}
                  onClose={applyFilters}
                  title={'Asset Status'}
                />
              </Form>
            )}
          </>
        )}
      </Formik>
    </>
  )
}

AssetTable.propTypes = exact(propTypes)
AssetTable.defaultProps = defaultProps

export default AssetTable
