import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { setOnSuccess, handleSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { set, get } from 'lodash/fp'

const reducerKey = 'confirmLiquidation'
const slice = 'root.confirmLiquidation'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchLiquidation]: setOnSuccess('liquidation'),
    [apiActions.updateLiquidation]: handleSuccess((state, action) => {
      const updatedLiquidation = action.payload.data
      const existingLiquidation = get('liquidation', state) || {}
      return set(
        'liquidation',
        { ...existingLiquidation, ...updatedLiquidation },
        state
      )
    }),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  liquidation: select('liquidation'),
}

export { reducer, selectors, reducerKey }
