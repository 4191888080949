import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import InspiraLogo from 'images/InspiraLogo.svg'
import { HelpDetails } from 'components'

const propTypes = {
  title: PropTypes.string.isRequired,
}

const defaultProps = {}

function ConfirmPurchaseHeader({ title }) {
  return (
    <header className="add-asset-header no-print">
      <div className="header-responsive">
        <img alt="inspira-logo" src={InspiraLogo} className="logo" />
        <h3 id="header-title">{title}</h3>
        <div className="add-asset-header-button-group">
          <HelpDetails isWizardFlow={true} />
        </div>
      </div>
    </header>
  )
}

ConfirmPurchaseHeader.propTypes = exact(propTypes)
ConfirmPurchaseHeader.defaultProps = defaultProps

export default pure(ConfirmPurchaseHeader)
