import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'
import * as Views from './views'
import Group1AuthorizedLayout from '../Group1AuthorizedLayout'
import Layout from './Layout'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  return (
    <Group1AuthorizedLayout>
      <Layout>
        <Switch>
          <Route exact path={path} component={Views.Invitation} />
          <Redirect path="*" to="/home" />
        </Switch>
      </Layout>
    </Group1AuthorizedLayout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
