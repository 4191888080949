import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import * as LS from 'local-storage'

import { AssetTable, AddAssetModal } from '../../home/components'
import TabbedHeader from '../components/TabbedHeader'

const propTypes = {
  assets: PropTypes.array,
}

const defaultProps = {
  assets: null,
}

function Home({ assets }) {
  const [addAssetModalShown, setAddAssetModalShown] = useState(false)

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  return (
    <TabbedHeader>
      <div className="container">
        {addAssetModalShown && (
          <AddAssetModal
            onClose={() => setAddAssetModalShown(false)}
            path="/add-asset/new"
          />
        )}
        <div className="all-assets-wrapper view-all-page">
          <AssetTable
            assets={assets}
            showAddAssetModal={() => {
              LS.clearAddAssetDetails()
              setAddAssetModalShown(true)
            }}
          />
        </div>
      </div>
    </TabbedHeader>
  )
}

Home.propTypes = propTypes

Home.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assets: globalSelectors.assets(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home)
