import {
  convertUSStateCodeToFullName,
  convertCAProvinceCodeToFullName,
  normalizeCountryValue,
} from 'utils'
import { isEmpty } from 'lodash'

function normalizeStateAndProvinceValue(country, state) {
  const normalizedCountry = normalizeCountryValue(country)
  if (isEmpty(state)) return ''
  return normalizedCountry === 'US'
    ? convertUSStateCodeToFullName(state)
    : normalizedCountry === 'CA'
    ? convertCAProvinceCodeToFullName(state)
    : state
}

export default normalizeStateAndProvinceValue
