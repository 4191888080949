import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  data: PropTypes.array.isRequired,
}

const defaultProps = {}

function CollapsableList({ data }) {
  const [showMore, setShowMore] = useState(false)

  if (!data || data.length < 1) {
    return ''
  }

  return (
    <ul className="cell-list">
      {!showMore &&
        data.slice(0, 2).map((asset, index) => {
          return (
            <li className="cell-list-item" key={index}>
              {asset}
            </li>
          )
        })}
      {showMore &&
        data.map((asset, index) => {
          return (
            <li className="cell-list-item" key={index}>
              {asset}
            </li>
          )
        })}

      {!showMore && data.length > 2 && (
        <li>
          <button
            className="collapse-list-buttons"
            onClick={() => setShowMore(true)}
          >
            {`Show +${data.length - 2} more`}
          </button>
        </li>
      )}
      {showMore && (
        <li>
          <button
            className="collapse-list-buttons"
            onClick={() => setShowMore(false)}
          >
            {`Collapse List`}
          </button>
        </li>
      )}
    </ul>
  )
}

CollapsableList.propTypes = exact(propTypes)
CollapsableList.defaultProps = defaultProps

export default CollapsableList
