import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  children: PropTypes.object,
  onComplete: PropTypes.func,
}

const defaultProps = {}

function ScrollingContainer({ children, onComplete }) {
  const handleScroll = (e) => {
    const height = document.getElementById('scrolling-wrapper').clientHeight
    const paddingBottom = parseInt(
      document.defaultView
        .getComputedStyle(e.target)
        .getPropertyValue('padding-bottom')
    )

    const isBottom =
      height <= e.target.clientHeight + e.target.scrollTop - paddingBottom
    if (isBottom) onComplete(true)
  }

  return (
    <div onScroll={handleScroll} className="scrolling-container">
      <div id="scrolling-wrapper">{children}</div>
    </div>
  )
}

ScrollingContainer.propTypes = exact(propTypes)
ScrollingContainer.defaultProps = defaultProps

export default React.memo(ScrollingContainer)
