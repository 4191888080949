import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import InspiraLogo from 'images/InspiraLogo.svg'
import CloseCircleWhite from 'images/close-circle-white.svg'
import { HelpDetails, ExitWithoutSavingModal } from 'components'
import { useHistory } from 'react-router'

const propTypes = {
  title: PropTypes.string,
}

const defaultProps = {
  title: 'Client Template Header',
}

function Header({ title }) {
  const [modalShown, setModalShown] = useState(false)
  const history = useHistory()
  return (
    <header className="add-asset-header">
      <div className="header-responsive">
        <img src={InspiraLogo} className="logo" alt="Inspira Logo" />
        <h3 id="header-title">{title}</h3>
        <div className="add-asset-header-button-group">
          <HelpDetails isWizardFlow={true} />
          <div>
            <img
              className="add-asset-close-circle"
              src={CloseCircleWhite}
              alt="exit"
              onClick={() => setModalShown(true)}
            />
          </div>
        </div>
      </div>
      {modalShown && (
        <ExitWithoutSavingModal
          onClose={() => setModalShown(false)}
          onExit={() => history.push('/home')}
          workFlowName="funding instructions"
        />
      )}
    </header>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

export default pure(Header)
