import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function TabbedHeader({ children }) {
  return (
    <div>
      <div className="assets-navigation-menu">
        <NavLink to="/assets/view-all">All Assets</NavLink>
        <NavLink to="/assets/capital-commitments">Capital Commitments</NavLink>
      </div>
      <div className="navigation-line"></div>
      {children}
    </div>
  )
}

TabbedHeader.propTypes = propTypes

TabbedHeader.defaultProps = defaultProps

export default TabbedHeader
