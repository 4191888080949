import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'
import Layout from './Layout'
import {
  LIQUIDATION_CONFIRMATION_STAGES_PART_ONE,
  LIQUIDATION_CONFIRMATION_STAGES_PART_TWO,
} from 'flow-stages'
import { flatMap } from 'lodash'
import { LIQUIDATION_CONFIRMATION_STEPS } from 'config'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  const stages = [
    ...LIQUIDATION_CONFIRMATION_STAGES_PART_ONE.map((stage) => ({
      ...stage,
      part: LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_FIRST_STEP,
    })),
    ...LIQUIDATION_CONFIRMATION_STAGES_PART_TWO.map((stage) => ({
      ...stage,
      part: LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_SECOND_STEP,
    })),
  ]

  return (
    <Switch>
      {flatMap(stages, (stage) =>
        stage.routes.map((route) => {
          const { pathname, component: Component } = route
          return (
            <Route
              exact
              key={pathname}
              path={path + '/:liquidationID' + pathname}
              render={(routeProps) => (
                <Layout currentStage={stage.name} part={stage.part}>
                  <Component {...routeProps} />
                </Layout>
              )}
            />
          )
        })
      )}
      <Redirect path="*" to="/home" />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
