import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { DocumentsTable } from '../components'
import { Spinner } from 'lp-components'
import exact from 'prop-types-exact'
import { selectors } from '../reducer'

const propTypes = {
  fetchCompletedTransactions: PropTypes.func.isRequired,
  completedTransactions: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {}

function AvailableDocuments({
  completedTransactions,
  fetchCompletedTransactions,
}) {
  useEffect(() => {
    !completedTransactions && fetchCompletedTransactions()
  }, [completedTransactions])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!completedTransactions) return <Spinner />

  return <DocumentsTable documents={completedTransactions} />
}

AvailableDocuments.propTypes = exact(propTypes)
AvailableDocuments.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    completedTransactions: selectors.completedTransactions(state),
  }
}

const mapDispatchToProps = {
  fetchCompletedTransactions: apiActions.fetchCompletedTransactions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AvailableDocuments
)
