import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { useParams, useHistory } from 'react-router-dom'
import * as apiActions from 'api-actions'
import { bulkPromiseUploads, createFileErrors } from 'utils'
import * as flashActions from 'redux-flash'
import { DocumentUploadForm } from '../forms'

const propTypes = {
  createDocument: PropTypes.func.isRequired,
  exchangeConfirmation: PropTypes.object.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function DocumentUpload({
  createDocument,
  exchangeConfirmation,
  flashErrorMessage,
}) {
  const history = useHistory()
  const { exchangeID } = useParams()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const handleSubmit = async (values, { setErrors }) => {
    delete values['requireAll']

    const fileUploadPromises = Object.entries(values)
      .filter((e) => e[1].fileName && e[1].requiredDocumentID)
      .map((e) => ({
        fileName: e[1].fileName,
        fileContents: e[1].fileContents,
        fileType: e[1].fileType,
        documentID: e[1].documentID,
        requiredDocumentID: e[1].requiredDocumentID,
      }))
      .map((doc) => {
        const formData = new FormData()
        formData.append(
          'fileContents',
          new Blob([doc.fileContents], {
            type: doc.fileType,
          }),
          doc.fileName
        )
        return createDocument({ ...doc, exchangeID }, formData)
      })

    const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromises)

    if (rejectedUploads.length) {
      const errors = createFileErrors(rejectedUploads)
      setErrors(errors)
    } else {
      try {
        history.push(`/confirm-exchange/${exchangeID}/exchange-confirmation`)
      } catch (e) {
        flashErrorMessage(
          e.errors.message || 'Something went wrong, please try again.'
        )
      }
    }
  }

  return (
    <div className="flow-card-container">
      <DocumentUploadForm
        exchangeConfirmation={exchangeConfirmation}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

DocumentUpload.propTypes = propTypes
DocumentUpload.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    exchangeConfirmation: selectors.exchangeConfirmation(state),
  }
}

const mapDispatchToProps = {
  createDocument: apiActions.createRequiredDocumentForExchange,
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DocumentUpload
)
