import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
}

const defaultProps = {
  children: null,
}

const CustomCheckBox = ({
  children,
  handleClick,
  id,
  isChecked,
  name,
  ...rest
}) => {
  return (
    <>
      <div className="checkbox" {...rest}>
        <input
          id={id}
          name={name}
          type="checkbox"
          onChange={handleClick}
          checked={isChecked}
        />
        {children}
      </div>
    </>
  )
}

CustomCheckBox.propTypes = propTypes
CustomCheckBox.defaultProps = defaultProps

export default CustomCheckBox
