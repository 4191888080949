import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { CurrencyInput, LabelWithTooltip, ACPInputField } from 'components'
import { DisplayInput } from '../components'
import {
  VALUATION_METHODOLOGY,
  EXCHANGE_CONFIRMATION_RECORD_TYPE_FORM_MAPPING,
  EXCHANGE_CONFIRMATION_RECORD_TYPE,
  EXCHANGE_CONFIRMATION_REQUEST_TYPE,
} from 'config'
import { isEmpty, map, get } from 'lodash'
import { convertStringToFixedFloat, preformInputOperation } from 'utils'
import { useFormikContext } from 'formik'
import ValuationMethodModal from 'components/ValuationMethodModal'

const propTypes = {
  lineItem: PropTypes.object.isRequired,
}

const defaultProps = {}

const valuationMethodologyOptions = map(VALUATION_METHODOLOGY)

function LineItemFormElement({ lineItem }) {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    initialValues,
  } = useFormikContext()

  const [showValMethodModal, setShowValMethodModal] = useState(false)
  const [confirmValMethod, setConfirmValMethod] = useState(false)

  const recordType =
    EXCHANGE_CONFIRMATION_RECORD_TYPE_FORM_MAPPING[lineItem.lineItemRecordType]

  const fieldPrefix = `${recordType}.${lineItem.investmentLineItemDirectionID}`

  const isPricePerShare =
    get(values, `${fieldPrefix}.valuationMethodology`) ===
    VALUATION_METHODOLOGY.PRICE_PER_SHARE.value

  const setFieldValueOnCancel = (name, value) => {
    setFieldValue(name, value)
    setFieldValue(
      `${fieldPrefix}.actualAmount`,
      get(initialValues, `${fieldPrefix}.actualAmount`)
    )
    setFieldValue(
      `${fieldPrefix}.sponsorShares`,
      get(initialValues, `${fieldPrefix}.sponsorShares`)
    )
    setFieldValue(
      `${fieldPrefix}.sponsorPrice`,
      get(initialValues, `${fieldPrefix}.sponsorPrice`)
    )
    setFieldTouched(`${fieldPrefix}.actualAmount`, false, false)
    setFieldTouched(`${fieldPrefix}.sponsorShares`, false, false)
    setFieldTouched(`${fieldPrefix}.sponsorPrice`, false, false)
  }

  return (
    <div className="line-item-exchange-form-area">
      <h1>{lineItem.lineItemAssetName}</h1>
      <div className="row">
        <div className="one-half column">
          <DisplayInput
            item={lineItem}
            name={`${fieldPrefix}.requestedValue`}
            label={`Requested Exchange ${
              lineItem.lineItemRecordType ===
              EXCHANGE_CONFIRMATION_RECORD_TYPE.INVESTMENT
                ? 'In'
                : 'Out'
            } Amount (${
              lineItem.requestedType ===
              EXCHANGE_CONFIRMATION_REQUEST_TYPE.AMOUNT
                ? 'Dollars'
                : lineItem.requestedType
            })`}
            labelComponent={LabelWithTooltip}
            tooltipContent="The amount of funds the client requested to exchange."
            disabled
          />
        </div>
        <div className="one-half column">
          <CurrencyInput
            name={`${fieldPrefix}.actualAmount`}
            label={isPricePerShare ? 'Actual Amount' : 'Actual Amount *'}
            labelComponent={LabelWithTooltip}
            tooltipContent={`The actual amount of funds exchanged ${
              lineItem.lineItemRecordType ===
              EXCHANGE_CONFIRMATION_RECORD_TYPE.INVESTMENT
                ? 'to'
                : 'from'
            } the asset.`}
            readOnly={isPricePerShare}
            placeholder={isPricePerShare ? 'Shares x Price' : null}
            disabled={isPricePerShare}
          />
        </div>
      </div>
      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name={`${fieldPrefix}.valuationMethodology`}
            radioOptions={valuationMethodologyOptions}
            label="Valuation Method"
            tooltipContent={
              <div>
                <div>
                  Capital Value: The above asset is reflected on the investment
                  sponsor's books and records using total dollars, not shares
                  and a price per share.
                </div>
                <br />
                <div>
                  Price Per Share: The above asset is reflected on the
                  investment sponsor's books and records using a specified share
                  quantity and price per share.
                </div>
              </div>
            }
            onChange={() => {
              setShowValMethodModal(`showValMethodModal`, true)
              setFieldValue(`${fieldPrefix}.actualAmount`, '', false)
              setFieldValue(`${fieldPrefix}.sponsorShares`, '', false)
              setFieldValue(`${fieldPrefix}.sponsorPrice`, '', false)
              setFieldTouched(`${fieldPrefix}.actualAmount`, false, false)
              setFieldTouched(`${fieldPrefix}.sponsorShares`, false, false)
              setFieldTouched(`${fieldPrefix}.sponsorPrice`, false, false)
            }}
          />
        </div>
        {recordType ===
          EXCHANGE_CONFIRMATION_RECORD_TYPE_FORM_MAPPING[
            EXCHANGE_CONFIRMATION_RECORD_TYPE.INVESTMENT
          ] && (
          <div className="one-half column">
            <ACPInputField
              name={`${fieldPrefix}.physicalSecurity`}
              radioOptions={[
                { key: 'Yes', value: 'true' },
                { key: 'No', value: 'false' },
              ]}
              label="Will a security be issued for this new asset?"
            />
          </div>
        )}
      </div>
      {isPricePerShare && (
        <div className="row">
          <div className="one-half column">
            <ACPInputField
              name={`${fieldPrefix}.sponsorShares`}
              label="Shares"
              tooltipContent="Number of shares exchanged."
              // update the Total Value field when the user changes the shares
              onChange={(e) => {
                const totalValue = preformInputOperation(
                  e.target.value,
                  values[recordType][lineItem.investmentLineItemDirectionID][
                    `sponsorPrice`
                  ],
                  'multiply'
                )
                setFieldValue(`${fieldPrefix}.actualAmount`, totalValue, false)
              }}
              onBlur={(e) => {
                if (isEmpty(e.target.value)) {
                  setFieldValue(`${fieldPrefix}.sponsorShares`, '')
                } else {
                  const num = convertStringToFixedFloat(e.target.value, 4)
                  setFieldValue(`${fieldPrefix}.sponsorShares`, num)
                }
              }}
              maskOptions={{
                numeral: true,
                numeralDecimalScale: 4,
              }}
            />
          </div>
          <div className="one-half column">
            <CurrencyInput
              name={`${fieldPrefix}.sponsorPrice`}
              label="Price *"
              labelComponent={LabelWithTooltip}
              tooltipContent="Price per share exchanged."
              onChange={(e) => {
                const totalValue = preformInputOperation(
                  e.target.value,
                  values[recordType][lineItem.investmentLineItemDirectionID][
                    `sponsorShares`
                  ],
                  'multiply'
                )
                setFieldValue(`${fieldPrefix}.actualAmount`, totalValue, false)
              }}
            />
          </div>
        </div>
      )}
      {showValMethodModal && !confirmValMethod && (
        <ValuationMethodModal
          onClose={() => setShowValMethodModal(false)}
          fieldName={`${fieldPrefix}.valuationMethodology`}
          setFieldValue={setFieldValue}
          setConfirmValMethod={setConfirmValMethod}
          setFieldValueOnCancel={setFieldValueOnCancel}
          initialValue={get(
            initialValues,
            `${fieldPrefix}.valuationMethodology`
          )}
          currentValue={get(values, `${fieldPrefix}.valuationMethodology`)}
        />
      )}
    </div>
  )
}

LineItemFormElement.propTypes = exact(propTypes)
LineItemFormElement.defaultProps = defaultProps

export default React.memo(LineItemFormElement)
