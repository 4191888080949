import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ConfirmPurchaseHeader, FlowStatusTracker } from 'components'
import { FlashMessageContainer, Spinner } from 'lp-components'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { selectors as globalSelectors } from 'global-reducer'
import { getStageLabel } from 'utils'
import { BULK_CONFIRMATION_STAGES } from 'flow-stages'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
  currentUser: PropTypes.object,
}

const defaultProps = {
  currentUser: null,
}
function Layout({ flashMessages, currentStage, children, currentUser }) {
  if (!currentUser?.firstName) return <Spinner />

  return (
    <>
      <FlashMessageContainer messages={flashMessages} />
      <ConfirmPurchaseHeader title={'Confirm Pending Transactions'} />
      <div className="add-asset-layout-wrapper contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title">
            {getStageLabel(
              BULK_CONFIRMATION_STAGES,
              currentStage,
              'purchaseConfirmation'
            )}
          </h1>
        </div>
        <div className="content-div">
          <FlowStatusTracker
            stages={BULK_CONFIRMATION_STAGES}
            currentStage={currentStage}
          />
          {children}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
