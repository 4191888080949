import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Types from 'types'
import { ascendingAlphaSort } from 'utils'
import { ROLE_IDS, countries, usStates, canadianProvinces } from 'config'
import { isEmpty, uniqBy } from 'lodash'
import { ACPInputField, SearchableSelect } from 'components'
import { useFormikContext } from 'formik'

const propTypes = {
  keyName: PropTypes.string,
  isNotEditable: PropTypes.bool,
  isAddNewContact: PropTypes.bool,
  tpaCompanies: PropTypes.arrayOf(Types.company),
  currentUser: Types.user.isRequired,
}

const defaultProps = {}

const normalizeOptionsForSearchableSelect = (array) => {
  // transform payload values to react-select options
  return array.map((value) => ({
    key: value.companyName,
    value: value.companyID,
  }))
}

function ContactFields({
  keyName,
  isNotEditable,
  isAddNewContact,
  tpaCompanies,
  currentUser,
}) {
  const {
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext()
  const isUsOrCanada =
    values[keyName]?.country === 'US' || values[keyName]?.country === 'CA'

  const defaultCompany = {
    companyName: currentUser.accountName,
    companyID: currentUser.accountID,
  }

  // get alpha sorted list of unique TPAs relevant to contact
  const filteredTPACompanies = uniqBy(tpaCompanies, 'companyID')
    .filter((company) => company.companyID !== defaultCompany.companyID)
    .sort(ascendingAlphaSort('companyName'))

  const companyOptions = normalizeOptionsForSearchableSelect([
    ...filteredTPACompanies,
  ])

  return (
    <>
      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name={`${keyName}.firstName`}
            label="First Name"
            placeholder="Enter first name"
            disabled={isNotEditable}
          />
        </div>
        <div className="one-half column">
          <ACPInputField
            name={`${keyName}.lastName`}
            label="Last Name"
            placeholder="Enter last name"
            disabled={isNotEditable}
          />
        </div>
      </div>
      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name={`${keyName}.email`}
            tooltipContent="The email address is used to log in to the platform. To change the email address please contact the Client Service team. They can be reached Monday - Friday 7:00 AM - 6:00 PM C.T. at (800) 258-7878."
            label="Email"
            type="email"
            placeholder="Enter email"
            disabled={isNotEditable || !isAddNewContact}
          />
        </div>
        <div className="one-half column">
          <ACPInputField
            label="Phone"
            name={`${keyName}.phone`}
            type="tel"
            placeholder="Enter phone"
            disabled={isNotEditable}
            maskOptions={{
              numericOnly: true,
              blocks: [0, 3, 0, 3, 4],
              delimiters: ['(', ')', ' ', '-'],
            }}
          />
        </div>
      </div>
      {[
        ROLE_IDS.thirdPartyAdmin,
        ROLE_IDS.managingMember,
        ROLE_IDS.adminContact,
      ].includes(ROLE_IDS[keyName]) && (
        <div className="row">
          <div className="one-half column">
            {filteredTPACompanies?.length >= 1 &&
            !isNotEditable &&
            keyName == 'thirdPartyAdmin' ? (
              <SearchableSelect
                name={`${keyName}.companyID`}
                type="text"
                placeholder="Select Company"
                label="Company Name *"
                options={companyOptions}
                disabled={isNotEditable || !isAddNewContact}
                onChange={(e) => {
                  setFieldValue(
                    `${keyName}.companyName`,
                    companyOptions.find(
                      (options) => options.value === e.target.value
                    ).key
                  )
                }}
              />
            ) : (
              <ACPInputField
                name={`${keyName}.companyName`}
                value={
                  values[keyName]?.companyName || defaultCompany.companyName
                }
                label="Company Name"
                disabled={true}
              />
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name={`${keyName}.country`}
            label="Country"
            selectOptions={countries}
            enablePlaceholderOption={true}
            placeholder="Select country"
            disabled={isNotEditable}
            readOnly={isNotEditable}
            onChange={() => {
              setFieldValue(`${keyName}.state`, '', false)
              setFieldError(`${keyName}.state`, '')
              setFieldTouched(`${keyName}.state`, false, false)
            }}
          />
        </div>
        <div className="one-half column">
          {isEmpty(values[keyName]?.country) || isUsOrCanada ? (
            <ACPInputField
              name={`${keyName}.state`}
              label="State/Province/Region"
              selectOptions={
                values[keyName]?.country === 'US' ? usStates : canadianProvinces
              }
              enablePlaceholderOption={true}
              placeholder="Select State/Province/Region"
              disabled={isNotEditable}
              readOnly={!isUsOrCanada || isNotEditable}
            />
          ) : (
            <ACPInputField
              name={`${keyName}.state`}
              label="State/Province/Region"
              enablePlaceholderOption={true}
              placeholder="Enter State/Province/Region"
              disabled={isNotEditable}
              readOnly={
                isEmpty(values[keyName]?.country) ||
                isUsOrCanada ||
                isNotEditable
              }
            />
          )}
        </div>
      </div>

      <ACPInputField
        name={`${keyName}.streetAddress`}
        label="Street Address"
        placeholder="Enter street address"
        disabled={isNotEditable}
      />

      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name={`${keyName}.city`}
            label="City"
            placeholder="Enter city"
            disabled={isNotEditable}
          />
        </div>
        <div className="one-half column">
          <ACPInputField
            name={`${keyName}.zipcode`}
            label="Zip Code"
            placeholder="Enter zip code"
            disabled={isNotEditable}
          />
        </div>
      </div>
    </>
  )
}

ContactFields.propTypes = exact(propTypes)
ContactFields.defaultProps = defaultProps

export default React.memo(ContactFields)
