import { Prompt } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import OnExitModal from '../components/OnExitModal'

const ExitGuard = ({ when, navigate, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = () => {
    setModalVisible(false)
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <OnExitModal
        isOpen={modalVisible}
        titleText="Exit without saving?"
        contentText="You are about to exit the contact up workflow without saving. Are you sure
        you want to do this?"
        cancelButtonText="DISMISS"
        confirmButtonText="CONFIRM"
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  )
}

ExitGuard.propTypes = {
  when: PropTypes.bool,
  navigate: PropTypes.func,
  shouldBlockNavigation: PropTypes.func,
}

export default ExitGuard
