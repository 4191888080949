import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Spinner } from 'lp-components'
import { FlowActions } from '../components'
import { RoleAndAssetAssignmentFormFields } from '../forms'
import { Formik, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import { PROFILE_ROLES, ROLE_IDS } from 'config'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import * as Types from 'types'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { isEmpty } from 'lodash'
import AlertIcon from 'images/blue-alert.svg'

const propTypes = {
  fetchAssets: PropTypes.func.isRequired,
  assets: PropTypes.array,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  createContact: PropTypes.func.isRequired,
  sendAuthInvitation: PropTypes.func.isRequired,
  contactDetails: Types.user,
  clearContactDetails: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
}

const defaultProps = {}

const defaultChooseRole = {
  role: '',
  assignments: [],
}

// array of role strings sorted with Managing Member first then alpha

const sortedRoleArray = Object.values(ROLE_IDS).sort((a, b) => {
  if (a == b) return 0
  if (a == PROFILE_ROLES.MANAGING_MEMBER) return -1
  if (b == PROFILE_ROLES.MANAGING_MEMBER) return 1

  if (a < b) return -1
  if (a > b) return 1
})

function RoleAndAssetAssignment({
  fetchAssets,
  assets,
  createContact,
  sendAuthInvitation,
  flashErrorMessage,
  flashSuccessMessage,
  contactDetails,
  clearContactDetails,
  currentUser,
}) {
  const history = useHistory()

  if (isEmpty(contactDetails)) {
    history.push('contact-information')
  }

  useEffect(() => {
    fetchAssets()
    return () => clearContactDetails()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!assets) return <Spinner />

  // all assets mapped to populate dropdown checkbox options
  const assetOptionMapping = assets.map((asset) => {
    return {
      label: asset.name,
      value: asset.assetID,
    }
  })

  const initialValues = {
    roles: [{ ...defaultChooseRole }],
  }

  const roleAndAssetAssignmentValidationSchema = Yup.object({
    roles: Yup.array().of(
      Yup.object({
        role: Yup.string().required('Role is required.'),
        assignments: Yup.array()
          .min(1, 'At least one assignment is required')
          .required('At least one assignment is required'),
      })
    ),
  })

  const handleSubmit = async (values) => {
    const formattedRolesAndAssignments = values.roles.map((role) => ({
      role: role.role,
      relationships: role.assignments
        .filter((assignment) => assignment.value !== '*')
        .map((assignment) => {
          return {
            assetID: assignment.value,
            assetName: assignment.label,
          }
        }),
    }))

    const payload = {
      contactDetails: formattedRolesAndAssignments.find(
        (c) => c.role == 'Administrative Contact'
      )
        ? {
            ...contactDetails,
            isAuthorizedSigner: values.roles.find(
              (c) => c.role == 'Administrative Contact'
            ).isAuthorizedSigner,
          }
        : contactDetails,
      roleNames: formattedRolesAndAssignments,
    }

    try {
      const response = await createContact(payload)

      const authInvitationPayload = {
        contactID: response.contactID,
      }
      await sendAuthInvitation(authInvitationPayload)
      flashSuccessMessage(
        'Thank you for adding this contact to the platform. They will receieve an invitation email to complete their registration.'
      )
      history.push(`/contacts`)
    } catch (e) {
      flashErrorMessage(e.message || 'Something went wrong, please try again.')
    }
  }

  return (
    <div className="role-asset-assignment-form">
      <div className="flow-card-container">
        <Formik
          initialValues={initialValues}
          validationSchema={roleAndAssetAssignmentValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FieldArray name="roles">
                {(arrayHelpers) => (
                  <div>
                    <span className="alertBlueIcon">
                      <img src={AlertIcon} alt="Alert" />
                      Once submitted, this contact will receive a login to the
                      Asset Custody Platform.
                    </span>
                    <br />
                    <br />
                    <br />
                    {values.roles.map((role, index) => (
                      <div key={index}>
                        <RoleAndAssetAssignmentFormFields
                          rolesList={values.roles}
                          assetOptions={assetOptionMapping}
                          index={index}
                          role={role}
                          roleOptions={sortedRoleArray}
                          defaultChooseRole={defaultChooseRole}
                          arrayHelpers={arrayHelpers}
                          currentUser={currentUser}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <FlowActions submitting={isSubmitting} submitContent="Submit" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

RoleAndAssetAssignment.propTypes = exact(propTypes)
RoleAndAssetAssignment.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assets: globalSelectors.assets(state),
    currentUser: globalSelectors.currentUser(state),
    contactDetails: selectors.contactDetails(state),
  }
}

const mapDispatchToProps = {
  fetchAssets: apiActions.fetchAssets,
  createContact: apiActions.createContact,
  clearContactDetails: actions.clearContactDetails,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  sendAuthInvitation: apiActions.sendAuthInvitation,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RoleAndAssetAssignment
)
