import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { LineItemFormElement } from '../components'

const propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const defaultProps = {}

function ToNewTransactionFields({ transactions }) {
  return (
    <div className="card">
      <div className="form-card-content">
        <h4>New Investment</h4>

        {transactions?.map((transaction) => (
          <>
            <hr />
            <LineItemFormElement
              key={transaction.investmentLineItemDirectionID}
              lineItem={transaction}
            />
          </>
        ))}
      </div>
    </div>
  )
}

ToNewTransactionFields.propTypes = exact(propTypes)
ToNewTransactionFields.defaultProps = defaultProps

export default React.memo(ToNewTransactionFields)
