import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import * as actions from './actions'

const reducerKey = 'home'
const slice = `root.${reducerKey}`

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchResolutionItems]: setOnSuccess(
      'currentTaskResolutionItems'
    ),
    [actions.clearResolutionItems]: unsetState('currentTaskResolutionItems'),
    [apiActions.fetchTaskItem]: setOnSuccess('task'),
    [apiActions.fetchRelatedEntities]: setOnSuccess('relatedEntities'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  currentTaskResolutionItems: select('currentTaskResolutionItems'),
  task: select('task'),
  relatedEntities: select('relatedEntities'),
}

export { reducer, selectors, reducerKey }
