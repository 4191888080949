import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { DownloadIconButton, ACPTable } from 'components'
import * as apiActions from 'api-actions'

const propTypes = {
  fetchDocument: PropTypes.func.isRequired,
  investorsData: PropTypes.array.isRequired,
}

const defaultProps = {}

function NestedDownloadDocumentsTable({ fetchDocument, investorsData }) {
  const handleMultipleDocumentDownload = (params) => {
    const purchaseID = params.data.purchaseID
    params.data.downloadDocuments
      .filter((doc) => !doc.isSharedDocument)
      .map((doc) => {
        fetchDocument(
          purchaseID,
          doc.requiredDocumentID,
          doc.requiredDocumentName
        )
      })
  }

  const columnDefs = [
    {
      field: 'clientFBOName',
      headerName: 'Investor Name',
      headerTooltip: 'Investor Name: The name of the Investor.',
      sortable: false,
      cellRenderer: 'agGroupCellRenderer',
      menuTabs: [],
      minWidth: 300,
      resizable: false,
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      type: 'rightAligned',
      menuTabs: [],
      cellRenderer: (params) => {
        return (
          <DownloadIconButton
            label="Download Document(s)"
            onClick={() => handleMultipleDocumentDownload(params)}
          />
        )
      },
    },
  ]

  const documentDownloadRows = ({ data }) => {
    const lineItems = data.downloadDocuments
    const filteredLineItems = lineItems.filter(
      (item) => item.isSharedDocument !== true
    )

    return (
      <table className="document-download-nested-table">
        {filteredLineItems.map((doc) => (
          <tr key={data.investorID}>
            <td>{doc.requiredDocumentName}</td>
            <td className="nested-document-download-cell">
              <DownloadIconButton
                isSecondary={true}
                onClick={() =>
                  fetchDocument(
                    data.purchaseID,
                    doc.requiredDocumentID,
                    doc.requiredDocumentName
                  )
                }
              />
            </td>
          </tr>
        ))}
      </table>
    )
  }

  return (
    <ACPTable
      columnDefs={columnDefs}
      rowData={investorsData}
      isAnyFilterPresent={true}
      domLayout="autoHeight"
      masterDetail={true}
      pagination={investorsData.length > 5}
      paginationPageSize="5"
      overrideContainerStyle={{ height: 'auto' }}
      additionalClassName="ag-gridStyle"
      containerClassName="download-documents-table"
      detailCellRenderer={documentDownloadRows}
      detailCellRendererParams={{}}
      getContextMenuItems={[]}
    />
  )
}

NestedDownloadDocumentsTable.propTypes = exact(propTypes)
NestedDownloadDocumentsTable.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  fetchDocument: apiActions.fetchRequiredDocumentTemplate,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NestedDownloadDocumentsTable
)
