import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import * as Types from 'types'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import * as flashActions from 'redux-flash'
import { Card, RoleDefinitionModal } from 'components'
import { Spinner, SubmitButton } from 'lp-components'
import { unionBy, isEmpty, uniqBy, sortBy, cloneDeep, first } from 'lodash'
import classnames from 'classnames'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { ContactProfileForm, AssetReassignmentModal } from '../forms'
import DeactivateModal from '../forms/DeactivateModal'
import ExitUnsavedChanges from '../components/ExitUnsavedChanges'
import {
  PROFILE_ROLES,
  ROLE_IDS,
  defaultAllOption,
  ROLE_DEFINITION,
} from 'config'
import CheckIcon from 'images/utility-icons/checkmark-white.svg'
import RoleAssetAssignmentFields from '../forms/RoleAssetAssignmentFields'
import RoleAddNewFields from '../forms/RoleAddNewFields'
import {
  convertToAssetIDList,
  getRemainingContactOptions,
  sortRoleNames,
} from 'utils'
import moment from 'moment'

const propTypes = {
  assetOptions: PropTypes.array,
  assets: PropTypes.arrayOf(Types.asset),
  assignedRoles: PropTypes.arrayOf(PropTypes.string),
  clearContactProfile: PropTypes.func.isRequired,
  contact: PropTypes.object,
  contactsList: PropTypes.arrayOf(Types.contact),
  fetchAssets: PropTypes.func.isRequired,
  fetchContactProfile: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  updateContactAssignments: PropTypes.func.isRequired,
  validateContact: PropTypes.func.isRequired,
  sendAuthInvitation: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
}

const defaultProps = {}

function ContactProfile({
  assetOptions,
  assets,
  assignedRoles,
  clearContactProfile,
  contact,
  contactsList,
  fetchAssets,
  fetchContactProfile,
  flashErrorMessage,
  flashSuccessMessage,
  updateContactAssignments,
  validateContact,
  sendAuthInvitation,
  updateUserProfile,
  currentUser,
}) {
  const { contactID } = useParams()

  const [isReadOnly, setIsReadOnly] = useState(true)
  const [showReassignmentModal, setShowReassignmentModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [reassignmentAsset, setReassignmentAsset] = useState([])
  const history = useHistory()

  useEffect(() => {
    fetchContactProfile(contactID)
    fetchAssets()

    return () => clearContactProfile()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const assignments = contact?.roleNames

  const cardRoleSortOrder = sortRoleNames(Object.values(ROLE_IDS))

  // First Check if Managing Member is in the List, then sort alphabetically
  const roleOptions = useMemo(() => {
    if (!contact) return

    const sortedOptions = sortRoleNames(Object.values(ROLE_IDS))
    return sortedOptions.map((role) => {
      const contactMatch = assignments?.find(
        (roleName) => roleName.role === role
      )
      return {
        key: role,
        value: contactMatch
          ? uniqBy(contactMatch.relationships, 'assetID').map(
              (relationship) => relationship
            )
          : [],
      }
    })
  }, [contact])

  const defaultRole = PROFILE_ROLES.MANAGING_MEMBER

  const [currentRole, setCurrentRole] = useState(defaultRole)

  const [isTileEditing, setIsTileEditing] = useState(false)

  const [showRoleForm, setShowRoleForm] = useState(false)

  const [hasEditsPending, setHasEditsPending] = useState(false)

  const [submittingNewRole, setSubmittingNewRole] = useState(false)

  const [cardKeyBeingEdited, setCardKeyBeingEdited] = useState(-1)

  const [showRoleDefinitionModal, setShowRoleDefinitionModal] = useState(false)

  const isRestrictedRole = (role) => {
    if (
      role === ROLE_IDS.managingMember ||
      (role === ROLE_IDS.adminContact &&
        !currentUser.roleNames.find(
          (roleName) => roleName.role == 'Managing Member'
        ))
    ) {
      return true
    } else {
      return false
    }
  }

  if (!contact || !assets) return <Spinner />

  const { contactDetails, lastUpdated, dateAdded, roleNames } = contact
  const target = moment(lastUpdated)
  const sixMonthsAgo = moment().subtract(6, 'months')

  const needsReview = !dateAdded || !lastUpdated || sixMonthsAgo > target

  const isManagingMemberOrAdminContact = roleNames.some(
    (roleName) =>
      roleName.role === ROLE_IDS.managingMember ||
      roleName.role === ROLE_IDS.adminContact
  )

  const contactOptions = getRemainingContactOptions(
    contactDetails,
    contactsList
  )

  const unassignedRoles = sortRoleNames(
    roleOptions
      .map((option) => option.key)
      .filter((role) => !assignedRoles.includes(role))
  )

  const assignedAssetRelationships =
    sortBy(
      assignments
        ?.find((assignment) => {
          return assignment.role === currentRole
        })
        ?.relationships.map((relationship) => {
          return {
            label: relationship.assetName,
            value: relationship.assetID,
            relationshipID: relationship.relationshipID,
          }
        }),
      'key'
    ) || []

  let assetAssignmentValidation

  if (submittingNewRole) {
    assetAssignmentValidation = Yup.object({
      newAssetAssignments: Yup.array().min(
        1,
        'At least one assignment is required'
      ),
      newRole: Yup.string()
        .nullable()
        .required('Required'),
    })
  } else {
    assetAssignmentValidation = Yup.object({}).shape({})
  }

  const assetAssignmentInitialValues = {
    role: currentRole,
    assetsAssigned: isEmpty(assets) ? [] : assignedAssetRelationships,
    newRole: null,
    newAssetAssignments: [],
  }

  const sortedOptions = unionBy(
    uniqBy(assignedAssetRelationships, 'value'),
    sortBy(assetOptions, 'key'),
    'value'
  )

  const checkForManagingMember = (unassignedArray) => {
    if (unassignedRoles.includes('Managing Member')) {
      const formattedRoles = unassignedArray.filter(
        (role) => role !== 'Managing Member'
      )
      return formattedRoles
    } else {
      let formattedRoles = unassignedArray
      return formattedRoles
    }
  }

  const orderFormattedAssignments = assignments.sort(function(a, b) {
    return cardRoleSortOrder.indexOf(a.role) - cardRoleSortOrder.indexOf(b.role)
  })

  const formattedUnassignedRoles = checkForManagingMember(unassignedRoles)

  const checkForAdminContact = (unassignedArray) => {
    if (
      unassignedRoles.includes('Administrative Contact') &&
      !currentUser.roleNames.find(
        (roleName) => roleName.role == 'Managing Member'
      )
    ) {
      const formattedRoles = unassignedArray.filter(
        (role) => role !== 'Administrative Contact'
      )
      return formattedRoles
    } else {
      let formattedRoles = unassignedArray
      return formattedRoles
    }
  }

  const unassignedRolesFormatted = checkForAdminContact(
    formattedUnassignedRoles
  )

  const handleNewRole = async (data, resetForm) => {
    setHasEditsPending(false)
    setIsReadOnly(true)
    const payload = {
      contactDetails: {
        contactID: contactDetails.contactID,
        accountID: contactDetails.accountID,
      },
      roleNames: [
        {
          role: data.newRole,
          relationships: data.newAssetAssignments.map((assignment) => {
            return {
              assetID: assignment.value,
              assetName: assignment.label,
            }
          }),
        },
      ],
    }

    try {
      await updateContactAssignments(payload)
      await fetchContactProfile(contactID)
      setShowRoleForm(false)
      setIsTileEditing(false)
      await resetForm({ ...assetAssignmentInitialValues })
      setSubmittingNewRole(false)
      flashSuccessMessage('The contact has been successfully added.')
    } catch (e) {
      resetForm({ ...assetAssignmentInitialValues })
      flashErrorMessage(e.message || 'Something went wrong, please try again.')
    }
  }

  const handleAssetUpdate = async (data, resetForm) => {
    setHasEditsPending(false)
    try {
      const remapDataBackForSubmission = data.assetsAssigned.map((asset) => {
        return {
          assetID: asset.value,
          assetName: asset.label,
        }
      })

      const formatDataByID = convertToAssetIDList(remapDataBackForSubmission)

      const filterOutAll = formatDataByID.filter(
        (assignedAsset) => assignedAsset !== defaultAllOption.value
      )

      const currentAssetsArray = filterOutAll.map((assignedAssetID) => {
        const assignmentObject = sortedOptions.find(
          (option) => option.value === assignedAssetID
        )
        return {
          assetID: assignedAssetID,
          assetName: assignmentObject.key,
          relationshipID: assignmentObject.relationshipID || '',
          isRemoved: false,
        }
      })

      let removedAssetsArray = []

      if (assignments.find((assignment) => assignment.role === currentRole)) {
        const oldAssignmentsToRole =
          uniqBy(
            assignments.find((assignment) => assignment.role === currentRole)
              .relationships,
            'assetID'
          ) || []

        const removedAssets = oldAssignmentsToRole.filter(
          (oldAssignment) =>
            !currentAssetsArray
              .map((currentAssignment) => currentAssignment.assetID)
              .includes(oldAssignment.assetID)
        )

        removedAssetsArray = removedAssets.map((removedAsset) => ({
          ...removedAsset,
          isRemoved: true,
        }))
      }

      const payloadSchema = {
        contactDetails: {
          contactID: contactDetails.contactID,
          accountID: contactDetails.accountID,
        },
        roleNames: [
          {
            role: currentRole,
            relationships: [],
          },
        ],
      }

      let selectedAssetsPayload = cloneDeep(payloadSchema)
      selectedAssetsPayload.roleNames[0].relationships = currentAssetsArray

      let deselectedAssetsPayload = cloneDeep(payloadSchema)
      deselectedAssetsPayload.roleNames[0].relationships = removedAssetsArray

      let deselectedAssetsValidationObject = {}
      if (!isEmpty(removedAssetsArray)) {
        deselectedAssetsValidationObject = await validateContact(
          deselectedAssetsPayload
        )
      }

      const { roleNames: deselectedContactRole } =
        deselectedAssetsValidationObject || {}
      const removedAssetsForReassignment = first(deselectedContactRole)
      const hasProblematicAssignments = !isEmpty(
        removedAssetsForReassignment?.relationships
      )

      if (hasProblematicAssignments) {
        await updateContactAssignments(selectedAssetsPayload)
        setReassignmentAsset(removedAssetsForReassignment)
        setShowReassignmentModal(true)
      } else {
        await updateContactAssignments(selectedAssetsPayload)
        await fetchContactProfile(contactID)
        setIsTileEditing(false)
        setCardKeyBeingEdited(-1)
        await resetForm({ ...assetAssignmentInitialValues })
        flashSuccessMessage('The contact role assignments have been updated.')
      }
    } catch (e) {
      resetForm({ ...assetAssignmentInitialValues })
      flashErrorMessage(
        e.errors?.message || 'Something went wrong, please try again.'
      )
    }
  }

  const handleSubmit = async (data, resetForm) => {
    if (data.newRole === null) {
      await handleAssetUpdate(data, resetForm)
    } else {
      await handleNewRole(data, resetForm)
    }
  }

  const handleReview = async () => {
    setSubmitting(true)
    if (!dateAdded) {
      const authInvitationPayload = {
        contactID: contactID,
      }
      await sendAuthInvitation(authInvitationPayload)
    }
    await updateUserProfile(contactDetails)
    history.push('/contacts')
    flashSuccessMessage('Contact confirmed and platform login sent.')
    setSubmitting(false)
  }

  const shouldBlockNavigation = () => {
    if (hasEditsPending) {
      return true
    }
    return false
  }

  return (
    <>
      <ContactProfileForm />
      <ExitUnsavedChanges
        when={hasEditsPending}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={shouldBlockNavigation}
      />
      <Card className="contact-profile-card">
        <div className="profile-contents-wrapper">
          <div className="contact-profile-container">
            <Formik
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm)
              }}
              validationSchema={assetAssignmentValidation}
              initialValues={assetAssignmentInitialValues}
              enableReinitialize={true}
            >
              {({ values, setFieldValue, resetForm, isSubmitting }) => (
                <Form>
                  <Card className="profile-contents-card">
                    <div className="role-asset-assignment-card">
                      <h4>Roles and Asset Assignments</h4>
                      <div className="roles-assets-relationships">
                        <div className="roles-assets-relationships-container">
                          {orderFormattedAssignments.map((role, i) => (
                            <RoleAssetAssignmentFields
                              key={i}
                              cardKey={i}
                              role={role.role}
                              isRoleRestricted={isRestrictedRole(role.role)}
                              assetList={role.relationships.map(
                                (a) => a.assetName
                              )}
                              setCurrentRole={setCurrentRole}
                              setFieldValue={setFieldValue}
                              isReadOnly={isReadOnly}
                              setIsReadOnly={setIsReadOnly}
                              resetForm={resetForm}
                              isSubmitting={isSubmitting}
                              assetAssignmentInitialValues={
                                assetAssignmentInitialValues
                              }
                              values={values}
                              currentRole={currentRole}
                              assetOptions={assetOptions}
                              setIsTileEditing={setIsTileEditing}
                              assignments={assignments}
                              contactDetails={contactDetails}
                              isTileEditing={isTileEditing}
                              setHasEditsPending={setHasEditsPending}
                              setCardKeyBeingEdited={setCardKeyBeingEdited}
                              cardKeyBeingEdited={cardKeyBeingEdited}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className="create-new-role-container">
                    <div className="create-new-role-content">
                      <div
                        className={classnames('create-new-role-form-wrapper', {
                          'is-hidden': !showRoleForm,
                        })}
                      >
                        <RoleAddNewFields
                          unassignedRoles={unassignedRoles}
                          assetOptions={assetOptions}
                          setShowRoleForm={setShowRoleForm}
                          setIsTileEditing={setIsTileEditing}
                          initialValues={assetAssignmentInitialValues}
                          formattedRoles={unassignedRolesFormatted}
                          isSubmitting={isSubmitting}
                          setHasEditsPending={setHasEditsPending}
                          setSubmittingNewRole={setSubmittingNewRole}
                          setIsReadOnly={setIsReadOnly}
                          isReadOnly={isReadOnly}
                        />
                      </div>
                      {!isTileEditing && unassignedRolesFormatted.length > 0 && (
                        <div className="new-role-btn-wrapper">
                          <button
                            type="button"
                            className={classnames('create-new-role-btn ', {
                              'is-disabled': showRoleForm,
                            })}
                            onClick={() => {
                              setShowRoleForm(true)
                              setIsTileEditing(true)
                              setSubmittingNewRole(true)
                              resetForm({ ...assetAssignmentInitialValues })
                            }}
                          >
                            <span className="create-new-role-btn-text">
                              + Add New Role
                            </span>
                          </button>
                        </div>
                      )}
                      <span className="learn-more-text">
                        <button
                          className="modal-button-link"
                          type="button"
                          onClick={() => setShowRoleDefinitionModal(true)}
                        >
                          Learn more about the roles.
                        </button>
                      </span>
                    </div>
                  </div>

                  {showReassignmentModal && (
                    <AssetReassignmentModal
                      contactID={contactID}
                      contactOptions={contactOptions}
                      reassignmentAsset={reassignmentAsset}
                      onSubmit={() => {
                        setShowReassignmentModal(false)
                        fetchContactProfile(contactID)
                        setCardKeyBeingEdited(-1)
                        setIsTileEditing(false)
                      }}
                      onClose={() => {
                        setShowReassignmentModal(false)
                        resetForm({ ...assetAssignmentInitialValues })
                        fetchContactProfile(contactID)
                      }}
                      setIsReadOnly={() => setIsReadOnly(true)}
                    />
                  )}
                  {showDeactivateModal && (
                    <DeactivateModal
                      onClose={() => setShowDeactivateModal(false)}
                      contact={contact}
                      contactOptions={contactOptions}
                    />
                  )}
                  {showRoleDefinitionModal && (
                    <RoleDefinitionModal
                      onClose={() => setShowRoleDefinitionModal(false)}
                      title="Definition of Roles"
                      roles={ROLE_DEFINITION}
                    />
                  )}
                </Form>
              )}
            </Formik>
            {needsReview && (
              <div className="review-buttons">
                <hr />
                {!isManagingMemberOrAdminContact && (
                  <button
                    type="button"
                    className={'button-secondary'}
                    onClick={() => setShowDeactivateModal(true)}
                  >
                    Deactivate
                  </button>
                )}
                <SubmitButton
                  className="button-primary"
                  submitting={submitting}
                  onClick={handleReview}
                >
                  <>
                    <img
                      style={{ height: '14px', width: '14px' }}
                      src={CheckIcon}
                      alt="Check"
                    ></img>
                    Yes, this is correct
                  </>
                </SubmitButton>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  )
}

ContactProfile.propTypes = propTypes
ContactProfile.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assets: globalSelectors.assets(state),
    assetOptions: globalSelectors.assetOptions(state),
    assignedRoles: selectors.assignedRoles(state),
    contact: selectors.contact(state),
    contactsList: selectors.contactsList(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  fetchAssets: apiActions.fetchAssets,
  fetchContactProfile: apiActions.fetchContactProfile,
  clearContactProfile: actions.clearContactProfile,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
  updateContactAssignments: apiActions.updateContactAssignments,
  validateContact: apiActions.validateContact,
  sendAuthInvitation: apiActions.sendAuthInvitation,
  updateUserProfile: apiActions.updateUserProfile,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactProfile
)
