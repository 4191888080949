import React from 'react'
import PropTypes from 'prop-types'
import { FlowActions } from '../components'
import { DocumentUploadInput } from 'components'
import { Formik, Form } from 'formik'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  needsResubmission: PropTypes.bool.isRequired,
  failedSubmissionDocuments: PropTypes.array.isRequired,
  investorsData: PropTypes.array.isRequired,
}
const defaultProps = {}

function DocumentUploadForm({
  handleSubmit,
  needsResubmission,
  failedSubmissionDocuments,
  investorsData,
}) {
  const initialValues = investorsData.reduce((acc, investor) => {
    if (investor.purchaseID) {
      acc[investor.purchaseID] = investor.requiredDocuments[0]
    }
    return acc
  }, {})
  const isPurchaseUpload = true
  return (
    <div className="form-card-content">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ setFieldValue, isSubmitting, errors, values }) => {
          return (
            <Form className="asset-form asset-details-form">
              <div className="card">
                <h4>Upload Signature Page</h4>
                <p className="help-text-upload med-12 ">
                  Now that you’ve executed the purchase(s), you can upload the
                  signature page of each investor’s fully executed subscription
                  document, if available. Otherwise continue to the next step. 
                </p>
                <div className="mtc-flow-line">
                  <hr />
                </div>
                <div className="investorName">Investor(s)</div>
                {investorsData.map((investor) => {
                  return (
                    <>
                      {investor.requiredDocuments.map((doc) => {
                        return (
                          <DocumentUploadInput
                            key={doc.requiredDocumentID}
                            keyName={`${investor.purchaseID}`}
                            setFieldValue={setFieldValue}
                            isPurchaseUpload={isPurchaseUpload}
                            document={{
                              ...doc,
                              requiredDocumentName: investor.clientFBOName.toUpperCase(),
                            }}
                            error={errors[investor.purchaseID]}
                            isSubmitted={
                              needsResubmission &&
                              Boolean(values[investor.purchaseID]) &&
                              !failedSubmissionDocuments.includes(
                                investor.purchaseID
                              )
                            }
                          />
                        )
                      })}
                    </>
                  )
                })}
              </div>
              <FlowActions prevStep="templates" submitting={isSubmitting} />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
DocumentUploadForm.propTypes = propTypes
DocumentUploadForm.defaultProps = defaultProps
export default DocumentUploadForm
