import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { SignatureForm } from '../forms'
import { useHistory } from 'react-router-dom'
import * as flashActions from 'redux-flash'
import { selectors } from '../reducer'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  exchangeConfirmation: PropTypes.object.isRequired,
  updateExchangeConfirmation: PropTypes.func.isRequired,
}

const defaultProps = {}

function Confirmation({
  exchangeConfirmation,
  updateExchangeConfirmation,
  flashErrorMessage,
  flashSuccessMessage,
}) {
  const history = useHistory()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  return (
    <div className="flow-card-container">
      <div className="card">
        <div className="form-card-content">
          <div>
            <h4>Acknowledgments</h4>
            <h4>
              Please review the following acknowledgments below and e-sign at
              the bottom
            </h4>
          </div>
          <div className="fancy-ordered-list">
            <div className="bulleted-circle-item">
              <div className="bulleted-circle-contents">
                I confirm that this exchange has been executed and the new
                investment is properly registered to the account owner per
                “Investor Registration” on the prior screen.
              </div>
            </div>
            <div className="bulleted-circle-item">
              <div className="bulleted-circle-contents">
                When available, I will forward statements to Inspira that
                reflect both sides of this exchange.
              </div>
            </div>
            <div className="bulleted-circle-item">
              <div className="bulleted-circle-contents">
                I understand that in order for Inspira to continue to custody
                this asset, we must provide valuation updates on a regular basis
                - at least annually. Valuation updates can be submitted for
                updating through this platform.
              </div>
            </div>
          </div>
          <hr className="has-top-margin" />
          <SignatureForm
            handleSubmit={async (params) => {
              try {
                const payload = {
                  ...exchangeConfirmation,
                  eSignFirstName: params.firstName,
                  eSignLastName: params.lastName,
                  eSignTitle: params.title,
                  eSignDate: new Date(Date.now()),
                  status: 'Confirmed',
                }
                await updateExchangeConfirmation(payload)
                flashSuccessMessage('We received your exchange confirmation.')
                history.push('/home')
              } catch (e) {
                flashErrorMessage(
                  e.errors.message || 'Something went wrong, please try again.'
                )
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

Confirmation.propTypes = propTypes
Confirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    exchangeConfirmation: selectors.exchangeConfirmation(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  updateExchangeConfirmation: apiActions.updateExchangeConfirmation,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Confirmation
)
