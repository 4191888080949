import React from 'react'
import { Redirect } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import OnboardingLayout from './OnboardingLayout'

const RolesAuthorizedLayout = ({ currentUser, children }) => {
  if (
    currentUser?.roleNames?.length < 1 &&
    currentUser?.permissions?.addAssets === false
  ) {
    return <Redirect to="/unauthorized-profile" />
  } else if (
    currentUser?.roleNames?.length < 1 &&
    currentUser?.permissions?.addAssets === true
  ) {
    return <OnboardingLayout />
  } else return children
}

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}
const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RolesAuthorizedLayout
)
