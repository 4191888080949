import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { HelpDetails } from 'components'
import { useHistory } from 'react-router'
import CloseCircleWhite from 'images/close-circle-white.svg'
import InspiraLogo from 'images/InspiraLogo.svg'

const propTypes = {
  title: PropTypes.string.isRequired,
}

const defaultProps = {}

function InviteInvesorsHeader({ title }) {
  const history = useHistory()
  return (
    <header className="add-asset-header no-print">
      <div className="asset-header-content">
        <img src={InspiraLogo} className="logo" alt="inspira logo" />
        <h3 id="header-title">{title}</h3>
        <div className="add-asset-header-button-group">
          <HelpDetails isWizardFlow={true} />
          <div>
            <img
              className="add-asset-close-circle"
              src={CloseCircleWhite}
              alt="exit"
              onClick={() => history.push('/home')}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

InviteInvesorsHeader.propTypes = exact(propTypes)
InviteInvesorsHeader.defaultProps = defaultProps

export default pure(InviteInvesorsHeader)
