import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import { DocumentUploadForm } from '../forms'
import * as apiActions from 'api-actions'
import { createFileErrors, bulkPromiseUploads } from 'utils'
import { Redirect, useHistory } from 'react-router'
import { selectors } from '../reducer'
import { isEmpty } from 'lodash'
import { Spinner } from 'lp-components'
import * as actions from '../actions'
import { selectors as globalSelectors } from 'global-reducer'

const propTypes = {
  createDocument: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  fetchInvestorsData: PropTypes.func.isRequired,
  purchaseIDs: PropTypes.array.isRequired,
  investorsData: PropTypes.array.isRequired,
  storePurchaseIDs: PropTypes.func.isRequired,
  clearInvestorsData: PropTypes.func.isRequired,
}

const defaultProps = {}

function DocumentUpload({
  createDocument,
  flashErrorMessage,
  fetchInvestorsData,
  purchaseIDs,
  investorsData,
  storePurchaseIDs,
  clearInvestorsData,
}) {
  const history = useHistory()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (isEmpty(purchaseIDs)) return <Redirect to="/transactions/pending" />
  const [
    failedSubmissionDocuments,
    setFailedSubmissionDocuments,
  ] = React.useState([])
  const [needsResubmission, setNeedsResubmission] = React.useState(false)

  useEffect(() => {
    fetchInvestorsData(purchaseIDs.toString(), 'upload')
    return () => {
      clearInvestorsData()
    }
  }, [])

  if (!investorsData || !investorsData[0].requiredDocuments) return <Spinner />

  const handleSubmit = async (values, { setErrors }) => {
    const filterCondition = needsResubmission
      ? (e) =>
          e[1].fileName &&
          e[1].documentID &&
          failedSubmissionDocuments.includes(e[1].documentID)
      : (e) => e[1].fileName && e[1].documentID

    const fileUploadPromises = Object.entries(values)
      .filter(filterCondition)
      .map((e) => ({
        purchaseID: e[0],
        fileName: e[1].fileName,
        fileContents: e[1].fileContents,
        fileType: e[1].fileType,
        requiredDocumentID: e[1].requiredDocumentID,
        documentID: e[1].documentID,
      }))
      .map((doc) => {
        const formData = new FormData()
        formData.append(
          'fileContents',
          new Blob([doc.fileContents], {
            type: doc.fileType,
          }),
          doc.fileName
        )
        return createDocument(doc, formData)
      })

    const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromises)

    if (rejectedUploads.length) {
      const errors = createFileErrors(rejectedUploads)
      setErrors(errors)
      setFailedSubmissionDocuments(Object.keys(errors))
      setNeedsResubmission(true)
    } else {
      try {
        storePurchaseIDs(purchaseIDs)
        history.push(`/bulk-confirmations/details`)
      } catch (e) {
        flashErrorMessage(
          e.errors.message || 'Something went wrong, please try again.'
        )
      }
    }
  }

  return (
    <div className="flow-card-container">
      <DocumentUploadForm
        handleSubmit={handleSubmit}
        needsResubmission={needsResubmission}
        failedSubmissionDocuments={failedSubmissionDocuments}
        investorsData={investorsData}
      />
    </div>
  )
}

DocumentUpload.propTypes = propTypes
DocumentUpload.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    purchaseIDs: globalSelectors.purchaseIDs(state),
    investorsData: selectors.investorsData(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  createDocument: apiActions.createRequiredDocumentForBulkPurchase,
  fetchInvestorsData: apiActions.fetchInvestorsData,
  clearInvestorsData: actions.clearInvestorsData,
  storePurchaseIDs: actions.storePurchaseIDs,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DocumentUpload
)
