import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import classnames from 'classnames'
import useOnclickOutside from 'react-cool-onclickoutside'
import { NotificationsItem } from 'components'

const propTypes = {
  showList: PropTypes.bool.isRequired,
  closeList: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
  notifications: null,
}

function NotificationsList({ showList, closeList, notifications }) {
  const ref = useOnclickOutside(closeList, {
    ignoreClass: 'notification-badge',
  })

  return (
    <div
      ref={ref}
      className={classnames('notifications-list-wrapper', {
        hidden: !showList,
      })}
    >
      <header>
        <h3 className="is-marginless">My Notifications</h3>
      </header>
      {!notifications?.length && (
        <p className="empty-notifications">No notifications</p>
      )}
      {/* <h4>Notifications</h4> */}
      <List dense={true} component="nav">
        {notifications?.map((notification, index) => (
          <div key={notification.notificationID}>
            <NotificationsItem
              notification={notification}
              isRead={notification.isRead}
            />
            {index !== notifications.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </div>
  )
}

NotificationsList.propTypes = exact(propTypes)
NotificationsList.defaultProps = defaultProps

export default React.memo(NotificationsList)
