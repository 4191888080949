import React, { useState } from 'react'
import exact from 'prop-types-exact'
import { CurrencyInput, LabelWithTooltip, ACPInputField } from 'components'

const propTypes = {}

const defaultProps = {}

function ValueDifferenceFields() {
  const [valueMethodRequired, setValueMethodRequired] = useState(false)
  const [noLabelModifier, setNoLabelModifier] = useState(true)

  return (
    <div className="card">
      <div className="form-card-content">
        <h4>Value Difference (If Applicable)</h4>
        <div className="value-difference-form-container">
          <div className="row">
            <div className="one-half column">
              <CurrencyInput
                name="valueDifference.value"
                label="Value"
                defaultValue={0}
                labelComponent={LabelWithTooltip}
                tooltipContent="The difference between exchange out and exchange in market value."
                onChange={(e) => {
                  if (e.target.value !== '0.00') {
                    setValueMethodRequired(true)
                    setNoLabelModifier(false)
                  } else {
                    setValueMethodRequired(false)
                    setNoLabelModifier(true)
                  }
                }}
              />
            </div>
            <div className="one-half column">
              <ACPInputField
                name="valueDifference.method"
                label="How will Inspira receive this difference?"
                selectOptions={[
                  { key: 'Wire Transfer', value: 'Wire' },
                  { key: 'Check', value: 'Check' },
                  { key: 'ACH', value: 'ACH' },
                ]}
                enablePlaceholderOption={true}
                placeholder="Select"
                required={valueMethodRequired}
                noLabelModifier={noLabelModifier}
              />
            </div>
          </div>
          <div className="row">
            <ACPInputField
              name="valueDifference.comment"
              label="Comments Regarding this Transaction"
              placeholder="Enter comment here..."
              textarea
              required={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ValueDifferenceFields.propTypes = exact(propTypes)
ValueDifferenceFields.defaultProps = defaultProps

export default React.memo(ValueDifferenceFields)
