import { configureApi, http } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
import { getSessionToken } from './auth'
import { parseJSONValues } from 'utils'

// Configure lp-redux-api middleware

// This function will be passed the request options before every request.
// You can use it to set additional options or override existing ones.
function before() {
  return {
    bearerToken: getSessionToken(),
  }
}

// Any transformations of successful responses can go here.
// By default, we pull out the value nested at `data.attributes`.

// Any transformations of failed responses can go here.
function onFailure(res) {
  return parseJSONValues(res)
}

export const config = {
  root: process.env.API_URL,
  before,
  onFailure,
  mode: 'cors',
}

export const middleware = configureMiddleware(http, config)
export const api = configureApi(config)
