import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { CapitalCommitmentsTable } from '../components'
import TabbedHeader from '../components/TabbedHeader'

const propTypes = {
  capitalCommitments: PropTypes.arrayOf(PropTypes.object),
  fetchCapitalCommitments: PropTypes.func,
}

const defaultProps = {}

function CapitalCommitments({ capitalCommitments, fetchCapitalCommitments }) {
  useEffect(() => {
    fetchCapitalCommitments()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  return (
    <TabbedHeader>
      <div className="container">
        <CapitalCommitmentsTable capitalCommitments={capitalCommitments} />
      </div>
    </TabbedHeader>
  )
}

CapitalCommitments.propTypes = propTypes
CapitalCommitments.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    capitalCommitments: selectors.capitalCommitments(state),
  }
}

const mapDispatchToProps = {
  fetchCapitalCommitments: apiActions.fetchCapitalCommitments,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CapitalCommitments
)
