import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { FlowStatusTracker } from 'components'
import { FlashMessageContainer, Spinner } from 'lp-components'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { selectors as globalSelectors } from 'global-reducer'
import { FUNDING_INSTRUCTIONS_STAGES } from 'flow-stages'
import * as apiActions from 'api-actions'
import { selectors } from './reducer'
import FundingInstructionHeader from './components/FundingInstructionHeader'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
  currentUser: PropTypes.object,
  fundingInstructions: PropTypes.object,
  fetchFundingInstructions: PropTypes.func.isRequired,
}

const defaultProps = {
  currentUser: null,
}

function Layout({
  flashMessages,
  currentStage,
  children,
  currentUser,
  fundingInstructions,
  fetchFundingInstructions,
}) {
  if (!currentUser?.firstName) return <Spinner />
  let { assetID } = useParams()

  useEffect(() => {
    fetchFundingInstructions(assetID)
    /*return () => {
      clearFundingInstructions()
    }*/
  }, [])

  return (
    <>
      <FlashMessageContainer messages={flashMessages} />
      <FundingInstructionHeader title={'Update Funding Instructions'} />
      <div className="add-asset-layout-wrapper contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title">{fundingInstructions?.assetName}</h1>
        </div>
        <div className="content-div">
          <FlowStatusTracker
            stages={FUNDING_INSTRUCTIONS_STAGES}
            currentStage={currentStage}
          />
          {children}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    currentUser: globalSelectors.currentUser(state),
    fundingInstructions: selectors.fundingInstructions(state),
  }
}

const mapDispatchToProps = {
  fetchFundingInstructions: apiActions.fetchAssetFundingInstructions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
