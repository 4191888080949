import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useHistory } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { Modal, SubmitButton } from 'lp-components'
import { ACPInputField } from 'components'
import { startCase } from 'lodash'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { CONTACT_CLASSIFICATION } from 'config'
import { formatReplacementNames } from 'utils'

const propTypes = {
  contact: PropTypes.object.isRequired,
  contactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  contactType: PropTypes.object,
  deleteContact: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
const defaultProps = {}

function DeactivateModal({
  contact,
  contactOptions,
  contactType,
  deleteContact,
  flashErrorMessage,
  flashSuccessMessage,
  onClose,
}) {
  const history = useHistory()
  const contactRoles = contact.roleNames.map((roleName) => roleName.role)
  const isACPUser = contactType === CONTACT_CLASSIFICATION.ACP_USER

  // Dynamically create the list of initial values from the contactRoles that
  // are assigned to the contact
  const initialValues = contactRoles.reduce(
    (a, v) => ({ ...a, [formatReplacementNames(v)]: '' }),
    {}
  )

  const handleDeactivate = async (values) => {
    let payload = []

    // For each contact that will inherit the new role's assets, find the
    // role in the roleNames array and set the relationshipID to an empty
    // string and the isRemoved property to false.
    Object.entries(values).map(([key, value]) => {
      const replacementAccountID = contactOptions.find(
        (option) => option.value === value
      ).accountID

      const roleObject = contact.roleNames.find((obj) => {
        return startCase(obj.role) === startCase(key)
      })
      roleObject.relationships.forEach((relationship) => {
        relationship.isRemoved = false
      })

      // Add the object for the contact that will inherit the deleted
      // contact's role and assets to the payload array
      const contactDetails = {
        accountID: replacementAccountID,
        contactID: value,
      }
      payload.push({
        contactDetails: contactDetails,
        roleNames: [roleObject],
      })
    })

    try {
      await deleteContact(payload)
      flashSuccessMessage('The contact has been successfully deactivated.')
      history.push('/contacts')
    } catch (e) {
      flashErrorMessage(e.message || 'Something went wrong, please try again.')
    }
  }

  const deactivateValidation = Yup.object().shape(
    contactRoles.reduce(
      (a, v) => ({
        ...a,
        [formatReplacementNames(v)]: Yup.string().required(
          `In order to deactivate this contact, you are required to select a replacement ${v.toLowerCase()}.`
        ),
      }),
      {}
    )
  )

  return (
    <Modal onClose={onClose}>
      <h4>Deactivate Contact</h4>
      <p>
        {`This contact will no longer be able to ${
          isACPUser ? 'access the Asset Custody Platform' : 'manage this asset'
        }. Are you sure you want to deactivate this contact?`}
      </p>
      <button
        className="modal-close"
        type="button"
        onClick={() => {
          onClose()
        }}
      >
        ×
      </button>
      <Formik
        onSubmit={handleDeactivate}
        validationSchema={deactivateValidation}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => (
          <Form>
            {contactRoles.map((role) => (
              <ACPInputField
                key={role}
                name={formatReplacementNames(role)}
                label={`Contact to Replace ${startCase(role)} Role `}
                selectOptions={contactOptions}
                enablePlaceholderOption={true}
                placeholder="Select"
              />
            ))}
            <hr />
            <div className="button-wrapper">
              <button
                className="button-secondary"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              <SubmitButton
                className="button-primary"
                submitting={isSubmitting}
              >
                Yes, Deactivate Contact
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

DeactivateModal.propTypes = exact(propTypes)
DeactivateModal.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    contactType: selectors.contactType(state),
  }
}

const mapDispatchToProps = {
  deleteContact: apiActions.deleteContact,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeactivateModal
)
