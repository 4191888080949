import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player/youtube'
import classnames from 'classnames'

const propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
}

const defaultProps = {
  width: '100%',
  height: '101%', //increase the height of video image to fit the card(removing the default black line beneath)
}

// a component to override the play icon over the video thumbnail
// note: this is only applicable when react player is in light mode
function PlayIcon() {
  return (
    <div className="react-player__shadow">
      <div className="react-player__play-icon" />
    </div>
  )
}
function YoutubeVideo({ url, width, height, className, ...rest }) {
  return (
    <ReactPlayer
      url={url}
      width={width}
      height={height}
      light={true} // enabling light mode renders a thumbnail, where on thumbnail click loads the player
      playing={true} // must set to true when light mode is enabled, so that the video plays on thumbnail click/video on load
      playIcon={<PlayIcon />}
      controls={true}
      className={classnames('react-player', className)}
      {...rest}
    />
  )
}

YoutubeVideo.propTypes = propTypes

YoutubeVideo.defaultProps = defaultProps

export default YoutubeVideo
