import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useDropzone } from 'react-dropzone'
import DocumentsIcon from 'images/acp_upload_icon.svg'
import DeleteIcon from 'images/delete.svg'
import { isEmpty, first, get } from 'lodash'
import classnames from 'classnames'
import {
  ACCEPTED_FILE_TYPES,
  INVALID_FILE_TYPE_ERROR,
  MAX_FILE_SIZE_IN_BYTES,
} from 'config'
import { formatBytes } from 'utils'
import Tooltip from './Tooltip'
import LabelWithTooltip from './LabelWithTooltip'

const propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.array,
  acceptedFileTypeNames: PropTypes.array,
  error: PropTypes.string,
  document: PropTypes.object.isRequired,
  keyName: PropTypes.string,
  isNotEditable: PropTypes.bool,
  hideLabel: PropTypes.bool,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  isSubmitted: PropTypes.bool,
  isPurchaseUpload: PropTypes.bool,
  isPaymentRecordUpload: PropTypes.bool,
  tooltipContent: PropTypes.string,
}

const defaultProps = {
  acceptedFileTypes: ACCEPTED_FILE_TYPES,
  acceptedFileTypeNames: ['PDF', 'CSV', '.DOC', '.DOCX', 'XLSX', 'XLS'],
  error: null,
  isNotEditable: false,
  multiple: false,
}

function DocumentUploadInput({
  setFieldValue,
  acceptedFileTypes,
  acceptedFileTypeNames,
  error,
  document,
  isNotEditable,
  hideLabel,
  keyName,
  multiple,
  label,
  isSubmitted,
  isPurchaseUpload,
  isPaymentRecordUpload,
  tooltipContent,
}) {
  const {
    documentID,
    requiredDocumentID,
    name,
    requiredDocumentName,
    fileName,
    fileSize,
    isDocumentRequired,
    requiredDocumentNotes,
  } = document
  const uploadedFileExists = !isEmpty(documentID)
  const [uploadedFiles, setUploadedFiles] = useState(
    uploadedFileExists
      ? [
          {
            name: fileName,
            size: fileSize,
          },
        ]
      : []
  )

  // subscribe to uploadedFileExists for possible stale closures
  useEffect(() => {
    if (uploadedFileExists)
      setUploadedFiles([
        {
          name: fileName,
          size: fileSize,
        },
      ])
  }, [uploadedFileExists])

  const fieldName = keyName ? keyName : requiredDocumentID

  const onDrop = useCallback((acceptedFiles) => {
    const files = acceptedFiles.map((file, index) => {
      const fileData = {
        name: file.name,
        size: formatBytes(file.size),
        fieldId: index,
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        // Do whatever you want with the file contents
        const fieldName = keyName || requiredDocumentID || `files[${index}]`
        setFieldValue(fieldName, {
          documentID: requiredDocumentID,
          requiredDocumentID: requiredDocumentID,
          fileName: file.name,
          fileContents: e.currentTarget.result,
          fileSize: formatBytes(file.size),
          isDocumentRequired,
          fileType: file.type,
          requiredDocumentName,
        })
      }
      reader.readAsArrayBuffer(file)
      return fileData
    })
    setUploadedFiles(files)
  }, [])

  const removeFile = ({ index, fieldId }) => {
    const name = multiple ? `files[${fieldId}]` : fieldName
    setFieldValue(name, '')
    const files = [...uploadedFiles]
    files.splice(index, 1)
    setUploadedFiles(files)
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: multiple ? 0 : 1,
    multiple,
    accept: acceptedFileTypes?.join(', '),
    maxSize: MAX_FILE_SIZE_IN_BYTES,
  })
  const hasError = !isEmpty(error)

  if (isNotEditable && !uploadedFileExists) return null

  const isFileTooLarge =
    get(first(fileRejections), 'file.size') > MAX_FILE_SIZE_IN_BYTES

  const isFileTypeInvalid =
    get(first(get(first(fileRejections), 'errors')), 'code') ===
    INVALID_FILE_TYPE_ERROR

  const formattedMaxFileSize = formatBytes(MAX_FILE_SIZE_IN_BYTES)

  const documentName = name ?? requiredDocumentName

  return (
    <div
      className={classnames('document-upload-container', {
        multiple: multiple,
        isSubmitted: isSubmitted,
        'purchase-upload-container': isPurchaseUpload,
      })}
      name={requiredDocumentID}
    >
      {/* <div className='custom-border'></div> */}
      {!isSubmitted && !hideLabel && !isPaymentRecordUpload && (
        <div className="document-upload-label">
          <div className="document-upload-tooltip">
            {documentName}
            {requiredDocumentNotes && (
              <Tooltip
                showIcon={true}
                showText={true}
                visible={true}
                imageAlt={`${documentName} Tooltip`}
              >
                {requiredDocumentNotes}
              </Tooltip>
            )}
          </div>
          {!isPurchaseUpload && (
            <>
              {!isDocumentRequired && (
                <>
                  - <span className="optional-document">Optional</span>
                </>
              )}
            </>
          )}
        </div>
      )}
      {!isSubmitted && !hideLabel && isPaymentRecordUpload && (
        <div className="document-upload-label-payment-doc-upload">
          <div className="document-upload-tooltip-payment-doc-upload">
            <LabelWithTooltip
              label={label}
              tooltipContent={tooltipContent}
            ></LabelWithTooltip>
          </div>
        </div>
      )}
      {!isSubmitted &&
        uploadedFiles.map((uploadedFile, index) => {
          return (
            <div key={index} className="document-upload-flex-tile">
              <img
                className="upload-file-icon"
                src={DocumentsIcon}
                alt="documents icon"
              />
              <div className="file-details">
                <p className="file-name">{uploadedFile?.name}</p>
                {uploadedFile?.size && uploadedFile?.size !== '0' && (
                  <p>{uploadedFile?.size}</p>
                )}
              </div>
              {!isNotEditable && (
                <button
                  type="button"
                  onClick={() =>
                    removeFile({ index, fieldId: uploadedFile.fieldId })
                  }
                >
                  <img
                    className="remove-file-icon"
                    src={DeleteIcon}
                    alt={`Remove file ${uploadedFile?.name}`}
                  />
                </button>
              )}
            </div>
          )
        })}
      {!isSubmitted && !uploadedFiles?.length && (
        <div className="document-upload-dragzone">
          <div
            className={classnames('document-upload-dragzone', {
              error: hasError,
            })}
            {...getRootProps()}
            multiple={multiple}
          >
            <input
              name={requiredDocumentID}
              type="file"
              {...getInputProps()}
              multiple={multiple}
            />
            {isDragActive ? (
              <div className="document-upload-instructions custom-border">
                Drop file here ...
              </div>
            ) : (
              <div className="document-upload-instructions custom-border">
                {isPurchaseUpload ? (
                  <>
                    <div className="upload-labels-container">
                      <span className="upload-label-main">
                        Drag & drop or{' '}
                        <a className="browse-link">browse files</a>
                      </span>
                    </div>
                  </>
                ) : isPaymentRecordUpload ? (
                  <>
                    <div className="payment-record-labels-container">
                      <span className="payment-record-label-main">
                        Drag or{' '}
                        <a className="payment-browse-link">browse files</a> to
                        upload
                      </span>
                    </div>
                  </>
                ) : (
                  <span className={label ? 'labeled' : 'unlabeled'}>
                    {label ? (
                      label
                    ) : (
                      <>
                        Drag or <a>browse files</a> to upload
                      </>
                    )}
                  </span>
                )}
                {!!acceptedFileTypeNames?.length && (
                  <span className="file-types">
                    Supports: {acceptedFileTypeNames?.join(', ')}
                  </span>
                )}
              </div>
            )}
            {isFileTooLarge && (
              <div className="error-message">
                Alert! The size limit for documents and images is{' '}
                {formattedMaxFileSize}. Reduce the file size and try again.
              </div>
            )}
            {isFileTypeInvalid && (
              <div className="error-message">
                File type must be {acceptedFileTypeNames?.join(', ')}.
              </div>
            )}
          </div>
        </div>
      )}
      {isSubmitted && (
        <span className="success-message">
          Document Successfully Submitted!
        </span>
      )}
      <div className="error-message">{error}</div>
    </div>
  )
}
DocumentUploadInput.propTypes = exact(propTypes)
DocumentUploadInput.defaultProps = defaultProps
export default React.memo(DocumentUploadInput)
