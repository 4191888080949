import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'
import { Card } from 'components'
import { Spinner } from 'lp-components'
import { first, unionBy, isEmpty } from 'lodash'
import { Formik, Form } from 'formik'
import { ProfileFormFields, RoleAssetAssignmentFields } from '../forms'
import { PROFILE_ROLES, USER_CLASSIFICATION } from 'config'
import { areAllAssetsSelected, ascendingAlphaSort } from 'utils'
import * as flashActions from 'redux-flash'

const propTypes = {
  currentUser: PropTypes.object,
  fetchAssets: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(Types.asset),
  fetchUserProfile: PropTypes.func.isRequired,
}

const defaultProps = {}

function UserProfile({ assets, currentUser, fetchUserProfile, fetchAssets }) {
  useEffect(() => {
    fetchUserProfile()
    fetchAssets()
    return () => {
      fetchUserProfile()
    }
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!currentUser) return <Spinner />

  const readOnly =
    currentUser.userClassification !== USER_CLASSIFICATION.GROUP_1

  if (isEmpty(currentUser.roleNames)) {
    currentUser.roleNames = [
      {
        role: 'Error Loading Roles',
        relationships: [
          {
            assetName: 'Error Loading Roles & Assignements',
            assetID: 'Error Loading Roles & Assignements',
          },
        ],
      },
    ]
  }

  // First Check if Managing Member is in the List, then sort alphabetically
  const roleOptions = currentUser.roleNames
    .map((roleName) => ({
      key: roleName.role,
      value: roleName.relationships.map((relationship) => relationship.assetID),
    }))
    .sort((a, b) => {
      if (a.key === PROFILE_ROLES.MANAGER_MEMBER) return -1
      if (b.key === PROFILE_ROLES.MANAGER_MEMBER) return 1
      return a.key.localeCompare(b.key)
    })

  // Set Default Role as the first in the Sorted Role Options
  const defaultRole = first(roleOptions)

  const [currentRole, setCurrentRole] = useState(defaultRole.key)

  if (!assets) return <Spinner />

  const initialValues = {
    role: defaultRole.key,
    assetsAssigned: isEmpty(assets)
      ? []
      : areAllAssetsSelected(assets, defaultRole?.value)
      ? ['All', ...defaultRole.value]
      : defaultRole?.value,
  }

  // Create a Options Map of all Assets
  const assetOptions = assets.map((asset) => ({
    key: asset.name,
    value: asset.assetID,
  }))

  // Create a Filtered Options Map for Assets Assigned by Role
  const filteredAssetOptions = currentUser?.roleNames
    .find((roleName) => {
      return roleName.role === currentRole
    })
    ?.relationships.map((relationship) => {
      return {
        key: relationship.assetName,
        value: relationship.assetID,
      }
    })
    .sort(ascendingAlphaSort('key'))

  // Remove assets in All Asset Options that are in the Filtered Assets Options
  const sortedOptions = unionBy(
    filteredAssetOptions,
    assetOptions,
    'value'
  ).sort(ascendingAlphaSort('key'))

  // Concatentate with ALL type first, then Assets Assigned by Role, then
  // remaining Assets left
  const sortedAssignmentOptions = [
    { key: 'All', value: 'All' },
    ...filteredAssetOptions,
    ...sortedOptions,
  ]

  return (
    <Card>
      <div className="profile-contents-wrapper">
        <h4>Profile Details</h4>
        <Formik initialValues={initialValues} enableReinitialize={true}>
          {({ setFieldValue, values }) => (
            <Form>
              <RoleAssetAssignmentFields
                assets={assets}
                {...{
                  sortedAssignmentOptions,
                  roleOptions,
                  setCurrentRole,
                  setFieldValue,
                  values,
                }}
              />
              <hr />
            </Form>
          )}
        </Formik>
        <ProfileFormFields readOnly={readOnly} currentUser={currentUser} />
      </div>
    </Card>
  )
}

UserProfile.propTypes = propTypes
UserProfile.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assets: globalSelectors.assets(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  fetchUserProfile: apiActions.fetchUserProfile,
  updateUserProfile: apiActions.updateUserProfile,
  fetchAssets: apiActions.fetchAssets,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UserProfile
)
