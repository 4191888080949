import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Card, ScrollToFieldError, ACPInputField } from 'components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import * as apiActions from 'api-actions'
import { ADD_ASSET_STAGE } from 'config'
import { FlowActions } from '../components'

const propTypes = {
  updateAsset: PropTypes.func.isRequired,
  storedAsset: PropTypes.object.isRequired,
  isNotEditable: PropTypes.bool,
}

const defaultProps = {
  isNotEditable: false,
}

function InvestmentDetails({ storedAsset, updateAsset, isNotEditable }) {
  const { assetID } = useParams()
  const history = useHistory()
  const [error, setError] = useState()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const handleSubmit = async (values) => {
    const payload = {
      isInvestmentCompany:
        values.companyTypes === 'isInvestment' ? true : false,
      isOperatingCompany: values.companyTypes === 'isOperating' ? true : false,
      isRegulationD: values.redemptions === 'isRegulationD' ? true : false,
      isSecuritiesAct: values.redemptions === 'isSecuritiesAct' ? true : false,
      isNA: values.redemptions === 'isNA' ? true : false,
      isPhysicalAsset: values.isIssuedPhysicalAssets || false,
    }

    try {
      await updateAsset({
        ...storedAsset,
        stage: values.requireAll
          ? ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS
          : ADD_ASSET_STAGE.INVESTMENT_DETAILS,
        saveStage: ADD_ASSET_STAGE.INVESTMENT_DETAILS,
        investmentDetails: { ...payload },
      })

      if (values.requireAll) {
        history.push(`/add-asset/${assetID}/funding-instructions`)
      } else {
        history.push('/home')
      }
    } catch (e) {
      setError(e)
    }
  }
  const initialValues = {
    companyTypes: storedAsset?.investmentDetails?.isOperatingCompany
      ? 'isOperating'
      : storedAsset?.investmentDetails?.isInvestmentCompany
      ? 'isInvestment'
      : undefined,
    redemptions: storedAsset?.investmentDetails?.isRegulationD
      ? 'isRegulationD'
      : storedAsset?.investmentDetails?.isSecuritiesAct
      ? 'isSecuritiesAct'
      : storedAsset?.investmentDetails?.isNA
      ? 'isNA'
      : undefined,
    isIssuedPhysicalAssets:
      storedAsset?.investmentDetails?.isPhysicalAsset?.toString() === 'true'
        ? true
        : storedAsset?.investmentDetails?.isPhysicalAsset?.toString() ===
          'false'
        ? false
        : null,
    requireAll: false,
  }

  const investmentDetailsValidationSchema = Yup.object().shape({
    companyTypes: Yup.string().when('requireAll', {
      is: true,
      then: Yup.string().required('Required'),
    }),
    redemptions: Yup.string().when('requireAll', {
      is: true,
      then: Yup.string().required('Required'),
    }),
    isIssuedPhysicalAssets: Yup.bool()
      .when('requireAll', {
        is: true,
        then: Yup.bool()
          .required('Required')
          .nullable(),
      })
      .nullable(),
  })

  const companyTypeOptions = [
    { key: 'Operating Company', value: 'isOperating' },
    { key: 'Investment Company', value: 'isInvestment' },
  ]

  const redemptionOptions = [
    { key: 'Regulation D', value: 'isRegulationD' },
    {
      key: 'Securities Act Section 4(a)(5)',
      value: 'isSecuritiesAct',
    },
    { key: 'N/A', value: 'isNA' },
  ]

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={investmentDetailsValidationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {() => {
            return (
              <Form className="asset-form asset-details-form">
                <ScrollToFieldError />
                <Card>
                  <div className="form-card-content">
                    <fieldset
                      className="read-only-wrapper"
                      disabled={isNotEditable}
                    >
                      <div className="container">
                        <h4 className="flow-section-headers">
                          Investment Details
                        </h4>
                        <ACPInputField
                          name="companyTypes"
                          radioOptions={companyTypeOptions}
                          label="What is the company type?"
                        />
                        <ACPInputField
                          name="redemptions"
                          radioOptions={redemptionOptions}
                          label="For offerings exempt from securities registration, please select the redemptions relied upon."
                        />
                        <ACPInputField
                          name="isIssuedPhysicalAssets"
                          radioOptions={[
                            { key: 'Yes', value: true },
                            { key: 'No', value: false },
                          ]}
                          label="Will physical assets (e.g., stock certificates, notes) be issued?"
                        />
                      </div>
                    </fieldset>
                  </div>
                </Card>
                {!isNotEditable && <FlowActions />}
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

InvestmentDetails.propTypes = propTypes
InvestmentDetails.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateAsset: apiActions.updateAsset,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InvestmentDetails
)
