import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
}

const defaultProps = {}

const DEFAULT_OPTION_HEIGHT = 32
const DEFAULT_MENU_PADDING_TOP = 4
const DEFAULT_MENU_PADDING_BOTTOM = 4

function CustomMultiSelect({
  name,
  value,
  error,
  touched,
  onChange,
  onBlur,
  ...rest
}) {
  const handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.
    onChange(name, value)
  }

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.
    onBlur(name, true)
  }

  const errorId = `${name}-error`

  return (
    <div style={{ margin: '1rem 0' }}>
      <label htmlFor={name}>
        Please include all state(s) the Fund Manager is registered in. *
      </label>
      <Select
        id={name}
        name={name}
        isMulti
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        aria-describedby={error ? errorId : null}
        styles={{
          control: (provided) => ({
            ...provided,
            height: 48,
            borderRadius: '8px',
            border: '1px solid #B5B5B4',
          }),
          input: (provided) => ({
            ...provided,
            margin: '0px',
            height: DEFAULT_OPTION_HEIGHT,
            fontStyle: 'normal',
            color: '#1C1C1C',
          }),
          singleValue: (provided) => ({
            ...provided,
            paddingTop: 8,
            marginTop: 2,
            height: DEFAULT_OPTION_HEIGHT,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontStyle: 'normal',
            fontWeight: 400,
            fontFamily: 'CircularXXWeb-Regular',
          }),
          menu: (provided) => ({
            ...provided,
            paddingTop: DEFAULT_MENU_PADDING_TOP,
            paddingBottom: DEFAULT_MENU_PADDING_BOTTOM,
          }),
          option: (provided) => ({
            ...provided,
            height: DEFAULT_OPTION_HEIGHT,
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingLeft: 32,
            height: DEFAULT_OPTION_HEIGHT,
          }),
          indicatorSeparator: () => ({
            display: 'none', // hiding this default pipe design element to fit our design
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: '#DBE1FF',
            primary25: '#DBE1FF',
            primary: '#4553A9',
          },
        })}
        {...rest}
        {...rest}
      />
      {!!error && touched && (
        <div
          id={errorId}
          style={{ color: '#EA1C00', marginTop: '.5rem', fontSize: '14px' }}
        >
          {error}
        </div>
      )}
    </div>
  )
}

CustomMultiSelect.propTypes = propTypes
CustomMultiSelect.defaultProps = defaultProps

export default React.memo(CustomMultiSelect)
