import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as routerSelectors from 'connected-react-router'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { FlashMessageContainer } from 'lp-components'
import {
  Header,
  Footer,
  ServerStatusOverlay,
  SkipNavLink,
  Sidebar,
} from 'components'
import { isProduction } from 'config'
import { scrollToTop } from 'utils'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'
import classNames from 'classnames'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  notifications: PropTypes.arrayOf(PropTypes.object),
  // relatedEntities: PropTypes.array,
  fetchNotifications: PropTypes.func,
  // fetchRelatedEntities: PropTypes.func,
}

const defaultProps = {
  currentUser: {},
}

function SidebarLayout({
  flashMessages,
  pathname,
  children,
  currentUser,
  // relatedEntities,
  notifications,
  fetchNotifications,
  // fetchRelatedEntities,
}) {
  // Scroll to top of page when route changes
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    scrollToTop()
  }, [pathname])

  useEffect(() => {
    fetchNotifications()
  }, [])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true)
  //     // await fetchRelatedEntities()
  //     setLoading(false)
  //   }
  //   fetchData()
  // }, [])

  const [expanded, setExpanded] = useState(true)

  // if (loading) return <Spinner />

  return (
    <div className="layout-with-sidebar tran-layout-with-sidebar">
      {!isProduction() && <ServerStatusOverlay />}
      <FlashMessageContainer messages={flashMessages} />
      <SkipNavLink targetId="main-content">Skip to main content</SkipNavLink>
      <Sidebar
        userClassification={currentUser.userClassification}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      <Header
        title={`Welcome, ${currentUser.firstName} ${currentUser.lastName}`}
        notifications={notifications}
        pathname={pathname}
        expanded={expanded}
        // relatedEntities={relatedEntities}
      />
      <main id="main-content" className={classNames({ expand: !expanded })}>
        {children}
      </main>
      <Footer expanded={expanded} />
    </div>
  )
}

SidebarLayout.propTypes = propTypes
SidebarLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    pathname: routerSelectors.getLocation(state).pathname,
    currentUser: globalSelectors.currentUser(state),
    notifications: globalSelectors.notifications(state),
    // relatedEntities: globalSelectors.relatedEntities(state),
  }
}

const mapDispatchToProps = {
  fetchNotifications: apiActions.fetchNotifications,
  // fetchRelatedEntities: apiActions.fetchRelatedEntities,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SidebarLayout
)
