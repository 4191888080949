import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'

const propTypes = {
  fetchAssets: PropTypes.func,
  fetchTransactions: PropTypes.func,
  fetchContactsList: PropTypes.func,
  fetchTasks: PropTypes.func,
}

const defaultProps = {}

function SwitchEntity({
  fetchAssets,
  fetchTransactions,
  fetchContactsList,
  fetchTasks,
}) {
  useEffect(() => {
    const fetchData = async () => {
      await fetchAssets()
      await fetchTasks()
      await fetchTransactions()
      await fetchContactsList()
    }
    fetchData()
  }, [])

  return <span></span>
}

SwitchEntity.propTypes = propTypes
SwitchEntity.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}
const mapDispatchToProps = {
  updateRelatedEntity: apiActions.updateRelatedEntity,
  flashErrorMessage: flashActions.flashErrorMessage,
  fetchAssets: apiActions.fetchAssets,
  fetchTransactions: apiActions.fetchTransactions,
  fetchContactsList: apiActions.fetchContactsList,
  fetchTasks: apiActions.fetchTasks,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SwitchEntity
)
