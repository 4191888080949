import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { selectors } from './reducer'
import * as apiActions from 'api-actions'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { ConfirmPurchaseHeader, FlowStatusTracker } from 'components'
import { FlashMessageContainer, Spinner } from 'lp-components'
import { ADD_NEW_CONTACT_STAGES } from 'flow-stages'
import { getStageLabel, scrollToTop } from 'utils'
import * as Types from 'types'

const propTypes = {
  children: PropTypes.node.isRequired,
  contactsList: PropTypes.arrayOf(Types.contact),
  currentStage: PropTypes.string,
  fetchContactsList: PropTypes.func,
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
}

const defaultProps = {}

function Layout({
  children,
  contactsList,
  currentStage,
  fetchContactsList,
  flashMessages,
}) {
  const [error, setError] = useState(null)

  useEffect(() => {
    scrollToTop()
    fetchContactsList().catch((e) => setError(e))
  }, [])

  if (error) {
    return (
      <div>
        <Redirect
          to={{
            pathname: '/error',
            state: {
              errorMessage:
                error.errors.message ||
                'Something went wrong, please try again.',
            },
          }}
        />
      </div>
    )
  }
  if (!contactsList) return <Spinner />

  return (
    <>
      <FlashMessageContainer messages={flashMessages} />
      <ConfirmPurchaseHeader title={'Add Contact'} />
      <div className="add-asset-layout-wrapper contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title">
            {getStageLabel(ADD_NEW_CONTACT_STAGES, currentStage)}
          </h1>
        </div>
        <div className="content-div">
          <FlowStatusTracker
            stages={ADD_NEW_CONTACT_STAGES}
            currentStage={currentStage}
            disableNavigation={true}
          />
          {children}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    contactsList: selectors.contactsList(state),
  }
}

const mapDispatchToProps = {
  fetchContactsList: apiActions.fetchContactsList,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
