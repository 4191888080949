import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as bulkConfirmationActions from '../bulk-confirmations/actions'

const reducerKey = 'bulk-confirmations'
const slice = 'root.bulk-confirmations'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchInvestorsData]: setOnSuccess('investorsData'),
    [bulkConfirmationActions.clearInvestorsData]: unsetState('investorsData'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  investorsData: select('investorsData'),
}

export { reducer, selectors, reducerKey }
