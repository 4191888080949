import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import InfoIcon from 'images/help-information.svg'

const propTypes = {
  displayName: PropTypes.string,
  props: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  progressSort: PropTypes.func.isRequired,
}

const defaultProps = {}

function StatusHeader({ props, showModal }) {
  const { displayName, column, progressSort } = props

  return (
    <div className="customHeaderLabel">
      <span className="custom-header-sort-label">
        <div className="header-label-name" onClick={() => progressSort(false)}>
          {displayName}
        </div>
        <div className="header-sort-icon-wrapper">
          {column.sort && (
            <div
              className={`ag-icon ag-icon-${column.sort}`}
              unselectable="on"
              role="presentation"
            ></div>
          )}
        </div>
      </span>
      <span className="custom-header-tooltip-modal">
        <button className="modal-button" onClick={showModal}>
          <img src={InfoIcon} alt="show status key" />
        </button>
      </span>
    </div>
  )
}

StatusHeader.propTypes = exact(propTypes)
StatusHeader.defaultProps = defaultProps

export default StatusHeader
