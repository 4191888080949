import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import HelpCircleIconBlack from 'images/help_outline_black_24dp.svg'
import HelpCircleIconWhite from 'images/help_outline_white.svg'
import { ContactModal } from 'components'
const propTypes = {
  isWizardFlow: PropTypes.bool,
}

const defaultProps = {}

function HelpDetails({ isWizardFlow }) {
  const [modalShown, setModalShown] = useState(false)

  return (
    <div className="help-details-container">
      <img
        src={isWizardFlow ? HelpCircleIconWhite : HelpCircleIconBlack}
        alt="help circle"
        onClick={() => {
          setModalShown(true)
        }}
      />
      {modalShown && <ContactModal onClose={() => setModalShown(false)} />}
    </div>
  )
}

HelpDetails.propTypes = exact(propTypes)
HelpDetails.defaultProps = defaultProps

export default React.memo(HelpDetails)
