import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { withPermission } from 'utils'

const propTypes = {
  showAddAssetModal: PropTypes.func,
}

const defaultProps = {}

function AddNewAssetButton({ showAddAssetModal }) {
  if (!withPermission('AddNewAssetButton')) return null

  return (
    <button
      className="button-primary"
      onClick={() => {
        showAddAssetModal()
      }}
    >
      Add New Asset
    </button>
  )
}

AddNewAssetButton.propTypes = exact(propTypes)
AddNewAssetButton.defaultProps = defaultProps

export default React.memo(AddNewAssetButton)
