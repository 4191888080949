import React from 'react'
import exact from 'prop-types-exact'
import { Button } from 'lp-components'
import { useHistory } from 'react-router-dom'

const propTypes = {}

const defaultProps = {}

function FlowActions() {
  const history = useHistory()

  return (
    <div className="flow-button-container">
      <div className="invitation-flow-button-inner">
        <Button
          className="button-primary button-back"
          onClick={() => history.push('/home')}
        >
          Close
        </Button>
      </div>
    </div>
  )
}

FlowActions.propTypes = exact(propTypes)
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
