import React, { useCallback, useRef } from 'react'
import 'ag-grid-community'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card, ACPTable } from 'components'
import { Spinner } from 'lp-components'
import { formatNumberAsCurrency } from 'utils'

const propTypes = {
  clientAccounts: PropTypes.arrayOf(PropTypes.object),
  setSelectedClients: PropTypes.func,
  selectedClientAccountIDs: PropTypes.array,
}

const defaultProps = {}

function InvestorSelectionTable({
  clientAccounts,
  selectedClientAccountIDs,
  setSelectedClients,
}) {
  const gridRef = useRef()
  const columnDefs = [
    {
      headerName: 'Investor Name',
      field: 'clientAccountName',
      minWidth: 280,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: true,
      lockPosition: true,
      headerClass: 'ag-left-aligned-header',
      headerTooltip: 'Investor Name: The client for this transaction.',
    },
    {
      field: 'totalAmount',
      sortable: true,
      type: 'rightAligned',
      cellClass: 'table-right-align',
      headerClass: 'ag-right-aligned-header',
      headerTooltip:
        'Total Amount: The total capital commitment by the client in the asset.',
      valueFormatter: (params) => formatNumberAsCurrency(params.value),
    },
    {
      headerName: 'Process Amount',
      field: 'processedAmount',
      sortable: true,
      type: 'rightAligned',
      cellClass: 'table-right-align',
      headerClass: 'ag-right-aligned-header',
      headerTooltip:
        'Process Amount: The total capital amount already processed for the client for this asset.',
      valueFormatter: (params) => formatNumberAsCurrency(params.value),
    },
    {
      headerName: 'Balance',
      field: 'balanceAmount',
      sortable: true,
      type: 'rightAligned',
      cellClass: 'table-right-align',
      headerClass: 'ag-right-aligned-header',
      headerTooltip:
        'Balance: The total remaining balance of the commitment amount by the client for this asset.',
      valueFormatter: (params) => formatNumberAsCurrency(params.value),
    },
    {
      headerName: 'Commitment Document Date Sent',
      field: 'documentSentDate',
      headerClass: 'ag-left-aligned-header',
      sortable: true,
      minWidth: 220,
      headerTooltip:
        'Commitment Document Date Sent: The date of when the client commitment document was sent to Inspira.',
    },
  ]

  const onGridReady = (params) => {
    gridRef.current.api.forEachNode((node) => {
      if (selectedClientAccountIDs.includes(node.data.clientAccountID)) {
        node.setSelected(true)
      }
    })
    params.api.sizeColumnsToFit()
  }

  const autoSizeColumns = (params) => {
    const colIds = params.columnApi
      .getAllDisplayedColumns()
      .map((col) => col.getColId())

    params.columnApi.autoSizeColumns(colIds)
  }

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows()
    setSelectedClients(selectedRows)
  }, [])

  if (!clientAccounts) {
    return (
      <Card className="investor-selection-card">
        <Spinner />
      </Card>
    )
  }

  if (!clientAccounts.length) {
    return (
      <Card className="investor-selection-card">
        <div className="no-assets-content">
          <p>You currently have no clients to select from</p>
        </div>
      </Card>
    )
  }
  return (
    <>
      <Card className="investor-selection-card">
        <div className="investor-selection-table-header">
          <h4>{clientAccounts[0].assetName}</h4>
        </div>
        {clientAccounts && (
          <ACPTable
            columnDefs={columnDefs}
            additionalDefaultColDef={{
              headerClass: 'ag-right-aligned-header',
            }}
            rowData={clientAccounts}
            domLayout="autoHeight"
            rowSelection="multiple"
            onGridReady={onGridReady}
            overrideGridRef={gridRef}
            onFirstDataRendered={autoSizeColumns}
            onSelectionChanged={onSelectionChanged}
            paginationPageSize={'10'}
          />
        )}
      </Card>
    </>
  )
}

InvestorSelectionTable.propTypes = exact(propTypes)
InvestorSelectionTable.defaultProps = defaultProps

export default InvestorSelectionTable
