import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, Table, TableColumn } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
}

const defaultProps = {}

function RoleDefinitionModal({ onClose, roles, title }) {
  return (
    <Modal onClose={onClose} id="status-key-modal">
      <h2>{title}</h2>

      <div className="status-key-table-container">
        <Table data={roles} className="status-key-table">
          <TableColumn name="key" label="Role Name" />
          <TableColumn name="value" label="Role Description" />
        </Table>
      </div>
    </Modal>
  )
}

RoleDefinitionModal.propTypes = exact(propTypes)
RoleDefinitionModal.defaultProps = defaultProps

export default React.memo(RoleDefinitionModal)
