import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { components } from 'react-select'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { truncate } from 'lodash'
import {
  LabelWithTooltip,
  CurrencyInput,
  ACPInputField,
  MultiSelect,
  ACPDateInputField,
  DocumentUploadInput,
} from 'components'
import { Spinner, SubmitButton } from 'lp-components'

const propTypes = {
  assets: PropTypes.arrayOf(Types.asset).isRequired,
  fetchAssets: PropTypes.func.isRequired,
  fetchClients: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

const defaultProps = {}

const Option = (props) => {
  return (
    <div>
      <components.Option innerProps={props} {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          disabled={props.isDisabled}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props
  const values = getValue()

  const valuesWithoutAll = values.filter((value) => value.value !== '*')
  const numberOfValues = valuesWithoutAll.length
  const isSelectAll = values[0] === '*'

  const valueMessage = hasValue
    ? valuesWithoutAll
        .slice(0, 7)
        .map((value) => truncate(value.label, { length: 24 }))
        .join(', ')
    : null

  const trailingMessage =
    numberOfValues > 7 &&
    `, ...${numberOfValues - 7} other ${pluralize(
      'item',
      numberOfValues - 7
    )} selected`

  return (
    <components.ValueContainer {...props}>
      {isSelectAll && 'All Assets Selected'}
      {!isSelectAll && valueMessage && valueMessage}
      {!isSelectAll && trailingMessage && trailingMessage}
      {children}
    </components.ValueContainer>
  )
}

function PaymentFormFields({ assets, fetchAssets, fetchClients, index }) {
  const {
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    errors,
    touched,
  } = useFormikContext()

  useEffect(() => {
    fetchAssets()
    fetchClients()
  }, [])

  if (!assets) return <Spinner />

  const mappedAssets = assets.map(({ name }) => {
    return { value: name, label: name }
  })

  return (
    <div className="payment-record-form-fields sponsor-form-fields">
      <div className="row asset-select-input-row">
        <div className="twelve-columns column">
          <MultiSelect
            name="assetList"
            label="Select Assets *"
            withAllOptions={false}
            options={mappedAssets}
            onBlur={setFieldTouched}
            components={{
              Option,
              ValueContainer,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            index={index}
            withAllOption={false}
            placeholderText="Search Assets"
            className="payment-asset-select"
          />
        </div>
      </div>
      <div className="row">
        <div className="one-half column">
          <CurrencyInput
            name="totalPaymentAmount"
            label="Total Payment Amount *"
            defaultValue={0}
            labelComponent={LabelWithTooltip}
            tooltipContent="This is the total amount sent to Inspira for each of the investors in this asset."
          />
        </div>
        <div className="one-half column">
          <ACPDateInputField
            name="paymentDate"
            label="Payment Date"
            tooltipContent="This is the date Inspira should expect to receive the payment for the investors."
          />
        </div>
      </div>
      <div className="row">
        <div className="one-half column payment-type-radio-group">
          <ACPInputField
            name="paymentMethod"
            label="Payment Method"
            tooltipContent="This is the method the sponsor is using to send investor payment to Inspira."
            radioOptions={[
              { key: 'ACH', value: 'ach' },
              { key: 'Check', value: 'check' },
              { key: 'RTP', value: 'rtp' },
              { key: 'Wire', value: 'Wire' },
            ]}
          />
        </div>
      </div>
      <div className="payment-type-file-upload">
        <DocumentUploadInput
          keyName="documents.uploadNewDocument"
          setFieldValue={setFieldValue}
          multiple={false}
          label="Investor Payment Breakdown File"
          tooltipContent="Upload your payment record file here. Payment record files must be 40 MB or less."
          isPaymentRecordUpload={true}
          document={{
            isDocumentRequired: false,
          }}
          error={
            touched?.documents && errors?.documents
              ? errors?.documents['uploadNewDocument']
              : errors['myUploadsError']
          }
        />
        <div className="document-upload-flow-button-container">
          <SubmitButton submitting={isSubmitting}>
            Submit Document(s)
          </SubmitButton>
        </div>
      </div>
    </div>
  )
}

PaymentFormFields.propTypes = propTypes

PaymentFormFields.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assets: globalSelectors.assets(state),
    normalizedClients: selectors.normalizedClients(state),
  }
}

const mapDispatchToProps = {
  fetchAssets: apiActions.fetchAssets,
  fetchClients: apiActions.fetchClients,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PaymentFormFields
)
