import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import ResourceCard from '../components/ResourceCard'
import * as apiActions from 'api-actions'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'

const propTypes = {
  resources: PropTypes.array,
  fetchResources: PropTypes.func.isRequired,
}

const defaultProps = {}

function Resources({ resources, fetchResources }) {
  useEffect(() => {
    fetchResources()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!resources) return <Spinner />

  const sortedResources = resources.sort((a, b) => {
    return a.order - b.order
  })

  return (
    <div className="resources">
      <div className="resources-container">
        {sortedResources.map((resource) => (
          <ResourceCard key={resource.url} {...resource} />
        ))}
      </div>
    </div>
  )
}

Resources.propTypes = propTypes
Resources.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    resources: selectors.resources(state),
  }
}

const mapDispatchToProps = {
  fetchResources: apiActions.fetchResources,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Resources)
