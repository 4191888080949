import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useParams, useHistory } from 'react-router-dom'
import {
  Card,
  CurrencyInput,
  ScrollToFieldError,
  ACPInputField,
  ACPCheckbox,
} from 'components'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { isEmpty, compact } from 'lodash'
import { ADD_ASSET_STAGE } from 'config'
import { FlowActions } from '../components'

const propTypes = {
  storedAsset: PropTypes.object.isRequired,
  updateAsset: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
}

const defaultProps = {
  isNotEditable: false,
}

function ValuationRequirements({ storedAsset, updateAsset, isNotEditable }) {
  let { assetID } = useParams()
  const history = useHistory()
  const [error, setError] = useState()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const initialValues = {
    valuationFrequency: storedAsset?.valuationRequirements?.isMonthly
      ? 'monthly'
      : storedAsset?.valuationRequirements?.isQuarterly
      ? 'quarterly'
      : storedAsset?.valuationRequirements?.isSemiAnnual
      ? 'semiannually'
      : storedAsset?.valuationRequirements?.isAnnual
      ? 'annually'
      : '',
    incomeType: storedAsset?.valuationRequirements?.isDividends
      ? 'dividends'
      : storedAsset?.valuationRequirements?.isInterest
      ? 'interest'
      : storedAsset?.valuationRequirements?.isReturnOfEarnings
      ? 'roe'
      : '',
    isSponsorIssuingTaxForms:
      storedAsset?.valuationRequirements?.isSponsorIssuingTaxForms,
    taxFormsProvided:
      storedAsset?.valuationRequirements?.isTaxFormK1 ||
      storedAsset?.valuationRequirements?.isTaxForm1099 ||
      storedAsset?.valuationRequirements?.isTaxFormOther
        ? [
            storedAsset?.valuationRequirements?.isTaxFormK1 && 'k1',
            storedAsset?.valuationRequirements?.isTaxForm1099 && '1099',
            storedAsset?.valuationRequirements?.isTaxFormOther && 'other',
          ]
        : [],
    otherTaxForms: !isEmpty(
      storedAsset?.valuationRequirements?.taxFormOtherDetails
    )
      ? storedAsset?.valuationRequirements?.taxFormOtherDetails
      : '',
    FMVReportsAs: storedAsset?.valuationRequirements?.isFMVPricePerShare
      ? 'initialPrice'
      : storedAsset?.valuationRequirements?.isFMVCapitalAccount
      ? 'capitalAccountBalance'
      : '',
    initialPrice: storedAsset?.initialPrice ? storedAsset?.initialPrice : '',
  }

  // if there is an assetID then update, else post new
  const handleSubmit = async (values) => {
    const payload = {
      isMonthly: values.valuationFrequency === 'monthly',
      isQuarterly: values.valuationFrequency === 'quarterly',
      isSemiAnnual: values.valuationFrequency === 'semiannually',
      isAnnual: values.valuationFrequency === 'annually',
      isDividends: values.incomeType === 'dividends',
      isInterest: values.incomeType === 'interest',
      isReturnOfEarnings: values.incomeType === 'roe',
      isSponsorIssuingTaxForms: values.isSponsorIssuingTaxForms,
      isTaxFormK1: values.taxFormsProvided.includes('k1'),
      isTaxForm1099: values.taxFormsProvided.includes('1099'),
      isTaxFormOther: values.taxFormsProvided.includes('other'),
      taxFormOtherDetails: values.otherTaxForms,
      isFMVPricePerShare: values.FMVReportsAs === 'initialPrice',
      isFMVCapitalAccount: values.FMVReportsAs === 'capitalAccountBalance',
    }

    try {
      await updateAsset({
        ...storedAsset,
        stage: values.requireAll
          ? ADD_ASSET_STAGE.DOCUMENTS
          : ADD_ASSET_STAGE.VALUATION_REQUIREMENTS,
        saveStage: ADD_ASSET_STAGE.VALUATION_REQUIREMENTS,
        initialPrice:
          values.FMVReportsAs === 'initialPrice'
            ? parseFloat(values.initialPrice)
            : '',
        valuationRequirements: { ...payload },
      })

      if (values.requireAll) {
        history.push(`/add-asset/${assetID}/documents`)
      } else {
        history.push('/home')
      }
    } catch (e) {
      setError(e)
    }
  }

  const valuationRequirementsValidationSchema = Yup.object().shape({
    valuationFrequency: Yup.string().when('requireAll', {
      is: true,
      then: Yup.string().required('Required'),
    }),
    incomeType: Yup.string().when('requireAll', {
      is: true,
      then: Yup.string().required('Required'),
    }),
    isSponsorIssuingTaxForms: Yup.bool()
      .when('requireAll', {
        is: true,
        then: Yup.bool()
          .required('Required')
          .nullable(),
      })
      .nullable(),
    taxFormsProvided: Yup.array().when('isSponsorIssuingTaxForms', {
      is: 'true',
      then: Yup.array()
        .min(1)
        .required('Required'),
    }),
    otherTaxForms: Yup.string().when('taxFormsProvided', {
      is: (val) => val.includes('other'),
      then: Yup.string().required('Must include an additional form'),
    }),
    initialPrice: Yup.string().when('FMVReportsAs', {
      is: 'initialPrice',
      then: Yup.string()
        .required('Required')
        .test('test_value_greater_0', '', function(value) {
          // your condition
          if (parseFloat(value) <= 0) {
            // setting the error message using the value's length
            return this.createError({ message: `Value must be greater than 0` })
          }
          return true
        }),
    }),
    FMVReportsAs: Yup.string().when('requireAll', {
      is: true,
      then: Yup.string().required('Required'),
    }),
  })

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  const frequencyOptions = [
    { key: 'Monthly', value: 'monthly' },
    { key: 'Quarterly', value: 'quarterly' },
    { key: 'Semi-Annually', value: 'semiannually' },
    { key: 'Annually', value: 'annually' },
  ]

  const incomeTypeOptions = [
    { key: 'Dividends', value: 'dividends' },
    { key: 'Interest', value: 'interest' },
    { key: 'Return Of Earnings', value: 'roe' },
  ]

  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={valuationRequirementsValidationSchema}
          initialValues={initialValues}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form className="asset-form asset-details-form">
              <ScrollToFieldError />
              <Card>
                <div className="form-card-content">
                  <fieldset
                    className="read-only-wrapper"
                    disabled={isNotEditable}
                  >
                    <div className="container">
                      <h4 className="flow-section-headers">
                        Valuation Requirements
                      </h4>
                      <p>
                        The valuation will be provided within the Inspira
                        Platform.
                      </p>
                      <div className="row">
                        <div className="twelve columns">
                          <ACPInputField
                            name="valuationFrequency"
                            radioOptions={frequencyOptions}
                            label="Please select valuation frequency (12/31 values are required annually)"
                            disabled={isNotEditable}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="twelve columns">
                          <ACPInputField
                            name="incomeType"
                            radioOptions={incomeTypeOptions}
                            label="What type of income will be generated from this investment?"
                            disabled={isNotEditable}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="twelve columns">
                          <ACPInputField
                            name="isSponsorIssuingTaxForms"
                            radioOptions={[
                              { key: 'Yes', value: true },
                              { key: 'No', value: false },
                            ]}
                            onChange={(e) => {
                              if (e.target.value === false) {
                                setFieldValue('taxFormsProvided', [])
                                setFieldValue('otherTaxForms', '')
                              }
                              setFieldValue(
                                'isSponsorIssuingTaxForms',
                                e.target.value
                              )
                            }}
                            label="Does the sponsor issue any tax form(s)? (ie: K-1, 1099 )"
                            disabled={isNotEditable}
                          />
                        </div>
                      </div>
                      {values.isSponsorIssuingTaxForms && (
                        <div className="row">
                          <div className="twelve columns">
                            <ACPCheckbox
                              name="taxFormsProvided"
                              input={{
                                name: 'taxFormsProvided',
                                value: values.taxFormsProvided,
                                onChange: (value) => {
                                  if (!value.includes('other')) {
                                    setFieldValue('otherTaxForms', '')
                                  }

                                  if (isEmpty(value)) {
                                    setFieldValue('taxFormsProvided', [], true)
                                  } else {
                                    setFieldValue(
                                      'taxFormsProvided',
                                      compact(value),
                                      true
                                    )
                                  }
                                },
                              }}
                              label="Which tax form(s) would be provided?"
                              options={[
                                { key: 'K-1', value: 'k1' },
                                { key: '1099', value: '1099' },
                                { key: 'Other', value: 'other' },
                              ]}
                              type="group"
                            />
                            <div className="error-message">
                              <ErrorMessage name="taxFormsProvided">
                                {() => 'Must select at least one form'}
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                      )}
                      {values.taxFormsProvided.includes('other') && (
                        <ACPInputField
                          name="otherTaxForms"
                          label="Please enter other tax forms that will be provided. (ie: 1040EZ, W4)"
                          placeholder="Enter other tax forms"
                          disabled={isNotEditable}
                        />
                      )}
                      <div className="row">
                        <div className="twelve columns">
                          <ACPInputField
                            name="FMVReportsAs"
                            radioOptions={[
                              {
                                key: 'Price per share/unit',
                                value: 'initialPrice',
                              },
                              {
                                key: 'Capital Account balance',
                                value: 'capitalAccountBalance',
                              },
                            ]}
                            label="What is the valuation method for the asset?"
                            onChange={() => {
                              setFieldValue('initialPrice', '', false)
                            }}
                            disabled={isNotEditable}
                          />
                        </div>
                      </div>
                      {values.FMVReportsAs === 'initialPrice' && (
                        <div className="row">
                          <div className="one-half column">
                            <CurrencyInput
                              name="initialPrice"
                              placeholder="Enter price per share"
                              label={
                                isNotEditable
                                  ? 'InitialPrice'
                                  : 'Initial Price *'
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </fieldset>
                </div>
              </Card>
              {!isNotEditable && <FlowActions />}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

ValuationRequirements.propTypes = propTypes

ValuationRequirements.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateAsset: apiActions.updateAsset,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ValuationRequirements
)
