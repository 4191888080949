import React from 'react'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function NotificationsStatusMarker() {
  return (
    <div className="notification-status-marker-wrapper">
      <div className="notification-status-bubble"></div>
    </div>
  )
}

NotificationsStatusMarker.propTypes = exact(propTypes)
NotificationsStatusMarker.defaultProps = defaultProps

export default React.memo(NotificationsStatusMarker)
