import html2canvas from 'html2canvas'
import { isScreenshotDebuggingEnabled } from 'config'

export async function createCanvasScreenshot(el, timestamp) {
  const uid = `screenshot-${Date.now()}`
  el.dataset.screenshotContainer = uid
  const canvas = await html2canvas(el, {
    allowTaint: true,
    logging: isScreenshotDebuggingEnabled(),
    onclone: (clonedDoc) => {
      delete el.dataset.screenshotContainer
      const container = clonedDoc.querySelector(
        `[data-screenshot-container=${uid}]`
      )

      // Automatically add container to allow for some screenshot specific styling overrides / workarounds
      clonedDoc.body.classList.add('screenshot-container')

      // Show elements that have been identified as only appropriate to show on the image (not to the user)
      Array.from(clonedDoc.querySelectorAll('[data-screenshot-only]')).forEach(
        (el) => {
          el.classList.remove('visually-hidden')
        }
      )

      // Show _all_ content within a scrolling container
      Array.from(clonedDoc.querySelectorAll('.scrolling-container')).forEach(
        (el) => {
          el.style.height = 'auto'
        }
      )

      // Box shadow isn't supported
      Array.from(clonedDoc.querySelectorAll('.card')).forEach((el) => {
        el.style['box-shadow'] = 'none'
      })

      // Textarea wrapping isn't supported; Make a fake textarea instead
      Array.from(clonedDoc.querySelectorAll('textarea')).forEach((el) => {
        const div = clonedDoc.createElement('div')
        div.innerText = el.value
        div.style.border = '1px solid #9b9b9b'
        div.style.padding = '10px'
        el.style.display = 'none'
        el.parentElement.append(div)
      })

      // SVGs used as background-image do not render correctly, so remove them
      // https://github.com/niklasvh/html2canvas/issues/2801
      Array.from(clonedDoc.querySelectorAll('select')).forEach((el) => {
        el.style.backgroundImage = 'none'
      })

      if (timestamp) {
        const div = clonedDoc.createElement('div')
        div.style.paddingBottom = '20px'
        div.style.textAlign = 'center'
        div.style.fontWeight = 'bold'
        div.innerText = timestamp
        container.append(div)
      }
    },
  })
  // If canvas size is exceeded on Chrome or Safari, the net result will be an empty canvas
  if (canvas.toDataURL() === 'data:,')
    throw new Error('Canvas size exceeded. Cannot generate an image.')
  return canvas
}

export function generateTimestamp(date) {
  const dateString = date.toLocaleDateString('en-US')
  const timeString = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    hour12: true,
  })
  return `${dateString} ${timeString}`
}
