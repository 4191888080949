import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { useAuth0 } from '@auth0/auth0-react'
import { Modal, SubmitButton, Spinner } from 'lp-components'
import * as flashActions from 'redux-flash'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { ACPInputField } from 'components'
import arrow from 'images/expand-less.svg'
import * as Yup from 'yup'

const propTypes = {
  assetID: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  backToView: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  generateToken: PropTypes.func.isRequired,
  verifyToken: PropTypes.func.isRequired,
}

const defaultProps = {}

function ReauthenticationModal({
  onClose,
  flashErrorMessage,
  backToView,
  history,
  assetID,
  generateToken,
  verifyToken,
}) {
  var { logout } = useAuth0()
  var numberOfTries = 3
  const [exceededLimit, setExceededLimit] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const generation = async () => {
      setLoading(true)
      let generationResponse = await generateToken()
      setLoading(false)
      return generationResponse
    }
    generation().then((response) => {
      if (!exceededLimit && response?.tokenStatus == 'EXECEEDED') {
        setExceededLimit(true)
      }
    })
  }, [])

  const handleConfirm = async (values, { setErrors }) => {
    try {
      const verification = async () => {
        let verificationResponse = await verifyToken({
          token: `${values.passCode}`,
        })
        return verificationResponse
      }
      verification().then((response) => {
        if (response?.isValid) {
          history.push({
            pathname: `/funding-instructions/${assetID}/details`,
            state: {
              hasValidToken: true,
            },
          })
          onClose()
        } else {
          numberOfTries -= 1
          if (numberOfTries == 0) {
            logout({ returnTo: window.location.origin })
          }
          setErrors({
            passCode: `The code you entered is invalid. ${numberOfTries} ${
              numberOfTries > 1 ? 'tries' : 'try'
            } remaining.`,
          })
        }
      })
    } catch (e) {
      flashErrorMessage(
        e.errors.message || 'Something went wrong, please try again.'
      )
    }
  }

  const passCodeSchema = Yup.object({
    passCode: Yup.string()
      .test('test_pass_code', '', (value, context) => {
        const { createError } = context
        if (!value || value.length < 6 || value.length > 6) {
          return createError({
            message: 'Please enter a 6-digit code.',
          })
        }

        return true
      })
      .required('Please enter a 6-digit code.'),
  })

  return (
    <Modal onClose={onClose}>
      <Formik
        validationSchema={passCodeSchema}
        onSubmit={handleConfirm}
        initialValues={{ passCode: '' }}
      >
        {({ isSubmitting }) => {
          return (
            <Form className="asset-form asset-details-form">
              <div className="reauthentication-button-header">
                <button className="button-secondary" onClick={backToView}>
                  <img src={arrow} alt="back-arrow" className="back-arrow" />
                  Back to View Funding Instructions
                </button>
              </div>
              <h4 className="reauthentication-header">Verify it's you</h4>
              <div className="reauthentication-subheader">
                We sent you an email with your verification code. To update the
                funding instructions, enter the code below. This extra step
                helps keep your account secure.
              </div>
              <div className="form-card-content reauthentication-body">
                {loading ? (
                  <Spinner />
                ) : (
                  <ACPInputField
                    name="passCode"
                    label="One-time Passcode"
                    placeholder="######"
                    maskOptions={{
                      numericOnly: true,
                      blocks: [6],
                      stripLeadingZeroes: false,
                      numeralThousandsGroupStyle: 'none',
                    }}
                  />
                )}
              </div>
              <div className="button-block--inline authentication-button">
                <SubmitButton
                  className="button-primary button-check"
                  submitting={isSubmitting}
                >
                  Submit Code
                </SubmitButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

ReauthenticationModal.propTypes = exact(propTypes)
ReauthenticationModal.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  generateToken: apiActions.generateToken,
  verifyToken: apiActions.verifyToken,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReauthenticationModal
)
