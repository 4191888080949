import { TaskPriorityFilters, PriorityLevels } from 'config'

const taskTypeMap = {
  Liquidation_1: 'Liquidation Transaction',
  Liquidation_2: 'Liquidation Transaction',
  Liquidation_3: 'Liquidation Transaction',
  Purchase: 'Purchase Transaction',
  Exchange: 'Exchange Transaction',
  Resolution: 'Resolution',
  ['Pending Asset Submission']: 'Submit New Asset',
  ['Valuation']: 'Asset Valuation',
  ['Contact']: 'Review Contact Information',
  ['Funding']: 'Review Funding Instructions',
}

const tasksFilteredByType = (tasks, selection) => {
  if (selection.length > 0) {
    return tasks.filter((task) => {
      return (
        (selection.includes(TaskPriorityFilters.URGENT)
          ? task.priority === PriorityLevels.URGENT
          : false) ||
        selection.includes(taskTypeMap[task.taskType]) ||
        (task.subType ? selection.includes(taskTypeMap[task.subType]) : false)
      )
    })
  } else {
    return tasks
  }
}

export default tasksFilteredByType
