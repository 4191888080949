import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Tooltip } from 'components'

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  tooltipContent: PropTypes.node,
  name: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  customClass: PropTypes.string,
}

const defaultProps = {
  id: '',
  customClass: '',
  tooltipContent: null,
}

function LabelWithTooltip({
  label,
  tooltipContent,
  name,
  id,
  children,
  customClass,
}) {
  return (
    <div className="labeled-field-with-tooltip">
      <div className="tool-tip-block">
        <label className="tooltip-label" htmlFor={id || name}>
          {label}
        </label>
        {tooltipContent ? (
          <Tooltip className={customClass} imageAlt={`${label} Tooltip`}>
            {tooltipContent}
          </Tooltip>
        ) : null}
      </div>
      <div name={name}>{children}</div>
    </div>
  )
}

LabelWithTooltip.propTypes = exact(propTypes)
LabelWithTooltip.defaultProps = defaultProps

export default React.memo(LabelWithTooltip)
