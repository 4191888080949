import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import exact from 'prop-types-exact'
import { useLocation, useHistory } from 'react-router'
import FundingInstructionsModal from './FundingInstructionsModal'
import ReauthenticationModal from './ReauthenticationModal'

const propTypes = {
  fundingInstructions: PropTypes.object,
  assetID: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
}

const defaultProps = {}

function ViewFundingInstructionsButton({
  fundingInstructions,
  assetName,
  assetID,
}) {
  const location = useLocation()
  const history = useHistory()
  const [modalShown, setModalShown] = useState(
    location.state?.reviewAssetFundingInstructions || false
  )
  const [reauthenticationShown, setReauthenticationShown] = useState(false)
  const ref = useRef(null)
  const handleOptionClickView = () => {
    setModalShown(true)
  }
  return (
    <>
      <div className="update-funding-link" ref={ref}>
        <div className="funding-update-link-text">
          <Link onClick={handleOptionClickView}>View Funding Instructions</Link>
        </div>
      </div>
      {modalShown && fundingInstructions && (
        <FundingInstructionsModal
          fundingInstructions={fundingInstructions}
          onClose={() => {
            setModalShown(false)
          }}
          onUpdateFundingInstructions={() => {
            setModalShown(false), setReauthenticationShown(true)
          }}
          assetName={assetName}
          assetID={assetID}
          isReview={location.state?.reviewAssetFundingInstructions}
          history={history}
        />
      )}
      {reauthenticationShown && (
        <ReauthenticationModal
          onClose={() => {
            setReauthenticationShown(false)
          }}
          backToView={() => {
            setModalShown(true), setReauthenticationShown(false)
          }}
          history={history}
          assetID={assetID}
        />
      )}
    </>
  )
}

ViewFundingInstructionsButton.propTypes = exact(propTypes)
ViewFundingInstructionsButton.defaultProps = defaultProps

export default React.memo(ViewFundingInstructionsButton)
