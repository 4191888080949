import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const propTypes = {}

const defaultProps = {}

function Unauthorized() {
  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])
  const { logout } = useAuth0()
  return (
    <div id="main-content-unauthorized">
      <h4>Unauthorized Credentials</h4>
      <p className="unauthorized-message">
        You`re not authorized to access this page. Please contact the Client
        Service team
        <br className="unauthorized-line-break" />
        Monday - Friday 7:00 AM - 6:00 PM C.T. at (800) 258-7878 with any
        questions.
      </p>
      <button
        className="button-primary"
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Return to Login
      </button>
    </div>
  )
}

Unauthorized.propTypes = propTypes
Unauthorized.defaultProps = defaultProps

export default Unauthorized
