import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { useHistory } from 'react-router-dom'
import * as flashActions from 'redux-flash'
import { ContactInformationForm } from '../forms'
import { selectors as globalSelectors } from 'global-reducer'
import * as Types from 'types'
import * as actions from '../actions'
import * as apiActions from 'api-actions'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  currentUser: Types.user.isRequired,
  setContactDetails: PropTypes.func.isRequired,
  tpaCompanies: PropTypes.arrayOf(PropTypes.object),
  fetchSponsorTPACompanies: PropTypes.func.isRequired,
  clearTPACompanies: PropTypes.func.isRequired,
}
const defaultProps = {
  tpaCompanies: [],
}

function ContactInformation({
  flashErrorMessage,
  currentUser,
  setContactDetails,
  tpaCompanies,
  fetchSponsorTPACompanies,
  clearTPACompanies,
}) {
  const history = useHistory()

  useEffect(() => {
    fetchSponsorTPACompanies()

    return () => clearTPACompanies()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    accountID: currentUser.accountID,
  }

  return (
    <ContactInformationForm
      {...{ initialValues }}
      currentUser={currentUser}
      handleSubmit={(params) => {
        try {
          setContactDetails(params)
          history.push(`/contacts/add-contact/role-assignments`)
        } catch (e) {
          flashErrorMessage(
            e.message || 'Something went wrong, please try again.'
          )
        }
      }}
      tpaCompanies={tpaCompanies}
    />
  )
}

ContactInformation.propTypes = propTypes
ContactInformation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    tpaCompanies: selectors.tpaCompanies(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  setContactDetails: actions.setContactDetails,
  fetchSponsorTPACompanies: apiActions.fetchSponsorTPACompanies,
  clearTPACompanies: actions.clearTPACompanies,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactInformation
)
