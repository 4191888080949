import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import moment from 'moment'

import DatePicker from 'react-datepicker'

import { Portal } from 'react-overlays'

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return <Portal container={el}>{children}</Portal>
}

const DateCellEditor = forwardRef((props, ref) => {
  const refDatePicker = useRef()
  const [date, setDate] = useState(
    moment(props.value).isValid() ? moment(props.value) : ''
  )
  const [editing, setEditing] = useState(true)

  useEffect(() => {
    if (!editing) {
      props.api.stopEditing()
    }
  }, [editing])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return moment(date)
      },
    }
  })

  const onChange = (selectedDate) => {
    setDate(selectedDate)
    setEditing(false)
  }

  return (
    <div>
      <DatePicker
        ref={refDatePicker}
        selected={date}
        placeholderText="mm/dd/yyyy"
        onChange={onChange}
        popperContainer={CalendarContainer}
      />
    </div>
  )
})

DateCellEditor.displayName = 'DateCellEditor'

export default DateCellEditor
