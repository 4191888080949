import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { Routes as OnboardingRoutes } from './onboarding-layout'
import { Routes as OnboardingAddAssetRoutes } from './onboarding'
import { compose } from 'recompose'
import { connect } from 'react-redux'

const OnboardingLayout = () => {
  return (
    <Switch>
      <Route path="/onboarding" component={OnboardingRoutes} />
      <Route
        path="/onboarding-add-asset"
        component={OnboardingAddAssetRoutes}
      />
      <Redirect path="*" to="/onboarding" />
    </Switch>
  )
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  OnboardingLayout
)
