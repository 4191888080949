import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card } from 'components'
import { Link, useLocation } from 'react-router-dom'
import { ADD_ASSET_STAGE, LIQUIDATION_CONFIRMATION_STEPS } from 'config'
import { Spinner } from 'lp-components'
import {
  displayDateTime,
  mapTaskToType,
  sortByDateAttribute,
  namedRoute,
} from 'utils'
import { TASK_RESOLUTION_PATH } from '../../Routes'

const propTypes = {
  tasks: PropTypes.array,
  loading: PropTypes.bool,
}

const defaultProps = {}

const TASKS_LIMIT = 2

function TaskListItem({ task }) {
  let path, state
  const {
    isConfirmPurchase,
    isSubmitAsset,
    isConfirmLiquidation,
    isResolution,
    isConfirmExchange,
    isValuation,
    isFunding,
    isContactReview,
  } = mapTaskToType(task)
  if (isConfirmPurchase) {
    path = `/bulk-confirmations/templates`
    state = {
      selectedInvestors: [{ transactionID: task.recordID }],
    }
  }
  if (isSubmitAsset) {
    // if assetStage is empty, default to asset-details stage (first step)
    const stage = task.assetStage || ADD_ASSET_STAGE.ASSET_DETAILS
    path = `/add-asset/${task.recordID}/${stage}`
  }
  if (isConfirmLiquidation) {
    const endpoint =
      task.subType === LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_SECOND_STEP
        ? 'details'
        : 'templates'
    path = `/confirm-liquidation/${task.recordID}/${endpoint}`
  }
  if (isResolution) {
    path = namedRoute(TASK_RESOLUTION_PATH, {
      subType: task.subType?.toLowerCase(),
      recordId: task.recordID,
      taskId: task.taskID,
    })
    state = {
      subtitle: `Resolve ${task.subType} Submission Documents`,
      breadcrumb: {
        to: '/tasks',
        title: 'Tasks',
      },
    }
  }
  if (isContactReview) {
    path = `/contacts`
  }
  if (isValuation) {
    path = `/assets/${task.recordID}/investors`
  }

  if (isFunding) {
    (path = `/assets/${task.recordID}/investors`),
      (state = {
        reviewAssetFundingInstructions: true,
      })
  }
  if (isConfirmExchange) {
    path = `/confirm-exchange/${task.recordID}/exchange-authorization`
  }
  return (
    <li className="task-list-item">
      <Link to={{ pathname: path, state }}>{task.subject}</Link>
      <p>{displayDateTime(task.createDate, 'MM/DD/YYYY')}</p>
      <p>{task.description}</p>
    </li>
  )
}

function TaskList({ tasks, loading }) {
  if (!tasks || loading) {
    return (
      <Card label="Tasks">
        <Spinner />
      </Card>
    )
  }

  if (!tasks.length) {
    return (
      <Card label="Tasks">
        <h5>All Clear</h5>
        <p>You've completed all your tasks</p>
      </Card>
    )
  }

  const location = useLocation()
  const selectedTasks = sortByDateAttribute(tasks, 'createDate').slice(
    0,
    TASKS_LIMIT
  )

  return (
    <Card
      className="truncated-list"
      label={`Tasks (${tasks.length})`}
      actions={
        <Link
          to={{
            pathname: '/tasks',
            state: {
              target: location.pathname,
              goBackDashboard: true,
              backTo: 'Home',
            },
          }}
        >
          View all
        </Link>
      }
    >
      <ul>
        {selectedTasks.map((task) => (
          <TaskListItem key={task.taskID} task={task} />
        ))}
      </ul>
    </Card>
  )
}

TaskList.propTypes = exact(propTypes)
TaskList.defaultProps = defaultProps

export default React.memo(TaskList)
