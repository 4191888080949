import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { Card, TitleWithTooltip, ScrollToFieldError } from 'components'
import { useParams, useHistory } from 'react-router-dom'
import { Spinner, Checkbox as BaseCheckbox } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'
import { Formik, Form } from 'formik'
import {
  normalizeCountryValue,
  normalizeStateAndProvinceValue,
  withFormikAdapter,
} from 'utils'
import { selectors } from '../reducer'
import * as Yup from 'yup'
import { getSessionToken } from 'auth'
import {
  find,
  keys,
  omitBy,
  isEmpty,
  isNil,
  startCase,
  defaults,
  compact,
} from 'lodash'
import {
  ADD_ASSET_STAGE,
  PHONE_NUMBER_REGEX,
  REAL_ESTATE_ENTITY_STRUCTURE,
  ASSET_TYPES,
  ZIP_CODE_REGEX,
  CANADIAN_ZIP_CODE_REGEX,
  ROLE_IDS,
  COPYABLE_CONTACT_TYPES,
  CONTACT_TYPES,
  addNewContactOption,
} from 'config'
import {
  ConditionalContactSubForm,
  FlowActions,
  ContactSearchAndSelect,
  AdditionalContactFormBlock,
} from '../components'
import * as Types from 'types'
import LabelWithTooltip from 'components/LabelWithTooltip'

const Checkbox = withFormikAdapter()(BaseCheckbox)

const propTypes = {
  storedAsset: PropTypes.object.isRequired,
  updateAsset: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
  contactsList: PropTypes.arrayOf(Types.contact),
  currentUser: Types.user.isRequired,
}

const defaultProps = {
  isNotEditable: false,
}

function ContactDetails({
  storedAsset,
  updateAsset,
  isNotEditable,
  contactsList,
  currentUser,
}) {
  let { assetID } = useParams()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const [savedContacts, setSavedContacts] = useState()
  const [
    thirdPartyFundAdministratorContact,
    setThirdPartyFundAdministratorContact,
  ] = useState()
  const [noteServicerContact, setNoteServicerContact] = useState()
  const [error, setError] = useState()
  const history = useHistory()

  const defaultCompany = {
    companyName: currentUser.accountName,
    companyID: currentUser.accountID,
  }

  // show Note Servicer?
  const isRealEstateTypeSecuredByMortgageOrDeed =
    storedAsset.entityType === REAL_ESTATE_ENTITY_STRUCTURE.securedByMortgage
  const isPrivateDebtType =
    storedAsset.investmentType === ASSET_TYPES.privateDebt

  // show noteServicer?
  const showNoteServicer =
    isRealEstateTypeSecuredByMortgageOrDeed || isPrivateDebtType

  // show thirdPartyAdmin?
  const showThirdPartyAdministrator =
    storedAsset.investmentType === ASSET_TYPES.hedgeFund ||
    storedAsset.investmentType === ASSET_TYPES.privateEquity ||
    storedAsset.entityType === REAL_ESTATE_ENTITY_STRUCTURE.entityWillInvest

  const contactsToOmit = ['adminContact', 'thirdPartyAdmin', 'noteServicer']
  const copyableContactRoleIds = compact(
    Object.values(COPYABLE_CONTACT_TYPES).map(
      (contactType) => ROLE_IDS[contactType]
    )
  )

  useEffect(() => {
    setSavedContacts({
      adminContact: find(storedAsset?.contacts, {
        roleID: ROLE_IDS.adminContact,
      }),
      noteServicer: find(storedAsset?.contacts, {
        roleID: ROLE_IDS.noteServicer,
      }),
      purchaseContact: find(storedAsset?.contacts, {
        roleID: ROLE_IDS.purchaseContact,
      }),
      liquidationContact: find(storedAsset?.contacts, {
        roleID: ROLE_IDS.liquidationContact,
      }),
      exchangeContact: find(storedAsset?.contacts, {
        roleID: ROLE_IDS.exchangeContact,
      }),
      reRegContact: find(storedAsset?.contacts, {
        roleID: ROLE_IDS.reRegContact,
      }),
      thirdPartyAdmin: showThirdPartyAdministrator
        ? find(storedAsset?.contacts, {
            roleID: ROLE_IDS.thirdPartyAdmin,
          })
        : {},
    })

    const thirdPartyContact = find(storedAsset?.contacts, {
      roleID: ROLE_IDS.thirdPartyAdmin,
    })

    setThirdPartyFundAdministratorContact(thirdPartyContact)

    const noteServicer = find(storedAsset?.contacts, {
      roleID: ROLE_IDS.noteServicer,
    })

    setNoteServicerContact(noteServicer)
  }, [storedAsset])

  if (!contactsList) {
    return <Spinner />
  }

  const contactInfo = {
    purchaseContact: {
      displayName: 'Purchase Contact',
      helpText:
        'Individual who is responsible for receiving and confirming executed subscription documents for purchases of an investment',
      showAddButton: true,
    },
    liquidationContact: {
      displayName: 'Liquidation Contact',
      helpText:
        'Individual who is responsible for receiving and confirming executed liquidation requests for liquidations of an investment',
      showAddButton: true,
    },
    exchangeContact: {
      displayName: 'Exchange Contact',
      helpText:
        'Individual who is responsible for receiving and confirming executed subscription and liquidations documents for exchanges between investments',
      showAddButton: true,
    },
    reRegContact: {
      displayName: 'Re-Reg Contact',
      helpText:
        'Individual Issuer who is responsible for receiving and confirming (if applicable) executed offering documents for incoming and outgoing transfers of investments',
      showAddButton: true,
    },
  }

  const handleSubmit = async (values) => {
    const contactData = []

    const { adminContact } = values
    contactData.push({
      ...adminContact,
      contactID:
        adminContact.match !== addNewContactOption.value
          ? adminContact.match
          : '',
      roleID: ROLE_IDS.adminContact,
      relationshipID: savedContacts?.adminContact?.relationshipID || '',
      isActive: true,
    })

    const hasThirdPartyAdmin =
      values.isThereThirdPartyFundAdministrator?.toString() === 'true'
    const hasNoteServicer = values.isThereNoteServicer?.toString() === 'true'

    // add 3pa values to submission object
    const thirdPartyAdmin = {
      ...thirdPartyFundAdministratorContact,
      ...values.thirdPartyAdmin,
      accountID: values.thirdPartyAdmin.companyID,
      contactID:
        values.thirdPartyAdmin.match !== addNewContactOption.value
          ? values.thirdPartyAdmin.match
          : '',
      roleID: ROLE_IDS.thirdPartyAdmin,
      isActive: true,
    }

    if (hasThirdPartyAdmin) contactData.push(thirdPartyAdmin)
    if (!hasThirdPartyAdmin && thirdPartyFundAdministratorContact?.contactID)
      contactData.push({ ...thirdPartyAdmin, isActive: false })

    // add note servicer values to submission object
    const noteServicer = {
      ...noteServicerContact,
      ...values.noteServicer,
      contactID:
        values.noteServicer.match !== addNewContactOption.value
          ? values.noteServicer.match
          : '',

      roleID: ROLE_IDS.noteServicer,
      isActive: true,
    }

    if (hasNoteServicer) contactData.push(noteServicer)
    if (!hasNoteServicer && noteServicerContact?.contactID)
      contactData.push({ ...noteServicer, isActive: false })

    // we now must check the saved contacts that loaded from the backend so that we can set newly deleted contacts to isActive: false and clear the old data
    const keysToKeep = ['contactID', 'relationshipID', 'roleID']

    keys(omitBy(savedContacts, isNil))
      .filter((key) => !contactsToOmit.includes(key))
      .forEach((contactKey) => {
        // savedContact should be removed as explicitly done by user or matching contact is no longer found
        if (
          (!hasThirdPartyAdmin &&
            savedContacts[contactKey].equalSelection ===
              COPYABLE_CONTACT_TYPES.THIRD_PARTY_ADMIN) ||
          (!hasNoteServicer &&
            savedContacts[contactKey].equalSelection ===
              COPYABLE_CONTACT_TYPES.NOTE_SERVICER)
        ) {
          let clearedContact = { ...savedContacts[contactKey] }
          // null out fields that aren't IDs for contact
          Object.keys(clearedContact).forEach((key) => {
            if (!keysToKeep.includes(key)) clearedContact[key] = ''
          })
          clearedContact.isActive = false
          clearedContact.isAuthorizedSigner = false
          contactData.push(clearedContact)
        }
      })

    // add each active contact type (if they are copies of admin or 3pa, make that swap here)
    const setContactID = ({ contact }) => {
      return contact.match !== addNewContactOption.value ? contact.match : ''
    }

    Object.keys(contactInfo).forEach((contactType) => {
      const data = values[contactType]
      const contact = {
        ...data,
        roleID:
          contactType === CONTACT_TYPES.reRegContact
            ? ROLE_IDS.reRegContact
            : startCase(contactType),
        relationshipID: savedContacts[contactType]?.relationshipID || '',

        isCopiedFromAdminContact:
          data.equalSelection === COPYABLE_CONTACT_TYPES.ADMIN,
        isCopiedFromThirdPartyAdminContact:
          data.equalSelection === COPYABLE_CONTACT_TYPES.THIRD_PARTY_ADMIN,
        isCopiedFromNoteServicerContact:
          data.equalSelection === COPYABLE_CONTACT_TYPES.NOTE_SERVICER,
      }
      contact.contactID = setContactID({ contact: data })
      contactData.push(contact)
    })
    try {
      await updateAsset({
        ...storedAsset,
        stage: values.requireAll
          ? ADD_ASSET_STAGE.FUNDING_DETAILS
          : ADD_ASSET_STAGE.CONTACT_DETAILS,
        saveStage: ADD_ASSET_STAGE.CONTACT_DETAILS,
        contacts: contactData,
      })

      if (values.requireAll) {
        history.push(`/add-asset/${assetID}/funding-details`)
      } else {
        history.push('/home')
      }
    } catch (e) {
      setError(e)
    }
  }

  const getIsEqualSelection = (contact) => {
    let equalSelection = ''
    if (!savedContacts) return equalSelection

    const copyableContacts = Object.values(savedContacts).filter((contact) =>
      copyableContactRoleIds.includes(contact?.roleID)
    )

    copyableContacts.forEach((copyableContact) => {
      if (
        copyableContact?.contactID &&
        copyableContact?.contactID === contact?.contactID &&
        copyableContact?.roleID !== contact?.roleID
      ) {
        const roleId = copyableContact?.roleID
        equalSelection = Object.keys(ROLE_IDS).find(
          (key) => ROLE_IDS[key] === roleId
        )
      }
    })

    if (equalSelection) return equalSelection
    if (!isEmpty(contact)) return COPYABLE_CONTACT_TYPES.OTHER
    return equalSelection
  }

  const defaultContact = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    state: '',
    streetAddress: '',
    city: '',
    zipcode: '',
  }

  const initialValues = {
    adminContact: {
      ...defaults(
        {
          ...savedContacts?.adminContact,
          country: normalizeCountryValue(savedContacts?.adminContact?.country),
          state: normalizeStateAndProvinceValue(
            savedContacts?.adminContact?.country,
            savedContacts?.adminContact?.state
          ),
          companyID:
            savedContacts?.adminContact?.accountID || defaultCompany.companyID,
        },
        defaultContact
      ),
      isAuthorizedSigner:
        savedContacts?.adminContact?.isAuthorizedSigner || false,
      match: savedContacts?.adminContact?.contactID || '',
    },
    thirdPartyAdmin: {
      ...defaults(
        {
          ...savedContacts?.thirdPartyAdmin,
          country: normalizeCountryValue(
            savedContacts?.thirdPartyAdmin?.country
          ),
          state: normalizeStateAndProvinceValue(
            savedContacts?.thirdPartyAdmin?.country,
            savedContacts?.thirdPartyAdmin?.state
          ),
          companyID:
            savedContacts?.thirdPartyAdmin?.accountID ||
            defaultCompany.companyID,
        },
        defaultContact
      ),
      match: savedContacts?.thirdPartyAdmin?.contactID || '',
    },
    noteServicer: {
      ...defaults(
        {
          ...savedContacts?.noteServicer,
          country: normalizeCountryValue(savedContacts?.noteServicer?.country),
          state: normalizeStateAndProvinceValue(
            savedContacts?.noteServicer?.country,
            savedContacts?.noteServicer?.state
          ),
          companyID:
            savedContacts?.noteServicer?.accountID || defaultCompany.companyID,
        },
        defaultContact
      ),
      match: savedContacts?.noteServicer?.contactID || '',
    },
    purchaseContact: {
      ...defaults(
        {
          ...savedContacts?.purchaseContact,
          country: normalizeCountryValue(
            savedContacts?.purchaseContact?.country
          ),
          state: normalizeStateAndProvinceValue(
            savedContacts?.purchaseContact?.country,
            savedContacts?.purchaseContact?.state
          ),
        },
        defaultContact
      ),
      equalSelection: getIsEqualSelection(savedContacts?.purchaseContact),
      match: savedContacts?.purchaseContact?.contactID || '',
    },
    liquidationContact: {
      ...defaults(
        {
          ...savedContacts?.liquidationContact,
          country: normalizeCountryValue(
            savedContacts?.liquidationContact?.country
          ),
          state: normalizeStateAndProvinceValue(
            savedContacts?.liquidationContact?.country,
            savedContacts?.liquidationContact?.state
          ),
        },
        defaultContact
      ),
      equalSelection: getIsEqualSelection(savedContacts?.liquidationContact),
      match: savedContacts?.liquidationContact?.contactID || '',
    },
    exchangeContact: {
      ...defaults(
        {
          ...savedContacts?.exchangeContact,
          country: normalizeCountryValue(
            savedContacts?.exchangeContact?.country
          ),
          state: normalizeStateAndProvinceValue(
            savedContacts?.exchangeContact?.country,
            savedContacts?.exchangeContact?.state
          ),
        },
        defaultContact
      ),
      equalSelection: getIsEqualSelection(savedContacts?.exchangeContact),
      match: savedContacts?.exchangeContact?.contactID || '',
    },
    reRegContact: {
      ...defaults(
        {
          ...savedContacts?.reRegContact,
          country: normalizeCountryValue(savedContacts?.reRegContact?.country),
          state: normalizeStateAndProvinceValue(
            savedContacts?.reRegContact?.country,
            savedContacts?.reRegContact?.state
          ),
        },
        defaultContact
      ),
      equalSelection: getIsEqualSelection(savedContacts?.reRegContact),
      match: savedContacts?.reRegContact?.contactID || '',
    },

    isThereThirdPartyFundAdministrator:
      showThirdPartyAdministrator &&
      !isEmpty(thirdPartyFundAdministratorContact)
        ? 'true'
        : 'false',

    isThereNoteServicer:
      showNoteServicer && !isEmpty(noteServicerContact) ? 'true' : 'false',
  }

  const lazyValidation = Yup.lazy((values) => {
    const requireAllFieldValidationScheme = {
      email: Yup.string().when('match', {
        is: addNewContactOption.value,
        then: Yup.string()
          .email('Must enter valid email!')
          .test(
            'emailValidationCheck',
            'This email is already associated with a contact for Asset Custody Platform. Please use another email address.',
            (email) =>
              fetch(`${process.env.API_URL}/emailAddresses?email=${email}`, {
                method: 'GET',
                headers: {
                  Accept: `application/json`,
                  Authorization: `Bearer ${getSessionToken()}`,
                },
              }).then(async (res) => {
                const data = await res.json()
                if (data.isValid) {
                  return true
                } else {
                  return false
                }
              })
          )
          .required('Required')
          .nullable(),
        otherwise: Yup.string()
          .email('Must enter valid email!')
          .required('Required')
          .nullable(),
      }),
      phone: Yup.string()
        .matches(RegExp(PHONE_NUMBER_REGEX), 'Please enter valid phone number')
        .required('Required'),
      streetAddress: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Required'),
      city: Yup.string()
        .min(2, 'Too Short!')
        .max(40, 'Too Long!')
        .required('Required'),
      state: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      zipcode: Yup.string()
        .test('test_zip_code', '', (value, context) => {
          const { parent, createError } = context
          if (parent.country == 'US') {
            if (!RegExp(ZIP_CODE_REGEX).test(value)) {
              return createError({
                message: 'Must be valid 5 or 9 digit US zip code!',
              })
            }
          }
          if (parent.country == 'CA') {
            if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
              return createError({
                message: 'Must be a valid six-character CA code!',
              })
            }
          }
          return true
        })
        .required('Required')
        .nullable(),
    }

    const requiredFieldValidationScheme = {
      match: Yup.string().required('Required'),
      firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(40, 'Too Long!')
        .required('Required')
        .nullable(),
      lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(80, 'Too Long!')
        .required('Required')
        .nullable(),
    }

    const notRequiredCategoryFieldValidationScheme = {
      match: Yup.string().notRequired(),
      firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(40, 'Too Long!')
        .notRequired(),
      lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(80, 'Too Long!')
        .notRequired(),
      email: Yup.string().when('match', {
        is: addNewContactOption.value,
        then: Yup.string()
          .email('Must enter valid email!')
          .test(
            'emailValidationCheck',
            'This email is already associated with a contact for Asset Custody Platform. Please use another email address.',
            (email) =>
              fetch(`${process.env.API_URL}/emailAddresses?email=${email}`, {
                method: 'GET',
                headers: {
                  Accept: `application/json`,
                  Authorization: `Bearer ${getSessionToken()}`,
                },
              }).then(async (res) => {
                const data = await res.json()
                if (data.isValid) {
                  return true
                } else {
                  return false
                }
              })
          )
          .notRequired()
          .nullable(),
        otherwise: Yup.string()
          .email('Must enter valid email!')
          .notRequired()
          .nullable(),
      }),
      phone: Yup.string()
        .matches(RegExp(PHONE_NUMBER_REGEX), 'Please enter valid phone number')
        .notRequired(),
      streetAddress: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .notRequired(),
      city: Yup.string()
        .min(2, 'Too Short!')
        .max(40, 'Too Long!')
        .notRequired(),
      state: Yup.string().notRequired(),
      country: Yup.string().notRequired(),
      zipcode: Yup.string()
        .notRequired()
        .test('test_zip_code', '', (value, context) => {
          const { parent, createError } = context
          if (parent.country == 'US') {
            if (!RegExp(ZIP_CODE_REGEX).test(value)) {
              return createError({
                message: 'Must be valid 5 or 9 digit US zip code!',
              })
            }
          }
          if (parent.country == 'CA') {
            if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
              return createError({
                message: 'Must be a valid six-character CA code!',
              })
            }
          }
          return true
        }),
      companyID: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .notRequired(),
    }

    const validationObj = {
      adminContact: values.requireAll
        ? Yup.object().shape({
            ...requiredFieldValidationScheme,
            ...requireAllFieldValidationScheme,
            companyID: Yup.string()
              .min(2, 'Too Short!')
              .max(255, 'Too Long!')
              .required('Required'),
          })
        : Yup.object().shape({
            ...requiredFieldValidationScheme,
          }),
      thirdPartyAdmin:
        values.isThereThirdPartyFundAdministrator === 'true'
          ? values.requireAll
            ? Yup.object().shape({
                ...requiredFieldValidationScheme,
                ...requireAllFieldValidationScheme,
                companyID: Yup.string()
                  .min(2, 'Too Short!')
                  .max(255, 'Too Long!')
                  .required('Required'),
              })
            : Yup.object().shape({
                ...requiredFieldValidationScheme,
              })
          : Yup.object().shape(notRequiredCategoryFieldValidationScheme),
      noteServicer:
        values.isThereNoteServicer === 'true'
          ? values.requireAll
            ? Yup.object().shape({
                ...requiredFieldValidationScheme,
                ...requireAllFieldValidationScheme,
              })
            : Yup.object().shape({
                ...requiredFieldValidationScheme,
              })
          : Yup.object().shape(notRequiredCategoryFieldValidationScheme),
      isThereThirdPartyFundAdministrator: Yup.string().required('Required'),
      isThereNoteServicer: Yup.string().required('Required'),
    }

    Object.keys(contactInfo).forEach((type) => {
      if (values[type]?.equalSelection === COPYABLE_CONTACT_TYPES.OTHER) {
        validationObj[type] = values.requireAll
          ? Yup.object().shape({
              ...requiredFieldValidationScheme,
              ...requireAllFieldValidationScheme,
            })
          : Yup.object().shape({
              ...requiredFieldValidationScheme,
            })
      }
      if (!values[type]?.equalSelection) {
        validationObj[type] = Yup.object().shape({
          equalSelection: Yup.string().required('Required'),
        })
      }
    })
    return Yup.object(validationObj)
  })

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  const disclaimerMessage =
    'Issuer authorizes Inspira to discuss the investment(s) with any of the individuals listed above.'

  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={lazyValidation}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            setErrors,
            setTouched,
            touched,
            resetForm,
          }) => (
            <Form className="asset-form asset-details-form">
              <ScrollToFieldError />
              <Card>
                <div className="form-card-content">
                  <div className="additional-form-header">
                    <TitleWithTooltip
                      title="Administrative Contact"
                      content="Individual at Issuer who is responsible for day-to-day
                      communications and subscription documents (if there is no
                      third party fund administrator)"
                    />
                  </div>
                  <fieldset
                    className="read-only-wrapper"
                    disabled={isNotEditable}
                  >
                    <div className="container contact-upload-container">
                      <div className="searchable-select-wrapper">
                        <ContactSearchAndSelect
                          contactType="adminContact"
                          isNotEditable={isNotEditable}
                          contactsList={contactsList}
                        />
                      </div>
                      <hr />
                      <div className="row">
                        <div className="checkbox-line-item">
                          <Checkbox
                            name="adminContact[isAuthorizedSigner]"
                            label="By checking this box, the issuer authorizes the Administrative Contact listed above to sign all agreements, forms, and other documents related to the (asset/investment) on behalf of the issuer."
                          />
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </fieldset>
                </div>
              </Card>
              {// only show 3pa if hedge fund, private equity or real estate that will invest
              showThirdPartyAdministrator && (
                <Card className="conditional-contact-card">
                  <ConditionalContactSubForm
                    values={values}
                    title="Third Party Fund Administrator"
                    description="An independent third party administration company that is responsible
                    for collecting and processing subscription documents, valuations and
                    statements and maintaining records of investors."
                    keyName="thirdPartyAdmin"
                    conditionalKey="isThereThirdPartyFundAdministrator"
                    resetForm={resetForm}
                    contactsList={contactsList}
                    isNotEditable={isNotEditable}
                    customEqualValueSuffix="Equal3pa"
                  />
                </Card>
              )}

              {showNoteServicer && (
                <Card className="conditional-contact-card">
                  <ConditionalContactSubForm
                    values={values}
                    title="Note Servicer"
                    description="Individual Issuer who is responsible for administration of loans and notes."
                    keyName="noteServicer"
                    conditionalKey="isThereNoteServicer"
                    resetForm={resetForm}
                    isNotEditable={isNotEditable}
                    contactsList={contactsList}
                  />
                </Card>
              )}
              <section className="contact-cards">
                {Object.keys(contactInfo).map((type) => (
                  <React.Fragment key={type}>
                    {!values[type].contactID && isNotEditable ? (
                      ''
                    ) : (
                      <Card
                        className="conditional-contact-card"
                        label={
                          isNotEditable ? (
                            <LabelWithTooltip
                              label={`${contactInfo[type].displayName} Details`}
                              tooltipContent={contactInfo[type].helpText}
                            />
                          ) : (
                            <LabelWithTooltip
                              label={`Add ${contactInfo[type].displayName} Details`}
                              tooltipContent={contactInfo[type].helpText}
                            />
                          )
                        }
                      >
                        <AdditionalContactFormBlock
                          values={values}
                          type={type}
                          hideBlock={values[`${type}EqualAdmin`] === true}
                          setFieldValue={setFieldValue}
                          setErrors={setErrors}
                          setTouched={setTouched}
                          touched={touched}
                          contactsList={contactsList}
                          showThirdPartyAdministratorInput={
                            showThirdPartyAdministrator &&
                            values.isThereThirdPartyFundAdministrator === 'true'
                          }
                          showNoteServicerInput={
                            showNoteServicer &&
                            values.isThereNoteServicer === 'true'
                          }
                          isNotEditable={isNotEditable}
                        />
                      </Card>
                    )}
                  </React.Fragment>
                ))}
              </section>
              {!isNotEditable && (
                <Card className="conditional-contact-card">
                  <div className="disclosure">{disclaimerMessage}</div>
                </Card>
              )}
              {!isNotEditable && <FlowActions />}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

ContactDetails.propTypes = propTypes

ContactDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    contactsList: selectors.contactsList(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  updateAsset: apiActions.updateAsset,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactDetails
)
