import React from 'react'
import { Route, Redirect, Switch } from 'react-router'
import SidebarLayout from './SidebarLayout'
import { Routes as HomeRoutes } from './home'
import { Routes as AddAssetRoutes } from './add-asset-flow'
import { Routes as AssetCustodyRoutes } from './asset-custody'
import { Routes as ConfirmLiquidationRoutes } from './liquidation-confirmation'
import { Routes as ConfirmExchangeRoutes } from './exchange-confirmation'
import { Routes as CapitalCallRoutes } from './capital-commitments-flow'
import { Routes as DocumentsRoutes } from './documents'
import { Routes as UserProfileRoutes } from './user-profile'
import { Routes as ContactProfileRoutes } from './contact-profile'
import { Routes as ContactsRoutes } from './contacts-management'
import { Routes as InvitationRoutes } from './invitation'
import { Routes as ContactRoleReplacementRoutes } from './contact-role-replacements'
import { Routes as TransactionsRoutes } from './transactions'
import { Routes as BulkConfirmationRoutes } from './bulk-confirmations'
import { Routes as ResourceRoutes } from './resources'
import { Routes as UnauthorizedRoutes } from './unauthorized-layout'
import { Routes as FundingInstructionRoutes } from './funding-instructions'
import AuthorizedLayout from './AuthorizedLayout'
import RolesAuthorizedLayout from './RolesAuthorizedLayout'
import ErrorPage from './ErrorPage'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as routerSelectors from 'connected-react-router'
import { selectors as globalSelectors } from 'global-reducer'
import { Auth0Provider } from '@auth0/auth0-react'
import { TaskTable } from './home/components'
import * as Views from './home/views'

const propTypes = {
  // match: PropTypes.object.isRequired,
}

const defaultProps = {}

export const TASK_RESOLUTION_PATH = '/tasks/:taskId/resolve/:subType/:recordId'

function Routes() {
  return (
    <Switch>
      <Route path="/error" component={ErrorPage} />
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <AuthorizedLayout>
          <RolesAuthorizedLayout>
            <Switch>
              <Route path="/add-asset" component={AddAssetRoutes} />
              <Route path="/invitation" component={InvitationRoutes} />
              <Route path="/contacts/add-contact" component={ContactsRoutes} />
              <Route path="/capital-call" component={CapitalCallRoutes} />

              <Route
                path="/confirm-liquidation"
                component={ConfirmLiquidationRoutes}
              />
              <Route
                path="/confirm-exchange"
                component={ConfirmExchangeRoutes}
              />
              <Route
                path="/contact-role-replacements"
                component={ContactRoleReplacementRoutes}
              />
              <Route
                path="/bulk-confirmations"
                component={BulkConfirmationRoutes}
              />
              <Route
                path="/funding-instructions"
                component={FundingInstructionRoutes}
              />
              <SidebarLayout>
                <Switch>
                  <Route exact path="/home" component={HomeRoutes} />
                  <Route path="/assets" component={AssetCustodyRoutes} />
                  <Route path="/profile" component={UserProfileRoutes} />
                  <Route path="/documents" component={DocumentsRoutes} />
                  <Route
                    path={TASK_RESOLUTION_PATH}
                    component={Views.TaskResolution}
                  />
                  <Route exact path="/tasks" component={TaskTable} />
                  <Route path="/transactions" component={TransactionsRoutes} />
                  <Route path="/profiles" component={ContactProfileRoutes} />
                  <Route path="/contacts" component={ContactsRoutes} />
                  <Route path="/resources" component={ResourceRoutes} />

                  <Redirect path="*" to="/home" />
                </Switch>
              </SidebarLayout>
            </Switch>
          </RolesAuthorizedLayout>
          <Route
            exact
            path="/unauthorized-profile"
            component={UnauthorizedRoutes}
          />
        </AuthorizedLayout>
      </Auth0Provider>
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    pathname: routerSelectors.getLocation(state).pathname,
    isAuthenticated: globalSelectors.isAuthenticated(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Routes)
