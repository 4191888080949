import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { DocumentsTable } from '../components'
import { TRANSACTION_TYPE, DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS } from 'config'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'

const propTypes = {
  completedTransactions: PropTypes.arrayOf(Types.transactions),
  fetchCompletedTransactions: PropTypes.func.isRequired,
}

const defaultProps = {}

function LiquidationDocuments({
  completedTransactions,
  fetchCompletedTransactions,
}) {
  useEffect(() => {
    fetchCompletedTransactions()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!completedTransactions) return <Spinner />

  const filteredLiquidation = completedTransactions.filter((transaction) => {
    return transaction.type === TRANSACTION_TYPE.LIQUIDATION
  })

  const downloadTransactionType =
    DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS[TRANSACTION_TYPE.LIQUIDATION]

  return (
    <>
      <DocumentsTable
        documents={filteredLiquidation}
        downloadTransactionType={downloadTransactionType}
      />
    </>
  )
}

LiquidationDocuments.propTypes = propTypes

LiquidationDocuments.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    completedTransactions: selectors.completedTransactions(state),
  }
}

const mapDispatchToProps = {
  fetchCompletedTransactions: apiActions.fetchCompletedTransactions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LiquidationDocuments
)
