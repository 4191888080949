import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import classnames from 'classnames'
import { Card } from 'components'
import { CustomCheckBox } from '../components'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'

const propTypes = {
  clearDocuments: PropTypes.func.isRequired,
  downloadDocuments: PropTypes.arrayOf(PropTypes.object),
  downloadRequiredDocumentsByTransactionID: PropTypes.func.isRequired,
  fetchRequiredDocumentsByTransactionID: PropTypes.func.isRequired,
}

const defaultProps = {
  downloadDocuments: null,
}

function TransactionDocuments({
  clearDocuments,
  downloadDocuments,
  downloadRequiredDocumentsByTransactionID,
  fetchRequiredDocumentsByTransactionID,
}) {
  const params = useParams()
  const { transactionType } = params
  const [checkBoxGroup, setCheckBoxGroup] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [transactionID, setTransactionID] = useState(null)
  const [buttonState, setButtonState] = useState('loaded')
  const anyDocumentsSelected = checkBoxGroup.length
  const allDocumentsSelected =
    checkBoxGroup.length === downloadDocuments?.length
  const partialDocumentsSelected =
    anyDocumentsSelected < downloadDocuments?.length

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      const { transactionID } = params
      setTransactionID(transactionID)
      fetchRequiredDocumentsByTransactionID(transactionID)
    }
    return () => clearDocuments()
  }, [])

  useEffect(() => {
    setIsSelectAll(false)
    if (allDocumentsSelected) {
      setIsSelectAll(true)
    }
  }, [checkBoxGroup])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!downloadDocuments) return <Spinner />

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll)
    setCheckBoxGroup(
      downloadDocuments.map((li) => ({
        requiredDocumentID: li.requiredDocumentID,
        requiredDocumentName: li.requiredDocumentName,
      }))
    )
    if (isSelectAll) {
      setCheckBoxGroup([])
    }
  }

  const handleClick = (e) => {
    const { id, name, checked } = e.target
    setCheckBoxGroup([
      ...checkBoxGroup,
      { requiredDocumentID: id, requiredDocumentName: name },
    ])
    if (!checked) {
      setCheckBoxGroup(
        checkBoxGroup.filter((item) => item.requiredDocumentID !== id)
      )
    }
  }

  const onClick = () => {
    checkBoxGroup.forEach((document) => {
      downloadRequiredDocumentsByTransactionID(
        transactionType,
        transactionID,
        document.requiredDocumentID,
        document.requiredDocumentName
      )
    })
  }

  const asyncOnClick = async () => {
    setButtonState('loading')
    await onClick()
    await new Promise((r) => setTimeout(r, 2000))
    setButtonState('loaded')
  }

  const documents = downloadDocuments.map(
    ({ requiredDocumentID, requiredDocumentName }) => {
      return (
        <React.Fragment key={requiredDocumentID}>
          <div className="navigation-line"></div>
          <CustomCheckBox
            className="checkbox"
            name={requiredDocumentName}
            id={requiredDocumentID}
            handleClick={handleClick}
            isChecked={
              checkBoxGroup.find(
                (doc) => doc.requiredDocumentID === requiredDocumentID
              )
                ? true
                : false
            }
          >
            {requiredDocumentName}
          </CustomCheckBox>
        </React.Fragment>
      )
    }
  )

  return (
    <Card label="Transaction Documents" className="transaction-documents">
      <p>Please select the documents you would like to download</p>
      <div className="select-all-block">
        <CustomCheckBox
          className={classnames('checkbox', {
            'partial-documents-selected':
              anyDocumentsSelected && partialDocumentsSelected,
          })}
          handleClick={handleSelectAll}
          id="selectAll"
          isChecked={isSelectAll}
          name="selectAll"
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Select All
        </CustomCheckBox>
        <div className="table-actions-left">
          <button
            className={classnames('button-primary', {
              'is-disabled': anyDocumentsSelected === 0,
            })}
            disabled={anyDocumentsSelected === 0}
            onClick={asyncOnClick}
          >
            {buttonState === 'loaded' ? (
              <span>Download File(s)</span>
            ) : (
              <Spinner className="spinnerButton" />
            )}
          </button>
        </div>
      </div>
      <div>{documents}</div>
    </Card>
  )
}

function mapStateToProps(state) {
  return {
    downloadDocuments: selectors.downloadDocuments(state),
  }
}

const mapDispatchToProps = {
  fetchRequiredDocumentsByTransactionID:
    apiActions.fetchRequiredDocumentsByTransactionID,
  downloadRequiredDocumentsByTransactionID:
    apiActions.downloadRequiredDocumentsByTransactionID,
  clearDocuments: actions.clearDocuments,
}

TransactionDocuments.propTypes = propTypes
TransactionDocuments.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TransactionDocuments
)
