import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useParams, useHistory } from 'react-router-dom'
import { Card } from 'components'
import * as Yup from 'yup'
import {
  ADD_ASSET_STAGE,
  PHONE_NUMBER_REGEX,
  ZIP_CODE_REGEX,
  CANADIAN_ZIP_CODE_REGEX,
} from 'config'
import { FlowActions, AssetFlowFundingInstructionsContent } from '../components'

const propTypes = {
  storedAsset: PropTypes.object.isRequired,
  updateAsset: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
}

const defaultProps = {
  isNotEditable: false,
}

function FundingInstructions({ storedAsset, updateAsset, isNotEditable }) {
  let { assetID } = useParams()
  const history = useHistory()
  const [error, setError] = useState()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const initialValues = {
    fundingType: storedAsset?.fundingInstructions?.isACH
      ? 'ach'
      : storedAsset?.fundingInstructions?.isCheck
      ? 'check'
      : 'bankTransfer',

    isBankTransfer: storedAsset?.fundingInstructions?.isBankTransfer,
    isACH: storedAsset?.fundingInstructions?.isACH,
    isCheck: storedAsset?.fundingInstructions?.isCheck,

    // bank transfer info
    bankTransferTransitABANumber:
      storedAsset?.fundingInstructions?.abaNumber || '',
    bankTransferBankAccountNumber:
      storedAsset?.fundingInstructions?.accountNumber || '',
    bankTransferAccountName:
      storedAsset?.fundingInstructions?.accountName || '',
    bankTransferBankName: storedAsset?.fundingInstructions?.bankName || '',
    bankTransferBankPhone: storedAsset?.fundingInstructions?.bankPhone || '',
    bankTransferForFurtherCreditAccountNumber:
      storedAsset?.fundingInstructions?.furtherCreditAccountNumber || '', // optional
    bankTransferSwiftCode: storedAsset?.fundingInstructions?.swiftCode || '', // optional
    bankTransferBankAccountType: storedAsset?.fundingInstructions
      ?.isCheckingAccount
      ? 'checking'
      : storedAsset?.fundingInstructions?.isSavingsAccount
      ? 'savings'
      : '',
    bankTransferAdditionalWireInstructions:
      storedAsset?.fundingInstructions?.additionalWire || '', // optional

    // check info
    checkPayee: storedAsset?.fundingInstructions?.payee || '',
    checkAttention: storedAsset?.fundingInstructions?.attention || '', // optional
    checkCountry: storedAsset?.fundingInstructions?.country || '',
    checkAddress: storedAsset?.fundingInstructions?.streetAddress || '',
    checkAddressLine2:
      storedAsset?.fundingInstructions?.checkAddressLine2 || '', // optional
    checkCity: storedAsset?.fundingInstructions?.city || '',
    checkState: storedAsset?.fundingInstructions?.state || '',
    checkZipCode: storedAsset?.fundingInstructions?.zipcode || '',

    // ACH info
    ACHTransitABANumber: storedAsset?.fundingInstructions?.abaNumber || '',
    ACHAccountName: storedAsset?.fundingInstructions?.accountName || '',
    ACHBankAccountNumber: storedAsset?.fundingInstructions?.accountNumber || '',
    ACHBankName: storedAsset?.fundingInstructions?.bankName || '',
    ACHBankPhone: storedAsset?.fundingInstructions?.bankPhone || '',
    ACHForFurtherCreditAccountNumber:
      storedAsset?.fundingInstructions?.furtherCreditAccountNumber || '', // optional
  }

  const handleSubmit = async (values /*{ setErrors }*/) => {
    const payload = {
      abaNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferTransitABANumber
          : values.fundingType === 'ach'
          ? values.ACHTransitABANumber
          : undefined,
      accountNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountNumber
          : values.fundingType === 'ach'
          ? values.ACHBankAccountNumber
          : undefined,
      accountName:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferAccountName
          : values.fundingType === 'ach'
          ? values.ACHAccountName
          : undefined,
      additionalWire:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferAdditionalWireInstructions
          : '',
      swiftCode:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferSwiftCode
          : '',
      furtherCreditAccountNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferForFurtherCreditAccountNumber
          : values.fundingType === 'ach'
          ? values.ACHForFurtherCreditAccountNumber
          : '',
      bankName:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankName
          : values.fundingType === 'ach'
          ? values.ACHBankName
          : undefined,
      bankPhone:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankPhone
          : values.fundingType === 'ach'
          ? values.ACHBankPhone
          : undefined,

      payee: values.fundingType === 'check' ? values.checkPayee : '',
      streetAddress: values.fundingType === 'check' ? values.checkAddress : '',
      city: values.fundingType === 'check' ? values.checkCity : '',
      state: values.fundingType === 'check' ? values.checkState : '',
      country: values.fundingType === 'check' ? values.checkCountry : '',
      zipcode: values.fundingType === 'check' ? values.checkZipCode : '',
      attention: values.fundingType === 'check' ? values.checkAttention : '',

      isACH: values.fundingType === 'ach',
      isBankTransfer: values.fundingType === 'bankTransfer',
      isCheck: values.fundingType === 'check',
      isCheckingAccount:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountType === 'checking'
          : undefined,
      isSavingsAccount:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountType === 'savings'
          : undefined,
    }
    /*const giactPayload = {
      CheckRTPEligibility: true,
      Balance: null,
      AccountNumber: null,
      Account: {
        RoutingNumber: payload.abaNumber,
        AccountType: values.bankTransferBankAccountType,
        AccountNumber: payload.accountNumber,
      },
    }
    try {
      if (
        values.fundingType === 'bankTransfer' ||
        values.fundingType === 'ach'
      ) {
        const {
          bankName,
          accountResponse,
          verificationResponse,
        } = await giactVerify(giactPayload)
        if (
          values.fundingType === 'check' ||
          (bankName && verificationResponse.code === 'Pass')
        ) {
          await updateAsset({
            ...storedAsset,
            stage: values.requireAll
              ? ADD_ASSET_STAGE.PURCHASE_REQUIREMENTS
              : ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
            saveStage: ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
            fundingInstructions: { ...payload },
          })

          if (values.requireAll) {
            history.push(`/add-asset/${assetID}/purchase-requirements`)
          } else {
            history.push('/home')
          }
        } else {
          const errors = {}
          if (!bankName && accountResponse.code !== 'GS02') {
            errors[
              values.fundingType === 'bankTransfer'
                ? 'bankTransferTransitABANumber'
                : 'ACHTransitABANumber'
            ] = 'Please enter a valid 9-digit routing number.'
          }
          if (verificationResponse.code !== 'Pass') {
            errors[
              values.fundingType === 'bankTransfer'
                ? 'bankTransferBankAccountNumber'
                : 'ACHBankAccountNumber'
            ] = 'Please enter a valid bank account number.'
          }
          setErrors(errors)
        }
      } else {
        await updateAsset({
          ...storedAsset,
          stage: values.requireAll
            ? ADD_ASSET_STAGE.PURCHASE_REQUIREMENTS
            : ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
          saveStage: ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
          fundingInstructions: { ...payload },
        })

        if (values.requireAll) {
          history.push(`/add-asset/${assetID}/purchase-requirements`)
        } else {
          history.push('/home')
        }
      }
    } catch (e) {
      setError(e)
    }*/
    try {
      await updateAsset({
        ...storedAsset,
        stage: values.requireAll
          ? ADD_ASSET_STAGE.PURCHASE_REQUIREMENTS
          : ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
        saveStage: ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
        fundingInstructions: { ...payload },
      })

      if (values.requireAll) {
        history.push(`/add-asset/${assetID}/purchase-requirements`)
      } else {
        history.push('/home')
      }
    } catch (e) {
      setError(e)
    }
  }

  const fundingInstructionsValidationSchema = Yup.object().shape({
    fundingType: Yup.string()
      .oneOf(['bankTransfer', 'check', 'ach'])
      .required('Required'),
    bankTransferTransitABANumber: Yup.string().when('fundingType', {
      is: 'bankTransfer',
      then: Yup.string()
        .min(9, 'Must be exactly 9 digits')
        .max(9, 'Must be exactly 9 digits')
        .matches(/^[0-9]*$/, 'Must be only numbers')
        .required('Required'),
    }),
    bankTransferBankAccountNumber: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .max(17, 'Cannot be greater than 17 digits')
          .test('test_uniqueness', '', (value, context) => {
            const { parent, createError } = context
            if (parent.bankTransferTransitABANumber == value) {
              return createError({
                message:
                  'The Account Number must be unique from the Routing number. ',
              })
            }
            return true
          })
          .matches(/^[0-9]*$/, 'Must be only numbers')
          .required('Required'),
      })
      .default(undefined),
    bankTransferBankName: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Must be only letters'
          )
          .max(80, 'Too Long')
          .required('Required'),
      })
      .default(undefined),
    bankTransferAccountName: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Must be only letters'
          )
          .max(80, 'Too Long')
          .required('Required'),
      })
      .default(undefined),
    bankTransferBankPhone: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .matches(
            RegExp(PHONE_NUMBER_REGEX),
            'Please enter valid phone number'
          )
          .required('Required'),
      })
      .default(undefined),
    bankTransferForFurtherCreditAccountNumber: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .max(17, 'Cannot be greater than 17 digits')
          .optional(),
      })
      .default(undefined),
    bankTransferSwiftCode: Yup.string()
      .matches(
        '^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$',
        'Must match Swift format.'
      )
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string().notRequired(),
      })
      .default(undefined),
    bankTransferBankAccountType: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .oneOf(['checking', 'savings'])
          .required('Required'),
      })
      .default(undefined),
    bankTransferAdditionalWireInstructions: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .notRequired()
          .default(undefined),
      })
      .default(undefined),

    // check validations

    checkPayee: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string()
          .max(146, 'Too Long')
          .required('Required'),
      })
      .default(undefined),
    checkAttention: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string()
          .max(75, 'Too Long')
          .notRequired()
          .default(undefined),
      })
      .default(undefined),
    checkCountry: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string().required('Required'),
      })
      .default(undefined),
    checkAddress: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string().required('Required'),
      })
      .default(undefined),
    checkCity: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string()
          .max(75, 'Too Long')
          .required('Required'),
      })
      .default(undefined),
    checkState: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string().required('Required'),
      })
      .default(undefined),
    checkZipCode: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string()
          .test('test_zip_code', '', (value, context) => {
            const { parent, createError } = context
            if (parent.checkCountry == 'US') {
              if (!RegExp(ZIP_CODE_REGEX).test(value)) {
                return createError({
                  message: 'Must be valid 5 or 9 digit US zip code!',
                })
              }
            }
            if (parent.checkCountry == 'CA') {
              if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
                return createError({
                  message: 'Must be a valid six-character CA code!',
                })
              }
            }
            return true
          })
          .when('requireAll', {
            is: true,
            then: Yup.string().required('Required'),
          }),
      })
      .default(undefined),

    // ach validations

    ACHTransitABANumber: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .min(9, 'Must be exactly 9 digits')
          .max(9, 'Must be exactly 9 digits')
          .required('Required'),
      })
      .matches(/^[0-9]*$/, 'Must be only numbers')
      .default(undefined),
    ACHBankAccountNumber: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .max(17, 'Cannot be greater than 17 digits')
          .test('test_uniqueness', '', (value, context) => {
            const { parent, createError } = context
            if (parent.ACHTransitABANumber == value) {
              return createError({
                message:
                  'The Account Number must be unique from the Routing number. ',
              })
            }
            return true
          })
          .matches(/^[0-9]*$/, 'Must be only numbers')
          .required('Required'),
      })
      .default(undefined),
    ACHBankName: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Must be only letters'
          )
          .max(80, 'Too Long')
          .required('Required'),
      })
      .default(undefined),
    ACHAccountName: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Must be only letters'
          )
          .max(80, 'Too Long')
          .required('Required'),
      })
      .default(undefined),
    ACHBankPhone: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .matches(
            RegExp(PHONE_NUMBER_REGEX),
            'Please enter valid phone number'
          )
          .required('Required'),
      })
      .default(undefined),
    ACHForFurtherCreditAccountNumber: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .max(17, 'Cannot be greater than 17 digits')
          .optional(),
      })
      .matches(/^[0-9]*$/, 'Must be only numbers')
      .default(undefined),
  })

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  return (
    <AssetFlowFundingInstructionsContent
      handleSubmit={handleSubmit}
      fundingInstructionsValidationSchema={fundingInstructionsValidationSchema}
      isNotEditable={isNotEditable}
      initialValues={initialValues}
      FlowActions={FlowActions}
    />
  )
}

FundingInstructions.propTypes = propTypes

FundingInstructions.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateAsset: apiActions.updateAsset,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FundingInstructions
)
