import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, Table, TableColumn } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  statuses: PropTypes.array,
  title: PropTypes.string,
}

const defaultProps = {
  statuses: [],
}

const ListCell = (props) => {
  return (
    <td>
      <ul className="document-type-list">
        {props?.data?.examples?.map((example) => {
          return <li key={example}>{example}</li>
        })}
      </ul>
    </td>
  )
}

function DocumentTypeModal({ onClose, statuses, title }) {
  return (
    <Modal onClose={onClose} id="status-key-modal">
      <h2>{title}</h2>
      <button className="modal-close" onClick={onClose}>
        ×
      </button>
      <div className="status-key-table-container">
        <Table data={statuses} className="status-key-table">
          <TableColumn name="type" label="Document Type" />
          <TableColumn name="description" label="Description" />
          <TableColumn name="examples" label="Examples" component={ListCell} />
        </Table>
      </div>
    </Modal>
  )
}

DocumentTypeModal.propTypes = exact(propTypes)
DocumentTypeModal.defaultProps = defaultProps

export default React.memo(DocumentTypeModal)
