import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { setOnSuccess, handleSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { createSelector } from 'reselect'
import { set } from 'lodash/fp'
import { TRANSACTION_TYPE } from 'config'
import { ascendingAlphaSort, convertStringToFixedFloat } from 'utils'

const reducerKey = 'document'
const slice = 'root.document'

const initialState = {}

const reducer = handleActions(
  {
    [actions.clearDocuments]: unsetState('requiredDocuments'),
    [apiActions.fetchClients]: setOnSuccess('clients'),
    [apiActions.fetchCompletedTransactions]: handleSuccess((state, action) => {
      const completedTransactions = action.payload.data
      const reformattedTransactions = completedTransactions.map(
        (transaction) => {
          if (transaction?.type === TRANSACTION_TYPE.CAPITAL_COMMITMENT) {
            transaction.transactionAmount = convertStringToFixedFloat(
              transaction.transactionAmount,
              0
            )
          }
          return transaction
        }
      )

      return set('completedTransactions', reformattedTransactions, state)
    }),
    [apiActions.fetchDocumentFormIdNumbers]: setOnSuccess('formIdNumbers'),
    [apiActions.fetchRequiredDocumentsByTransactionID]: setOnSuccess(
      'requiredDocuments'
    ),
    [apiActions.fetchUploadedDocuments]: setOnSuccess('uploadedDocuments'),
    [apiActions.fetchCompletedTransactions]: setOnSuccess(
      'completedTransactions'
    ),
    [apiActions.fetchCapitalCommitments]: setOnSuccess('capitalCommitments'),
    [apiActions.fetchPurchase]: setOnSuccess('purchase'),
    [apiActions.fetchInvestorsData]: setOnSuccess('investorsData'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  clients: select('clients'),
  completedTransactions: select('completedTransactions'),
  capitalCommitments: select('capitalCommitments'),
  formIdNumbers: select('formIdNumbers'),
  requiredDocuments: select('requiredDocuments'),
  uploadedDocuments: select('uploadedDocuments'),
  purchase: select('purchase'),
  investorsData: select('investorsData'),
}

selectors.downloadDocuments = createSelector(
  [selectors.requiredDocuments],
  (requiredDocuments) => {
    if (!requiredDocuments) return
    return requiredDocuments.downloadDocuments
  }
)

selectors.normalizeFormIdNumbers = createSelector(
  [selectors.formIdNumbers],
  (formIdNumbers) => {
    if (!formIdNumbers) return
    return formIdNumbers
      .map((obj) => ({
        key: obj.documentName,
        value: obj.formNumber,
      }))
      .sort(ascendingAlphaSort('key'))
  }
)

selectors.normalizedClients = createSelector([selectors.clients], (clients) => {
  if (!clients) return
  return clients.map((client) => ({
    key: client.clientAccountName,
    value: client.clientAccountInnovestCustomerNumber,
  }))
})

export { reducer, selectors, reducerKey }
