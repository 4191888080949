import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import * as actions from './actions'

const reducerKey = 'assetCustody'
const slice = 'root.assetCustody'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchAssetDetails]: setOnSuccess('assetDetails'),
    [apiActions.fetchInvestors]: setOnSuccess('investors'),
    [apiActions.fetchCapitalCommitments]: setOnSuccess('capitalCommitments'),
    [apiActions.fetchAssetFundingInstructions]: setOnSuccess(
      'fundingInstructions'
    ),
    [actions.clearAssetDetails]: unsetState('assetDetails'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  assetDetails: select('assetDetails'),
  capitalCommitments: select('capitalCommitments'),
  investors: select('investors'),
  fundingInstructions: select('fundingInstructions'),
}

export { reducer, selectors, reducerKey }
