import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, Table, TableColumn } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  statuses: PropTypes.array,
  title: PropTypes.string,
}

const defaultProps = {
  statuses: [],
}

function StatusKeyModal({ onClose, statuses, title }) {
  return (
    <Modal onClose={onClose} id="status-key-modal">
      <h2>{title}</h2>
      <button className="modal-close" onClick={onClose}>
        ×
      </button>
      <div className="status-key-table-container">
        <Table data={statuses} className="status-key-table">
          <TableColumn name="name" label="Status Name" />
          <TableColumn name="description" label="Status Description" />
        </Table>
      </div>
    </Modal>
  )
}

StatusKeyModal.propTypes = exact(propTypes)
StatusKeyModal.defaultProps = defaultProps

export default React.memo(StatusKeyModal)
