import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Card, ACPFilterPicklist, StatusKeyModal } from 'components'
import { Spinner } from 'lp-components'
import {
  ADD_ASSET_STAGE,
  LIQUIDATION_CONFIRMATION_STEPS,
  TASK_TYPES,
  PriorityLevels,
} from 'config'
import { selectors as globalSelectors } from 'global-reducer'
import {
  mapTaskToType,
  createTaskTypesList,
  createStatusOptions,
  filterTasksByType,
  namedRoute,
  formatWithNullValues,
  displayDateTime,
  stringArrayComparator,
} from 'utils'
import { Link } from 'react-router-dom'
import FilterIcon from 'images/filter-icon.svg'
import * as Types from 'types'
import UrgentIcon from 'images/urgent-icon.svg'
import { isEmpty } from 'lodash'
import { TASK_RESOLUTION_PATH } from '../../Routes'
import ACPTable from 'components/ACPTable'
import { Form, Formik } from 'formik'

const propTypes = {
  urgentTasks: Types.tasks,
  uniqTaskTypes: PropTypes.array,
}

const defaultProps = {}

function TaskTable({ urgentTasks, uniqTaskTypes }) {
  const [filteredTasks, setFilteredTasks] = useState(urgentTasks)
  const [filterVisible, setFilterVisible] = useState(false)
  const [filterStatuses, setFilterStatuses] = useState([])
  const [statusOptions, setStatusOptions] = useState([])
  const [showStatusKeyModal, setShowStatusKeyModal] = useState(false)

  const filterCount = filterStatuses.length

  const columnDefs = [
    {
      field: 'subject',
      headerName: 'Task',
      minWidth: 300,
      wrapText: true,
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: (params) => {
        const { subject, priority } = params.data
        if (priority === PriorityLevels.URGENT) {
          return (
            <>
              <img className="urgent-icon" src={UrgentIcon} alt="urgent" />
              {subject}
            </>
          )
        }
        return subject
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: true,
      filter: 'agTextColumnFilter',
      wrapText: true,
      autoHeight: true,
      minWidth: 650,
      cellStyle: { wordBreak: 'normal' },
    },
    {
      field: 'createDate',
      headerName: 'Date Created',
      sortable: true,
      headerTooltip: 'Date Created: The date when the task was sent.',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => {
        return formatWithNullValues(params.data.createDate, (value) =>
          displayDateTime(value, 'MM/DD/YYYY')
        )
      },
    },
    {
      field: 'status',
      headerName: 'Required Action',
      sortable: true,
      headerTooltip: 'Required Action: Start, Resume, or Continue the task.',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const task = params.data
        const {
          isConfirmPurchase,
          isSubmitAsset,
          isConfirmLiquidation,
          isResolution,
          isConfirmExchange,
          isValuation,
          isContactReview,
        } = mapTaskToType(task)

        if (
          isConfirmPurchase ||
          isConfirmExchange ||
          isResolution ||
          isValuation ||
          isContactReview
        )
          return 'Start'
        if (isSubmitAsset) return 'Resume'
        if (isConfirmLiquidation) {
          const isPartTwo =
            task.subType ===
            LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_SECOND_STEP
          return isPartTwo ? 'Continue' : 'Start'
        }
      },
      comparator: stringArrayComparator,
      cellRenderer: (params) => {
        const task = params.data
        const {
          isConfirmPurchase,
          isSubmitAsset,
          isConfirmLiquidation,
          isResolution,
          isConfirmExchange,
          isValuation,
          isFunding,
          isContactReview,
        } = mapTaskToType(task)
        if (isConfirmPurchase) {
          return (
            <Link
              to={{
                pathname: `/bulk-confirmations/templates`,
                state: {
                  selectedInvestors: [{ transactionID: task.recordID }],
                },
              }}
            >
              Start
            </Link>
          )
        }
        if (isSubmitAsset) {
          // if assetStage is empty, default to asset-details stage (first step)
          const stage = task.assetStage || ADD_ASSET_STAGE.ASSET_DETAILS
          return <Link to={`/add-asset/${task.recordID}/${stage}`}>Resume</Link>
        }
        if (isConfirmLiquidation) {
          const isPartTwo =
            task.subType ===
            LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_SECOND_STEP
          const endpoint = isPartTwo ? 'details' : 'templates'
          const path = `/confirm-liquidation/${task.recordID}/${endpoint}`
          return <Link to={path}>{isPartTwo ? 'Continue' : 'Start'}</Link>
        }
        if (isConfirmExchange) {
          const path = `/confirm-exchange/${task.recordID}/exchange-authorization`
          return <Link to={path}>Start</Link>
        }
        if (isContactReview) {
          const path = `/contacts`
          return <Link to={path}>Start</Link>
        }
        if (isValuation) {
          const path = `/assets/${task.recordID}/investors`
          return <Link to={path}>Start</Link>
        }
        if (isFunding) {
          const path = `/assets/${task.recordID}/investors`
          return (
            <Link
              to={{
                pathname: path,
                state: { reviewAssetFundingInstructions: true },
              }}
            >
              {' '}
              Start
            </Link>
          )
        }
        if (isResolution) {
          return (
            <Link
              to={{
                pathname: namedRoute(TASK_RESOLUTION_PATH, {
                  subType: task.subType?.toLowerCase(),
                  recordId: task.recordID,
                  taskId: task.taskID,
                }),
                state: {
                  target: location.pathname,
                  subtitle: `Resolve ${task.subType} Submission Documents`,
                  taskDescription: task.description,
                  breadcrumb: {
                    to: '/tasks',
                    title: 'Tasks',
                  },
                },
              }}
            >
              Start
            </Link>
          )
        }
      },
    },
  ]

  useEffect(() => {
    if (isEmpty(urgentTasks)) return

    if (uniqTaskTypes) {
      const uniqueTaskTypesMap = createTaskTypesList(uniqTaskTypes)
      setStatusOptions(createStatusOptions(uniqueTaskTypesMap))
    }
  }, [urgentTasks])

  useEffect(() => {
    const tasksFilteredByType = filterTasksByType(urgentTasks, filterStatuses)

    setFilteredTasks(tasksFilteredByType)
  }, [urgentTasks, filterStatuses])

  const handleExternalFilterChange = (newValue) => {
    setFilterStatuses(newValue)
  }

  const applyFilters = (values) => {
    handleExternalFilterChange(values.filter)
    setFilterVisible(false)
  }

  const taskTableActions = (
    <div className="table-actions">
      <div className="table-actions-single">
        <button
          type="button"
          onClick={() => {
            setFilterVisible(!filterVisible)
          }}
        >
          <img alt="" src={FilterIcon} />
          Filters: {filterCount > 0 ? 'Task Types ' + filterCount : 'None'}
        </button>
      </div>
    </div>
  )

  if (isEmpty(filteredTasks) && !isEmpty(urgentTasks)) return <Spinner />

  return (
    <>
      <Card label="My Tasks" actions={taskTableActions}>
        <div className="tasks-wrapper tasks-table">
          <ACPTable
            columnDefs={columnDefs}
            rowData={filteredTasks}
            additionalDefaultColDef={{ sortable: true }}
          />
        </div>
      </Card>
      {showStatusKeyModal && (
        <StatusKeyModal
          onClose={() => setShowStatusKeyModal(false)}
          title="Task Type Key"
          statuses={TASK_TYPES}
        />
      )}
      <Formik
        onSubmit={applyFilters}
        initialValues={{
          filter: [],
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            {filterVisible && (
              <Form>
                <ACPFilterPicklist
                  handleExternalFilterChange={handleExternalFilterChange}
                  setPicklistVisible={setFilterVisible}
                  setFieldValue={setFieldValue}
                  values={values}
                  currentFilter={filterStatuses}
                  options={statusOptions}
                  onClose={applyFilters}
                  title={'Task Type'}
                />
              </Form>
            )}
          </>
        )}
      </Formik>
    </>
  )
}

TaskTable.propTypes = exact(propTypes)
TaskTable.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    urgentTasks: globalSelectors.urgentTasks(state),
    uniqTaskTypes: globalSelectors.uniqTaskTypes(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TaskTable)
