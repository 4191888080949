import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ACPInputField } from 'components'
import { Checkbox as BaseCheckbox } from 'lp-components'
import { FlowActions } from '../components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { selectors } from '../reducer'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withFormikAdapter } from 'utils'

const Checkbox = withFormikAdapter()(BaseCheckbox)

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  liquidation: PropTypes.object.isRequired,
  prevStep: PropTypes.string.isRequired,
  isPartTwo: PropTypes.string.isRequired,
}

const defaultProps = {}

function SignatureForm({ handleSubmit, liquidation, prevStep, isPartTwo }) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    doesAcknowledge: Yup.bool().oneOf(
      [true],
      'Please check this box if you want to proceed'
    ),
  })

  const setInitialValue = (keyName) => {
    if (!isPartTwo) return liquidation[keyName] || ''
    return liquidation[`${keyName}2`] || ''
  }

  const initialValues = {
    firstName: setInitialValue('eSignFirstName'),
    lastName: setInitialValue('eSignLastName'),
    title: setInitialValue('eSignTitle'),
    doesAcknowledge: false,
  }

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-flow-container">
              <div className="flow-card-container">
                <div className="card">
                  <div className="form-card-content">
                    <h4>Acknowledgments</h4>
                    <h4>
                      Please review the following acknowledgment below and
                      e-sign at the bottom.
                    </h4>
                    <p>
                      I certify that I have processed this liquidation in
                      accordance with instructions received from Inspira
                      Financial. I understand that holdback funds, once
                      available, should be sent directly to Inspira and should
                      not be directed to the account owner or another entity.
                    </p>
                    <hr />
                    <h4>E-Signature</h4>
                    <div className="row">
                      <div className="one-half column">
                        <ACPInputField label="First Name" name="firstName" />
                      </div>
                      <div className="one-half column">
                        <ACPInputField label="Last Name" name="lastName" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="one-half column">
                        <ACPInputField label="Title" name="title" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="checkbox-line-item twelve columns">
                        <Checkbox
                          name="doesAcknowledge"
                          label="By checking this box, I agree that I am signing these Acknowledgments, which I intend to be my legally binding signature on behalf of the Issuer. I also certify that the Issuer acknowledges and agrees and that I am authorized to and this e-signature does bind the Issuer named above to the terms of this Acknowledgment."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FlowActions
              prevStep={prevStep}
              submitting={isSubmitting}
              submitContent="Submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

SignatureForm.propTypes = exact(propTypes)
SignatureForm.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    liquidation: selectors.liquidation(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SignatureForm
)
