import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState, setState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as contactsActions from './actions'

const reducerKey = 'contactsManagement'
const slice = 'root.contactsManagement'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchContactsList]: setOnSuccess('contactsList'),
    [apiActions.fetchSponsorTPACompanies]: setOnSuccess('tpaCompanies'),
    [contactsActions.clearTPACompanies]: unsetState('tpaCompanies'),
    [contactsActions.clearContacts]: unsetState('contactsList'),
    [contactsActions.clearContactDetails]: unsetState('contactDetails'),
    [contactsActions.setContactDetails]: setState('contactDetails'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  tpaCompanies: select('tpaCompanies'),
  contactsList: select('contactsList'),
  contactDetails: select('contactDetails'),
}

export { reducer, selectors, reducerKey }
