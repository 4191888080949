import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { FlowActions } from '../components'
import * as Yup from 'yup'
import * as actions from '../actions'
import { Formik, Form } from 'formik'
import { getRemainingContactOptions } from 'utils'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { ACPInputField } from 'components'
import { groupBy, omit } from 'lodash'
import { Link, useHistory, useParams } from 'react-router-dom'
import * as flashActions from 'redux-flash'
import * as Types from 'types'

const propTypes = {
  replaceContact: PropTypes.func.isRequired,
  reassignmentAsset: Types.roleRelationships,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  clearContactProfile: PropTypes.func.isRequired,
  fetchContactProfile: PropTypes.func.isRequired,
  contact: PropTypes.object,
  contactsList: PropTypes.arrayOf(Types.contact),
}
const defaultProps = {
  reassignmentAsset: {},
  contactsList: [],
}

const ColumnOneHalf = ({ children, className }) => (
  <div className={`one-half column ${className}`}>{children}</div>
)

function ContactIndividualReplacementsForm({
  reassignmentAsset,
  replaceContact,
  contact,
  contactsList,
  clearContactProfile,
  fetchContactProfile,
  flashSuccessMessage,
  flashErrorMessage,
}) {
  const history = useHistory()
  const { contactID } = useParams()

  useEffect(() => {
    fetchContactProfile(contactID)
    return () => clearContactProfile()
  }, [])

  const initialValues = reassignmentAsset.relationships.reduce(
    (obj, relationship) => {
      return {
        ...obj,
        [relationship.relationshipID]: {
          ...relationship,
          replacementContactID: '',
        },
      }
    },
    {}
  )
  const contactInformationValidation = Yup.object().shape(
    reassignmentAsset.relationships.reduce((obj, relationship) => {
      return {
        ...obj,
        [relationship.relationshipID]: Yup.object().shape({
          replacementContactID: Yup.string().required('Required'),
        }),
      }
    }, {})
  )

  const handleSubmit = async (values) => {
    const relationshipsToUpdate = Object.values(values)
    const relationshipUpdatesGroupedByReplacingContactID = groupBy(
      relationshipsToUpdate,
      'replacementContactID'
    )

    const payload = Object.entries(
      relationshipUpdatesGroupedByReplacingContactID
    ).map((contactUpdate) => {
      const [contactID, relationshipsToReplace] = contactUpdate
      const formattedRelationships = relationshipsToReplace.map(
        (relationship) => {
          return {
            ...omit(relationship, ['replacementContactID']),
            isRemoved: false,
          }
        }
      )
      const associatedContactAccountID = contactOptions.find(
        (contact) => contact.value === contactID
      ).accountID
      return {
        contactDetails: {
          accountID: associatedContactAccountID,
          contactID,
        },
        roleNames: [
          {
            role: reassignmentAsset.role,
            relationships: formattedRelationships,
          },
        ],
      }
    })

    try {
      await replaceContact(payload)
      flashSuccessMessage('Contact replacement successful!')
      history.push(`/profiles/${contactID}`)
    } catch (e) {
      flashErrorMessage(e.message || 'Something went wrong, please try again.')
    }
  }

  if (!contact || !initialValues) return <Spinner />

  const { contactDetails } = contact
  const contactOptions = getRemainingContactOptions(
    contactDetails,
    contactsList
  )

  return (
    <div className="flow-card-container">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={contactInformationValidation}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="card">
                <h6>Select Asset Contacts</h6>
                <div className="role-replacement-instructions">
                  <p>
                    {`These assets need a ${reassignmentAsset.role}. Please select each
                    individual contact per asset.`}
                  </p>
                  <p>
                    If you would like to add a contact that is not in the list
                    please{' '}
                    <Link
                      className="modal-button-link"
                      to="/contacts/add-contact/contact-information"
                    >
                      click here
                    </Link>
                  </p>
                </div>
                <div className="form-card-content">
                  <div className="row role-replacement-header-row">
                    <ColumnOneHalf>Assets</ColumnOneHalf>
                    <ColumnOneHalf>Contact</ColumnOneHalf>
                  </div>
                  {reassignmentAsset.relationships.map((relationship) => (
                    <div
                      key={relationship.relationshipID}
                      className="row role-replacement-row"
                    >
                      <ColumnOneHalf className="role-replacement-asset-label">
                        {relationship.assetName}
                      </ColumnOneHalf>
                      <ColumnOneHalf>
                        <ACPInputField
                          name={`${relationship.relationshipID}.replacementContactID`}
                          label={`Contact to Replace ${reassignmentAsset.role} Role`}
                          selectOptions={contactOptions}
                          enablePlaceholderOption={true}
                          placeholder="Select"
                        />
                      </ColumnOneHalf>
                    </div>
                  ))}
                </div>
              </div>
              <FlowActions submitting={isSubmitting} submitContent="Submit" />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

ContactIndividualReplacementsForm.propTypes = propTypes
ContactIndividualReplacementsForm.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    contact: selectors.contact(state),
    contactsList: selectors.contactsList(state),
  }
}

const mapDispatchToProps = {
  replaceContact: apiActions.replaceContact,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  fetchContactProfile: apiActions.fetchContactProfile,
  clearContactProfile: actions.clearContactProfile,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactIndividualReplacementsForm
)
