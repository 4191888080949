import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { withFormikAdapter } from 'utils'
import { DateInput as BaseDateInput } from 'lp-components'
import LabelWithTooltip from './LabelWithTooltip'

const DateInput = withFormikAdapter()(BaseDateInput)

const propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  tooltipContent: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  parse: PropTypes.func,
}

const defaultProps = {}

function ACPDateInputField({
  name,
  label = null,
  value = null,
  type = 'text',
  disabled = false,
  onChange = null,
  tooltipContent = null, //presence of tooltipContent indicates we should use a tooltip on the input.
  onBlur = null,
  required = true,
  maxDate = null,
  minDate = null,
  parse = null,
}) {
  const formattedLabel = disabled ? (
    label
  ) : required ? (
    label + ' *'
  ) : (
    <span>
      {label}
      <span className="optionalTag"> (optional)</span>
    </span>
  )

  const options = {
    label: tooltipContent ? false : formattedLabel,
    name: name,
    type: type,
    placeholderText: 'MM/DD/YYYY',
    disabled: disabled,
    onChange: onChange,
    onBlur: onBlur,
    maxDate: maxDate,
    minDate: minDate,
    parse: parse,
    autoComplete: 'off',
  }

  if (value) {
    options['value'] = value
  }

  if (tooltipContent) {
    return (
      <LabelWithTooltip label={formattedLabel} tooltipContent={tooltipContent}>
        <DateInput {...options} /*customInput={<CustomInput />}*/ />
      </LabelWithTooltip>
    )
  } else {
    return <DateInput {...options} /*customInput={<CustomInput />}*/ />
  }
}

ACPDateInputField.propTypes = exact(propTypes)
ACPDateInputField.defaultProps = defaultProps

export default ACPDateInputField
