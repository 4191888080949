import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import * as LS from 'local-storage'
import {
  AssetTable,
  TaskList,
  TransactionList,
  AddAssetModal,
  InviteInvestorsCard,
  Exploration,
} from '../components'
import * as Types from 'types'
import { USER_CLASSIFICATION } from 'config'
import { useHistory } from 'react-router-dom'

const propTypes = {
  setInitialLoginTrue: PropTypes.func.isRequired,
  assets: PropTypes.array,
  tasks: Types.tasks,
  transactions: PropTypes.array,
  currentUser: PropTypes.object,
  fetchContactsList: PropTypes.func,
  fetchAssets: PropTypes.func,
  fetchTransactions: PropTypes.func,
  fetchTasks: PropTypes.func,
}

const defaultProps = {
  assets: null,
  transactions: null,
}

function Home({
  assets,
  tasks,
  currentUser,
  transactions,
  setInitialLoginTrue,
  fetchAssets,
  fetchTransactions,
  fetchTasks,
  fetchContactsList,
}) {
  const [addAssetModalShown, setAddAssetModalShown] = useState(false)
  // const ref = useRef(null)
  const history = useHistory()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await fetchAssets()
      await fetchTasks()
      await fetchTransactions()
      await fetchContactsList()
    }
    fetchData()
  }, [])

  const isGroup1User =
    currentUser.userClassification === USER_CLASSIFICATION.GROUP_1

  useEffect(() => {
    const checkFirstLogin = async () => {
      if (!currentUser?.permissions?.initialLogin && isGroup1User) {
        await setInitialLoginTrue({
          initialLogin: true,
          permissionsID: currentUser?.permissions?.permissionsID,
        })
        history.push('/contacts')
      }
    }
    checkFirstLogin()
  }, [])

  return (
    <div className="container">
      {addAssetModalShown && (
        <AddAssetModal
          onClose={() => setAddAssetModalShown(false)}
          path="/add-asset/new"
        />
      )}
      <div className="row dashboard">
        <div className="two-thirds twelve column col-full-res">
          <div className="row row-space-evenly">
            <div className="one-half twelve column col-full-res task-card">
              <div className="row">
                <TaskList tasks={tasks} />
              </div>
            </div>
            <div className="one-half twelve column col-full-res transactions-card">
              <div className="row">
                <TransactionList transactions={transactions} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="twelve column col-full-res">
              <AssetTable
                assets={assets}
                showAddAssetModal={() => {
                  LS.clearAddAssetDetails()
                  setAddAssetModalShown(true)
                }}
                currentUser={currentUser}
              />
            </div>
          </div>
        </div>
        <div className="one-third column col-full-res">
          <div className="row">
            <div className="twelve columns col-full-res">
              <Exploration />
            </div>
          </div>

          {isGroup1User && (
            <div>
              <InviteInvestorsCard />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
Home.propTypes = propTypes
Home.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assets: globalSelectors.assets(state),
    tasks: globalSelectors.tasks(state),
    transactions: globalSelectors.transactions(state),
    currentUser: globalSelectors.currentUser(state),
    relatedEntities: globalSelectors.relatedEntities(state),
  }
}
const mapDispatchToProps = {
  fetchAssets: apiActions.fetchAssets,
  fetchTasks: apiActions.fetchTasks,
  fetchTransactions: apiActions.fetchTransactions,
  setInitialLoginTrue: apiActions.setInitialLoginTrue,
  fetchContactsList: apiActions.fetchContactsList,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home)
