import React from 'react'
import PropTypes from 'prop-types'
import { createCanvasScreenshot, generateTimestamp } from './helpers'

const propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function ScreenshotButton({ inputRef, children }) {
  return (
    <button
      className="button-primary-outline"
      onClick={async (e) => {
        const _self = e.target
        _self.classList.add('in-progress')
        // clean up existing
        document.querySelector('#screenshot-container')?.remove()

        const container = document.createElement('div')
        container.style = 'margin-top: 30px; border-top: 1px solid black;'
        container.id = 'screenshot-container'

        try {
          const canvas = await createCanvasScreenshot(
            inputRef.current,
            generateTimestamp(new Date())
          )

          const downloadButton = document.createElement('button')
          downloadButton.innerText = 'Download'
          downloadButton.style.display = 'block'
          downloadButton.classList.add('button-primary')
          downloadButton.onclick = () => {
            const link = document.createElement('a')
            link.download = `screenshot-${Date.now()}.png`
            link.href = canvas.toDataURL()
            link.click()
          }
          container.appendChild(downloadButton)
          container.appendChild(canvas)
        } catch (e) {
          // eslint-disable-next-line
          console.error(e)
          const errorDiv = document.createElement('div')
          errorDiv.innerText = e
          errorDiv.style.border = '1px solid red'
          errorDiv.style.padding = '20px'
          container.appendChild(errorDiv)
        } finally {
          document.querySelector('#root').append(container)
          _self.classList.remove('in-progress')
        }
      }}
    >
      {children}
    </button>
  )
}

ScreenshotButton.propTypes = propTypes
ScreenshotButton.defaultProps = defaultProps

export default ScreenshotButton
