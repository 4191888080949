import { isNaN, isNil } from 'lodash'

const formatNumberAsCurrency = (param, placeholder = '') => {
  if (isNaN(param) || isNil(param)) return placeholder

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(param)
}

export default formatNumberAsCurrency
