import React, { useState, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Card, ACPTable } from 'components'
import { Spinner } from 'lp-components'
import { dateComparator, dateFormatter, currencyComparator } from 'utils'
import classnames from 'classnames'
import { useHistory } from 'react-router'
import { isEmpty } from 'lodash'
import WarningIcon from 'images/warning.svg'
import LimitReachedModal from './LimitReachedModal'
import HelpIcon from 'images/help-information-blue.svg'

const propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
  transactions: null,
}

const loadingOverlay = `<div class="search-results-helper">
<div class="overlay-text-title med-18">No results found</div>
<span class="overlay-text reg-14">It looks like you don't have any confirmations yet.</span>
<span class="overlay-text reg-14">To get started, please initiate a transaction</span>
</div>`

function MultiSelectConfirmationsTable({ transactions }) {
  const [selectedInvestors, setSelectedInvestors] = useState(null)
  const [rowFilterSize, setRowFilterSize] = useState(null)
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false)
  const gridRef = useRef()
  const history = useHistory()
  const gridStyle = useMemo(() => ({ height: '518px', width: '100%' }), [])

  const columnDefs = [
    {
      field: 'accountName',
      headerName: 'Investor Name',
      headerTooltip: 'Investor Name: The name of the Investor.',
      sortable: true,
      cellRenderer: 'agGroupCellRenderer',
      menuTabs: [],
      minWidth: 250,
      wrapText: true,
      filter: 'agTextColumnFilter',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      resizable: true,
    },
    {
      field: 'assetName',
      menuTabs: [],
      minWidth: 200,
      wrapText: true,
      headerTooltip:
        'Asset Name: The legal name as stated in the offering document.',
      sortable: true,
      resizable: true,
    },
    {
      field: 'transactionAmount',
      menuTabs: [],
      sortable: true,
      minWidth: 100,
      cellClass: 'table-right-align',
      headerClass: 'confirm-table-trans-amount-header',
      headerTooltip: 'Amount: The amount of the transaction.',
      comparator: currencyComparator,
    },
    {
      field: 'type',
      headerName: 'Transaction Type',
      headerTooltip: 'Type: The type of transaction.',
      minWidth: 100,
      menuTabs: [],
    },
    {
      field: 'transactionDate',
      sortable: true,
      menuTabs: [],
      headerTooltip:
        'Transaction Date: The date that Inspira processed this transaction.',
      valueFormatter: dateFormatter,
      comparator: dateComparator,
    },
  ]

  const onFilterTextBoxChanged = useCallback(() => {
    const model = {
      accountName: {
        filterType: 'text',
        type: 'contains',
        filter: document.getElementById('filter-text-box').value,
      },
    }
    gridRef.current.api.setFilterModel(model)
    getDisplayedRowCount()
  }, [])

  const getDisplayedRowCount = useCallback(() => {
    const count = gridRef.current.api.getDisplayedRowCount()
    setRowFilterSize(count)
  }, [])

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows()
    setSelectedInvestors(selectedRows)
  }, [])

  const showNoResultsOverlay = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay()
  }, [])

  const onBtHide = useCallback(() => {
    gridRef.current.api.hideOverlay()
  }, [])

  const TransactionTableActions = (
    <div className="table-actions">
      <div className="table-actions-left">
        <span>
          <input
            type="text"
            className="search-items"
            id="filter-text-box"
            placeholder="Search Investor Name"
            onInput={onFilterTextBoxChanged}
          />
          {rowFilterSize === 0 && showNoResultsOverlay()}
          {rowFilterSize > 0 && onBtHide()}
        </span>
      </div>
      <div className="table-actions-last">
        <button
          className={classnames('button-primary button-all-transactions', {
            'is-disabled': isEmpty(selectedInvestors),
          })}
          onClick={() => {
            if (selectedInvestors.length > 200) {
              setShowLimitReachedModal(true)
              return
            } else {
              history.push({
                pathname: '/bulk-confirmations/templates',
                state: {
                  selectedInvestors: selectedInvestors,
                },
              })
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  )

  if (!transactions) return <Spinner />

  if (transactions.length === 0) {
    return (
      <div className="empty-results-wrapper">
        <div className="empty-results row">
          <span className="icon-wrapper">
            <img
              className="urgent-icon med-14"
              src={WarningIcon}
              alt="Urgent Icon"
            />
          </span>
          <span className="warning-text-wrapper">
            <h5 className="empty-state-banner-text med-14 lh-21">
              You have no transactions pending your confirmation at this time.
            </h5>
          </span>
        </div>
      </div>
    )
  } else {
    return (
      <div className="transactions-table">
        <Card
          className="transactions-table-card"
          label="Select the transactions you want to confirm."
          actions={TransactionTableActions}
        >
          <div className="info-msg-wrapper">
            <div className="info-results">
              <div className="info-msg">
                <span>
                  <img className="info-icon" src={HelpIcon} alt="Info Icon" />
                </span>
                <span>
                  Need to execute a purchase? You can download the transaction
                  documents on the next step.
                </span>
              </div>
            </div>
          </div>
          <ACPTable
            columnDefs={columnDefs}
            paginationPageSize="10"
            rowData={transactions}
            overrideGridRef={gridRef}
            overrideGridStyle={gridStyle}
            isAnyFilterPresent={true}
            rowSelection={'multiple'}
            onSelectionChanged={onSelectionChanged}
            overlayLoadingTemplate={loadingOverlay}
          />
        </Card>
        {showLimitReachedModal && (
          <div>
            <LimitReachedModal
              onClose={() => {
                setShowLimitReachedModal(false)
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

MultiSelectConfirmationsTable.propTypes = exact(propTypes)
MultiSelectConfirmationsTable.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MultiSelectConfirmationsTable
)
