import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { SearchableSelect } from 'components'
import { useFormikContext } from 'formik'
import {
  getRemainingContactOptions,
  normalizeCountryValue,
  normalizeStateAndProvinceValue,
} from 'utils'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as Types from 'types'
import { addNewContactOption, ROLE_IDS } from 'config'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { ContactFields } from '../components'

const propTypes = {
  contactType: PropTypes.string.isRequired,
  isNotEditable: PropTypes.bool,
  disclaimerMessage: PropTypes.string,
  contactsList: PropTypes.arrayOf(Types.contact).isRequired,
  tpaCompanies: PropTypes.arrayOf(PropTypes.object),
  fetchSponsorTPACompanies: PropTypes.func.isRequired,
  currentUser: Types.user.isRequired,
}

const defaultProps = {
  isNotEditable: true,
  disclaimerMessage: null,
}

function ContactSearchAndSelect({
  contactType,
  isNotEditable,
  disclaimerMessage,
  contactsList,
  tpaCompanies,
  fetchSponsorTPACompanies,
  currentUser,
}) {
  useEffect(() => {
    fetchSponsorTPACompanies()
  }, [])

  const { values, resetForm, setValues } = useFormikContext()

  // need to filter contactOptions by contactType

  const filteredContactsListByAppropriateRoleType = contactsList.filter(
    (contact) => contact.roles.includes(ROLE_IDS[contactType])
  )

  const contactOptions = getRemainingContactOptions(
    {},
    filteredContactsListByAppropriateRoleType.length === 0
      ? [currentUser]
      : filteredContactsListByAppropriateRoleType
  ) // this is the same as return all contact options

  const contactOptionsWithNew = [addNewContactOption, ...contactOptions]

  const defaultCompany = {
    companyName: currentUser.accountName,
    companyID: currentUser.accountID,
  }

  const onSelectChange = (e) => {
    // check if existing contact or new
    // copy contact selection to values or open cleared form

    resetForm({
      values: {
        ...values,
        [contactType]: {
          ...values[contactType],
          match: e.target.value,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          country: '',
          state: '',
          streetAddress: '',
          city: '',
          zipcode: '',
          companyID: defaultCompany.companyID,
          companyName: defaultCompany.companyName,
        },
      },
    })

    if (e.target.value !== addNewContactOption.value) {
      const selectedContact =
        contactsList.length === 0
          ? currentUser
          : contactsList.find(
              (contact) => contact.contactDetails.contactID === e.target.value
            )

      const selectedContactDetails = selectedContact?.contactDetails
      setValues({
        ...values,
        [contactType]: {
          ...values[contactType],
          match: e.target.value,
          firstName: selectedContactDetails?.firstName || '',
          lastName: selectedContactDetails?.lastName || '',
          email: selectedContactDetails?.email || '',
          phone: selectedContactDetails?.phone || '',
          country: normalizeCountryValue(selectedContactDetails?.country),
          state: normalizeStateAndProvinceValue(
            selectedContactDetails?.country,
            selectedContactDetails?.state
          ),
          streetAddress: selectedContactDetails?.streetAddress || '',
          city: selectedContactDetails?.city || '',
          zipcode: selectedContactDetails?.zipcode || '',
          // companyName is set by asset
          companyID:
            selectedContactDetails?.accountID ||
            selectedContact?.accountID ||
            '',
          companyName: selectedContactDetails?.accountName || '',
        },
      })
    }
  }

  return (
    <>
      {!isNotEditable && (
        <SearchableSelect
          name={`${contactType}.match`}
          label="Search and select contact name *"
          onChange={onSelectChange}
          options={contactOptionsWithNew}
          placeholder={'Select'}
          isClearable
        />
      )}
      {values[contactType].match && (
        <ContactFields
          keyName={contactType}
          isNotEditable={isNotEditable}
          isAddNewContact={
            values[contactType].match === addNewContactOption.value
          }
          tpaCompanies={tpaCompanies}
          currentUser={currentUser}
        />
      )}
      {!isNotEditable && disclaimerMessage && (
        <div className="disclosure">{disclaimerMessage}</div>
      )}
    </>
  )
}

ContactSearchAndSelect.propTypes = exact(propTypes)
ContactSearchAndSelect.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    tpaCompanies: selectors.tpaCompanies(state),
  }
}

const mapDispatchToProps = {
  fetchSponsorTPACompanies: apiActions.fetchSponsorTPACompanies,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactSearchAndSelect
)
