import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { SubmitButton } from 'lp-components'
import { BackButton } from 'components'
import { useFormikContext } from 'formik'
import { ADD_ASSET_STAGES } from 'flow-stages'
import RightArrow from 'images/right-arrow-icon.svg'

const propTypes = {
  preventSave: PropTypes.bool,
  preventBack: PropTypes.bool,
  submitContent: PropTypes.node,
  disabled: PropTypes.bool,
}

const defaultProps = {
  preventSave: false,
  preventBack: false,
  submitContent: 'Continue',
}

function FlowActions({ preventSave, submitContent, preventBack, disabled }) {
  const { isSubmitting, setErrors, setFieldValue } = useFormikContext()
  const [isSaving, setIsSaving] = useState(false)
  const isContinuing = isSubmitting && !isSaving

  return (
    <div className="flow-button-container" data-html2canvas-ignore>
      <div className="asset-flow-button-inner">
        {!preventSave && (
          <SubmitButton
            onClick={() => {
              setIsSaving(true)
              setErrors({ errors: {} })
              setFieldValue('requireAll', false, false)
            }}
            className="button-secondary save-button"
            submitting={isSaving}
            invalid={isContinuing}
          >
            Save & Exit
          </SubmitButton>
        )}
        {!preventBack && <BackButton stages={ADD_ASSET_STAGES} />}
        <SubmitButton
          onClick={() => setFieldValue('requireAll', true, false)}
          className="form-button"
          submitting={isContinuing}
          invalid={isSaving}
          disabled={disabled}
        >
          {submitContent}{' '}
          {!isContinuing && (
            <img
              src={RightArrow}
              className="right-arrow-icon"
              alt="Right Arrow"
            />
          )}
        </SubmitButton>
      </div>
    </div>
  )
}

FlowActions.propTypes = exact(propTypes)
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
