import { camelCase, isEmpty } from 'lodash'
import { TASK_SUBTYPES } from 'config'

/* Given an array of statuses, create an object with camelized keys
  example:
  statuses = [ 'Pending Review', 'Confirmed' ]
  statusList = { pendingReview: 'Pending Review', confirmed: 'Confirmed' }
*/

function createTaskTypesList(tasks) {
  return tasks.reduce((acc, task) => {
    if (!isEmpty(task)) {
      if (task === TASK_SUBTYPES.VALUATION) {
        acc[camelCase(task)] = 'Asset Valuation'
      } else if (task === TASK_SUBTYPES.CONTACT) {
        acc[camelCase(task)] = 'Review Contact Information'
      } else if (task === TASK_SUBTYPES.FUNDING) {
        acc[camelCase(task)] = 'Review Funding Instructions'
      } else {
        acc[camelCase(task)] = task
      }
    }
    return acc
  }, {})
}

export default createTaskTypesList
