import React from 'react'
import { Route, Redirect, Switch } from 'react-router'
import * as Views from './views'
import Group1AuthorizedLayout from '../Group1AuthorizedLayout'
import Layout from './Layout'

const propTypes = {}

const defaultProps = {}

function Routes() {
  return (
    <Group1AuthorizedLayout>
      <Layout>
        <Switch>
          <Route
            exact
            path={'/profiles/:contactID'}
            component={Views.ContactProfile}
          />
          <Redirect from="*" to="/home" />
        </Switch>
      </Layout>
    </Group1AuthorizedLayout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
