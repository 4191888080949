import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from './reducer'
import * as apiActions from 'api-actions'
import { Spinner } from 'lp-components'
import * as Types from 'types'

const propTypes = {
  children: PropTypes.node.isRequired,
  contactsList: PropTypes.arrayOf(Types.contact),
  fetchContactsList: PropTypes.func.isRequired,
}

const defaultProps = {
  contactsList: null,
}

function Layout({ children, contactsList, fetchContactsList }) {
  useEffect(() => {
    fetchContactsList()
  }, [])

  if (!contactsList) return <Spinner />

  return (
    <div className="profile-layout-wrapper">
      {React.cloneElement(children, {
        contactsList: contactsList,
      })}
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    contactsList: selectors.contactsList(state),
  }
}

const mapDispatchToProps = {
  fetchContactsList: apiActions.fetchContactsList,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
