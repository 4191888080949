import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { FlashMessage, Spinner } from 'lp-components'
import * as apiActions from 'api-actions'
import {
  Card,
  ScrollToFieldError,
  ACPInputField,
  LabelWithTooltip,
} from 'components'
import { Formik, Form } from 'formik'
import { getSessionToken } from 'auth'
import { normalizeCountryValue, normalizeStateAndProvinceValue } from 'utils'
import * as Yup from 'yup'
import {
  ADD_ASSET_STAGE,
  PHONE_NUMBER_REGEX,
  ZIP_CODE_REGEX,
  CANADIAN_ZIP_CODE_REGEX,
  addNewContactOption,
} from 'config'
import {
  ContactSearchAndSelect,
  FlowActions,
} from '../../add-asset-flow/components'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as Types from 'types'

const propTypes = {
  createAsset: PropTypes.func.isRequired,
  updateAsset: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
  contactsList: PropTypes.arrayOf(Types.contact),
  currentUser: Types.user.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
}

const defaultProps = {
  isNotEditable: false,
}

function AssetDetails({
  isNotEditable,
  contactsList,
  currentUser,
  createAsset,
  updateAsset,
  fetchUserProfile,
}) {
  const [error, setError] = useState()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!contactsList) {
    return <Spinner />
  }

  const initialValues = {
    investmentType: location.state?.params?.assetType
      ? location.state?.params?.assetType
      : '',
    securityType: location.state?.params?.securityType
      ? location.state?.params?.securityType
      : '',
    investmentName: '',
    managingMember: {
      match: currentUser.contactID,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      companyID: currentUser.accountID,
      companyName: '',
      email: currentUser.contactDetails.email,
      streetAddress: currentUser.contactDetails.streetAddress,
      country: normalizeCountryValue(currentUser.contactDetails.country),
      city: currentUser.contactDetails.city,
      state: normalizeStateAndProvinceValue(
        currentUser.contactDetails.country,
        currentUser.contactDetails.state
      ),
      zipcode: currentUser.contactDetails.zipcode,
      phone: currentUser.contactDetails.phone,
    },
  }

  const setErrorMessage = (e) => {
    const { error, status } = e.response
    if (error === 'name')
      return setError({
        message: 'Please provide an Investment Name for this asset.',
      })
    setError({ message: e?.message || status })
  }
  // if there is an assetID then update, else post new
  const handleSubmit = async (values) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      phoneType,
      streetAddress,
      city,
      state,
      country,
      zipcode,
      match,
      companyName,
    } = values['managingMember']

    const isSaveAndExit = !values.requireAll
    let asset = {}

    if (isSaveAndExit) {
      try {
        asset = await createAsset(values.investmentName)
      } catch (e) {
        setErrorMessage(e)
        return
      }
    }

    const mainContact = {
      firstName,
      lastName,
      email,
      phone,
      phoneType,
      streetAddress,
      city,
      state,
      country,
      zipcode,
      contactID: match && match !== addNewContactOption.value ? match : '',
      // if a managing member exists, replace their details using their relationshipID
      relationshipID: '',
      roleID: 'Managing Member', // issuer and managing member are interchangeable in this context, but since we refer to this as the Issuer Details page
    }

    const payload = {
      ...asset,
      stage: ADD_ASSET_STAGE.ASSET_DETAILS,
      saveStage: ADD_ASSET_STAGE.ASSET_DETAILS,
      name: values.investmentName,
      investmentType: values.investmentType,
      securityType: location.state?.params.securityType,
      entityType: location.state?.params.entityType,
      companyName,
      contacts: [mainContact], // We're updating contacts by name, so we don't need to pass every other existing contact every time.
    }

    try {
      if (isSaveAndExit) {
        await updateAsset(payload)
        await fetchUserProfile()
        history.push('/home')
      } else {
        history.push({
          pathname: `/onboarding-add-asset/contact-details`,
          state: {
            storedAsset: payload,
          },
        })
      }
    } catch (e) {
      setErrorMessage(e)
    }
  }

  const assetDetailsValidationSchema = Yup.object().shape({
    investmentName: Yup.string()
      .min(2, 'Too Short!')
      .max(80, 'Too Long!')
      .required('Required'),
    requireAll: Yup.boolean(),
    managingMember: Yup.object()
      .when('requireAll', {
        is: true,
        then: Yup.object().shape({
          companyID: Yup.string().required('Required'),
          email: Yup.string().when('match', {
            is: addNewContactOption.value,
            then: Yup.string()
              .email('Must enter valid email!')
              .test(
                'emailValidationCheck',
                'This email is already associated with a contact for Asset Custody Platform. Please use another email address.',
                (email) =>
                  fetch(
                    `${process.env.API_URL}/emailAddresses?email=${email}`,
                    {
                      method: 'GET',
                      headers: {
                        Accept: `application/json`,
                        Authorization: `Bearer ${getSessionToken()}`,
                      },
                    }
                  ).then(async (res) => {
                    const data = await res.json()
                    if (data.isValid) {
                      return true
                    } else {
                      return false
                    }
                  })
              )
              .required('Required')
              .nullable(),
            otherwise: Yup.string()
              .email('Must enter valid email!')
              .required('Required')
              .nullable(),
          }),
          phone: Yup.string()
            .matches(
              RegExp(PHONE_NUMBER_REGEX),
              'Please enter valid phone number'
            )
            .required('Required')
            .nullable(),
          streetAddress: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Required')
            .nullable(),
          city: Yup.string()
            .min(2, 'Too Short!')
            .max(40, 'Too Long!')
            .required('Required')
            .nullable(),
          state: Yup.string()
            .required('Required')
            .nullable(),
          country: Yup.string()
            .required('Required')
            .nullable(),
          zipcode: Yup.string()
            .test('test_zip_code', '', (value, context) => {
              const { parent, createError } = context
              if (parent.country == 'US') {
                if (!RegExp(ZIP_CODE_REGEX).test(value)) {
                  return createError({
                    message: 'Must be valid 5 or 9 digit US zip code!',
                  })
                }
              }
              if (parent.country == 'CA') {
                if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
                  return createError({
                    message: 'Must be a valid six-character CA code!',
                  })
                }
              }
              return true
            })
            .required('Required')
            .nullable(),
        }),
      })
      .shape({
        match: Yup.string().required('Required'),
        firstName: Yup.string()
          .min(2, 'Too Short!')
          .max(40, 'Too Long!')
          .required('Required')
          .nullable(),
        lastName: Yup.string()
          .min(2, 'Too Short!')
          .max(80, 'Too Long!')
          .required('Required')
          .nullable(),
      }),
  })

  const disclaimerMessage =
    'Prior to accepting custody, an asset undergoes a pre-custody process to ensure that Inspira can perform its duties as a directed custodian. We do not evaluate or perform any due diligence or suitability reviews on any investment or investment issuer, and we do not offer or sell investments or give investment, tax or legal advice.'

  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={assetDetailsValidationSchema}
        >
          {({ values }) => {
            return (
              <Form className="asset-form asset-details-form">
                <ScrollToFieldError />
                <div className="form-card-content">
                  <fieldset
                    className="read-only-wrapper"
                    disabled={isNotEditable}
                  >
                    <div className="container">
                      {error && (
                        <FlashMessage
                          isError={true}
                          onDismiss={() => {
                            setError(null)
                          }}
                        >
                          {error.message}
                        </FlashMessage>
                      )}
                      <Card>
                        <h4 className="flow-section-headers">
                          Investment Details
                        </h4>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              name="investmentName"
                              label="Investment Name"
                              placeholder="Enter investment name"
                            />
                          </div>
                          <div className="one-half column"></div>
                        </div>
                        <p className="investment-name-text">
                          (Investment name should reflect the legal name as
                          stated in the Offering Document)
                        </p>
                      </Card>
                      <Card
                        className="contact-details-sub-form-card"
                        label={
                          <LabelWithTooltip
                            label="Managing Member Details"
                            tooltipContent="Individual at Issuer who is responsible for the day-to-day
                          business operations and is an authorized signer for the company."
                          />
                        }
                      >
                        <ContactSearchAndSelect
                          contactType="managingMember"
                          isNotEditable={isNotEditable}
                          disclaimerMessage={disclaimerMessage}
                          contactsList={contactsList}
                        />
                      </Card>
                    </div>
                  </fieldset>
                </div>
                {!isNotEditable && (
                  <FlowActions
                    preventBack
                    preventSave={
                      values.managingMember.match === addNewContactOption.value
                    }
                  />
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

AssetDetails.propTypes = propTypes
AssetDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    contactsList: selectors.contactsList(state),
  }
}

const mapDispatchToProps = {
  createAsset: apiActions.createAsset,
  updateAsset: apiActions.updateAsset,
  fetchUserProfile: apiActions.fetchUserProfile,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AssetDetails
)
