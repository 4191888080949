import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RoleDefinitionModal, ACPInputField, MultiSelect } from 'components'
import { SubmitButton, Spinner } from 'lp-components'
import { ROLE_DEFINITION } from 'config'
import { useFormikContext } from 'formik'
import classnames from 'classnames'
import { components } from 'react-select'
import { truncate } from 'lodash'
import pluralize from 'pluralize'

const propTypes = {
  sortedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  unassignedRoles: PropTypes.arrayOf(PropTypes.string.isRequired),
  isReadOnly: PropTypes.bool,
  setIsReadOnly: PropTypes.func,
  assetAssignmentInitialValues: PropTypes.object,
  assetOptions: PropTypes.array,
  setShowRoleForm: PropTypes.func,
  setIsTileEditing: PropTypes.func,
  formattedRoles: PropTypes.array,
  setHasEditsPending: PropTypes.func,
  setSubmittingNewRole: PropTypes.func,
}
const defaultProps = {}

const Option = (props) => {
  return (
    <div>
      <components.Option innerProps={props} {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          disabled={props.isDisabled}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
)

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props
  const values = getValue()

  const valuesWithoutAll = values.filter((value) => value.value !== '*')
  const numberOfValues = valuesWithoutAll.length
  const isSelectAll = values[0] === '*'

  const valueMessage = hasValue
    ? valuesWithoutAll
        .slice(0, 3)
        .map((value) => truncate(value.label, { length: 24 }))
        .join(', ')
    : null

  const trailingMessage =
    numberOfValues > 3 &&
    `, ...${numberOfValues - 3} other ${pluralize(
      'item',
      numberOfValues - 3
    )} selected`

  return (
    <components.ValueContainer {...props}>
      {isSelectAll && 'All Assets Selected'}
      {!isSelectAll && valueMessage && valueMessage}
      {!isSelectAll && trailingMessage && trailingMessage}
      {children}
    </components.ValueContainer>
  )
}

function RoleAddNewFields({
  sortedOptions,
  unassignedRoles,
  isReadOnly,
  setIsReadOnly,
  assetAssignmentInitialValues,
  assetOptions,
  setShowRoleForm,
  setIsTileEditing,
  setHasEditsPending,
  formattedRoles,
  setSubmittingNewRole,
}) {
  useEffect(() => {}, [sortedOptions])

  const [showRoleDefinitionModal, setShowRoleDefinitionModal] = useState(false)
  const [isRoleSelected, setIsRoleSelected] = useState(false)

  const { setFieldValue, dirty, resetForm, isSubmitting } = useFormikContext()

  if (!unassignedRoles || !assetOptions) return <Spinner />

  const mappedAssetOptions = assetOptions.map((asset) => ({
    value: asset.value,
    label: asset.key,
  }))

  if (!formattedRoles) return <Spinner />

  return (
    <div className="create-new-role-form">
      <div className="row role-select-row">
        <ACPInputField
          name="newRole"
          label="Role"
          enablePlaceholderOption={true}
          selectOptions={formattedRoles}
          onChange={(e) => {
            setFieldValue('newRole', e.target.value)
            setIsRoleSelected(true)
            setHasEditsPending(true)
            setIsReadOnly(false)
          }}
          defaultValue={null}
          placeholder="Select Role"
        />

        <span>
          To learn more about roles,{' '}
          <button
            className="modal-button-link"
            type="button"
            onClick={() => setShowRoleDefinitionModal(true)}
          >
            click here.
          </button>
        </span>
      </div>
      <div className="row asset-select-row">
        <MultiSelect
          name="newAssetAssignments"
          label="Asset Assignments"
          options={mappedAssetOptions}
          components={{
            Option,
            MultiValue,
            ValueContainer,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={() => {
            setHasEditsPending(true)
          }}
          disabled={!isRoleSelected || isReadOnly}
          wrapStyle="nowrap"
        />
      </div>

      <div className="assign-new-btns-row">
        <>
          <button
            className="button-secondary"
            onClick={() => {
              setShowRoleForm(false)
              setIsTileEditing(false)
              resetForm({ ...assetAssignmentInitialValues })
              setHasEditsPending(false)
              setSubmittingNewRole(false)
            }}
            type="button"
          >
            Cancel
          </button>
          <SubmitButton
            className={classnames('button-primary', {
              'is-disabled': !dirty,
            })}
            submitting={isSubmitting}
            disabled={!dirty}
          >
            Save Assignments
          </SubmitButton>
        </>
      </div>
      {showRoleDefinitionModal && (
        <RoleDefinitionModal
          onClose={() => setShowRoleDefinitionModal(false)}
          title="Definition of Roles"
          roles={ROLE_DEFINITION}
        />
      )}
    </div>
  )
}

RoleAddNewFields.propTypes = propTypes
RoleAddNewFields.defaultProps = defaultProps

export default RoleAddNewFields
