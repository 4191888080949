function sortByDateAttribute(array, attribute, options = {}) {
  return array.sort((a, b) => {
    const date1 = new Date(a[attribute])
    const date2 = new Date(b[attribute])

    if (options.isDesc) {
      return date1 - date2
    } else {
      return date2 - date1
    }
  })
}

export default sortByDateAttribute
