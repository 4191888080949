import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import * as contactRoleReplacementActions from './actions'

const reducerKey = 'contactRoleReplacement'
const slice = 'root.contactRoleReplacement'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchContactsList]: setOnSuccess('contactsList'),
    [contactRoleReplacementActions.clearContactsList]: unsetState(
      'contactsList'
    ),
    [apiActions.fetchContactProfile]: setOnSuccess('contact'),
    [contactRoleReplacementActions.clearContactProfile]: unsetState('contact'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  contact: select('contact'),
  contactsList: select('contactsList'),
}

export { reducer, selectors, reducerKey }
