import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  milliseconds: PropTypes.number.isRequired,
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

function ProgressBar({ milliseconds }) {
  const [time, setTime] = useState(milliseconds)
  const [percentage, setPercentage] = useState(0)

  useInterval(() => {
    setTime(time - milliseconds / 100)
    setPercentage(
      (milliseconds - time) / milliseconds >= 1
        ? 100
        : ((milliseconds - time) / milliseconds) * 100
    )
  }, milliseconds / 100)

  return (
    <div className="progressBar">
      <div className="innerDiv">
        <h2>Uploading Document(s)...</h2>
        <p>Please stay on page.</p>
        <div
          style={{
            height: 4,
            width: '350px',
            backgroundColor: '#FFECB1',
            borderRadius: 50,
            marginBottom: 8,
          }}
        >
          <div
            style={{
              height: '100%',
              width: `${percentage}%`,
              backgroundColor: '#4553A9',
              borderRadius: 'inherit',
              textAlign: 'right',
              transition: 'width 1s ease-in-out',
            }}
          ></div>
        </div>
        {time > 0 ? (
          <span>
            {time / 1000 / 60 < 1
              ? 'Less than a minute remaining'
              : `About ${Math.round(time / 1000 / 60)} minute(s) remaining`}
          </span>
        ) : (
          <span>Document upload is taking longer than expected...</span>
        )}
      </div>
    </div>
  )
}

ProgressBar.propTypes = exact(propTypes)

export default ProgressBar
