import React, { useState } from 'react'
import {
  countries,
  usStates,
  canadianProvinces,
  ZIP_CODE_REGEX,
  PHONE_NUMBER_REGEX,
  CANADIAN_ZIP_CODE_REGEX,
} from 'config'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as flashActions from 'redux-flash'
import * as apiActions from 'api-actions'
import { Formik, Form } from 'formik'
import { ACPInputField, ExitWithoutSavingModal } from 'components'
import { isEmpty } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import classnames from 'classnames'
import * as Yup from 'yup'
import { Modal, SubmitButton } from 'lp-components'
import { normalizeStateAndProvinceValue, normalizeCountryValue } from 'utils'

const propTypes = {
  contactDetails: PropTypes.object.isRequired,
  setShowContactProfileModal: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  fetchContactProfile: PropTypes.func.isRequired,
}

const defaultProps = {}

const ColumnOneHalf = ({ children }) => (
  <div className="one-half column">{children}</div>
)

const ColumnOneThird = ({ children }) => (
  <div className="one-third column">{children}</div>
)

const ColumnFull = ({ children }) => (
  <div className="twelve columns">{children}</div>
)

function ContactProfileModal({
  setShowContactProfileModal,
  contactDetails,
  updateUserProfile,
  flashSuccessMessage,
  flashErrorMessage,
  fetchContactProfile,
}) {
  const [showExitWithoutSavingModal, setShowExitWithoutSavingModal] = useState(
    false
  )

  const contactProfileInitialValues = {
    contactID: contactDetails?.contactID,
    firstName: contactDetails?.firstName,
    lastName: contactDetails?.lastName,
    company: contactDetails?.accountName,
    email: contactDetails?.email,
    phone: contactDetails?.phone,
    streetAddress: contactDetails?.streetAddress,
    city: contactDetails?.city,
    country: normalizeCountryValue(contactDetails?.country),
    state: normalizeStateAndProvinceValue(
      contactDetails?.country,
      contactDetails?.state
    ),
    zipcode: contactDetails?.zipcode,
  }

  const profileValidation = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(40, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(80, 'Too Long!')
      .required('Required'),
    company: Yup.string().required('Required'),
    phone: Yup.string()
      .matches(RegExp(PHONE_NUMBER_REGEX), 'Please enter valid phone number')
      .required('Required'),
    email: Yup.string()
      .email('Must enter valid email!')
      .required('Required'),
    streetAddress: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    city: Yup.string()
      .min(2, 'Too Short!')
      .max(40, 'Too Long!')
      .required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    contactID: Yup.string().nullable(),
    zipcode: Yup.string()
      .required('Required')
      .test('test_zip_code', '', (value, context) => {
        const { parent, createError } = context
        if (parent.country == 'US') {
          if (!RegExp(ZIP_CODE_REGEX).test(value)) {
            return createError({
              message: 'Must be valid 5 or 9 digit US zip code!',
            })
          }
        }
        if (parent.country == 'CA') {
          if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
            return createError({
              message: 'Must be a valid six-character CA code!',
            })
          }
        }
        return true
      }),
  })

  const handleProfileSubmit = async (data) => {
    try {
      await updateUserProfile(data)
      await fetchContactProfile(contactDetails?.contactID)
      setShowContactProfileModal(false)
      flashSuccessMessage('This contact has been updated.')
    } catch (e) {
      flashErrorMessage(
        e.errors.message || 'Something went wrong, please try again.'
      )
    }
  }

  return (
    <Formik
      onSubmit={handleProfileSubmit}
      validationSchema={profileValidation}
      initialValues={contactProfileInitialValues}
      enableReinitialize={true}
    >
      {({
        values,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        dirty,
        isSubmitting,
      }) => {
        const isUsOrCanada = values.country === 'US' || values.country === 'CA'
        return (
          <Modal
            onClose={() => {
              if (!dirty) {
                setShowContactProfileModal(false)
              } else {
                setShowExitWithoutSavingModal(true)
              }
            }}
          >
            <h4>Edit Contact Information</h4>
            <button
              className="modal-close"
              type="button"
              onClick={() => {
                if (!dirty) {
                  setShowContactProfileModal(false)
                } else {
                  setShowExitWithoutSavingModal(true)
                }
              }}
            >
              ×
            </button>
            <Form className="contact-profile-modal-form">
              <div className="row name-row">
                <ColumnOneHalf>
                  <ACPInputField
                    name="firstName"
                    label="First Name"
                    placeholder="Enter first name"
                  />
                </ColumnOneHalf>
                <ColumnOneHalf>
                  <ACPInputField
                    name="lastName"
                    label="Last Name"
                    placeholder="Enter last name"
                  />
                </ColumnOneHalf>
              </div>
              <div className="row contact-method-row">
                <ColumnOneHalf>
                  <ACPInputField
                    label="Phone Number"
                    name="phone"
                    type="tel"
                    placeholder="Enter phone"
                    maskOptions={{
                      numericOnly: true,
                      blocks: [0, 3, 0, 3, 4],
                      delimiters: ['(', ')', ' ', '-'],
                    }}
                  />
                </ColumnOneHalf>
                <ColumnOneHalf>
                  <ACPInputField
                    label="Email"
                    tooltipContent="The email address is used to log in to the platform. To change the email address please contact the Client Service team. They can be reached Monday - Friday 7:00 AM - 6:00 PM C.T. at (800) 258-7878."
                    name="email"
                    disabled={true}
                  />
                </ColumnOneHalf>
              </div>
              <div className="row company-row">
                <ColumnOneHalf>
                  <ACPInputField
                    name="company"
                    label="Company"
                    disabled={true}
                  />
                </ColumnOneHalf>
              </div>
              <div className="row street-address-row">
                <ColumnFull>
                  <ACPInputField
                    name="streetAddress"
                    label="Street Address"
                    placeholder="Enter street address"
                  />
                </ColumnFull>
              </div>
              <div className="row country-row">
                <ColumnFull>
                  <ACPInputField
                    name="country"
                    label="Country"
                    selectOptions={countries}
                    enablePlaceholderOption={true}
                    placeholder="Select country"
                    onChange={() => {
                      setFieldValue('state', '', false)
                      setFieldError('state', '')
                      setFieldTouched('state', false, false)
                    }}
                  />
                </ColumnFull>
              </div>
              <div className="row city-state-zip-row">
                <ColumnOneThird>
                  {isEmpty(values.country) || isUsOrCanada ? (
                    <ACPInputField
                      name="state"
                      label="State/Province/Region"
                      selectOptions={
                        values.country === 'US' ? usStates : canadianProvinces
                      }
                      enablePlaceholderOption={true}
                      placeholder="Select State/Province/Region"
                      readOnly={!isUsOrCanada}
                    />
                  ) : (
                    <ACPInputField
                      name="state"
                      label="State/Province/Region"
                      enablePlaceholderOption={true}
                      placeholder="Enter State/Province/Region"
                      readOnly={isEmpty(values.country) || isUsOrCanada}
                    />
                  )}
                </ColumnOneThird>
                <ColumnOneThird>
                  <ACPInputField name="city" label="City" />
                </ColumnOneThird>
                <ColumnOneThird>
                  <ACPInputField name="zipcode" label="Zip Code" />
                </ColumnOneThird>
              </div>
              <div className="profile-submit-button-area submit-button-area-contact-profile-modal">
                <>
                  <button
                    className="button-secondary"
                    onClick={() => {
                      if (!dirty) {
                        setShowContactProfileModal(false)
                      } else {
                        setShowExitWithoutSavingModal(true)
                      }
                    }}
                    type="button"
                  >
                    Cancel
                  </button>
                  <SubmitButton
                    className={classnames({
                      'is-disabled': !dirty,
                    })}
                    disabled={!dirty}
                    submitting={isSubmitting}
                  >
                    Save Changes
                  </SubmitButton>
                </>
              </div>
            </Form>
            {showExitWithoutSavingModal && (
              <ExitWithoutSavingModal
                onClose={() => setShowExitWithoutSavingModal(false)}
                onExit={() => {
                  setShowExitWithoutSavingModal(false)
                  setShowContactProfileModal(false)
                }}
                workFlowName="contact"
              />
            )}
          </Modal>
        )
      }}
    </Formik>
  )
}

ContactProfileModal.propTypes = exact(propTypes)
ContactProfileModal.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
  updateUserProfile: apiActions.updateUserProfile,
  fetchContactProfile: apiActions.fetchContactProfile,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactProfileModal
)
