import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router'
import * as Views from './views'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  return <Route exact path={path} component={Views.Onboarding} />
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
