import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Field } from 'formik'
import { ACCOUNT_TYPE_OPTIONS } from 'config'
import { ascendingAlphaSort } from 'utils'
import { useClickAway } from 'react-use'

const propTypes = {
  handleExternalFilterChange: PropTypes.func.isRequired,
  setPicklistVisible: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  filterTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

const typeOptions = [
  ...Object.values(ACCOUNT_TYPE_OPTIONS).sort(ascendingAlphaSort),
]

function TypesFilterPicklist({
  handleExternalFilterChange,
  setPicklistVisible,
  setFieldValue,
  values,
  filterTypes,
  onClose,
}) {
  const ref = useRef(null)

  useClickAway(
    ref,
    () => {
      onClose(values)
    },
    ['click']
  )

  const onCancel = () => {
    setFieldValue('typesFilter', filterTypes)
    setPicklistVisible(false)
  }

  const handleClearFilters = () => {
    setFieldValue('typesFilter', [])
    handleExternalFilterChange([])
    setPicklistVisible(false)
  }

  return (
    <div ref={ref} className="types-input-wrapper">
      <label className="filter-header" htmlFor="role">
        Account Type
      </label>
      <div role="group" className="CheckboxGroup">
        {typeOptions.map((type) => (
          <label key={type}>
            <Field type="checkbox" name="typesFilter" value={type} />
            {type}
          </label>
        ))}
      </div>
      <button className="button-primary" type="submit">
        Apply Filter(s)
      </button>
      <button type="button" className="button-secondary" onClick={onCancel}>
        Cancel
      </button>
      <button
        className="button-secondary clear-all-button"
        type="button"
        onClick={handleClearFilters}
      >
        Clear All Filters
      </button>
    </div>
  )
}

TypesFilterPicklist.propTypes = exact(propTypes)

export default TypesFilterPicklist
