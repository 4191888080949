import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { ACPInputField } from 'components'
import { Formik, Form } from 'formik'
import { Modal, SubmitButton } from 'lp-components'
import StatusUrgentIcon from 'images/status-urgent.svg'
import CheckIcon from 'images/utility-icons/checkmark-white.svg'
import * as flashActions from 'redux-flash'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  fundingInstructions: PropTypes.object,
  assetName: PropTypes.string.isRequired,
  assetID: PropTypes.string.isRequired,
  updateFundingInstructions: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  isReview: PropTypes.bool,
  tasks: PropTypes.arrayOf(PropTypes.object),
  fetchTasks: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  onUpdateFundingInstructions: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

const defaultProps = {}

function FundingInstructionsModal({
  onClose,
  fundingInstructions,
  assetName,
  assetID,
  isReview,
  flashSuccessMessage,
  flashErrorMessage,
  updateFundingInstructions,
  tasks,
  fetchTasks,
  updateTask,
  currentUser,
  onUpdateFundingInstructions,
  history,
}) {
  const instructions = fundingInstructions.fundingInstructions

  useEffect(() => {
    fetchTasks()
  }, [])

  const fundingOptions = {
    bankTransfer: 'Wire Transfer',
    check: 'Check',
    ach: 'ACH',
  }

  const bankOptions = {
    checking: 'Checking',
    savings: 'Savings',
  }

  const handleConfirm = async (params) => {
    const payload = {
      assetID: assetID,
      name: params?.name,
      fundingInstructions: params,
      eSign: {
        FirstName: currentUser?.firstName,
        LastName: currentUser?.lastName,
        Title: null,
        Date: Date.now(),
      },
    }

    try {
      await updateFundingInstructions(payload)
      var task = tasks.find(
        (task) => task.recordID === fundingInstructions?.assetID
      )

      if (task?.taskID && task?.subType === 'Funding') {
        await updateTask([{ taskID: task?.taskID, status: 'Completed' }])
      }
      await flashSuccessMessage(
        `You confirmed the funding instructions for ${assetName}.`
      )
      history.push(`/assets`)
      onClose()
    } catch (e) {
      flashErrorMessage(
        e.errors.message || 'Something went wrong, please try again.'
      )
    }
  }

  return (
    <Modal onClose={onClose}>
      <Formik
        initialValues={{
          ...instructions,
          fundingType: instructions.isCheck
            ? 'check'
            : instructions.isACH
            ? 'ach'
            : 'bankTransfer',
          bankTransferBankAccountType: instructions.isCheckingAccount
            ? 'checking'
            : 'savings',
        }}
        onSubmit={handleConfirm}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form className="asset-form asset-details-form">
              <h4>Funding Instructions for {assetName}</h4>
              {isReview && (
                <div className="funding-review-chip">
                  <img
                    alt="Urgent"
                    style={{ height: '20px', width: '20px' }}
                    src={StatusUrgentIcon}
                  ></img>
                  Please confirm or update funding instructions.
                </div>
              )}
              <div className="form-card-content">
                <fieldset className="read-only-wrapper">
                  <div className="container funding-instructions">
                    <p className="funding-modal-subheader">
                      Inspira uses these funding instructions to send funds to
                      the Investment Issuer/Sponsor on behalf of the investor.
                    </p>
                    <div className="row">
                      <div className="one-half column radio-group">
                        <ACPInputField
                          name="fundingType"
                          label="Funding Method"
                          value={fundingOptions[values?.fundingType]}
                          disabled
                        />
                      </div>
                      {values.isBankTransfer && (
                        <div className="one-half column radio-group">
                          <ACPInputField
                            name="bankTransferBankAccountType"
                            label="Bank Account Type"
                            value={
                              bankOptions[values?.bankTransferBankAccountType]
                            }
                            disabled
                          />
                        </div>
                      )}
                    </div>
                    {values.isBankTransfer && (
                      <>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              label="Routing Number"
                              name="abaNumber"
                              placeholder="Enter ABA number"
                              disabled
                            />
                          </div>
                          <div className="one-half column">
                            <ACPInputField
                              name="accountNumber"
                              label="Account Number"
                              placeholder="Enter bank account number"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              name="bankName"
                              label="Bank Name"
                              placeholder="Enter bank name"
                              textarea
                              disabled
                            />
                          </div>
                          <div className="one-half column">
                            <ACPInputField
                              label="Account Name"
                              name="accountName"
                              placeholder="Enter account name"
                              textarea
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              name="bankPhone"
                              label="Bank Phone Number"
                              type="tel"
                              placeholder="Enter bank phone number"
                              disabled
                            />
                          </div>
                          <div className="row">
                            {values?.furtherCreditAccountNumber && (
                              <div className="one-half column">
                                <ACPInputField
                                  label="FFC Account Number"
                                  name="furtherCreditAccountNumber"
                                  placeholder="Enter account number"
                                  disabled
                                />
                              </div>
                            )}
                            {values?.swiftCode && (
                              <div className="one-half column">
                                <ACPInputField
                                  label="SWIFT Code"
                                  name="swiftCode"
                                  placeholder="Enter SWIFT code"
                                  disabled
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {values?.additionalWire && (
                          <div className="row">
                            <div className="one-half column">
                              <ACPInputField
                                label="Additonal Wire Instruction"
                                name="additionalWire"
                                placeholder="Enter additional wire instructions"
                                textarea
                                disabled
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {values.isCheck && (
                      <>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              label="Payee Name"
                              name="payee"
                              placeholder="Enter payee name"
                              textarea
                              disabled
                            />
                          </div>
                          {values?.attention && (
                            <div className="one-half column">
                              <ACPInputField
                                label="Attention (For Mailing)"
                                name="attention"
                                placeholder="Enter check attention"
                                textarea
                                disabled
                              />
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              name="country"
                              label="Country"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row">
                          <ACPInputField
                            name="streetAddress"
                            label="Street Address"
                            placeholder="Enter street address"
                            textarea
                            disabled
                          />
                        </div>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              name="city"
                              label="City"
                              placeholder="Enter city"
                              disabled
                            />
                          </div>
                          <div className="one-half column">
                            <ACPInputField
                              name="state"
                              label="State/Province/Region"
                              enablePlaceholderOption={true}
                              placeholder="Enter State/Province/Region"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              name="zipcode"
                              label="Zip Code"
                              placeholder="Enter zip code"
                              disabled
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {values.isACH && (
                      <>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              label="Routing Number"
                              name="abaNumber"
                              placeholder="Enter transit/ABA number"
                              disabled
                            />
                          </div>
                          <div className="one-half column">
                            <ACPInputField
                              name="accountNumber"
                              label="Account Number"
                              placeholder="Enter bank account number"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              name="bankName"
                              label="Bank Name"
                              placeholder="Enter bank name"
                              disabled
                              textarea
                            />
                          </div>
                          <div className="one-half column">
                            <ACPInputField
                              label="Account Name"
                              name="accountName"
                              placeholder="Enter account name"
                              textarea
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="one-half column">
                            <ACPInputField
                              name="bankPhone"
                              label="Bank Phone Number"
                              type="tel"
                              placeholder="Enter bank phone number"
                              disabled
                            />
                          </div>
                          {values?.furtherCreditAccountNumber && (
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  label="FFC Account Number"
                                  name="furtherCreditAccountNumber"
                                  placeholder="Enter account number"
                                  disabled
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </fieldset>
              </div>
              {isReview && (
                <div className="button-block--inline">
                  <SubmitButton
                    className="button-primary button-check"
                    submitting={isSubmitting}
                  >
                    <img
                      style={{ height: '14px', width: '14px' }}
                      src={CheckIcon}
                      alt="Check"
                    ></img>
                    Yes, this is correct
                  </SubmitButton>
                  <button
                    className="button-secondary"
                    onClick={onUpdateFundingInstructions}
                  >
                    Update Instructions
                  </button>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
      {!isReview && (
        <div className="button-block--inline">
          <button className="button-primary" onClick={onClose}>
            Close
          </button>
          <button
            className="button-secondary"
            onClick={onUpdateFundingInstructions}
          >
            Update Instructions
          </button>
        </div>
      )}
    </Modal>
  )
}

FundingInstructionsModal.propTypes = exact(propTypes)
FundingInstructionsModal.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    tasks: globalSelectors.tasks(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  fetchTasks: apiActions.fetchTasks,
  updateTask: apiActions.updateTask,
  updateFundingInstructions: apiActions.updateAssetFundingInstructions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FundingInstructionsModal
)
