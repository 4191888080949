import React from 'react'
import { Card } from 'components'
import ArrowIcon from 'images/right-arrow-icon.svg'
import { useHistory } from 'react-router'

function InviteInvestorsCard() {
  const history = useHistory()
  return (
    <Card
      className="invite-investors-card"
      label="Invite New Investors"
      onClick={() => history.push('/invitation')}
    >
      <div className="content-wrapper">
        <p>Have someone you'd like to invite to open an account at Inspira?</p>
        <span>
          Click Here to Invite <img src={ArrowIcon} alt="arrow" />
        </span>
      </div>
    </Card>
  )
}
export default InviteInvestorsCard
