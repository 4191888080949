import React from 'react'
import StatusClockIcon from 'images/status-time.svg'
import StatusFailedIcon from 'images/status-failed.svg'
import StatusSuccessIcon from 'images/status-confirmed.svg'
import StatusUrgentIcon from 'images/status-urgent.svg'

function AssetStatusRenderer(props) {
  const cellValue = props.value
  let statusIcon

  if (
    cellValue === 'In Review' ||
    cellValue === 'Reviewed' ||
    cellValue === 'Received'
  ) {
    statusIcon = StatusClockIcon
  } else if (
    cellValue === 'Awaiting Response' ||
    cellValue === 'Review Required' ||
    cellValue === 'Hold'
  ) {
    statusIcon = StatusUrgentIcon
  } else if (cellValue === 'Cancelled' || cellValue === 'Declined') {
    statusIcon = StatusFailedIcon
  } else if (cellValue === 'Accepted') {
    statusIcon = StatusSuccessIcon
  } else {
    return cellValue
  }

  return (
    <div className="status-cell-wrapper">
      <span className="status-icon">
        <img
          style={{ height: '16px', width: '16px' }}
          src={statusIcon}
          alt="status icon"
        ></img>
      </span>
      <span>{cellValue}</span>
    </div>
  )
}

export default React.memo(AssetStatusRenderer)
