import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useParams, useHistory } from 'react-router-dom'
import { Card, ScrollToFieldError, ACPInputField } from 'components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ADD_ASSET_STAGE } from 'config'
import { FlowActions } from '../components'

const propTypes = {
  storedAsset: PropTypes.object.isRequired,
  updateAsset: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
}

const defaultProps = {
  isNotEditable: false,
}

function PurchaseRequirements({ storedAsset, updateAsset, isNotEditable }) {
  let { assetID } = useParams()
  const history = useHistory()
  const [error, setError] = useState()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const initialValues = {
    additionalSubscriptionDocumentsRequiredForPurchases:
      storedAsset?.purchaseRequirements?.areSubscriptionDocumentsRequired?.toString() ||
      '',
    originalDocumentsRequiredForPurchases:
      storedAsset?.purchaseRequirements?.areOriginalDocumentsRequiredForPurchase?.toString() ||
      '',
    eSignatureAcceptedForPurchases:
      storedAsset?.purchaseRequirements?.isESignatureAcceptableForPurchase?.toString() ||
      '',
    issuerWithdrawalFormRequiredToProcessRedemption:
      storedAsset?.purchaseRequirements?.isWithdrawalFormRequired?.toString() ||
      '',
    liquidationPolicy:
      storedAsset?.purchaseRequirements?.liquidationPolicy || '',
    originalDocumentsRequiredToProcessRedemption:
      storedAsset?.purchaseRequirements?.areOriginalDocumentsRequiredForRedemption?.toString() ||
      '',
    eSignatureAcceptedForRedemptions:
      storedAsset?.purchaseRequirements?.isESignatureAcceptableForRedemption?.toString() ||
      '',
  }

  // if there is an assetID then update, else post new
  const handleSubmit = async (values) => {
    const payload = {
      areOriginalDocumentsRequiredForPurchase:
        values.originalDocumentsRequiredForPurchases === 'true' ? true : false,
      areOriginalDocumentsRequiredForRedemption:
        values.originalDocumentsRequiredToProcessRedemption === 'true'
          ? true
          : false,
      areSubscriptionDocumentsRequired:
        values.additionalSubscriptionDocumentsRequiredForPurchases === 'true'
          ? true
          : false,
      isESignatureAcceptableForPurchase:
        values.eSignatureAcceptedForPurchases === 'true' ? true : false,
      isESignatureAcceptableForRedemption:
        values.eSignatureAcceptedForRedemptions === 'true' ? true : false,
      isWithdrawalFormRequired:
        values.issuerWithdrawalFormRequiredToProcessRedemption === 'true'
          ? true
          : false,
      liquidationPolicy: values.liquidationPolicy,
    }

    try {
      await updateAsset({
        ...storedAsset,
        stage: values.requireAll
          ? ADD_ASSET_STAGE.VALUATION_REQUIREMENTS
          : ADD_ASSET_STAGE.PURCHASE_REQUIREMENTS,
        saveStage: ADD_ASSET_STAGE.PURCHASE_REQUIREMENTS,
        purchaseRequirements: { ...payload },
      })

      if (values.requireAll) {
        history.push(`/add-asset/${assetID}/valuation-requirements`)
      } else {
        history.push('/home')
      }
    } catch (e) {
      setError(e)
    }
  }

  const purchaseRequirementsValidationSchema = Yup.object().shape({
    additionalSubscriptionDocumentsRequiredForPurchases: Yup.string().required(
      'Required'
    ),
    originalDocumentsRequiredForPurchases: Yup.string().required('Required'),
    eSignatureAcceptedForPurchases: Yup.string().required('Required'),
    issuerWithdrawalFormRequiredToProcessRedemption: Yup.boolean().required(
      'Required'
    ),
    liquidationPolicy: Yup.string().optional(),
    originalDocumentsRequiredToProcessRedemption: Yup.string().required(
      'Required'
    ),
    eSignatureAcceptedForRedemptions: Yup.string().required('Required'),
  })

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={purchaseRequirementsValidationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {() => (
            <Form className="asset-form asset-details-form">
              <ScrollToFieldError />
              <Card>
                <div className="form-card-content">
                  <fieldset
                    className="read-only-wrapper"
                    disabled={isNotEditable}
                  >
                    <div className="container">
                      <h4 className="flow-section-headers">
                        Purchase Requirements
                      </h4>
                      <div className="row">
                        <div className="twelve columns radio-group">
                          <ACPInputField
                            name="additionalSubscriptionDocumentsRequiredForPurchases"
                            label="Are additional subscription documents required for additional purchases?"
                            radioOptions={[
                              { key: 'Yes', value: 'true' },
                              { key: 'No', value: 'false' },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="twelve columns radio-group">
                          <ACPInputField
                            name="originalDocumentsRequiredForPurchases"
                            label="Are original documents required for purchases?"
                            radioOptions={[
                              { key: 'Yes', value: 'true' },
                              { key: 'No', value: 'false' },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="twelve columns radio-group">
                          <ACPInputField
                            name="eSignatureAcceptedForPurchases"
                            label="Is an e-signature acceptable for purchases?"
                            radioOptions={[
                              { key: 'Yes', value: 'true' },
                              { key: 'No', value: 'false' },
                            ]}
                          />
                        </div>
                      </div>
                      <hr />
                      <h4 className="flow-section-headers">
                        Liquidation Requirements
                      </h4>
                      <div className="row">
                        <div className="twelve columns radio-group">
                          <ACPInputField
                            name="issuerWithdrawalFormRequiredToProcessRedemption"
                            label="Is Issuer’s withdrawal form required to process a
                            redemption?"
                            radioOptions={[
                              { key: 'Yes', value: 'true' },
                              { key: 'No', value: 'false' },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="twelve columns radio-group">
                          <ACPInputField
                            name="liquidationPolicy"
                            type="text"
                            label="Liquidation Policy"
                            placeholder={
                              isNotEditable
                                ? ''
                                : 'Enter or paste liquidation policy'
                            }
                            disabled={isNotEditable}
                            required={false}
                            textarea
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="twelve columns radio-group">
                          <ACPInputField
                            name="originalDocumentsRequiredToProcessRedemption"
                            label="Are original documents required to process a
                            redemption?"
                            radioOptions={[
                              { key: 'Yes', value: 'true' },
                              { key: 'No', value: 'false' },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="twelve columns radio-group">
                          <ACPInputField
                            name="eSignatureAcceptedForRedemptions"
                            label="Is an e-signature acceptable for redemptions?"
                            radioOptions={[
                              { key: 'Yes', value: 'true' },
                              { key: 'No', value: 'false' },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </Card>
              {!isNotEditable && <FlowActions />}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

PurchaseRequirements.propTypes = propTypes

PurchaseRequirements.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateAsset: apiActions.updateAsset,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PurchaseRequirements
)
