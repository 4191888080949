import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useParams, useHistory } from 'react-router-dom'
import { DocumentUploadInput, Card, ScrollToFieldError } from 'components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ADD_ASSET_STAGE, MAX_FILE_SIZE_IN_BYTES } from 'config'
import { FlowActions } from '../components'
import { formatBytes, bulkPromiseUploads, createFileErrors } from 'utils'

const propTypes = {
  storedAsset: PropTypes.object.isRequired,
  updateAsset: PropTypes.func.isRequired,
  updateDocument: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
}

const defaultProps = {
  isNotEditable: false,
}

function DocumentUpload({
  storedAsset,
  updateAsset,
  updateDocument,
  isNotEditable,
}) {
  let { assetID } = useParams()
  const history = useHistory()
  const [documents, setDocuments] = useState(storedAsset?.documents)
  const [error, setError] = useState()
  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    const assetDocuments = storedAsset?.documents || []
    setDocuments(assetDocuments)
    const initialValues = {}

    assetDocuments.forEach((doc) => {
      if (doc.documentID) {
        initialValues[doc.requiredDocumentID] = {
          ...doc,
        }
      }
    })
    setInitialValues(initialValues)
  }, [storedAsset])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const handleSubmit = async (values, { setErrors }) => {
    const isSubmitAndContinue = values.requireAll

    delete values['requireAll']

    const fileUploadPromises = Object.entries(values)
      .filter((e) => e[1].fileName && e[1].documentID)
      .map((e) => ({
        fileName: e[1].fileName,
        documentID: e[1].documentID,
        fileContents: e[1].fileContents,
        fileType: e[1].fileType,
      }))
      .map((doc) => {
        const formData = new FormData()
        formData.append(
          'fileContents',
          new Blob([doc.fileContents], {
            type: doc.fileType,
          }),
          doc.fileName
        )
        return updateDocument(assetID, doc, formData)
      })

    const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromises)
    if (rejectedUploads.length) {
      const errors = createFileErrors(rejectedUploads)
      setErrors(errors)
      const fieldErrorNames = Object.keys(errors)
      const container = document.querySelector(
        `div[name='${fieldErrorNames[0]}']`
      )
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    } else {
      try {
        await updateAsset({
          ...storedAsset,
          stage: isSubmitAndContinue
            ? ADD_ASSET_STAGE.E_SIGN
            : ADD_ASSET_STAGE.DOCUMENTS,
          saveStage: ADD_ASSET_STAGE.DOCUMENTS,
        })

        if (isSubmitAndContinue) {
          history.push(`/add-asset/${assetID}/e-sign`)
        } else {
          history.push('/home')
        }
      } catch (e) {
        setError(e)
      }
    }
  }

  const lazyValidation = Yup.lazy(() => {
    const validationObj = {}

    storedAsset.documents.forEach((doc) => {
      if (doc.isDocumentRequired) {
        validationObj[doc.requiredDocumentID] = Yup.object().when(
          'requireAll',
          {
            is: true,
            then: Yup.object().required('Please attach the required document'),
            otherwise: Yup.object().notRequired(),
          }
        )
      } else {
        validationObj[doc.requiredDocumentID] = Yup.object().notRequired()
      }
    })

    const validationObject = Yup.object(validationObj)

    return validationObject
  })

  const formattedMaxFileSize = formatBytes(MAX_FILE_SIZE_IN_BYTES)

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={lazyValidation}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({ errors, setFieldValue }) => (
            <Form className="asset-form asset-details-form">
              <ScrollToFieldError />
              <Card>
                <div className="form-card-content">
                  <fieldset
                    className="read-only-wrapper"
                    disabled={isNotEditable}
                  >
                    <div className="container">
                      <h4 className="flow-section-headers">
                        Documents To Upload
                      </h4>
                      <p>
                        Note: The size limit for documents and images is{' '}
                        {formattedMaxFileSize}.
                      </p>
                      {documents?.map((doc, index) => {
                        return (
                          <DocumentUploadInput
                            key={index}
                            document={doc}
                            setFieldValue={setFieldValue}
                            error={errors[doc.requiredDocumentID]}
                            isNotEditable={isNotEditable}
                          />
                        )
                      })}
                    </div>
                  </fieldset>
                </div>
              </Card>
              {!isNotEditable && <FlowActions />}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

DocumentUpload.propTypes = propTypes

DocumentUpload.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateAsset: apiActions.updateAsset,
  updateDocument: apiActions.updateDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DocumentUpload
)
