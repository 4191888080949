import { canadianProvinceCodes } from 'config'
import { startCase } from 'lodash'

// takes a value given from BE, checks if it's a province full name and returns the full name
// else it checks if it's a province code and returns the full name if it is
// else it returns "" so that initial values will prepopulate to empty selection, raising validation

function convertUSStateCodeToFullName(value) {
  const arrayOfProvinceNames = Object.values(
    canadianProvinceCodes
  ).map((name) => name.toLowerCase())

  if (arrayOfProvinceNames.includes(value.toLowerCase())) {
    return startCase(value)
  }

  const arrayOfProvinceCodes = Object.keys(canadianProvinceCodes).map((code) =>
    code.toLowerCase()
  )

  if (arrayOfProvinceCodes.includes(value.toLowerCase())) {
    return canadianProvinceCodes[value.toUpperCase()]
  } else {
    // if it's not a province code and not a valid state name, then it's bad data and should be cleared so dropdown will appear blank
    return ''
  }
}

export default convertUSStateCodeToFullName
