import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { assets, assetOptions, StepNames } from 'config'
import { isEmpty } from 'lodash'

const propTypes = {
  storedAsset: Types.asset,
}

const defaultProps = {}

const SelectionDetails = ({ type, selectedValue, options }) => {
  return (
    <div id="asset-selection-details">
      <div>
        <label htmlFor={type}>{type}</label>
        <input type="text" name={type} disabled value={selectedValue} />
      </div>
      <div>
        <p>Unselected {type} Options:</p>
        <div className="line"></div>
        <ul>
          {options
            .filter((option) => option.value !== selectedValue)
            .map((option) => (
              <li key={option.key}>{option.value}</li>
            ))}
        </ul>
      </div>
    </div>
  )
}

// determine if details for the current step should be rendered
function shouldRender({ curStep, params, payload, prevStep = null }) {
  // if the current step is empty, there are no options to display
  if (isEmpty(params[curStep])) return false
  // if the current step does not have a condition, always display
  if (!params[curStep].condition) return true
  // if the current step has a condition, evaluate if the condition is met
  return params[curStep].condition.value === payload[params[prevStep].name]
}

function AssetSummary({ storedAsset }) {
  const { investmentType } = storedAsset
  const assetParams = assets[investmentType]

  return (
    <div className="flow-card-container">
      <div className="card">
        <div className="form-card-content">
          <h4 className="title">Asset Details</h4>
          <div className="row">
            <div className="one-half column">
              <SelectionDetails
                type="Asset Type"
                selectedValue={investmentType}
                options={assetOptions}
              />
            </div>
            {shouldRender({
              curStep: StepNames.SECOND_STEP,
              params: assetParams,
              payload: storedAsset,
            }) && (
              <div className="one-half column">
                <SelectionDetails
                  type={assetParams[StepNames.SECOND_STEP].label}
                  selectedValue={
                    storedAsset[assetParams[StepNames.SECOND_STEP].name]
                  }
                  options={assetParams[StepNames.SECOND_STEP].options}
                />
              </div>
            )}
          </div>
          {shouldRender({
            curStep: StepNames.THIRD_STEP,
            prevStep: StepNames.SECOND_STEP,
            params: assetParams,
            payload: storedAsset,
          }) && (
            <div className="row">
              <div className="six columns">
                <SelectionDetails
                  type={assetParams[StepNames.THIRD_STEP].label}
                  selectedValue={
                    storedAsset[assetParams[StepNames.THIRD_STEP].name]
                  }
                  options={assetParams[StepNames.THIRD_STEP].options}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

AssetSummary.propTypes = exact(propTypes)
AssetSummary.defaultProps = defaultProps

export default React.memo(AssetSummary)
