import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import {
  SearchableSelect,
  ACPInputField,
  DocumentUploadInput,
} from 'components'
import { Spinner, SubmitButton } from 'lp-components'
import { SPONSOR_FORM_TYPES, SPONSOR_FORM_TYPE_OPTIONS } from 'config'
import { useFormikContext } from 'formik'

const propTypes = {
  assets: PropTypes.arrayOf(Types.asset).isRequired,
  fetchAssets: PropTypes.func.isRequired,
  normalizedClients: PropTypes.arrayOf(PropTypes.object),
  fetchClients: PropTypes.func.isRequired,
}

const defaultProps = {}

function SponsorFormFields({
  assets,
  fetchAssets,
  normalizedClients,
  fetchClients,
}) {
  const {
    values,
    setFieldValue,
    isSubmitting,
    errors,
    touched,
  } = useFormikContext()

  useEffect(() => {
    fetchAssets()
    fetchClients()
  }, [])

  if (!assets) return <Spinner />

  const mappedAssets = assets.map(({ name }) => {
    return { key: name, value: name }
  })

  return (
    <div className="sponsor-form-fields">
      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name="sponsorFormType"
            label="Is the sponsor document Client or Asset Specific?"
            radioOptions={SPONSOR_FORM_TYPE_OPTIONS}
            onChange={(e) => {
              setFieldValue('isExistingClient', '')
              setFieldValue('sponsorFormType', e.target.value)
            }}
          />
        </div>
        <div className="one-half column">
          {values.sponsorFormType === SPONSOR_FORM_TYPES.CLIENT_SPECIFIC && (
            <ACPInputField
              name="isExistingClient"
              label="Is the sponsor document for an existing or new client?"
              radioOptions={[
                { key: 'Existing Client', value: true },
                { key: 'New Client', value: false },
              ]}
            />
          )}
        </div>
      </div>

      {values.sponsorFormType === SPONSOR_FORM_TYPES.CLIENT_SPECIFIC && (
        <>
          {values.isExistingClient && (
            <div className="row">
              <div className="one-half column">
                {normalizedClients && (
                  <SearchableSelect
                    classNamePrefix="filter"
                    isClearable
                    closeMenuOnSelect
                    label="Search By Client Name *"
                    name="accountNumber"
                    options={normalizedClients}
                    placeholder="Enter Client Name"
                  />
                )}
              </div>
            </div>
          )}
          {!values.isExistingClient && (
            <div className="row">
              <div className="one-half column">
                <ACPInputField
                  name="clientFirstName"
                  label="First Name"
                  placeholder="Enter First Name"
                />
              </div>
              <div className="one-half column">
                <ACPInputField
                  name="clientLastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                />
              </div>
            </div>
          )}
        </>
      )}
      {values.sponsorFormType === SPONSOR_FORM_TYPES.ASSET_SPECIFIC && (
        <div className="row">
          <div className="one-half column">
            <SearchableSelect
              classNamePrefix="filter"
              isClearable
              closeMenuOnSelect
              label="Search By Asset Name"
              name="assetName"
              options={mappedAssets}
              placeholder="Enter Asset Name"
            />
          </div>
        </div>
      )}
      <DocumentUploadInput
        keyName="documents.uploadNewDocument"
        setFieldValue={setFieldValue}
        label="Browse Files"
        multiple={false}
        document={{
          isDocumentRequired: true,
        }}
        error={
          touched?.documents && errors?.documents
            ? errors?.documents['uploadNewDocument']
            : errors['myUploadsError']
        }
      />
      <div className="document-upload-flow-button-container">
        <SubmitButton submitting={isSubmitting}>
          Submit Document(s)
        </SubmitButton>
      </div>
    </div>
  )
}

SponsorFormFields.propTypes = propTypes

SponsorFormFields.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assets: globalSelectors.assets(state),
    normalizedClients: selectors.normalizedClients(state),
  }
}

const mapDispatchToProps = {
  fetchAssets: apiActions.fetchAssets,
  fetchClients: apiActions.fetchClients,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SponsorFormFields
)
