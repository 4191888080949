import { isNumber } from 'lodash'

function convertStringToFixedFloat(value, numOfDigits, defaultVal = 0) {
  let formattedValue = value
  if (!value) return parseFloat(defaultVal).toFixed(numOfDigits)
  if (isNumber(value)) formattedValue = value.toString()
  const parsedValue = parseFloat(
    formattedValue.replace(/[^0-9.-]+/g, '')
  ).toFixed(numOfDigits)
  if (parsedValue === 'NaN') return parseFloat(defaultVal).toFixed(numOfDigits)
  return parsedValue
}

export default convertStringToFixedFloat
