import React, { useCallback, useMemo, useRef, useState } from 'react'
import 'ag-grid-community'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card, ACPTable } from 'components'
import { Spinner } from 'lp-components'
import { isEmpty } from 'lodash'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { currencyComparator, withPermission } from 'utils'

const propTypes = {
  capitalCommitments: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {}

const loadingOverlay = `<div class="search-results-helper">
<div class="overlay-text-title med-18">No results found</div>
<span class="overlay-text reg-14">It looks like you don't have any commitments yet.</span>
<span class="overlay-text reg-14">To get started, please initiate a capital commitment.</span>
</div>`

function CapitalCommitmentsTable({ capitalCommitments }) {
  const [selectedAssetID, setSelectedAssetID] = useState()
  const history = useHistory()

  const [rowFilterSize, setRowFilterSize] = useState(null)
  const gridRef = useRef()
  const gridStyle = useMemo(() => ({ height: '518px', width: '100%' }), [])
  const columnDefs = [
    {
      field: 'assetName',
      minWidth: 350,
      checkboxSelection: true,
      sortable: true,
      lockPosition: true,
      filter: 'agTextColumnFilter',
      headerTooltip:
        'Asset Name: The legal name as stated in Offering Document.',
      cellStyle: {
        color: '#196664',
        fontWeight: 'bold',
      },
      headerClass: 'ag-left-aligned-header',
    },
    {
      field: 'totalCommittedAmount',
      sortable: true,
      type: 'rightAligned',
      cellClass: 'table-right-align',
      comparator: currencyComparator,
      headerTooltip:
        'Total Committed Amount: The total capital commitment by the investor(s) in the asset.',
    },
    {
      field: 'totalCommittedBalance',
      sortable: true,
      type: 'rightAligned',
      cellClass: 'table-right-align',
      headerTooltip:
        'Total Committed Balance: The total capital amount already called for the asset.',
      comparator: currencyComparator,
    },
    {
      field: 'totalProcessAmount',
      sortable: true,
      type: 'rightAligned',
      cellClass: 'table-right-align',
      comparator: currencyComparator,
      headerTooltip:
        'Total Process Amount: The total remaining balance of the commitment amount by the investor.',
    },
  ]

  const onFilterTextBoxChanged = useCallback(() => {
    const model = {
      assetName: {
        filterType: 'text',
        type: 'contains',
        filter: document.getElementById('filter-text-box').value,
      },
    }
    gridRef.current.api.setFilterModel(model)
    getDisplayedRowCount()
  }, [])

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows()
    setSelectedAssetID(selectedRows[0]?.assetID ?? null)
  }, [])

  const getDisplayedRowCount = useCallback(() => {
    const count = gridRef.current.api.getDisplayedRowCount()
    setRowFilterSize(count)
  }, [])

  let disableCapitalCommitmentButton =
    isEmpty(selectedAssetID) || !withPermission('add-capital-call')

  const showNoResultsOverlay = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay()
  }, [])

  const onBtHide = useCallback(() => {
    gridRef.current.api.hideOverlay()
  }, [])

  const capitalCommitmentsTableActions = (
    <div className="capital-commitments-table">
      <div className="table-actions">
        <div className="table-actions-left">
          <input
            type="text"
            id="filter-text-box"
            placeholder="Search Asset Name"
            onInput={onFilterTextBoxChanged}
          />
          {rowFilterSize === 0 && showNoResultsOverlay()}
          {rowFilterSize > 0 && onBtHide()}
        </div>
        <div className="table-actions-last">
          <button
            disabled={disableCapitalCommitmentButton}
            className={classnames('button-primary', {
              'is-disabled': disableCapitalCommitmentButton,
            })}
            onClick={() => {
              history.push(
                `/capital-call/${selectedAssetID}/investor-selection`
              )
            }}
          >
            Initiate Capital Call
          </button>
        </div>
      </div>
    </div>
  )

  if (!capitalCommitments) {
    return (
      <Card label="Capital Commitments">
        <Spinner />
      </Card>
    )
  }

  return (
    <>
      <Card
        label="Capital Commitments"
        actions={capitalCommitmentsTableActions}
        className="capital-commitments-card"
      >
        {capitalCommitments && (
          <ACPTable
            columnDefs={columnDefs}
            additionalDefaultColDef={{
              sortable: true,
              headerClass: 'ag-right-aligned-header',
            }}
            onSelectionChanged={onSelectionChanged}
            rowData={capitalCommitments}
            isAnyFilterPresent={true}
            paginationPageSize="10"
            overrideGridStyle={gridStyle}
            overrideGridRef={gridRef}
            overlayLoadingTemplate={loadingOverlay}
          />
        )}
      </Card>
    </>
  )
}

CapitalCommitmentsTable.propTypes = exact(propTypes)
CapitalCommitmentsTable.defaultProps = defaultProps

export default CapitalCommitmentsTable
