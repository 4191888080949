import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { withFormikAdapter } from 'utils'
import {
  Input as BaseInput,
  MaskedInput as BaseMaskedInput,
  Select as BaseSelect,
  RadioGroup as BaseRadioGroupInput,
  Textarea as BaseTextarea,
} from 'lp-components'
import LabelWithTooltip from './LabelWithTooltip'

const Input = withFormikAdapter()(BaseInput)
const MaskedInput = withFormikAdapter()(BaseMaskedInput)
const Select = withFormikAdapter()(BaseSelect)
const RadioGroupInput = withFormikAdapter()(BaseRadioGroupInput)
const Textarea = withFormikAdapter()(BaseTextarea)

const propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selectOptions: PropTypes.array,
  radioOptions: PropTypes.array,
  maskOptions: PropTypes.object,
  tooltipContent: PropTypes.string,
  onBlur: PropTypes.func,
  enablePlaceholderOption: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  noLabelModifier: PropTypes.bool,
  textarea: PropTypes.bool,
}

const defaultProps = {}

function ACPInputField({
  name,
  label = null,
  value = null,
  type = 'text',
  placeholder = null,
  disabled = false,
  onChange = null,
  maskOptions = null, //presence of maskOptions indicates a masked field.
  selectOptions = null, //presence of select options indicates a select field.
  radioOptions = null, //presence of radio options indicates a radio field.
  tooltipContent = null, //presnece of tooltipContent indicates we should use a tooltip on the input.
  onBlur = null,
  enablePlaceholderOption = false,
  required = true,
  readOnly = false,
  textarea = false,
  noLabelModifier,
}) {
  const formattedLabel =
    readOnly || disabled || noLabelModifier ? (
      label
    ) : required ? (
      label + ' *'
    ) : (
      <span>
        {label}
        <span className="optionalTag"> (optional)</span>
      </span>
    )

  const options = {
    label: tooltipContent ? false : formattedLabel,
    name: name,
    type: type,
    placeholder: disabled ? null : placeholder,
    disabled: disabled,
    onChange: onChange,
    onBlur: onBlur,
    readOnly: readOnly,
  }

  if (value) {
    options['value'] = value
  }

  if (maskOptions) {
    if (tooltipContent) {
      return (
        <LabelWithTooltip
          label={formattedLabel}
          tooltipContent={tooltipContent}
        >
          <MaskedInput {...options} maskOptions={maskOptions} />
        </LabelWithTooltip>
      )
    } else {
      return <MaskedInput {...options} maskOptions={maskOptions} />
    }
  } else if (selectOptions && !readOnly) {
    if (tooltipContent) {
      return (
        <LabelWithTooltip
          label={formattedLabel}
          tooltipContent={tooltipContent}
        >
          <Select
            {...options}
            options={selectOptions}
            enablePlaceholderOption={enablePlaceholderOption}
          />
        </LabelWithTooltip>
      )
    } else {
      return (
        <Select
          {...options}
          options={selectOptions}
          enablePlaceholderOption={enablePlaceholderOption}
        />
      )
    }
  } else if (radioOptions) {
    if (tooltipContent) {
      return (
        <LabelWithTooltip
          label={formattedLabel}
          tooltipContent={tooltipContent}
        >
          <RadioGroupInput
            label={false}
            name={name}
            options={radioOptions}
            onChange={onChange}
            disabled={disabled}
          />
        </LabelWithTooltip>
      )
    } else {
      return (
        <RadioGroupInput
          label={label}
          name={name}
          options={radioOptions}
          onChange={onChange}
          disabled={disabled}
        />
      )
    }
  } else if (tooltipContent) {
    if (textarea) {
      <LabelWithTooltip label={formattedLabel} tooltipContent={tooltipContent}>
        <Textarea {...options} />
      </LabelWithTooltip>
    }
    return (
      <LabelWithTooltip label={formattedLabel} tooltipContent={tooltipContent}>
        <Input {...options} />
      </LabelWithTooltip>
    )
  } else if (textarea) {
    return <Textarea {...options} />
  }
  return <Input {...options} type="text" />
}

ACPInputField.propTypes = exact(propTypes)
ACPInputField.defaultProps = defaultProps

export default ACPInputField
