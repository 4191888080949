import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Button, SubmitButton } from 'lp-components'
import { noop } from 'lodash'
import { useHistory } from 'react-router-dom'
import { ExitWithoutSavingModal, BackButton } from 'components'
import ConfirmEffectiveDateModal from './ConfirmEffectiveDateModal'
import {
  LIQUIDATION_CONFIRMATION_STAGES_PART_ONE,
  LIQUIDATION_CONFIRMATION_STAGES_PART_TWO,
} from 'flow-stages'
import { LIQUIDATION_CONFIRMATION_STEPS } from 'config'
import { setNestedObjectValues } from 'formik'
import RightArrow from 'images/right-arrow-icon.svg'

const propTypes = {
  prevStep: PropTypes.string,
  onContinue: PropTypes.func,
  submitting: PropTypes.bool,
  submitContent: PropTypes.node,
  continueCondition: PropTypes.bool,
  validateForm: PropTypes.func,
  setTouched: PropTypes.func,
}

const defaultProps = {
  prevStep: null,
  onContinue: noop,
  submitting: false,
  isLastStep: false,
  submitContent: 'Continue',
  continueCondition: true,
}

// To do: incorporate useFormikContext (similar to add-asset FlowActions)
function FlowActions({
  prevStep,
  onContinue,
  submitting,
  submitContent,
  continueCondition,
  validateForm,
  setTouched,
}) {
  const history = useHistory()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [modalShown, setModalShown] = useState(false)

  const stages = [
    ...LIQUIDATION_CONFIRMATION_STAGES_PART_ONE.map((stage) => ({
      ...stage,
      part: LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_FIRST_STEP,
    })),
    ...LIQUIDATION_CONFIRMATION_STAGES_PART_TWO.map((stage) => ({
      ...stage,
      part: LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_SECOND_STEP,
    })),
  ]

  return (
    <div className="flow-button-container no-print">
      <div className="flow-button-inner">
        <Button
          className="button-secondary  quit-button"
          onClick={() => setModalShown(true)}
        >
          Quit & Exit
        </Button>
        {prevStep && <BackButton stages={stages} />}
        {continueCondition ? (
          <SubmitButton onClick={onContinue} submitting={submitting}>
            {submitContent}{' '}
            {!submitting && (
              <img
                src={RightArrow}
                className="right-arrow-icon"
                alt="Right Arrow"
              />
            )}
          </SubmitButton>
        ) : (
          <Button
            onClick={() => {
              validateForm().then((errors) =>
                Object.keys(errors).length === 0
                  ? setShowConfirmModal(true)
                  : setTouched(setNestedObjectValues(errors, true))
              )
            }}
          >
            {submitContent}{' '}
            {!submitting && (
              <img
                src={RightArrow}
                className="right-arrow-icon"
                alt="Right Arrow"
              />
            )}
          </Button>
        )}
        {showConfirmModal && (
          <ConfirmEffectiveDateModal
            onClose={() => setShowConfirmModal(false)}
            onSubmit={onContinue}
            submitting={submitting}
          />
        )}
        {modalShown && (
          <ExitWithoutSavingModal
            onClose={() => setModalShown(false)}
            onExit={() => history.push('/home')}
            workFlowName="liquidation"
          />
        )}
      </div>
    </div>
  )
}

FlowActions.propTypes = exact(propTypes)
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
