import React from 'react'
import PropTypes from 'prop-types'
import { Card, ScrollToFieldError, ACPInputField } from 'components'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import { countries, usStates, canadianProvinces } from 'config'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fundingInstructionsValidationSchema: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isNotEditable: PropTypes.bool.isRequired,
  FlowActions: PropTypes.object.isRequired,
}

const defaultProps = {}

function AssetFlowFundingInstructionsContent({
  handleSubmit,
  fundingInstructionsValidationSchema,
  initialValues,
  isNotEditable,
  FlowActions,
}) {
  const handleChangeFundingType = ({
    setErrors,
    setTouched,
    setFieldValue,
  }) => {
    setErrors({ errors: {} })
    setTouched({ touched: {} })
    setFieldValue('ACHAccountName', '', false)
    setFieldValue('accountNumber', '', false)
    setFieldValue('bankTransferAccountName', '', false)
    setFieldValue('bankTransferTransitABANumber', '', false)
    setFieldValue('bankTransferBankAccountNumber', '', false)
    setFieldValue('bankTransferBankName', '', false)
    setFieldValue('bankTransferBankPhone', '', false)
    setFieldValue('bankTransferForFurtherCreditAccountNumber', '', false)
    setFieldValue('bankTransferSwiftCode', '', false)
    setFieldValue('bankTransferBankAccountType', '', false)
    setFieldValue('bankTransferAdditionalWireInstructions', '', false)
    setFieldValue('checkPayee', '', false)
    setFieldValue('checkAttention', '', false)
    setFieldValue('checkCountry', '', false)
    setFieldValue('checkAddress', '', false)
    setFieldValue('checkAddressLine2', '', false)
    setFieldValue('checkCity', '', false)
    setFieldValue('checkState', '', false)
    setFieldValue('checkZipCode', '', false)
    setFieldValue('ACHTransitABANumber', '', false)
    setFieldValue('ACHBankName', '', false)
    setFieldValue('ACHBankPhone', '', false)
    setFieldValue('ACHForFurtherCreditAccountNumber', '', false)
    setFieldValue('ACHBankAccountNumber', '', false)
  }

  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={fundingInstructionsValidationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            setErrors,
            setTouched,
          }) => {
            const isUsOrCanada =
              values.checkCountry === 'US' || values.checkCountry === 'CA'
            return (
              <Form className="asset-form asset-details-form">
                <ScrollToFieldError />
                <Card>
                  <div className="form-card-content">
                    <fieldset
                      className="read-only-wrapper"
                      disabled={isNotEditable}
                    >
                      <div className="container funding-instructions">
                        <h4 className="flow-section-headers">
                          Funding Instructions
                        </h4>
                        <p className="funding-subheader">
                          Inspira uses these funding instructions to send funds
                          to the Investment Issuer/Sponsor on behalf of the
                          investor. Any changes you make will apply to all
                          investors of the asset.
                        </p>
                        <div className="row">
                          <div className="twelve columns radio-group">
                            <ACPInputField
                              name="fundingType"
                              label="Funding Method"
                              radioOptions={[
                                { key: 'Wire Transfer', value: 'bankTransfer' },
                                { key: 'Check', value: 'check' },
                                { key: 'ACH', value: 'ach' },
                              ]}
                              onChange={(e) => {
                                setFieldValue('fundingType', e.target.value)
                                handleChangeFundingType({
                                  setErrors,
                                  setTouched,
                                  setFieldValue,
                                })
                              }}
                              disabled={isNotEditable}
                            />
                          </div>
                        </div>
                        {values.fundingType === 'bankTransfer' && (
                          <>
                            <div className="row">
                              <div className="one-half column radio-group">
                                <ACPInputField
                                  name="bankTransferBankAccountType"
                                  label="Bank Account Type"
                                  radioOptions={[
                                    { key: 'Checking', value: 'checking' },
                                    { key: 'Savings', value: 'savings' },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  name="bankTransferTransitABANumber"
                                  label="Routing Number"
                                  placeholder="Enter routing number"
                                  maskOptions={{
                                    numericOnly: true,
                                    blocks: [9],
                                  }}
                                  tooltipContent="The routing number is the nine-digit number used to identify the financial institution."
                                  disabled={isNotEditable}
                                />
                              </div>
                              <div className="one-half column">
                                <ACPInputField
                                  name="bankTransferBankAccountNumber"
                                  label="Account Number"
                                  placeholder="Enter bank account number"
                                  maskOptions={{
                                    numericOnly: true,
                                  }}
                                  disabled={isNotEditable}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  name="bankTransferBankName"
                                  label="Bank Name"
                                  placeholder="Enter bank name"
                                  disabled={isNotEditable}
                                />
                              </div>
                              <div className="one-half column">
                                <ACPInputField
                                  label="Account Name"
                                  tooltipContent="This is the name that the financial institution uses to identify the account. It may
                                  include the account type or the account holder’s name."
                                  name="bankTransferAccountName"
                                  placeholder="Enter account name"
                                  disabled={isNotEditable}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  name="bankTransferBankPhone"
                                  label="Bank Phone Number"
                                  type="tel"
                                  placeholder="Enter bank phone number"
                                  maskOptions={{
                                    numericOnly: true,
                                    blocks: [0, 3, 0, 3, 4],
                                    delimiters: ['(', ')', ' ', '-'],
                                  }}
                                  disabled={isNotEditable}
                                />
                              </div>
                              <div className="row">
                                {(!isNotEditable ||
                                  values?.bankTransferForFurtherCreditAccountNumber) && (
                                  <div className="one-half column">
                                    <ACPInputField
                                      label="FFC Account Number"
                                      tooltipContent="For Further Credit account number is only needed if the funds must be
                                    credited to a different account."
                                      name="bankTransferForFurtherCreditAccountNumber"
                                      placeholder="Enter account number"
                                      required={false}
                                      disabled={isNotEditable}
                                    />
                                  </div>
                                )}
                                {(!isNotEditable ||
                                  values?.bankTransferSwiftCode) && (
                                  <div className="one-half column">
                                    <ACPInputField
                                      label="SWIFT Code"
                                      tooltipContent="This alphanumeric code is used to identify bank and financial institutions worldwide. It’s
                                    also known as a Bank Identifier Code or BIC. You only need to provide a SWIFT code if funds must be sent
                                    outside of the United States."
                                      name="bankTransferSwiftCode"
                                      placeholder="Enter SWIFT code"
                                      required={false}
                                      disabled={isNotEditable}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            {(!isNotEditable ||
                              values?.bankTransferAdditionalWireInstructions) && (
                              <div className="row">
                                <div className="one-half column">
                                  <ACPInputField
                                    label="Additonal Wire Instructions"
                                    tooltipContent="This is for any additional wire instructions that Inspira must follow to
                                    help ensure the funds get to the correct place."
                                    name="bankTransferAdditionalWireInstructions"
                                    placeholder="Enter additional wire instructions"
                                    required={false}
                                    disabled={isNotEditable}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {values.fundingType === 'check' && (
                          <>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  label="Payee Name"
                                  tooltipContent="This is the name of the financial institution that should be noted as the payee on the
                                  check."
                                  name="checkPayee"
                                  placeholder="Enter payee name"
                                  disabled={isNotEditable}
                                />
                              </div>
                              {(!isNotEditable || values?.checkAttention) && (
                                <div className="one-half column">
                                  <ACPInputField
                                    label="Attention (For Mailing)"
                                    tooltipContent="This is the name of the department or individual that should receive the check
                                  by mail."
                                    name="checkAttention"
                                    placeholder="Enter check attention"
                                    required={false}
                                    disabled={isNotEditable}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  name="checkCountry"
                                  label="Country"
                                  selectOptions={countries}
                                  enablePlaceholderOption={true}
                                  placeholder="Select country"
                                  onChange={() => {
                                    setFieldValue('checkState', '', false)
                                    setFieldError('checkState', '')
                                    setFieldTouched('checkState', false, false)
                                  }}
                                  disabled={isNotEditable}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <ACPInputField
                                name="checkAddress"
                                label="Street Address"
                                placeholder="Enter street address"
                              />
                            </div>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  name="checkCity"
                                  label="City"
                                  placeholder="Enter city"
                                  disabled={isNotEditable}
                                />
                              </div>
                              <div className="one-half column">
                                {isEmpty(values.checkCountry) ||
                                isUsOrCanada ? (
                                  <ACPInputField
                                    name="checkState"
                                    label="State/Province/Region"
                                    selectOptions={
                                      values.checkCountry === 'US'
                                        ? usStates
                                        : canadianProvinces
                                    }
                                    enablePlaceholderOption={true}
                                    placeholder="Select State/Province/Region"
                                    readOnly={!isUsOrCanada}
                                    disabled={isNotEditable}
                                  />
                                ) : (
                                  <ACPInputField
                                    name="checkState"
                                    label="State/Province/Region"
                                    enablePlaceholderOption={true}
                                    placeholder="Enter State/Province/Region"
                                    readOnly={
                                      isEmpty(values.checkCountry) ||
                                      isUsOrCanada
                                    }
                                    disabled={isNotEditable}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  name="checkZipCode"
                                  label="Zip Code"
                                  placeholder="Enter zip code"
                                  disabled={isNotEditable}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {values.fundingType === 'ach' && (
                          <>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  label="Routing Number"
                                  tooltipContent="The routing number is the nine-digit number used to identify the financial institution.
                                  It’s also known as a Transit/ABA number."
                                  name="ACHTransitABANumber"
                                  placeholder="Enter routing number"
                                  maskOptions={{
                                    numericOnly: true,
                                    blocks: [9],
                                  }}
                                  disabled={isNotEditable}
                                />
                              </div>
                              <div className="one-half column">
                                <ACPInputField
                                  name="ACHBankAccountNumber"
                                  label="Account Number"
                                  placeholder="Enter bank account number"
                                  disabled={isNotEditable}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  name="ACHBankName"
                                  label="Bank Name"
                                  placeholder="Enter bank name"
                                  disabled={isNotEditable}
                                />
                              </div>
                              <div className="one-half column">
                                <ACPInputField
                                  label="Account Name"
                                  tooltipContent="This is the name that the financial institution uses to identify the account. It may
                                  include the account type or the account holder’s name."
                                  name="ACHAccountName"
                                  placeholder="Enter account name"
                                  disabled={isNotEditable}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="one-half column">
                                <ACPInputField
                                  name="ACHBankPhone"
                                  label="Bank Phone Number"
                                  type="tel"
                                  placeholder="Enter bank phone number"
                                  maskOptions={{
                                    numericOnly: true,
                                    blocks: [0, 3, 0, 3, 4],
                                    delimiters: ['(', ')', ' ', '-'],
                                  }}
                                  disabled={isNotEditable}
                                />
                              </div>
                              {(!isNotEditable ||
                                values?.ACHForFurtherCreditAccountNumber) && (
                                <div className="row">
                                  <div className="one-half column">
                                    <ACPInputField
                                      label="FFC Account Number"
                                      tooltipContent="For Further Credit account number is only needed if the funds must be
                                    credited to a different account."
                                      name="ACHForFurtherCreditAccountNumber"
                                      placeholder="Enter account number"
                                      required={false}
                                      disabled={isNotEditable}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </fieldset>
                  </div>
                </Card>
                {!isNotEditable && <FlowActions />}
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

AssetFlowFundingInstructionsContent.propTypes = propTypes
AssetFlowFundingInstructionsContent.defaultProps = defaultProps

export default AssetFlowFundingInstructionsContent
