import React from 'react'
import PropTypes from 'prop-types'
import { withFormikAdapter } from 'utils'
import exact from 'prop-types-exact'
import { CheckboxGroup as BaseCheckboxGroup } from 'lp-components'

const CheckboxGroup = withFormikAdapter()(BaseCheckboxGroup)

const propTypes = {
  options: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checkboxInputProps: PropTypes.object,
  onChange: PropTypes.func,
  meta: PropTypes.object,
}

const defaultProps = {
  label: false,
  type: 'single',
  disabled: false,
  meta: {},
}

function ACPCheckboxGroup({
  options,
  label,
  input,
  name,
  disabled,
  checkboxInputProps,
  onChange,
  meta,
}) {
  return (
    <CheckboxGroup
      name={name}
      input={input}
      meta={meta}
      label={label}
      options={options}
      disabled={disabled}
      checkboxInputProps={checkboxInputProps}
      onChange={onChange}
    />
  )
}

ACPCheckboxGroup.propTypes = exact(propTypes)
ACPCheckboxGroup.defaultProps = defaultProps

export default ACPCheckboxGroup
