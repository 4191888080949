import React from 'react'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  altText: PropTypes.string,
}

function ACPIconButton({ icon, onClick, className, altText }) {
  return (
    <>
      <IconButton onClick={onClick} className={className}>
        <Badge>
          <img id="badge-image" src={icon} alt={altText} />
        </Badge>
      </IconButton>
    </>
  )
}

ACPIconButton.propTypes = exact(propTypes)

export default ACPIconButton
