import { hasValue } from 'utils'

var operations = {
  add: (a, b) => a + b,
  subtract: (a, b) => a - b,
  multiply: (a, b) => a * b,
  divide: (a, b) => a / b,
}

function preformInputOperation(input1, input2, operator) {
  if (!hasValue(input1) || !hasValue(input2)) return ''
  return operations[operator](input1, input2)
}

export default preformInputOperation
