// App-wide authentication logic goes here.

const TOKEN_NAME = 'bearerToken'

function setSessionToken(token) {
  if (token) localStorage.setItem(TOKEN_NAME, token)
}

function getSessionToken() {
  return localStorage.getItem(TOKEN_NAME)
}

function clearSessionToken() {
  return localStorage.removeItem(TOKEN_NAME)
}

function hasActiveSession() {
  return getSessionToken() !== null
}

export { setSessionToken, getSessionToken, clearSessionToken, hasActiveSession }
