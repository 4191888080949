import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
}

const defaultProps = {
  label: null,
  className: '',
}

function Card({ children, label, actions, className, onClick }) {
  return (
    <div className={classnames('card', className)} onClick={onClick}>
      {(label || actions) && (
        <div className="card-header">
          <h4>{label}</h4>
          {actions}
        </div>
      )}
      {children}
    </div>
  )
}

Card.propTypes = exact(propTypes)
Card.defaultProps = defaultProps

export default React.memo(Card)
