import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'
import * as Views from './views'
import Layout from './Layout'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  return (
    <>
      <Layout>
        <Switch>
          <Route exact path={path} component={Views.Resources} />
          <Redirect path="*" to={path} />
        </Switch>
      </Layout>
    </>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
