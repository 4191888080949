import { countries } from 'config'
import { isEmpty } from 'lodash'

function normalizeCountryValue(value) {
  if (isEmpty(value)) {
    return ''
  }

  if (value === 'United States Of America' || value === 'USA') {
    return 'US'
  }

  const codedCountryValuesArray = countries.map((country) =>
    country.value.toLowerCase()
  )

  // if the value is a valid country code, return it
  if (codedCountryValuesArray.includes(value.toLowerCase())) {
    return value.toUpperCase()
  } else {
    // if it's not a valid code, check if it's a valid full name and convert if so
    const fullNameCountryValuesArray = countries.map((country) =>
      country.key.toLowerCase()
    )

    if (fullNameCountryValuesArray.includes(value.toLowerCase())) {
      return countries.find(
        (country) => country.key.toLowerCase() === value.toLowerCase()
      ).value
    }
  }

  // if the value is neither a valid country code or name, return ''

  return ''
}

export default normalizeCountryValue
