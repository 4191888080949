import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { uniq } from 'lodash'
import { RoleDefinitionModal, ACPInputField } from 'components'
import { CheckboxGroup as BaseCheckboxGroup } from 'lp-components'
import { ROLE_DEFINITION } from 'config'
import { scrollToTop, areAllAssetsSelected, withFormikAdapter } from 'utils'

const CheckboxGroup = withFormikAdapter()(BaseCheckboxGroup)

const propTypes = {
  assets: PropTypes.arrayOf(Types.asset),
  sortedAssignmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired
  ).isRequired,
  setCurrentRole: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}
const defaultProps = {}

function RoleAssetAssignmentFields({
  assets,
  sortedAssignmentOptions,
  roleOptions,
  setCurrentRole,
  setFieldValue,
}) {
  const [showRoleDefinitionModal, setShowRoleDefinitionModal] = useState(false)

  useEffect(() => {
    scrollToTop('.scrolling-container')
  }, [sortedAssignmentOptions])

  return (
    <div className="profile-fields">
      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name="role"
            label="Role"
            selectOptions={roleOptions.map((option) => option.key)}
            onChange={(e) => {
              const selectedRole = e.target.value
              setCurrentRole(selectedRole)

              const roleAssets = roleOptions.find((option) => {
                return option.key === selectedRole
              }).value

              setFieldValue(
                'assetsAssigned',
                areAllAssetsSelected(assets, roleAssets)
                  ? ['All', ...roleAssets]
                  : roleAssets,
                true
              )
            }}
          />

          <span>
            To learn more about roles,{' '}
            <button
              className="modal-button-link"
              type="button"
              onClick={() => setShowRoleDefinitionModal(true)}
            >
              click here.
            </button>
          </span>
        </div>
        <span className="asset-assignments">
          <label hmtlFor="assetsAssigned">Asset Assignment(s)</label>
        </span>
        <div className="one-half column scrolling-container disabled">
          <CheckboxGroup
            name="assetsAssigned"
            label={false}
            options={uniq(sortedAssignmentOptions)}
            disabled
          />
        </div>
      </div>
      {showRoleDefinitionModal && (
        <RoleDefinitionModal
          onClose={() => setShowRoleDefinitionModal(false)}
          title="Definition of Roles"
          roles={ROLE_DEFINITION}
        />
      )}
    </div>
  )
}

RoleAssetAssignmentFields.propTypes = propTypes
RoleAssetAssignmentFields.defaultProps = defaultProps

export default React.memo(RoleAssetAssignmentFields)
