import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'
const propTypes = {
  onClose: PropTypes.func.isRequired,
}
const defaultProps = {}
function LimitReachedModal({ onClose }) {
  return (
    <Modal onClose={onClose} className="limit-modal">
      <div className="modal-header">
        <h5>Transaction Limit Reached</h5>
      </div>
      <p>
        You have exceeded the maximum number of confirmations allowed in a
        single transaction. Please return and limit your selections to 200 or
        less.
      </p>
      <div className="button-wrapper">
        <button className="button-primary" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  )
}
LimitReachedModal.propTypes = exact(propTypes)
LimitReachedModal.defaultProps = defaultProps
export default React.memo(LimitReachedModal)
