import React from 'react'
import { Redirect } from 'react-router-dom'
import { selectors as globalSelectors } from 'global-reducer'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { USER_CLASSIFICATION } from 'config'

const Group1AuthorizedLayout = ({ currentUser, children }) => {
  if (currentUser.userClassification !== USER_CLASSIFICATION.GROUP_1) {
    return <Redirect to="/home" />
  }
  return children
}

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Group1AuthorizedLayout
)
