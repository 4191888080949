import React from 'react'
import PropTypes from 'prop-types'
import { hasInputError } from 'lp-components'
import Select, { components } from 'react-select'
import { withFormikAdapter } from 'utils'
import { has, flatten, isArray } from 'lodash'
import magnifyingGlassIcon from 'images/utility-icons/magnifying-glass.svg'
import { ErrorMessage } from 'formik'
import { LabelWithTooltip } from 'components'

const propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  maxOptions: PropTypes.number, // maximum # of options to display in the scrollable container
  isMulti: PropTypes.bool,
  label: PropTypes.string,
}

const defaultProps = {
  id: null,
  isMulti: false,
  maxOptions: 6,
}

const DEFAULT_OPTION_HEIGHT = 32
const DEFAULT_MENU_PADDING_TOP = 4
const DEFAULT_MENU_PADDING_BOTTOM = 4

// Determine the maximum height of the expanded, scrollable dropdown list
// Note: this does not account for extra height added for grouped options
const calculateMaxHeight = (maxOptions) => {
  return (
    DEFAULT_MENU_PADDING_TOP +
    DEFAULT_MENU_PADDING_BOTTOM +
    DEFAULT_OPTION_HEIGHT * maxOptions +
    // add extra height to provide a visual cue on additional options below:
    DEFAULT_OPTION_HEIGHT / 4
  )
}

// react-select expects options have "label" and "value"
const serializeOptions = (optionArray) => {
  return optionArray.map((option) => {
    if (typeof option === 'object') {
      // grouped option:
      if (has(option, 'options')) {
        return {
          label: option.key ?? option.label,
          options: serializeOptions(option.options),
        }
      }
      if (has(option, 'key')) {
        return {
          label: option.key,
          value: option.value,
        }
      }
      return option
    }
    return {
      label: option,
      value: option,
    }
  })
}

const getValue = (options, selection) => {
  const allOptions = flatten(
    options.map((option) => {
      // if it's a grouped option, return just its options
      if (has(option, 'options')) return option.options
      return option
    })
  )

  // if the selection is an array, it is a multi select input
  if (isArray(selection)) {
    return allOptions.filter((option) => selection.includes(option.value))
  }

  // if selection can't be found, ie if cleared for blank string, must return blank string not undefined
  return allOptions.find((option) => option.value === selection) || ''
}

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <img
        style={{
          paddingTop: 6,
          width: '20px',
          position: 'absolute',
          left: 6,
        }}
        src={magnifyingGlassIcon}
        alt=""
      ></img>
      {children}
    </components.ValueContainer>
  )
}

function SearchableSelect(props) {
  const {
    input: { name, onBlur, onChange, value },
    id,
    meta,
    options,
    maxOptions,
    isMulti,
    label,
    ...rest
  } = props

  const selectOptions = serializeOptions(options)

  const onChangeHandler = (option) => {
    if (isMulti) {
      const multiValues = option.map((item) => item.value)
      return onChange(multiValues)
    }
    const value = option === null ? '' : option.value
    return onChange(value)
  }

  return (
    <LabelWithTooltip id={id || name} label={label}>
      <Select
        name={name}
        inputId={id || name}
        options={selectOptions}
        maxMenuHeight={calculateMaxHeight(maxOptions)}
        value={getValue(selectOptions, value)}
        onChange={onChangeHandler}
        isMulti={isMulti}
        onBlur={onBlur}
        components={{
          ValueContainer,
        }}
        aria-describedby={hasInputError(meta) ? `${name}-error` : null}
        styles={{
          control: (provided) => ({
            ...provided,
            height: DEFAULT_OPTION_HEIGHT,
            boxShadow: hasInputError(meta) && 'none',
            borderRadius: '8px',
            border: hasInputError(meta)
              ? '1px solid #E32416'
              : '1px solid #B5B5B4',
            '&:hover': {
              border: hasInputError(meta) && '1px solid #E32416',
              borderRadius: '8px',
            },
            marginBottom: '18px',
          }),
          input: (provided) => ({
            ...provided,
            margin: '0px',
            height: DEFAULT_OPTION_HEIGHT,
            fontStyle: 'normal',
            color: '#1C1C1C',
          }),
          singleValue: (provided) => ({
            ...provided,
            paddingTop: 8,
            marginTop: 2,
            height: DEFAULT_OPTION_HEIGHT,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontStyle: 'normal',
            fontWeight: 400,
            fontFamily: 'CircularXXWeb-Regular',
          }),
          menu: (provided) => ({
            ...provided,
            paddingTop: DEFAULT_MENU_PADDING_TOP,
            paddingBottom: DEFAULT_MENU_PADDING_BOTTOM,
          }),
          option: (provided) => ({
            ...provided,
            height: DEFAULT_OPTION_HEIGHT,
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingLeft: 32,
            height: DEFAULT_OPTION_HEIGHT,
          }),
          indicatorSeparator: () => ({
            display: 'none', // hiding this default pipe design element to fit our design
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: DEFAULT_OPTION_HEIGHT,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: '#DBE1FF',
            primary25: '#DBE1FF',
            primary: '#4553A9',
          },
        })}
        {...rest}
      />
      <ErrorMessage name={name} component={'div'} className="error-message" />
    </LabelWithTooltip>
  )
}

SearchableSelect.propTypes = propTypes
SearchableSelect.defaultProps = defaultProps

export default withFormikAdapter()(SearchableSelect)
