import { isEmpty } from 'lodash'

const getInspiraFBOFormat = (label) => {
  if (isEmpty(label)) {
    return ''
  }
  const fboFormattedLabel = `Inspira Financial Custodian FBO ${label}`
  return fboFormattedLabel
}

export default getInspiraFBOFormat
