import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import {
  Card,
  TitleWithTooltip,
  ScrollToFieldError,
  LabelWithTooltip,
} from 'components'
import { useLocation, useHistory } from 'react-router-dom'
import { Checkbox as BaseCheckboxInput, Spinner } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'
import { Formik, Form } from 'formik'
import { withFormikAdapter } from 'utils'
import { selectors } from '../reducer'
import * as Yup from 'yup'
import { getSessionToken } from 'auth'
import { startCase, defaults } from 'lodash'
import {
  ADD_ASSET_STAGE,
  PHONE_NUMBER_REGEX,
  REAL_ESTATE_ENTITY_STRUCTURE,
  ASSET_TYPES,
  ZIP_CODE_REGEX,
  CANADIAN_ZIP_CODE_REGEX,
  ROLE_IDS,
  COPYABLE_CONTACT_TYPES,
  CONTACT_TYPES,
  addNewContactOption,
} from 'config'
import {
  ConditionalContactSubForm,
  FlowActions,
  ContactSearchAndSelect,
  AdditionalContactFormBlock,
} from '../../add-asset-flow/components'
import * as Types from 'types'

const Checkbox = withFormikAdapter()(BaseCheckboxInput)

const propTypes = {
  createAsset: PropTypes.func.isRequired,
  updateAsset: PropTypes.func.isRequired,
  contactsList: PropTypes.arrayOf(Types.contact),
  currentUser: Types.user.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
}

const defaultProps = {}

function ContactDetails({
  updateAsset,
  contactsList,
  currentUser,
  createAsset,
  fetchUserProfile,
}) {
  const [error, setError] = useState()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const { storedAsset } = location?.state

  const isManagingMemberNotCurrent =
    currentUser.contactDetails.contactID !== storedAsset?.contacts[0].contactID

  // show Note Servicer?
  const isRealEstateTypeSecuredByMortgageOrDeed =
    storedAsset.entityType === REAL_ESTATE_ENTITY_STRUCTURE.securedByMortgage
  const isPrivateDebtType =
    storedAsset.investmentType === ASSET_TYPES.privateDebt

  // show noteServicer?
  const showNoteServicer =
    isRealEstateTypeSecuredByMortgageOrDeed || isPrivateDebtType

  // show thirdPartyAdmin?
  const showThirdPartyAdministrator =
    storedAsset.investmentType === ASSET_TYPES.hedgeFund ||
    storedAsset.investmentType === ASSET_TYPES.privateEquity ||
    storedAsset.entityType === REAL_ESTATE_ENTITY_STRUCTURE.entityWillInvest

  if (!contactsList) {
    return <Spinner />
  }

  const contactInfo = {
    purchaseContact: {
      displayName: 'Purchase Contact',
      helpText:
        'Individual who is responsible for receiving and confirming executed subscription documents for purchases of an investment',
      showAddButton: true,
    },
    liquidationContact: {
      displayName: 'Liquidation Contact',
      helpText:
        'Individual who is responsible for receiving and confirming executed liquidation requests for liquidations of an investment',
      showAddButton: true,
    },
    exchangeContact: {
      displayName: 'Exchange Contact',
      helpText:
        'Individual who is responsible for receiving and confirming executed subscription and liquidations documents for exchanges between investments',
      showAddButton: true,
    },
    reRegContact: {
      displayName: 'Re-Reg Contact',
      helpText:
        'Individual Issuer who is responsible for receiving and confirming (if applicable) executed offering documents for incoming and outgoing transfers of investments',
      showAddButton: true,
    },
  }

  const handleSubmit = async (values) => {
    const isSaveAndExit = !values.requireAll
    const contactData = storedAsset.contacts

    const { adminContact } = values

    let asset = {}

    try {
      asset = await createAsset(storedAsset.name)
    } catch (e) {
      return
    }

    contactData.push({
      ...adminContact,
      contactID:
        adminContact.match !== addNewContactOption.value
          ? adminContact.match
          : '',
      roleID: ROLE_IDS.adminContact,
      relationshipID: '',
      isActive: true,
    })

    const hasThirdPartyAdmin =
      values.isThereThirdPartyFundAdministrator?.toString() === 'true'
    const hasNoteServicer = values.isThereNoteServicer?.toString() === 'true'

    // add 3pa values to submission object
    const thirdPartyAdmin = {
      ...values.thirdPartyAdmin,
      accountID: values.thirdPartyAdmin.companyID,
      contactID:
        values.thirdPartyAdmin.match !== addNewContactOption.value
          ? values.thirdPartyAdmin.match
          : '',
      roleID: ROLE_IDS.thirdPartyAdmin,
      isActive: true,
    }

    if (hasThirdPartyAdmin) contactData.push(thirdPartyAdmin)
    if (!hasThirdPartyAdmin && values.thirdPartyAdmin?.contactID)
      contactData.push({ ...thirdPartyAdmin, isActive: false })

    // add note servicer values to submission object
    const noteServicer = {
      ...values.noteServicer,
      contactID:
        values.noteServicer.match !== addNewContactOption.value
          ? values.noteServicer.match
          : '',

      roleID: ROLE_IDS.noteServicer,
      isActive: true,
    }

    if (hasNoteServicer) contactData.push(noteServicer)
    if (!hasNoteServicer && values.noteServicer?.contactID)
      contactData.push({ ...noteServicer, isActive: false })

    // add each active contact type (if they are copies of admin or 3pa, make that swap here)
    const setContactID = ({ contact }) => {
      return contact.match !== addNewContactOption.value ? contact.match : ''
    }

    Object.keys(contactInfo).forEach((contactType) => {
      const data = values[contactType]
      const contact = {
        ...data,
        roleID:
          contactType === CONTACT_TYPES.reRegContact
            ? ROLE_IDS.reRegContact
            : startCase(contactType),
        relationshipID: '',

        isCopiedFromAdminContact:
          data.equalSelection === COPYABLE_CONTACT_TYPES.ADMIN,
        isCopiedFromThirdPartyAdminContact:
          data.equalSelection === COPYABLE_CONTACT_TYPES.THIRD_PARTY_ADMIN,
        isCopiedFromNoteServicerContact:
          data.equalSelection === COPYABLE_CONTACT_TYPES.NOTE_SERVICER,
      }
      contact.contactID = setContactID({ contact: data })
      contactData.push(contact)
    })

    try {
      await updateAsset({
        ...asset,
        ...storedAsset,
        stage: ADD_ASSET_STAGE.FUNDING_DETAILS,
        saveStage: ADD_ASSET_STAGE.CONTACT_DETAILS,
        contacts: contactData,
      })
      await fetchUserProfile()
      if (isSaveAndExit) {
        history.push(`/home`)
      } else {
        history.push(`/add-asset/${asset?.assetID}/funding-details`)
      }
    } catch (e) {
      setError(e)
    }
  }

  const defaultContact = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    state: '',
    streetAddress: '',
    city: '',
    zipcode: '',
    match: '',
    companyID: currentUser.accountID,
  }

  const initialValues = {
    adminContact: isManagingMemberNotCurrent
      ? {
          firstName: currentUser.contactDetails.firstName,
          lastName: currentUser.contactDetails.lastName,
          email: currentUser.contactDetails.email,
          phone: currentUser.contactDetails.phone,
          country: currentUser.contactDetails.country,
          state: currentUser.contactDetails.state,
          streetAddress: currentUser.contactDetails.streetAddress,
          companyID: currentUser.accountID,
          city: currentUser.contactDetails.city,
          zipcode: currentUser.contactDetails.zipcode,
          match: currentUser.contactDetails.contactID,
          isAuthorizedSigner: false,
        }
      : {
          ...defaults(defaultContact),
          isAuthorizedSigner: false,
        },
    thirdPartyAdmin: {
      ...defaults(defaultContact),
    },
    noteServicer: {
      ...defaults(defaultContact),
    },
    purchaseContact: {
      ...defaults(defaultContact),
      equalSelection: '',
    },
    liquidationContact: {
      ...defaults(defaultContact),
      equalSelection: '',
    },
    exchangeContact: {
      ...defaults(defaultContact),
      equalSelection: '',
    },
    reRegContact: {
      ...defaults(defaultContact),
      equalSelection: '',
    },

    isThereThirdPartyFundAdministrator: showThirdPartyAdministrator,

    isThereNoteServicer: showNoteServicer,
  }

  const lazyValidation = Yup.lazy((values) => {
    const requireAllFieldValidationScheme = {
      email: Yup.string().when('match', {
        is: addNewContactOption.value,
        then: Yup.string()
          .email('Must enter valid email!')
          .test(
            'emailValidationCheck',
            'This email is already associated with a contact for Asset Custody Platform. Please use another email address.',
            (email) =>
              fetch(`${process.env.API_URL}/emailAddresses?email=${email}`, {
                method: 'GET',
                headers: {
                  Accept: `application/json`,
                  Authorization: `Bearer ${getSessionToken()}`,
                },
              }).then(async (res) => {
                const data = await res.json()
                if (data.isValid) {
                  return true
                } else {
                  return false
                }
              })
          )
          .required('Required')
          .nullable(),
        otherwise: Yup.string()
          .email('Must enter valid email!')
          .required('Required')
          .nullable(),
      }),
      phone: Yup.string()
        .matches(RegExp(PHONE_NUMBER_REGEX), 'Please enter valid phone number')
        .required('Required'),
      streetAddress: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Required'),
      city: Yup.string()
        .min(2, 'Too Short!')
        .max(40, 'Too Long!')
        .required('Required'),
      state: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      zipcode: Yup.string()
        .test('test_zip_code', '', (value, context) => {
          const { parent, createError } = context
          if (parent.country == 'US') {
            if (!RegExp(ZIP_CODE_REGEX).test(value)) {
              return createError({
                message: 'Must be valid 5 or 9 digit US zip code!',
              })
            }
          }
          if (parent.country == 'CA') {
            if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
              return createError({
                message: 'Must be a valid six-character CA code!',
              })
            }
          }
          return true
        })
        .required('Required')
        .nullable(),
    }

    const requiredFieldValidationScheme = {
      match: Yup.string().required('Required'),
      firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(40, 'Too Long!')
        .required('Required')
        .nullable(),
      lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(80, 'Too Long!')
        .required('Required')
        .nullable(),
    }

    const notRequiredCategoryFieldValidationScheme = {
      match: Yup.string().notRequired(),
      firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(40, 'Too Long!')
        .notRequired(),
      lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(80, 'Too Long!')
        .notRequired(),
      email: Yup.string().when('match', {
        is: addNewContactOption.value,
        then: Yup.string()
          .email('Must enter valid email!')
          .test(
            'emailValidationCheck',
            'This email is already associated with a contact for Asset Custody Platform. Please use another email address.',
            (email) =>
              fetch(`${process.env.API_URL}/emailAddresses?email=${email}`, {
                method: 'GET',
                headers: {
                  Accept: `application/json`,
                  Authorization: `Bearer ${getSessionToken()}`,
                },
              }).then(async (res) => {
                const data = await res.json()
                if (data.isValid) {
                  return true
                } else {
                  return false
                }
              })
          )
          .notRequired()
          .nullable(),
        otherwise: Yup.string()
          .email('Must enter valid email!')
          .notRequired()
          .nullable(),
      }),
      phone: Yup.string()
        .matches(RegExp(PHONE_NUMBER_REGEX), 'Please enter valid phone number')
        .notRequired(),
      streetAddress: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .notRequired(),
      city: Yup.string()
        .min(2, 'Too Short!')
        .max(40, 'Too Long!')
        .notRequired(),
      state: Yup.string().notRequired(),
      country: Yup.string().notRequired(),
      zipcode: Yup.string()
        .notRequired()
        .test('test_zip_code', '', (value, context) => {
          const { parent, createError } = context
          if (parent.country == 'US') {
            if (!RegExp(ZIP_CODE_REGEX).test(value)) {
              return createError({
                message: 'Must be valid 5 or 9 digit US zip code!',
              })
            }
          }
          if (parent.country == 'CA') {
            if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
              return createError({
                message: 'Must be a valid six-character CA code!',
              })
            }
          }
          return true
        }),
      companyID: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .notRequired(),
    }

    const validationObj = {
      adminContact: values.requireAll
        ? Yup.object().shape({
            ...requiredFieldValidationScheme,
            ...requireAllFieldValidationScheme,
            companyID: Yup.string()
              .min(2, 'Too Short!')
              .max(255, 'Too Long!')
              .required('Required'),
          })
        : Yup.object().shape({
            ...requiredFieldValidationScheme,
          }),
      thirdPartyAdmin:
        values.isThereThirdPartyFundAdministrator === 'true'
          ? values.requireAll
            ? Yup.object().shape({
                ...requiredFieldValidationScheme,
                ...requireAllFieldValidationScheme,
                companyID: Yup.string()
                  .min(2, 'Too Short!')
                  .max(255, 'Too Long!')
                  .required('Required'),
              })
            : Yup.object().shape({
                ...requiredFieldValidationScheme,
              })
          : Yup.object().shape(notRequiredCategoryFieldValidationScheme),
      noteServicer:
        values.isThereNoteServicer === 'true'
          ? values.requireAll
            ? Yup.object().shape({
                ...requiredFieldValidationScheme,
                ...requireAllFieldValidationScheme,
              })
            : Yup.object().shape({
                ...requiredFieldValidationScheme,
              })
          : Yup.object().shape(notRequiredCategoryFieldValidationScheme),
      isThereThirdPartyFundAdministrator: Yup.string().required('Required'),
      isThereNoteServicer: Yup.string().required('Required'),
    }

    Object.keys(contactInfo).forEach((type) => {
      if (values[type]?.equalSelection === COPYABLE_CONTACT_TYPES.OTHER) {
        validationObj[type] = values.requireAll
          ? Yup.object().shape({
              ...requiredFieldValidationScheme,
              ...requireAllFieldValidationScheme,
            })
          : Yup.object().shape({
              ...requiredFieldValidationScheme,
            })
      }
      if (!values[type]?.equalSelection) {
        validationObj[type] = Yup.object().shape({
          equalSelection: Yup.string().required('Required'),
        })
      }
    })
    return Yup.object(validationObj)
  })

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  const disclaimerMessage =
    'Issuer authorizes Inspira to discuss the investment(s) with any of the individuals listed above.'
  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={lazyValidation}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            setErrors,
            setTouched,
            touched,
            resetForm,
          }) => (
            <Form className="asset-form asset-details-form">
              <ScrollToFieldError />
              <Card>
                <div className="form-card-content">
                  <div className="additional-form-header">
                    <TitleWithTooltip
                      title="Administrative Contact"
                      content="Individual at Issuer who is responsible for day-to-day
                      communications and subscription documents (if there is no
                      third party fund administrator)"
                    />
                  </div>
                  <fieldset className="read-only-wrapper">
                    <div className="container contact-upload-container">
                      <div className="searchable-select-wrapper">
                        <ContactSearchAndSelect
                          contactType="adminContact"
                          contactsList={contactsList}
                          isNotEditable={isManagingMemberNotCurrent}
                        />
                      </div>
                      <hr />
                      <div className="row">
                        <div className="checkbox-line-item">
                          <Checkbox
                            name="adminContact[isAuthorizedSigner]"
                            label="By checking this box, the issuer authorizes the Administrative Contact listed above to sign all agreements, forms, and other documents related to the (asset/investment) on behalf of the issuer."
                          />
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </fieldset>
                </div>
              </Card>
              {// only show 3pa if hedge fund, private equity or real estate that will invest
              showThirdPartyAdministrator && (
                <Card className="conditional-contact-card">
                  <ConditionalContactSubForm
                    values={values}
                    title="Third Party Fund Administrator"
                    description="An independent third party administration company that is responsible
                    for collecting and processing subscription documents, valuations and
                    statements and maintaining records of investors."
                    keyName="thirdPartyAdmin"
                    conditionalKey="isThereThirdPartyFundAdministrator"
                    resetForm={resetForm}
                    contactsList={contactsList}
                    customEqualValueSuffix="Equal3pa"
                    isNotEditable={false}
                  />
                </Card>
              )}

              {showNoteServicer && (
                <Card className="conditional-contact-card">
                  <ConditionalContactSubForm
                    values={values}
                    title="Note Servicer"
                    description="Individual Issuer who is responsible for administration of loans and notes."
                    keyName="noteServicer"
                    conditionalKey="isThereNoteServicer"
                    resetForm={resetForm}
                    contactsList={contactsList}
                    isNotEditable={false}
                  />
                </Card>
              )}
              <section className="contact-cards">
                {Object.keys(contactInfo).map((type) => (
                  <React.Fragment key={type}>
                    <Card
                      className="conditional-contact-card"
                      label={
                        <LabelWithTooltip
                          label={`Add ${contactInfo[type].displayName} Details`}
                          tooltipContent={contactInfo[type].helpText}
                        />
                      }
                    >
                      <AdditionalContactFormBlock
                        values={values}
                        type={type}
                        hideBlock={values[`${type}EqualAdmin`] === true}
                        setFieldValue={setFieldValue}
                        setErrors={setErrors}
                        setTouched={setTouched}
                        touched={touched}
                        contactsList={contactsList}
                        showThirdPartyAdministratorInput={
                          showThirdPartyAdministrator &&
                          values.isThereThirdPartyFundAdministrator === 'true'
                        }
                        showNoteServicerInput={
                          showNoteServicer &&
                          values.isThereNoteServicer === 'true'
                        }
                        isNotEditable={false}
                      />
                    </Card>
                  </React.Fragment>
                ))}
              </section>
              <Card className="conditional-contact-card">
                <div className="disclosure">{disclaimerMessage}</div>
              </Card>

              <FlowActions preventBack />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

ContactDetails.propTypes = propTypes
ContactDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    contactsList: selectors.contactsList(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  createAsset: apiActions.createAsset,
  updateAsset: apiActions.updateAsset,
  fetchUserProfile: apiActions.fetchUserProfile,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactDetails
)
