import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { useParams } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ConfirmPurchaseHeader, FlowStatusTracker } from 'components'
import { FlashMessageContainer, Spinner } from 'lp-components'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { selectors } from './reducer'
import * as apiActions from 'api-actions'
import { getStageLabel, scrollToTop } from 'utils'
import {
  LIQUIDATION_CONFIRMATION_STAGES_PART_ONE,
  LIQUIDATION_CONFIRMATION_STAGES_PART_TWO,
} from 'flow-stages'
import { LIQUIDATION_CONFIRMATION_STEPS } from 'config'
import withPermission from '../../utils/withPermission'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
  liquidation: PropTypes.object,
  fetchLiquidation: PropTypes.func,
  part: PropTypes.oneOf(Object.values(LIQUIDATION_CONFIRMATION_STEPS))
    .isRequired,
}

const defaultProps = {
  liquidation: null,
}
function Layout({
  flashMessages,
  currentStage,
  children,
  fetchLiquidation,
  liquidation,
  part,
}) {
  const { liquidationID } = useParams()
  const [error, setError] = useState(null)

  useEffect(() => {
    scrollToTop()
    fetchLiquidation(liquidationID).catch((e) => setError(e))
  }, [liquidationID])

  if (error) {
    return (
      <div>
        <Redirect
          to={{
            pathname: '/error',
            state: {
              errorMessage:
                error.errors.message ||
                'Something went wrong, please try again.',
            },
          }}
        />
      </div>
    )
  }
  if (!liquidation) return <Spinner />
  if (!withPermission('confirm-liquidation')) {
    return <Redirect path="*" to="/home" />
  }

  const stages =
    part === LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_FIRST_STEP
      ? LIQUIDATION_CONFIRMATION_STAGES_PART_ONE
      : LIQUIDATION_CONFIRMATION_STAGES_PART_TWO

  return (
    <>
      <FlashMessageContainer messages={flashMessages} />
      <ConfirmPurchaseHeader title={'Liquidation Details'} />
      <div className="add-asset-layout-wrapper contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title">{getStageLabel(stages, currentStage)}</h1>
        </div>
        <div className="content-div">
          <FlowStatusTracker stages={stages} currentStage={currentStage} />
          {children}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    liquidation: selectors.liquidation(state),
  }
}

const mapDispatchToProps = {
  fetchLiquidation: apiActions.fetchLiquidation,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
