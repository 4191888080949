import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FlowStatusTracker } from 'components'
import { selectors as globalSelectors } from '../reducer'
import * as apiActions from 'api-actions'
import { scrollToTop, getStageLabel } from 'utils'
import * as actions from './actions'
import { ONBOARDING_STAGES } from '../flowStages'
import OnboardingHeader from './components/OnboardingHeader'

const propTypes = {
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
  fetchContactsList: PropTypes.func.isRequired,
  clearContactsList: PropTypes.func.isRequired,
}

const defaultProps = {}

function Layout({
  currentStage,
  children,
  fetchContactsList,
  clearContactsList,
}) {
  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    return () => {
      clearContactsList()
    }
  }, [])

  useEffect(() => {
    fetchContactsList()
  }, [])

  return (
    <>
      <OnboardingHeader title={'Add New Asset'} />
      <div className="add-asset-layout-wrapper contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title">
            {getStageLabel(ONBOARDING_STAGES, currentStage)}
          </h1>
        </div>
        <div className="content-div">
          <FlowStatusTracker
            stages={ONBOARDING_STAGES}
            currentStage={currentStage}
          />
          {children}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  fetchContactsList: apiActions.fetchContactsList,
  clearContactsList: actions.clearContactsList,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
