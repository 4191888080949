function parseJSONValues(obj) {
  const result = {}

  // eslint-disable-next-line
  for (let key in obj) {
    if (typeof obj[key] !== 'string') {
      result[key] = obj[key]
    }
    try {
      result[key] = JSON.parse(obj[key])
    } catch {
      result[key] = obj[key]
    }
  }

  return result
}

export default parseJSONValues
