import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { createSelector } from 'reselect'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { EXCHANGE_CONFIRMATION_RECORD_TYPE } from 'config'

const reducerKey = 'exchangeConfirmations'
const slice = 'root.exchangeConfirmations'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.updateExchangeConfirmation]: setOnSuccess(
      'exchangeConfirmation'
    ),
    [apiActions.fetchExchangeConfirmation]: setOnSuccess(
      'exchangeConfirmation'
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  exchangeConfirmation: select('exchangeConfirmation'),
}

selectors.fromOriginalTransactions = createSelector(
  [selectors.exchangeConfirmation],
  (exchangeConfirmation) => {
    if (!exchangeConfirmation) return
    return exchangeConfirmation.lineItems.filter((item) => {
      return (
        item.lineItemRecordType === EXCHANGE_CONFIRMATION_RECORD_TYPE.DIVESTMENT
      )
    })
  }
)

selectors.toNewTransactions = createSelector(
  [selectors.exchangeConfirmation],
  (exchangeConfirmation) => {
    if (!exchangeConfirmation) return
    return exchangeConfirmation.lineItems.filter((item) => {
      return (
        item.lineItemRecordType === EXCHANGE_CONFIRMATION_RECORD_TYPE.INVESTMENT
      )
    })
  }
)

export { reducer, selectors, reducerKey }
