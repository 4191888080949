import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { formatNumberAsCurrency } from 'utils'

const propTypes = {
  clientName: PropTypes.string,
  totalAmount: PropTypes.number.isRequired,
}

const defaultProps = {}

function SelectedClientBadge({ clientName, totalAmount }) {
  return (
    <li className="client-badge-container">
      <div className="client-badge-details-container">
        <div className="client-badge-details-name">{clientName}</div>
        <div className="client-badge-details-total-amount">
          Individual Capital Call Balance: {formatNumberAsCurrency(totalAmount)}
        </div>
      </div>
    </li>
  )
}

SelectedClientBadge.propTypes = exact(propTypes)
SelectedClientBadge.defaultProps = defaultProps

export default React.memo(SelectedClientBadge)
