import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'
import Layout from './Layout'
import Group1AuthorizedLayout from '../Group1AuthorizedLayout.js'
import { ADD_NEW_CONTACT_STAGES } from 'flow-stages'
import { flatMap } from 'lodash'
import ContactsTable from './views/ContactsTable'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  return (
    <Group1AuthorizedLayout>
      <Switch>
        {flatMap(ADD_NEW_CONTACT_STAGES, (stage) =>
          stage.routes.map((route) => {
            const { pathname, component: Component } = route
            return (
              <Route
                exact
                key={pathname}
                path={path + pathname}
                render={(routeProps) => (
                  <Layout currentStage={stage.name}>
                    <Component {...routeProps} />
                  </Layout>
                )}
              />
            )
          })
        )}
        <Route exact path={path} component={ContactsTable} />
        <Redirect path="*" to={path} />
      </Switch>
    </Group1AuthorizedLayout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
