import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { selectors } from './reducer'
import * as actions from './actions'
import { NavLink } from 'react-router-dom'

const propTypes = {
  children: PropTypes.node.isRequired,
  tasks: PropTypes.array,
  transactions: PropTypes.array,
}

const defaultProps = {
  tasks: [],
  transactions: [],
}

function Layout({ children, transactions, tasks }) {
  if (!transactions || !tasks) return <Spinner />

  return (
    <div className="transactions-layout-wrapper">
      <div className="assets-navigation-menu">
        <NavLink to="/transactions/view-all">All Transactions</NavLink>
        <NavLink to="/transactions/pending">Confirmation Pending</NavLink>
      </div>

      {React.cloneElement(children, {
        transactions,
        tasks,
      })}
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    tasks: selectors.tasks(state),
    transactions: selectors.transactions(state),
  }
}

const mapDispatchToProps = {
  clearTransactions: actions.clearTransactions,
  clearTasks: actions.clearTasks,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
