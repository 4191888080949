// App-wide config (routes, env vars) goes here.
import { map } from 'lodash'

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export function isAddAssetScreenshotEnabled() {
  return process.env.ENABLE_ADD_ASSET_SCREENSHOT
}

export function isScreenshotDebuggingEnabled() {
  return process.env.ENABLE_SCREENSHOT_DEBUGGING
}

export function isFullStoryEnabled() {
  return process.env.ENABLE_FULLSTORY
}

export const MTC_FULLSTORY_ORG_ID = process.env.MTC_FULLSTORY_ORG_ID

const limitedOptions = [
  { key: 'Limited Partnership (LP)', value: 'Limited Partnership (LP)' },
  {
    key: 'Limited Liability Company (LLC)',
    value: 'Limited Liability Company (LLC)',
  },
  { key: 'Limited (LTD)', value: 'Limited (LTD)' },
]

const securityOptions = [
  {
    key: 'Secured by Mortgage/Deed of Trust',
    value: 'Secured by Mortgage/Deed of Trust',
  },
  {
    key: 'Secured by Something other than Real Estate',
    value: 'Secured by Something other than Real Estate',
  },
  { key: 'Unsecured', value: 'Unsecured' },
  { key: 'Private Debenture or Bond', value: 'Private Debenture or Bond' },
]

const realEstateOptions = [
  {
    key: 'Secured by Mortgage/Deed of Trust',
    value: 'Secured by Mortgage/Deed of Trust',
  },
  {
    key: 'Entity that will invest in real estate',
    value: 'Entity that will invest in real estate',
  },
]

export const REAL_ESTATE_ENTITY_STRUCTURE = {
  securedByMortgage: 'Secured by Mortgage/Deed of Trust',
  entityWillInvest: 'Entity that will invest in real estate',
}

export const ASSET_TYPES = {
  hedgeFund: 'Hedge Fund',
  privateEquity: 'Private Equity',
  privateDebt: 'Private Debt',
  realEstate: 'Real Estate',
}

export const CONTACT_TYPES = {
  adminContact: 'adminContact',
  noteServicer: 'noteServicer',
  purchaseContact: 'purchaseContact',
  liquidationContact: 'liquidationContact',
  exchangeContact: 'exchangeContact',
  reRegContact: 'reRegContact',
  managingMember: 'managingMember',
  thirdPartyAdmin: 'thirdPartyAdmin',
}

export const ROLE_IDS = {
  adminContact: 'Administrative Contact',
  noteServicer: 'Note Servicer',
  purchaseContact: 'Purchase Contact',
  liquidationContact: 'Liquidation Contact',
  exchangeContact: 'Exchange Contact',
  reRegContact: 'Re-Registration',
  thirdPartyAdmin: 'Third Party Fund Administrator',
  managingMember: 'Managing Member',
}

export const PROFILE_ROLES = {
  MANAGING_MEMBER: 'Managing Member',
  ADMINISTRATIVE_CONTACT: 'Administrative Contact',
  EXCHANGE_CONTACT: 'Exchange Contact',
  LIQUIDATION_CONTACT: 'Liquidation Contact',
  NOTE_SERVICER: 'Note Servicer',
  PURCHASE_CONTACT: 'Purchase Contact',
  REREGISTRATION_CONTACT: 'Re-Registration',
  THIRD_PARTY_ADMINISTRATOR: 'Third Party Fund Administrator',
}

export const ACCOUNT_TYPE_OPTIONS = {
  TRADITIONAL_IRA: 'Traditional IRA',
  ROTH_IRA: 'Roth IRA',
  SIMPLE_IRA: 'SIMPLE IRA',
  SEP_IRA: 'SEP IRA',
  EMPLOYEE_BENEFIT_PLAN: 'Employee Benefit Plan',
  INDIVIDUAL_401K_PROFIT_SHARING: 'Individual 401(k) OR Profit Sharing Plan',
  INDIVIDUAL_CUSTODY_ACCOUNT: 'Individual Custody Account',
  JOINT_WROS: 'Joint WROS (With Rights of Survivorship)',
  ORGANIZATION: 'Organization (LLC, Partnership, Corporation)',
  PERSONAL_TRUST: 'Personal Trust',
  TENANTS_IN_COMMON: 'Tenants in Common',
  UNIFORM_GIFT_TRANSFER_TO_MINORS: 'Uniform Gift/Transfer to Minors Act',
}

export const ROLE_DEFINITION = [
  {
    key: PROFILE_ROLES.MANAGING_MEMBER,
    value:
      'Individual at Issuer who is responsible for the day-to-day business operations and is an authorized signer for the company.',
  },
  {
    key: PROFILE_ROLES.ADMINISTRATIVE_CONTACT,
    value:
      'Individual at Issuer who is responsible for day-to day communications.',
  },
  {
    key: PROFILE_ROLES.EXCHANGE_CONTACT,
    value:
      'Individual who is responsible for receiving and confirming executed subscription and liquidations documents for exchanges between investments.',
  },
  {
    key: PROFILE_ROLES.LIQUIDATION_CONTACT,
    value:
      'Individual who is responsible for receiving and confirming executed liquidations requests for liquidations of an investment.',
  },
  {
    key: PROFILE_ROLES.NOTE_SERVICER,
    value:
      'Individual issuer who is responsible for the administration of loans and notes.',
  },
  {
    key: PROFILE_ROLES.PURCHASE_CONTACT,
    value:
      'Individual who is responsible for receiving and confirming executed subscription documents for purchases of an investment.',
  },
  {
    key: PROFILE_ROLES.REREGISTRATION_CONTACT,
    value:
      'Individual Issuer who is responsible for receiving and confirming (if applicable) executed documents for incoming and outgoing transfers of investments.',
  },
  {
    key: PROFILE_ROLES.THIRD_PARTY_ADMINISTRATOR,
    value:
      'An independent third-party administration company that is responsible for collecting and processing subscription documents, valuations and statements and maintaining records of investors.',
  },
]

export const COPYABLE_CONTACT_TYPES = {
  ADMIN: 'adminContact',
  THIRD_PARTY_ADMIN: 'thirdPartyAdmin',
  NOTE_SERVICER: 'noteServicer',
  OTHER: 'other',
}

export const StepNames = {
  SECOND_STEP: 'secondStep',
  THIRD_STEP: 'thirdStep',
}

export const assets = {
  'Hedge Fund': {
    label: 'Hedge Fund',
    [StepNames.SECOND_STEP]: {
      name: 'securityType',
      label: 'Security Type',
      options: limitedOptions,
    },
    [StepNames.THIRD_STEP]: {},
  },
  'Private Equity': {
    label: 'Private Equity',
    [StepNames.SECOND_STEP]: {},
    [StepNames.THIRD_STEP]: {},
  },
  'Private Debt': {
    label: 'Private Debt',
    [StepNames.SECOND_STEP]: {
      name: 'securityType',
      label: 'Security Type',
      options: securityOptions,
    },
    [StepNames.THIRD_STEP]: {},
  },
  'Real Estate': {
    label: 'Real Estate',
    [StepNames.SECOND_STEP]: {
      name: 'entityType',
      label: 'Entity Type',
      options: realEstateOptions,
    },
    [StepNames.THIRD_STEP]: {
      // the presence of the third step is contingent on the second step answer
      condition: {
        value: REAL_ESTATE_ENTITY_STRUCTURE.entityWillInvest,
      },
      name: 'securityType',
      label: 'Security Type',
      options: limitedOptions,
    },
  },
}

export const assetOptions = map(assets, (val, key) => ({
  key: val.label,
  value: key,
}))

// this maps to task.taskType
export const TASK_TYPES = {
  CONFIRMATION: 'Confirmation',
  PENDING_ASSET_SUBMISSION: 'Pending Asset Submission',
  ASSET_RESOLUTION: 'Asset Resolution',
  LIQUIDATION: 'Liquidation',
  EXCHANGE: 'Exchange',
}

export const TASK_SUBTYPES = {
  PURCHASE: 'Purchase',
  VALUATION: 'Valuation',
  FUNDING: 'Funding',
  CONTACT: 'Contact',
}

export const LIQUIDATION_CONFIRMATION_STEPS = {
  LIQUIDATION_FIRST_STEP: 'Liquidation_1',
  LIQUIDATION_SECOND_STEP: 'Liquidation_2',
}

export const ADD_ASSET_STAGE = {
  INITIAL: 'initial',
  ASSET_DETAILS: 'asset-details',
  CONTACT_DETAILS: 'contact-details',
  THIRD_PARTY_ADMINISTRATOR: 'third-party-administrator',
  FUNDING_DETAILS: 'funding-details',
  INVESTMENT_DETAILS: 'investment-details',
  FUNDING_INSTRUCTIONS: 'funding-instructions',
  REQUIREMENT_DETAILS: 'requirement-details',
  PURCHASE_REQUIREMENTS: 'purchase-requirements',
  VALUATION_REQUIREMENTS: 'valuation-requirements',
  DOCUMENT_UPLOAD: 'document-upload',
  DOCUMENTS: 'documents',
  E_SIGN: 'e-sign',
  COMPLETED: 'completed',
}

export const PHONE_TYPE_OPTIONS = [
  { key: 'Work', value: 'Work' },
  { key: 'Mobile', value: 'Mobile' },
]

export const INVESTOR_DETAILS_VALUATION_METHODOLOGY = {
  CAPITAL_VALUE: 'Capital Value',
  PRICE_PER_SHARE: 'Price per share',
  PRICE_PER_SHARE_UNIT: 'Price per share/unit',
}

export const INVESTOR_DETAILS_VALUATION_MAPPING = {
  [INVESTOR_DETAILS_VALUATION_METHODOLOGY.CAPITAL_VALUE]: 'Capital Value',
  [INVESTOR_DETAILS_VALUATION_METHODOLOGY.PRICE_PER_SHARE]: 'Price Per Share',
  [INVESTOR_DETAILS_VALUATION_METHODOLOGY.PRICE_PER_SHARE_UNIT]:
    'Price Per Share',
}

export const VALUATION_METHODOLOGY = {
  CAPITAL_VALUE: {
    key: 'Capital Value',
    value: 'Capital Value',
  },
  PRICE_PER_SHARE: { key: 'Price Per Share', value: 'Price per share' },
}

export const canadianProvinces = [
  {
    key: 'Alberta',
    value: 'Alberta',
  },
  {
    key: 'British Columbia',
    value: 'British Columbia',
  },
  {
    key: 'Manitoba',
    value: 'Manitoba',
  },
  {
    key: 'New Brunswick',
    value: 'New Brunswick',
  },
  {
    key: 'Newfoundland and Labrador',
    value: 'Newfoundland and Labrador',
  },
  {
    key: 'Northwest Territories',
    value: 'Northwest Territories',
  },
  {
    key: 'Nova Scotia',
    value: 'Nova Scotia',
  },
  {
    key: 'Nunavut',
    value: 'Nunavut',
  },
  {
    key: 'Ontario',
    value: 'Ontario',
  },
  {
    key: 'Prince Edward Island',
    value: 'Prince Edward Island',
  },
  {
    key: 'Quebec',
    value: 'Quebec',
  },
  {
    key: 'Saskatchewan',
    value: 'Saskatchewan',
  },
  {
    key: 'Yukon',
    value: 'Yukon',
  },
]

export const canadianProvinceCodes = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
}

export const usStateCodes = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const usStates = [
  {
    key: 'Alabama',
    value: 'Alabama',
  },
  {
    key: 'Alaska',
    value: 'Alaska',
  },
  {
    key: 'Arizona',
    value: 'Arizona',
  },
  {
    key: 'Arkansas',
    value: 'Arkansas',
  },
  {
    key: 'California',
    value: 'California',
  },
  {
    key: 'Colorado',
    value: 'Colorado',
  },
  {
    key: 'Connecticut',
    value: 'Connecticut',
  },
  {
    key: 'Delaware',
    value: 'Delaware',
  },
  {
    key: 'District Of Columbia',
    value: 'District Of Columbia',
  },
  {
    key: 'Florida',
    value: 'Florida',
  },
  {
    key: 'Georgia',
    value: 'Georgia',
  },
  {
    key: 'Hawaii',
    value: 'Hawaii',
  },
  {
    key: 'Idaho',
    value: 'Idaho',
  },
  {
    key: 'Illinois',
    value: 'Illinois',
  },
  {
    key: 'Indiana',
    value: 'Indiana',
  },
  {
    key: 'Iowa',
    value: 'Iowa',
  },
  {
    key: 'Kansas',
    value: 'Kansas',
  },
  {
    key: 'Kentucky',
    value: 'Kentucky',
  },
  {
    key: 'Louisiana',
    value: 'Louisiana',
  },
  {
    key: 'Maine',
    value: 'Maine',
  },
  {
    key: 'Maryland',
    value: 'Maryland',
  },
  {
    key: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    key: 'Michigan',
    value: 'Michigan',
  },
  {
    key: 'Minnesota',
    value: 'Minnesota',
  },
  {
    key: 'Mississippi',
    value: 'Mississippi',
  },
  {
    key: 'Missouri',
    value: 'Missouri',
  },
  {
    key: 'Montana',
    value: 'Montana',
  },
  {
    key: 'Nebraska',
    value: 'Nebraska',
  },
  {
    key: 'Nevada',
    value: 'Nevada',
  },
  {
    key: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    key: 'New Jersey',
    value: 'New Jersey',
  },
  {
    key: 'New Mexico',
    value: 'New Mexico',
  },
  {
    key: 'New York',
    value: 'New York',
  },
  {
    key: 'North Carolina',
    value: 'North Carolina',
  },
  {
    key: 'North Dakota',
    value: 'North Dakota',
  },
  {
    key: 'Ohio',
    value: 'Ohio',
  },
  {
    key: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    key: 'Oregon',
    value: 'Oregon',
  },
  {
    key: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    key: 'Rhode Island',
    value: 'Rhode Island',
  },
  {
    key: 'South Carolina',
    value: 'South Carolina',
  },
  {
    key: 'South Dakota',
    value: 'South Dakota',
  },
  {
    key: 'Tennessee',
    value: 'Tennessee',
  },
  {
    key: 'Texas',
    value: 'Texas',
  },
  {
    key: 'Utah',
    value: 'Utah',
  },
  {
    key: 'Vermont',
    value: 'Vermont',
  },
  {
    key: 'Virginia',
    value: 'Virginia',
  },
  {
    key: 'Washington',
    value: 'Washington',
  },
  {
    key: 'West Virginia',
    value: 'West Virginia',
  },
  {
    key: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    key: 'Wyoming',
    value: 'Wyoming',
  },
]

export const usStatesKV = [
  {
    key: 'Alabama',
    value: 'Alabama',
  },
  {
    key: 'Alaska',
    value: 'Alaska',
  },
  {
    key: 'American Samoa',
    value: 'American Samoa',
  },
  {
    key: 'Arizona',
    value: 'Arizona',
  },
  {
    key: 'Arkansas',
    value: 'Arkansas',
  },
  {
    key: 'California',
    value: 'California',
  },
  {
    key: 'Colorado',
    value: 'Colorado',
  },
  {
    key: 'Connecticut',
    value: 'Connecticut',
  },
  {
    key: 'Delaware',
    value: 'Delaware',
  },
  {
    key: 'District Of Columbia',
    value: 'District Of Columbia',
  },
  {
    key: 'Federated States Of Micronesia',
    value: 'Federated States Of Micronesia',
  },
  {
    key: 'Florida',
    value: 'Florida',
  },
  {
    key: 'Georgia',
    value: 'Georgia',
  },
  {
    key: 'Guam',
    value: 'Guam',
  },
  {
    key: 'Hawaii',
    value: 'Hawaii',
  },
  {
    key: 'Idaho',
    value: 'Idaho',
  },
  {
    key: 'Illinois',
    value: 'Illinois',
  },
  {
    key: 'Indiana',
    value: 'Indiana',
  },
  {
    key: 'Iowa',
    value: 'Iowa',
  },
  {
    key: 'Kansas',
    value: 'Kansas',
  },
  {
    key: 'Kentucky',
    value: 'Kentucky',
  },
  {
    key: 'Louisiana',
    value: 'Louisiana',
  },
  {
    key: 'Maine',
    value: 'Maine',
  },
  {
    key: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    key: 'Maryland',
    value: 'Maryland',
  },
  {
    key: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    key: 'Michigan',
    value: 'Michigan',
  },
  {
    key: 'Minnesota',
    value: 'Minnesota',
  },
  {
    key: 'Mississippi',
    value: 'Mississippi',
  },
  {
    key: 'Missouri',
    value: 'Missouri',
  },
  {
    key: 'Montana',
    value: 'Montana',
  },
  {
    key: 'Nebraska',
    value: 'Nebraska',
  },
  {
    key: 'Nevada',
    value: 'Nevada',
  },
  {
    key: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    key: 'New Jersey',
    value: 'New Jersey',
  },
  {
    key: 'New Mexico',
    value: 'New Mexico',
  },
  {
    key: 'New York',
    value: 'New York',
  },
  {
    key: 'North Carolina',
    value: 'North Carolina',
  },
  {
    key: 'North Dakota',
    value: 'North Dakota',
  },
  {
    key: 'Ohio',
    value: 'Ohio',
  },
  {
    key: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    key: 'Oregon',
    value: 'Oregon',
  },
  {
    key: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    key: 'Rhode Island',
    value: 'Rhode Island',
  },
  {
    key: 'South Carolina',
    value: 'South Carolina',
  },
  {
    key: 'South Dakota',
    value: 'South Dakota',
  },
  {
    key: 'Tennessee',
    value: 'Tennessee',
  },
  {
    key: 'Texas',
    value: 'Texas',
  },
  {
    key: 'Utah',
    value: 'Utah',
  },
  {
    key: 'Vermont',
    value: 'Vermont',
  },
  {
    key: 'Virginia',
    value: 'Virginia',
  },
  {
    key: 'Washington',
    value: 'Washington',
  },
  {
    key: 'West Virginia',
    value: 'West Virginia',
  },
  {
    key: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    key: 'Wyoming',
    value: 'Wyoming',
  },
]

// default filter option
export const defaultAllOption = { key: 'All', value: 'all' }

export const addNewContactOption = {
  key: 'Add New Contact',
  value: 'newContact',
}

export const countries = [
  { key: 'Afghanistan', value: 'AF' },
  { key: 'Åland Islands', value: 'AX' },
  { key: 'Albania', value: 'AL' },
  { key: 'Algeria', value: 'DZ' },
  { key: 'American Samoa', value: 'AS' },
  { key: 'Andorra', value: 'AD' },
  { key: 'Angola', value: 'AO' },
  { key: 'Anguilla', value: 'AI' },
  { key: 'Antarctica', value: 'AQ' },
  { key: 'Antigua and Barbuda', value: 'AG' },
  { key: 'Argentina', value: 'AR' },
  { key: 'Armenia', value: 'AM' },
  { key: 'Aruba', value: 'AW' },
  { key: 'Australia', value: 'AU' },
  { key: 'Austria', value: 'AT' },
  { key: 'Azerbaijan', value: 'AZ' },
  { key: 'Bahamas', value: 'BS' },
  { key: 'Bahrain', value: 'BH' },
  { key: 'Bangladesh', value: 'BD' },
  { key: 'Barbados', value: 'BB' },
  { key: 'Belarus', value: 'BY' },
  { key: 'Belgium', value: 'BE' },
  { key: 'Belize', value: 'BZ' },
  { key: 'Benin', value: 'BJ' },
  { key: 'Bermuda', value: 'BM' },
  { key: 'Bhutan', value: 'BT' },
  { key: 'Bolivia', value: 'BO' },
  { key: 'Bosnia and Herzegovina', value: 'BA' },
  { key: 'Botswana', value: 'BW' },
  { key: 'Bouvet Island', value: 'BV' },
  { key: 'Brazil', value: 'BR' },
  { key: 'British Indian Ocean Territory', value: 'IO' },
  { key: 'Brunei Darussalam', value: 'BN' },
  { key: 'Bulgaria', value: 'BG' },
  { key: 'Burkina Faso', value: 'BF' },
  { key: 'Burundi', value: 'BI' },
  { key: 'Cambodia', value: 'KH' },
  { key: 'Cameroon', value: 'CM' },
  { key: 'Canada', value: 'CA' },
  { key: 'Cape Verde', value: 'CV' },
  { key: 'Cayman Islands', value: 'KY' },
  { key: 'Central African Republic', value: 'CF' },
  { key: 'Chad', value: 'TD' },
  { key: 'Chile', value: 'CL' },
  { key: 'China', value: 'CN' },
  { key: 'Christmas Island', value: 'CX' },
  { key: 'Cocos (Keeling) Islands', value: 'CC' },
  { key: 'Colombia', value: 'CO' },
  { key: 'Comoros', value: 'KM' },
  { key: 'Congo', value: 'CG' },
  { key: 'Congo, The Democratic Republic of the', value: 'CD' },
  { key: 'Cook Islands', value: 'CK' },
  { key: 'Costa Rica', value: 'CR' },
  { key: "Cote D'Ivoire", value: 'CI' },
  { key: 'Croatia', value: 'HR' },
  { key: 'Cuba', value: 'CU' },
  { key: 'Cyprus', value: 'CY' },
  { key: 'Czech Republic', value: 'CZ' },
  { key: 'Denmark', value: 'DK' },
  { key: 'Djibouti', value: 'DJ' },
  { key: 'Dominica', value: 'DM' },
  { key: 'Dominican Republic', value: 'DO' },
  { key: 'Ecuador', value: 'EC' },
  { key: 'Egypt', value: 'EG' },
  { key: 'El Salvador', value: 'SV' },
  { key: 'Equatorial Guinea', value: 'GQ' },
  { key: 'Eritrea', value: 'ER' },
  { key: 'Estonia', value: 'EE' },
  { key: 'Ethiopia', value: 'ET' },
  { key: 'Falkland Islands (Malvinas)', value: 'FK' },
  { key: 'Faroe Islands', value: 'FO' },
  { key: 'Fiji', value: 'FJ' },
  { key: 'Finland', value: 'FI' },
  { key: 'France', value: 'FR' },
  { key: 'French Guiana', value: 'GF' },
  { key: 'French Polynesia', value: 'PF' },
  { key: 'French Southern Territories', value: 'TF' },
  { key: 'Gabon', value: 'GA' },
  { key: 'Gambia', value: 'GM' },
  { key: 'Georgia', value: 'GE' },
  { key: 'Germany', value: 'DE' },
  { key: 'Ghana', value: 'GH' },
  { key: 'Gibraltar', value: 'GI' },
  { key: 'Greece', value: 'GR' },
  { key: 'Greenland', value: 'GL' },
  { key: 'Grenada', value: 'GD' },
  { key: 'Guadeloupe', value: 'GP' },
  { key: 'Guam', value: 'GU' },
  { key: 'Guatemala', value: 'GT' },
  { key: 'Guernsey', value: 'GG' },
  { key: 'Guinea', value: 'GN' },
  { key: 'Guinea-Bissau', value: 'GW' },
  { key: 'Guyana', value: 'GY' },
  { key: 'Haiti', value: 'HT' },
  { key: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { key: 'Holy See (Vatican City State)', value: 'VA' },
  { key: 'Honduras', value: 'HN' },
  { key: 'Hong Kong', value: 'HK' },
  { key: 'Hungary', value: 'HU' },
  { key: 'Iceland', value: 'IS' },
  { key: 'India', value: 'IN' },
  { key: 'Indonesia', value: 'ID' },
  { key: 'Iran, Islamic Republic Of', value: 'IR' },
  { key: 'Iraq', value: 'IQ' },
  { key: 'Ireland', value: 'IE' },
  { key: 'Isle of Man', value: 'IM' },
  { key: 'Israel', value: 'IL' },
  { key: 'Italy', value: 'IT' },
  { key: 'Jamaica', value: 'JM' },
  { key: 'Japan', value: 'JP' },
  { key: 'Jersey', value: 'JE' },
  { key: 'Jordan', value: 'JO' },
  { key: 'Kazakhstan', value: 'KZ' },
  { key: 'Kenya', value: 'KE' },
  { key: 'Kiribati', value: 'KI' },
  { key: "Korea, Democratic People'S Republic of", value: 'KP' },
  { key: 'Korea, Republic of', value: 'KR' },
  { key: 'Kuwait', value: 'KW' },
  { key: 'Kyrgyzstan', value: 'KG' },
  { key: "Lao People'S Democratic Republic", value: 'LA' },
  { key: 'Latvia', value: 'LV' },
  { key: 'Lebanon', value: 'LB' },
  { key: 'Lesotho', value: 'LS' },
  { key: 'Liberia', value: 'LR' },
  { key: 'Libyan Arab Jamahiriya', value: 'LY' },
  { key: 'Liechtenstein', value: 'LI' },
  { key: 'Lithuania', value: 'LT' },
  { key: 'Luxembourg', value: 'LU' },
  { key: 'Macao', value: 'MO' },
  { key: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { key: 'Madagascar', value: 'MG' },
  { key: 'Malawi', value: 'MW' },
  { key: 'Malaysia', value: 'MY' },
  { key: 'Maldives', value: 'MV' },
  { key: 'Mali', value: 'ML' },
  { key: 'Malta', value: 'MT' },
  { key: 'Marshall Islands', value: 'MH' },
  { key: 'Martinique', value: 'MQ' },
  { key: 'Mauritania', value: 'MR' },
  { key: 'Mauritius', value: 'MU' },
  { key: 'Mayotte', value: 'YT' },
  { key: 'Mexico', value: 'MX' },
  { key: 'Micronesia, Federated States of', value: 'FM' },
  { key: 'Moldova, Republic of', value: 'MD' },
  { key: 'Monaco', value: 'MC' },
  { key: 'Mongolia', value: 'MN' },
  { key: 'Montserrat', value: 'MS' },
  { key: 'Morocco', value: 'MA' },
  { key: 'Mozambique', value: 'MZ' },
  { key: 'Myanmar', value: 'MM' },
  { key: 'Namibia', value: 'NA' },
  { key: 'Nauru', value: 'NR' },
  { key: 'Nepal', value: 'NP' },
  { key: 'Netherlands', value: 'NL' },
  { key: 'Netherlands Antilles', value: 'AN' },
  { key: 'New Caledonia', value: 'NC' },
  { key: 'New Zealand', value: 'NZ' },
  { key: 'Nicaragua', value: 'NI' },
  { key: 'Niger', value: 'NE' },
  { key: 'Nigeria', value: 'NG' },
  { key: 'Niue', value: 'NU' },
  { key: 'Norfolk Island', value: 'NF' },
  { key: 'Northern Mariana Islands', value: 'MP' },
  { key: 'Norway', value: 'NO' },
  { key: 'Oman', value: 'OM' },
  { key: 'Pakistan', value: 'PK' },
  { key: 'Palau', value: 'PW' },
  { key: 'Palestinian Territory, Occupied', value: 'PS' },
  { key: 'Panama', value: 'PA' },
  { key: 'Papua New Guinea', value: 'PG' },
  { key: 'Paraguay', value: 'PY' },
  { key: 'Peru', value: 'PE' },
  { key: 'Philippines', value: 'PH' },
  { key: 'Pitcairn', value: 'PN' },
  { key: 'Poland', value: 'PL' },
  { key: 'Portugal', value: 'PT' },
  { key: 'Puerto Rico', value: 'PR' },
  { key: 'Qatar', value: 'QA' },
  { key: 'Reunion', value: 'RE' },
  { key: 'Romania', value: 'RO' },
  { key: 'Russian Federation', value: 'RU' },
  { key: 'Rwanda', value: 'RW' },
  { key: 'Saint Helena', value: 'SH' },
  { key: 'Saint Kitts and Nevis', value: 'KN' },
  { key: 'Saint Lucia', value: 'LC' },
  { key: 'Saint Pierre and Miquelon', value: 'PM' },
  { key: 'Saint Vincent and the Grenadines', value: 'VC' },
  { key: 'Samoa', value: 'WS' },
  { key: 'San Marino', value: 'SM' },
  { key: 'Sao Tome and Principe', value: 'ST' },
  { key: 'Saudi Arabia', value: 'SA' },
  { key: 'Senegal', value: 'SN' },
  { key: 'Serbia and Montenegro', value: 'CS' },
  { key: 'Seychelles', value: 'SC' },
  { key: 'Sierra Leone', value: 'SL' },
  { key: 'Singapore', value: 'SG' },
  { key: 'Slovakia', value: 'SK' },
  { key: 'Slovenia', value: 'SI' },
  { key: 'Solomon Islands', value: 'SB' },
  { key: 'Somalia', value: 'SO' },
  { key: 'South Africa', value: 'ZA' },
  { key: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { key: 'Spain', value: 'ES' },
  { key: 'Sri Lanka', value: 'LK' },
  { key: 'Sudan', value: 'SD' },
  { key: 'Surikey', value: 'SR' },
  { key: 'Svalbard and Jan Mayen', value: 'SJ' },
  { key: 'Swaziland', value: 'SZ' },
  { key: 'Sweden', value: 'SE' },
  { key: 'Switzerland', value: 'CH' },
  { key: 'Syrian Arab Republic', value: 'SY' },
  { key: 'Taiwan, Province of China', value: 'TW' },
  { key: 'Tajikistan', value: 'TJ' },
  { key: 'Tanzania, United Republic of', value: 'TZ' },
  { key: 'Thailand', value: 'TH' },
  { key: 'Timor-Leste', value: 'TL' },
  { key: 'Togo', value: 'TG' },
  { key: 'Tokelau', value: 'TK' },
  { key: 'Tonga', value: 'TO' },
  { key: 'Trinidad and Tobago', value: 'TT' },
  { key: 'Tunisia', value: 'TN' },
  { key: 'Turkey', value: 'TR' },
  { key: 'Turkmenistan', value: 'TM' },
  { key: 'Turks and Caicos Islands', value: 'TC' },
  { key: 'Tuvalu', value: 'TV' },
  { key: 'Uganda', value: 'UG' },
  { key: 'Ukraine', value: 'UA' },
  { key: 'United Arab Emirates', value: 'AE' },
  { key: 'United Kingdom', value: 'GB' },
  { key: 'United States', value: 'US' },
  { key: 'United States Minor Outlying Islands', value: 'UM' },
  { key: 'Uruguay', value: 'UY' },
  { key: 'Uzbekistan', value: 'UZ' },
  { key: 'Vanuatu', value: 'VU' },
  { key: 'Venezuela', value: 'VE' },
  { key: 'Viet Nam', value: 'VN' },
  { key: 'Virgin Islands, British', value: 'VG' },
  { key: 'Virgin Islands, U.S.', value: 'VI' },
  { key: 'Wallis and Futuna', value: 'WF' },
  { key: 'Western Sahara', value: 'EH' },
  { key: 'Yemen', value: 'YE' },
  { key: 'Zambia', value: 'ZM' },
  { key: 'Zimbabwe', value: 'ZW' },
]

export const PHONE_NUMBER_REGEX = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/
export const ZIP_CODE_REGEX = '^[0-9]{5}(?:-[0-9]{4})?$'
export const CANADIAN_ZIP_CODE_REGEX = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i

export const TRANSACTION_STATUSES = [
  {
    name: 'Awaiting Funds',
    description: `Inspira is waiting for your client's account to be funded`,
  },
  {
    name: 'Awaiting Pre-Custody',
    description: `Inspira needs to complete the pre-custody process prior to processing your client's investment transaction`,
  },
  {
    name: 'Awaiting Resolution',
    description: `Inspira is awaiting your client's response on a resolution item`,
  },
  {
    name: 'Cancelled',
    description: `Your client's investment transaction was cancelled`,
  },
  {
    name: 'Confirmation Pending',
    description: `Inspira processed your client's investment transaction and is awaiting confirmation from you`,
  },
  {
    name: 'Confirmation Pending Verification',
    description: 'Found issues during review; working with sponsor to resolve',
  },
  {
    name: 'Confirmation Under Review',
    description: 'Sponsor has submitted confirmation; Inspira reviewing',
  },
  {
    name: 'Confirmed',
    description: `Your client's investment transaction is complete`,
  },
  {
    name: 'Document Execution',
    description:
      'Issuer Documents are pending signature from an authorized signer at Inspira',
  },
  {
    name: 'On Hold',
    description:
      'Inspira is currently waiting for confirmation(s) on prior transaction(s)',
  },
  {
    name: 'Pending Proceeds',
    description: `Inspira is waiting for the proceeds from a pending liquidation transaction`,
  },
  {
    name: 'Received',
    description: `Inspira has received your client's investment transaction request`,
  },
  {
    name: 'Reviewing',
    description: `Inspira is reviewing your client's investment transaction request`,
  },
  {
    name: 'Scheduled',
    description: `Inspira has scheduled the date in which we will send funds to you`,
  },
  {
    name: 'Sponsor Declined',
    description: `Sponsor has declined the investment transaction requested by Inspira`,
  },
  {
    name: 'Staged',
    description: `Shell purchase has been created with client name only`,
  },
]

export const PENDING_MTC_TRANSACTION_STATUSES = [
  'Pending Proceeds',
  'Confirmation Under Review',
  'Awaiting Funds',
  'Reviewing',
  'Document Execution',
  'Received',
  'Staged',
  'Scheduled',
  'Commitment Docs Sent',
]

export const PENDING_USER_TRANSACTION_STATUSES = [
  'Confirmation Pending Verification',
  'On Hold',
  'Hold',
  'Awaiting Resolution',
  'Confirmation Pending',
  'Awaiting Pre-Custody',
]

export const FAILED_TRANSACTION_STATUSES = [
  'Cancelled',
  'Closed-Duplicate',
  'Closed-Expired',
  'Sponsor Declined',
]

export const COMPLETED_TRANSACTION_STATUSES = ['Confirmed']

export const ASSET_STATUSES = [
  {
    name: 'Accepted',
    description: 'Inspira has accepted the asset for custody.',
  },
  {
    name: 'Declined',
    description: 'Inspira has declined custody of the asset.',
  },
  {
    name: 'Cancelled',
    description:
      'Inspira was unable to obtain all documentation required to complete the asset review.',
  },
  {
    name: 'Received',
    description: 'Inspira received your new asset submission for review.',
  },
  {
    name: 'In Review',
    description:
      'Inspira is reviewing the documentation submitted for the new asset.',
  },
  {
    name: 'Awaiting Response',
    description:
      'Inspira is awaiting your response for additional documentation required to complete the new asset review.',
  },
  {
    name: 'Review Required',
    description:
      'An asset currently held with Inspira that requires completion of the Submit New Asset process for transactions to be processed.',
  },
]

export const DOCUMENT_TYPES = [
  {
    type: 'Inspira',
    description:
      'Forms provided by Inspira Financial that you. as the sponsor, complete and sign',
    examples: [
      'Distribution, transfer, and deposit forms',
      'IRA adoption agreements',
      'Investment Valuation Forms',
      'Private Placement Purchase Direction forms',
    ],
  },
  {
    type: 'Sponsor',
    description:
      'Documents provided by your company, as the sponsor, to your investors to complete and sign as part of a purchase (can be client- or asset-related)',
    examples: [
      'Subscription documents',
      'Offering memorandums',
      'Funding instructions',
      'Custom valuation documents',
      'Capital call notices',
    ],
  },
  {
    type: 'Investor Payment Breakdown',
    description:
      'Document containing breakdown of asset payments by investor (for dividend, return of capital, and interest payments)',
    examples: ['Payment breakdown documents'],
  },
  {
    type: 'Other',
    description: 'Any other document not listed above',
    examples: ['Investor identification documents', 'Additional forms'],
  },
]

// Liquidations

export const IS_NOT_PROCESSABLE_OPTIONS = {
  assetIsIlliquid: 'Asset Is Illiquid',
  additionalDocumentRequired: 'Additional Document Required',
  other: 'Other',
}

export const LIQUIDATION_OPTIONS = [
  { key: 'Full', value: 'Full' },
  { key: 'Partial', value: 'Partial' },
  { key: 'RMD', value: 'RMD' },
  { key: 'Tender Offer', value: 'Tender Offer' },
]

export const LIQUIDATION_TYPES = {
  Full: 'Full',
  Partial: 'Partial',
  RMD: 'RMD',
  TenderOffer: 'Tender Offer',
}

export const ACCEPTED_FILE_TYPES = [
  '.doc',
  '.docx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]

export const TaskPriorityFilters = {
  ALL: 'all',
  URGENT: 'All Urgent Tasks',
}

export const TaskFilterConversions = {
  EXCHANGE: 'Exchange',
  EXCHANGE_TRANSACTION: 'Exchange Transaction',
  LIQUIDATION: 'Liquidation',
  LIQUIDATION_TRANSACTION: 'Liquidation Transaction',
  PENDING_ASSET_SUBMISSION: 'Pending Asset Submission',
  PURCHASE: 'Purchase',
  PURCHASE_TRANSACTION: 'Purchase Transaction',
  [RESOLUTION_TYPE]: RESOLUTION_TYPE,
  SUBMIT_NEW_ASSET: 'Submit New Asset',
}

export const PriorityLevels = {
  URGENT: 'Urgent',
}

export const TaskPriorityOrder = ['Urgent', '']

export const DocumentSubGroups = {
  LIQUIDATION_PART_2: 'Liquidation Part2',
  UNABLE_TO_LIQUIDATE: 'Unable to Liquidate',
}

export const MAX_FILE_SIZE_IN_BYTES = 41943040

export const INVALID_FILE_TYPE_ERROR = 'file-invalid-type'

export const PATHNAMES = {
  ASSETS: '/assets',
  DOCUMENTS: '/documents',
  HOME: '/home',
  TASKS: '/tasks',
  TRANSACTIONS: '/transactions',
  PROFILE: '/profile',
  PROFILES: '/profiles',
  CONTACTS: '/contacts',
  DOCUMENTS_VIEW: '/documents/view',
  RESOURCES: '/resources',
}

export const HEADER_TITLES = {
  ASSETS: 'Assets',
  DOCUMENTS: 'Documents',
  PROFILE: 'My Profile',
  PROFILES: '',
  CONTACTS: 'Contacts',
  TRANSACTIONS: 'Transactions',
  RESOURCES: 'Resources',
}

export const TRANSACTION_TYPE = {
  PURCHASE: 'Purchase',
  CAPITAL_COMMITMENT: 'Capital_Commitment',
  LIQUIDATION: 'Liquidation',
  EXCHANGE: 'Exchange',
}

export const DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS = {
  [TRANSACTION_TYPE.PURCHASE]: 'purchases',
  [TRANSACTION_TYPE.CAPITAL_COMMITMENT]: 'capitalCommitments',
  [TRANSACTION_TYPE.LIQUIDATION]: 'liquidations',
  [TRANSACTION_TYPE.EXCHANGE]: 'exchangeConfirmations',
}

export const LETTER_UPLOAD_TYPES = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
}

export const SPONSOR_FORM_TYPES = {
  CLIENT_SPECIFIC: 'isClientSpecific',
  ASSET_SPECIFIC: 'isAssetSpecific',
}

export const SPONSOR_FORM_TYPE_OPTIONS = [
  { key: 'Client Specific', value: SPONSOR_FORM_TYPES.CLIENT_SPECIFIC },
  { key: 'Asset Specific', value: SPONSOR_FORM_TYPES.ASSET_SPECIFIC },
]

export const DOCUMENT_UPLOAD_TYPES = {
  MTC_FORM: 'Inspira',
  SPONSOR_FORM: 'Sponsor',
  PAYMENT_FORM: 'Investor Payment Breakdown',
  OTHER: 'Other',
}

export const RESOLUTION_TYPE = 'Resolution'

export const RESOLUTION_DOCUMENT_NAMES = {
  VALUATION_DOCUMENT: 'Valuation Document',
  WIRE_INSTRUCTIONS: 'Wire Instructions',
  SUBSCRIPTION_DOCUMENT: 'Subscription Document',
}

export const RESOLUTION_DOCUMENT_HELP_TEXT = {
  [RESOLUTION_DOCUMENT_NAMES.VALUATION_DOCUMENT]:
    'The last reported asset price/value are outdated.  Provide current client statements or a completed Inspira Investment Valuation form.',
  [RESOLUTION_DOCUMENT_NAMES.WIRE_INSTRUCTIONS]:
    "Provide the current wire instructions for this asset in order to proceed with investor's investment in the asset.",
  [RESOLUTION_DOCUMENT_NAMES.SUBSCRIPTION_DOCUMENT]:
    'Provide the most current subscription agreement and any corresponding offering documents for this asset.',
}

export const EXCHANGE_CONFIRMATION_RECORD_TYPE = {
  INVESTMENT: 'Investment',
  DIVESTMENT: 'Divestment',
}

export const EXCHANGE_CONFIRMATION_RECORD_TYPE_MAPPING = {
  [EXCHANGE_CONFIRMATION_RECORD_TYPE.INVESTMENT]: 'To',
  [EXCHANGE_CONFIRMATION_RECORD_TYPE.DIVESTMENT]: 'From',
}

export const EXCHANGE_CONFIRMATION_RECORD_TYPE_FORM_MAPPING = {
  [EXCHANGE_CONFIRMATION_RECORD_TYPE.INVESTMENT]: 'toNew',
  [EXCHANGE_CONFIRMATION_RECORD_TYPE.DIVESTMENT]: 'fromOriginal',
}

export const EXCHANGE_CONFIRMATION_RECORD_TYPE_HELP_TEXT_MAPPING = {
  [EXCHANGE_CONFIRMATION_RECORD_TYPE.INVESTMENT]: 'The new investment.',
  [EXCHANGE_CONFIRMATION_RECORD_TYPE.DIVESTMENT]: 'The original investment.',
}

export const EXCHANGE_CONFIRMATION_REQUEST_TYPE = {
  PERCENTAGE: 'Percentage',
  AMOUNT: 'Amount',
  SHARES: 'Shares',
}

export const EXCHANGE_CONFIRMATION_DISPLAY_TYPE = {
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.PERCENTAGE]: 'Percentage',
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.AMOUNT]: 'Dollars',
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.SHARES]: 'Shares',
}

export const EXCHANGE_CONFIRMATION_REQUEST_TYPE_MAPPING = {
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.PERCENTAGE]: 'Percentage',
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.AMOUNT]: 'Amount',
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.SHARES]: 'Number of Shares',
}

export const EXCHANGE_CONFIRMATION_REQUEST_TYPE_HELP_TEXT_MAPPING = {
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.PERCENTAGE]:
    'The percentage requested for exchange.',
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.AMOUNT]:
    'The dollar amount requested for exchange.',
  [EXCHANGE_CONFIRMATION_REQUEST_TYPE.SHARES]:
    'The number of shares requested for exchange.',
}

export const AVAILABLE_DOCUMENT_TYPES = {
  CAPITAL_COMMITMENT: 'Capital Commitments',
  PURCHASE: 'Completed Transactions',
}

export const USER_CLASSIFICATION = {
  GROUP_1: 'Group1',
  GROUP_2: 'Group2',
}

export const CONTACT_CLASSIFICATION = {
  ACP_USER: 'ACP User',
  ASSET_CONTACT: 'Asset Contact',
}

export const PURCHASE_EXECUTION_INSTRUCTIONS = [
  'Download the Inspira documents and each investor’s documents.',
  'Review the processing requirements from Inspira.',
  'Execute the purchase(s).',
  'After you execute the purchase(s), come back here to confirm.',
]

export const TABLE_NO_ROWS_TEMPLATE =
  '<div class="search-results-helper">' +
  '<div class="overlay-text-title med-18">No results found</div>' +
  '<span class="overlay-text">Try adjusting your filter or search.</span>' +
  '</div>'
