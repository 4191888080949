import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'
import Layout from './Layout'
import { EXCHANGE_CONFIRMATION_STAGES } from 'flow-stages'
import { flatMap } from 'lodash'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  return (
    <Switch>
      {flatMap(EXCHANGE_CONFIRMATION_STAGES, (stage) =>
        stage.routes.map((route) => {
          const { pathname, component: Component } = route
          return (
            <Route
              exact
              key={pathname}
              path={path + '/:exchangeID' + pathname}
              render={(routeProps) => (
                <Layout currentStage={stage.name}>
                  <Component {...routeProps} />
                </Layout>
              )}
            />
          )
        })
      )}
      <Redirect path="*" to="/home" />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
