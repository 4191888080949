import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'
import { SubmitButton } from 'lp-components'
import * as flashActions from 'redux-flash'
import { pickBy, isNil } from 'lodash'
import {
  Card,
  DocumentUploadInput,
  ACPInputField,
  ProgressBar,
} from 'components'
import { DocumentTypeModal } from '../components'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import { Formik, Form } from 'formik'
import {
  bulkPromiseUploads,
  createFileErrors,
  numberOfSecondsByBytes,
} from 'utils'
import * as Yup from 'yup'
import { selectors } from '../reducer'
import { SponsorFormFields, MTCFormFields, PaymentFormFields } from '../forms'
import {
  SPONSOR_FORM_TYPES,
  DOCUMENT_UPLOAD_TYPES,
  DOCUMENT_TYPES,
} from 'config'
import InfoIcon from 'images/help-information.svg'
import HelpIcon from 'images/help-information-blue.svg'

const propTypes = {
  currentUser: PropTypes.object.isRequired,
  uploadExchangeDocument: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  normalizedClients: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {}

const fixedEncodeURIComponent = (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16)
  })
}

function AddNewDocument({
  currentUser,
  uploadExchangeDocument,
  flashErrorMessage,
  flashSuccessMessage,
  normalizedClients,
}) {
  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const initialValues = {
    documentType: '',
    formNumber: '',
    clientFirstName: '',
    clientLastName: '',
    accountNumber: '',
    assetName: '',
    sponsorFormType: '',
    addSubscriptionDocument: false,
    totalPaymentAmount: '',
    paymentMethod: '',
    paymentDate: '',
    assetList: [],
    documents: {},
  }

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showDocumentTypeModal, setShowDocumentTypeModal] = useState(false)
  const [numberOfSecondsToComplete, setNumberOfSecondsToComplete] = useState(0)

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    const { documentType, sponsorFormType, accountNumber } = values

    const metadata = (({
      formNumber,
      clientFirstName,
      clientLastName,
      isExistingClient,
      assetName,
      totalPaymentAmount,
      paymentMethod,
      assetList,
      paymentDate,
      accountNumber,
    }) => ({
      formNumber,
      clientFirstName,
      clientLastName,
      isExistingClient,
      assetName,
      accountNumber,
      totalPaymentAmount,
      paymentMethod,
      assetList: assetList.map(({ label }) => label),
      paymentDate,
    }))(values)

    if (documentType === DOCUMENT_UPLOAD_TYPES.SPONSOR_FORM) {
      metadata.isClientSpecific =
        sponsorFormType === SPONSOR_FORM_TYPES.CLIENT_SPECIFIC
      metadata.isAssetSpecific =
        sponsorFormType === SPONSOR_FORM_TYPES.ASSET_SPECIFIC
      if (sponsorFormType === SPONSOR_FORM_TYPES.ASSET_SPECIFIC) {
        metadata.companyName = currentUser.accountName
        metadata.assetName = fixedEncodeURIComponent(values.assetName)
      }
    }

    if (values.isExistingClient) {
      metadata.clientFullName = normalizedClients.find(
        (client) => client.value === accountNumber
      ).key
    }

    const filteredMetadata = pickBy(
      metadata,
      (value) => !isNil(value) && value !== ''
    )

    const uploads = [
      values.documents.uploadNewDocument,
      values.documents.subscriptionDocument,
    ]

    setIsSubmitting(true)
    const fileContents = uploads.filter((e) => e?.fileName)

    const fileUploadPromise = () => {
      var numberOfBytesUploaded = 0
      const formData = new FormData()
      formData.append(
        'fileContents1',
        new Blob([fileContents[0].fileContents], {
          type: fileContents[0].fileType,
        }),
        fileContents[0].fileName
      )

      numberOfBytesUploaded = fileContents[0].fileContents.byteLength

      if (fileContents.length > 1) {
        formData.append(
          'fileContents2',
          new Blob([fileContents[1].fileContents], {
            type: fileContents[1].fileType,
          }),
          fileContents[1].fileName
        )
        if (numberOfBytesUploaded < fileContents[1].fileContents.byteLength) {
          numberOfBytesUploaded = fileContents[1].fileContents.byteLength
        }
      }

      setNumberOfSecondsToComplete(
        numberOfSecondsByBytes(numberOfBytesUploaded)
      )

      formData.append('documentType', documentType)
      formData.append('metadata', `'${JSON.stringify(filteredMetadata || {})}'`)
      return [uploadExchangeDocument(formData)]
    }

    const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromise())

    if (rejectedUploads.length) {
      const errors = createFileErrors(rejectedUploads, 'myUploadsError')
      setNumberOfSecondsToComplete(0)
      setErrors(errors)
    } else {
      try {
        flashSuccessMessage('We received your documents.')
        setNumberOfSecondsToComplete(0)
        resetForm()
      } catch (e) {
        flashErrorMessage(
          'We were not able to upload your requested documents. Please try again.'
        )
      }
    }
    setIsSubmitting(false)
  }

  const fundingInstructionsValidationSchema = Yup.object().shape({
    documentType: Yup.string().required('Required'),
    formNumber: Yup.string().when('documentType', {
      is: DOCUMENT_UPLOAD_TYPES.MTC_FORM,
      then: Yup.string().required('Required'),
    }),
    sponsorFormType: Yup.string().when('documentType', {
      is: DOCUMENT_UPLOAD_TYPES.SPONSOR_FORM,
      then: Yup.string().required('Required'),
    }),
    isExistingClient: Yup.string().when('documentType', {
      is: DOCUMENT_UPLOAD_TYPES.SPONSOR_FORM,
      then: Yup.string().when('sponsorFormType', {
        is: SPONSOR_FORM_TYPES.CLIENT_SPECIFIC,
        then: Yup.string().required('Required'),
      }),
    }),
    clientFirstName: Yup.string().when('documentType', {
      is: (value) =>
        value === DOCUMENT_UPLOAD_TYPES.SPONSOR_FORM ||
        value === DOCUMENT_UPLOAD_TYPES.MTC_FORM,
      then: Yup.string().when('isExistingClient', {
        is: 'false',
        then: Yup.string().required('Required'),
      }),
    }),
    clientLastName: Yup.string().when('documentType', {
      is: (value) =>
        value === DOCUMENT_UPLOAD_TYPES.SPONSOR_FORM ||
        value === DOCUMENT_UPLOAD_TYPES.MTC_FORM,
      then: Yup.string().when('isExistingClient', {
        is: 'false',
        then: Yup.string().required('Required'),
      }),
    }),
    accountNumber: Yup.string().when('documentType', {
      is: (value) =>
        value === DOCUMENT_UPLOAD_TYPES.SPONSOR_FORM ||
        value === DOCUMENT_UPLOAD_TYPES.MTC_FORM,
      then: Yup.string().when('isExistingClient', {
        is: 'true',
        then: Yup.string().required('Required'),
      }),
    }),
    assetName: Yup.string().when('documentType', {
      is: DOCUMENT_UPLOAD_TYPES.SPONSOR_FORM,
      then: Yup.string().when('sponsorFormType', {
        is: SPONSOR_FORM_TYPES.ASSET_SPECIFIC,
        then: Yup.string().required('Required'),
      }),
    }),
    assetList: Yup.array().when('documentType', {
      is: DOCUMENT_UPLOAD_TYPES.PAYMENT_FORM,
      then: Yup.array()
        .min(1, 'You must select at least one asset.')
        .required('Required'),
    }),
    paymentMethod: Yup.string().when('documentType', {
      is: DOCUMENT_UPLOAD_TYPES.PAYMENT_FORM,
      then: Yup.string().required('Required'),
    }),
    paymentDate: Yup.string().when('documentType', {
      is: DOCUMENT_UPLOAD_TYPES.PAYMENT_FORM,
      then: Yup.string().required('Required'),
    }),
    totalPaymentAmount: Yup.string().when('documentType', {
      is: DOCUMENT_UPLOAD_TYPES.PAYMENT_FORM,
      then: Yup.string().required('Required'),
    }),
    documents: Yup.object().shape({
      uploadNewDocument: Yup.object().required(
        'Please attach the required document'
      ),
    }),
  })

  return (
    <Card label={numberOfSecondsToComplete ? '' : 'Upload New Document'}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={fundingInstructionsValidationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          setFieldValue,
          resetForm,
          setTouched,
          touched,
        }) => {
          if (numberOfSecondsToComplete) {
            return <ProgressBar milliseconds={numberOfSecondsToComplete} />
          } else {
            return (
              <Form className="asset-form asset-details-form document-upload">
                <div className="form-card-content">
                  <div className="container">
                    <div className="row form-types">
                      <div className="twelve columns radio-group add-docs-radio-group">
                        <ACPInputField
                          name="documentType"
                          label={
                            <>
                              <span className="modal-header med-16">
                                What type of document are you uploading?{' '}
                              </span>
                              <IconButton
                                className="modal-button"
                                onClick={() => setShowDocumentTypeModal(true)}
                              >
                                <Badge>
                                  <img src={InfoIcon} alt="show status key" />
                                </Badge>
                              </IconButton>
                            </>
                          }
                          radioOptions={Object.values(DOCUMENT_UPLOAD_TYPES)}
                          onChange={(e) => {
                            resetForm()
                            if (
                              e.target.value === DOCUMENT_UPLOAD_TYPES.MTC_FORM
                            ) {
                              setFieldValue('isExistingClient', true)
                            }
                            setFieldValue('documentType', e.target.value)
                            setTouched({})
                          }}
                        />
                        {values.documentType ===
                          DOCUMENT_UPLOAD_TYPES.PAYMENT_FORM && (
                          <>
                            <div className="info-msg-wrapper payment-upload-banner-wrapper">
                              <div className="info-results">
                                <div className="info-msg">
                                  <span>
                                    <img
                                      className="info-icon"
                                      src={HelpIcon}
                                      alt="Help"
                                    />
                                  </span>
                                  <span className="info-msg-span">
                                    <p className="payment-upload-info-msg-text">
                                      Note: The investor payment breakdown will
                                      not be reflected in the investor's account
                                      until payment is received by Inspira.
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {values.documentType === DOCUMENT_UPLOAD_TYPES.MTC_FORM && (
                      <MTCFormFields documentType={values.documentType} />
                    )}
                    {values.documentType ===
                      DOCUMENT_UPLOAD_TYPES.SPONSOR_FORM && (
                      <SponsorFormFields />
                    )}
                    {values.documentType ===
                      DOCUMENT_UPLOAD_TYPES.PAYMENT_FORM && (
                      <PaymentFormFields />
                    )}
                  </div>
                </div>
                {showDocumentTypeModal && (
                  <DocumentTypeModal
                    onClose={() => setShowDocumentTypeModal(false)}
                    title="Document Type Key"
                    statuses={DOCUMENT_TYPES}
                  />
                )}
                {values.documentType === DOCUMENT_UPLOAD_TYPES.OTHER && (
                  <>
                    <DocumentUploadInput
                      keyName="documents.uploadNewDocument"
                      setFieldValue={setFieldValue}
                      label="Browse Files"
                      multiple={false}
                      document={{
                        isDocumentRequired: true,
                      }}
                      error={
                        touched?.documents && errors?.documents
                          ? errors?.documents['uploadNewDocument']
                          : errors['myUploadsError']
                      }
                    />
                    <div className="document-upload-flow-button-container">
                      <SubmitButton submitting={isSubmitting}>
                        Submit Document(s)
                      </SubmitButton>
                    </div>
                  </>
                )}
              </Form>
            )
          }
        }}
      </Formik>
    </Card>
  )
}

AddNewDocument.propTypes = propTypes

AddNewDocument.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    normalizedClients: selectors.normalizedClients(state),
  }
}

const mapDispatchToProps = {
  uploadExchangeDocument: apiActions.uploadExchangeDocument,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddNewDocument
)
