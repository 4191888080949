import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePopperTooltip } from 'react-popper-tooltip'
import InfoIcon from 'images/help-information.svg'
import classnames from 'classnames'
const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  imageAlt: PropTypes.string,
  isUrgent: PropTypes.bool,
  className: PropTypes.string,
  customIcon: PropTypes.string,
  customOnClick: PropTypes.func,
  customTrigger: PropTypes.string,
  showIcon: PropTypes.bool,
}

const defaultProps = {
  isUrgent: false,
  className: '',
  customIcon: InfoIcon,
  imageAlt: 'tooltip',
  showIcon: true,
}

function Tooltip({
  children,
  imageAlt,
  className,
  customIcon: CustomIcon,
  customOnClick,
  customTrigger,
  showIcon,
}) {
  const [isOpen, setIsOpen] = useState(false)

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'top',
    trigger: customTrigger ? customTrigger : 'hover',
    visible: isOpen,
    onVisibleChange: setIsOpen,
    closeOnTriggerHidden: true,
    delayShow: 300,
    delayHide: 200,
    interactive: true,
  })

  function handleTooltipOnClick() {
    if (customOnClick) {
      customOnClick()
    }
    setIsOpen(!isOpen)
  }

  if (!showIcon) {
    return null
  }

  return (
    <>
      <button
        type="button"
        ref={setTriggerRef}
        className={classnames('btn-tooltip', className)}
        onClick={handleTooltipOnClick}
        aria-label={imageAlt}
      >
        <img src={CustomIcon} className="tooltip" alt="Tooltip" />
      </button>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          {children}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </>
  )
}

Tooltip.propTypes = propTypes
Tooltip.defaultProps = defaultProps

export default Tooltip
