import React from 'react'
import PropTypes from 'prop-types'
import {
  LabelWithTooltip,
  DownloadIconButton,
  ACPInputField,
  ACPDateInputField,
} from 'components'
import { FlowActions, DisplayInput } from '../components'
import { Formik, Form } from 'formik'
import {
  EXCHANGE_CONFIRMATION_RECORD_TYPE_MAPPING,
  EXCHANGE_CONFIRMATION_REQUEST_TYPE_MAPPING,
  EXCHANGE_CONFIRMATION_RECORD_TYPE_HELP_TEXT_MAPPING,
  EXCHANGE_CONFIRMATION_REQUEST_TYPE_HELP_TEXT_MAPPING,
} from 'config'
import { getInspiraFBOFormat } from 'utils'
import moment from 'moment'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  fromOriginalTransactions: PropTypes.object.isRequired,
  toNewTransactions: PropTypes.object.isRequired,
}
const defaultProps = {}

function TransactionForm({
  handleSubmit,
  initialValues,
  fromOriginalTransactions,
  toNewTransactions,
}) {
  const { clientFBOName } = initialValues

  return (
    <div className="flow-card-container">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <Form>
                <div className="card">
                  <div className="form-card-content">
                    <h4 className="flow-section-header">
                      Exchange Authorization
                    </h4>
                    <div className="row">
                      <div className="twelve columns">
                        <ACPInputField
                          name="clientFBOName"
                          value={getInspiraFBOFormat(clientFBOName)}
                          label="Investor Account Registered As"
                          tooltipContent="The full name of the registered investor, including Custodian Name, Client Name, Account Type, and Account Number."
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="one-half column">
                        <ACPInputField
                          name="taxID"
                          value="#36-4400066"
                          label="Tax ID"
                          tooltipContent="The tax ID of Inspira Financial."
                          disabled={true}
                        />
                      </div>
                      <div className="one-half column">
                        <ACPDateInputField
                          name="documentSentDate"
                          label="Request Date"
                          tooltipContent="The requested date of the exchange."
                          disabled={true}
                        />
                      </div>
                    </div>
                    <h4 className="flow-section-header">
                      Authorization Statement
                    </h4>
                    <div className="form-card-content">
                      You are hereby authorized to transfer the amounts
                      specified below:
                    </div>
                    {fromOriginalTransactions?.map((transaction) => {
                      return (
                        <TransactionsFieldsFlow
                          key={transaction.investmentLineItemDirectionID}
                          transaction={transaction}
                        />
                      )
                    })}
                    {toNewTransactions?.map((transaction) => {
                      return (
                        <TransactionsFieldsFlow
                          key={transaction.investmentLineItemDirectionID}
                          transaction={transaction}
                        />
                      )
                    })}
                    <div className="form-card-content">
                      <div className="flow-header-help-text">
                        <h4 className="flow-section-header">
                          Important Information Regarding Exchange of Funds
                        </h4>
                        <p>
                          Duplicate information/copies as to this exchange may
                          be forwarded directly to the beneficiary at their home
                          or business address. No Redemption proceeds,
                          dividends, return of capital, etc. should ever be sent
                          directly to a client at their home or business
                          address. Such transactions will result as a taxable
                          distribution and a possible penalty by the IRS to the
                          beneficiary of the retirement account. Additional
                          special service fees along with all corresponding
                          redemption charges, etc. will also be charged by the
                          custodian of the plan to the beneficiary. The
                          custodian may also choose to resign on the account.
                        </p>
                        <div className="card-download">
                          <DownloadIconButton
                            onClick={() => {
                              const date = new Date()
                              const formattedDate = moment(date).format(
                                'MM_DD_YYYY'
                              )
                              document.title = `ExchangeAuthorization${formattedDate}`
                              window.print()
                            }}
                          >
                            <span>
                              Please download this page for your records
                            </span>
                          </DownloadIconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <FlowActions submitting={isSubmitting} />
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

TransactionForm.propTypes = propTypes
TransactionForm.defaultProps = defaultProps

export default TransactionForm

function TransactionsFieldsFlow({ transaction }) {
  return (
    <div key={transaction.investmentLineItemDirectionID} className="row">
      <div className="one-half column">
        <ACPInputField
          name={`${transaction.investmentLineItemDirectionID}.exchangeName`}
          value={transaction.lineItemAssetName}
          label={`Exchange ${
            EXCHANGE_CONFIRMATION_RECORD_TYPE_MAPPING[
              transaction.lineItemRecordType
            ]
          }`}
          tooltipContent={
            EXCHANGE_CONFIRMATION_RECORD_TYPE_HELP_TEXT_MAPPING[
              transaction.lineItemRecordType
            ]
          }
          disabled={true}
        />
      </div>
      <div className="one-half column">
        <DisplayInput
          item={transaction}
          name={`${transaction.investmentLineItemDirectionID}.exchangeAmount`}
          label={
            EXCHANGE_CONFIRMATION_REQUEST_TYPE_MAPPING[
              transaction.requestedType
            ]
          }
          labelComponent={LabelWithTooltip}
          tooltipContent={
            EXCHANGE_CONFIRMATION_REQUEST_TYPE_HELP_TEXT_MAPPING[
              transaction.requestedType
            ]
          }
          readOnly
          disabled={true}
        />
      </div>
    </div>
  )
}
