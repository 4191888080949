import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState, setState } from 'lp-redux-utils'
import { reducer as homeReducer, reducerKey as homeReducerKey } from './home'
import {
  reducer as addAssetReducer,
  reducerKey as addAssetReducerKey,
} from './add-asset-flow'
import {
  reducer as confirmLiquidationReducer,
  reducerKey as confirmLiquidationReducerKey,
} from './liquidation-confirmation'
import {
  reducer as exchangeConfirmationsReducer,
  reducerKey as exchangeConfirmationsReducerKey,
} from './exchange-confirmation'
import {
  reducer as contactsManagementReducer,
  reducerKey as contactsManagementReducerKey,
} from './contacts-management'
import {
  reducer as contactProfileReducer,
  reducerKey as contactProfileReducerKey,
} from './contact-profile'
import {
  reducer as documentsReducer,
  reducerKey as documentsReducerKey,
} from './documents'
import {
  reducer as assetCustodyReducer,
  reducerKey as assetCustodyReducerKey,
} from './asset-custody'
import {
  reducer as capitalCommitmentsReducer,
  reducerKey as capitalCommitmentsKey,
} from './capital-commitments-flow'
import {
  reducer as invitationReducer,
  reducerKey as invitationReducerKey,
} from './invitation'
import {
  reducer as contactRoleReplacementsReducer,
  reducerKey as contactRoleReplacementsKey,
} from './contact-role-replacements'
import {
  reducer as bulkConfirmationsReducer,
  reducerKey as bulkConfirmationsKey,
} from './bulk-confirmations'
import {
  reducer as pendingPurchaseConfirmationsReducer,
  reducerKey as pendingPurchaseConfirmationsKey,
} from './transactions'
import {
  reducer as resourcesReducer,
  reducerKey as resourcesKey,
} from './resources'
import {
  reducer as fundingInstructionsReducer,
  reducerKey as fundingInstructionsKey,
} from './funding-instructions'
import {
  reducer as onboardingReducer,
  reducerKey as onboardingKey,
} from './onboarding'
import * as apiActions from 'api-actions'
import { setOnSuccess, handleSuccess } from 'lp-redux-api'
import { createSelector } from 'reselect'
import {
  ADD_ASSET_STAGE,
  TASK_TYPES,
  LIQUIDATION_CONFIRMATION_STEPS,
  PriorityLevels,
  TaskPriorityFilters,
  USER_CLASSIFICATION,
  TASK_SUBTYPES,
} from 'config'
import { set } from 'lodash/fp'
import { orderBy, toString, uniq, some, startCase } from 'lodash'
import { mapTaskToType, sortTasksByPriorityOrder } from 'utils'
import * as assetActions from './add-asset-flow/actions'
import * as bulkConfirmationActions from './bulk-confirmations/actions'
import * as fundingInstructionsActions from './funding-instructions/actions'

const reducerKey = 'root'
const slice = 'root.global'

const initialState = {
  isAuthenticated: false,
}

const updateNotificationInPlace = (notifications, notificationID, payload) => {
  return notifications
    .map((notification) => {
      if (notification.notificationID !== notificationID) return notification
      return {
        ...notification,
        ...payload,
      }
    })
    .filter(({ isRemoved }) => !isRemoved)
}

const globalReducer = handleActions(
  {
    [apiActions.fetchUserProfile]: setOnSuccess('currentUser', (action) => {
      return {
        ...action.payload.data,
        userClassification:
          action.payload.data.userClassification || USER_CLASSIFICATION.GROUP_2,
      }
    }),
    [assetActions.clearAsset]: unsetState('asset'),
    [apiActions.fetchAssets]: setOnSuccess('assets'),
    [apiActions.fetchCapitalCommitments]: setOnSuccess('capitalCommitments'),
    [apiActions.createAsset]: setOnSuccess('asset'),
    [apiActions.fetchAsset]: setOnSuccess('asset'),
    [apiActions.fetchRelatedEntities]: setOnSuccess('relatedEntities'),
    [bulkConfirmationActions.storePurchaseIDs]: setState('purchaseIDs'),
    [bulkConfirmationActions.clearPurchaseIDs]: unsetState('purchaseIDs'),
    [fundingInstructionsActions.storeFundingInstructionsPayload]: setState(
      'fundingInstructionsPayload'
    ),
    [apiActions.fetchNotifications]: handleSuccess((state, action) => {
      const notifications = action.payload.data
      // if the isRead value is a string (e.g., "true", "false"), convert it to boolean
      const updatedNotifications = notifications.map((notification) => ({
        ...notification,
        isRead: toString(notification.isRead) === 'true',
      }))
      // display unread & newest notifications first
      const sortedNotifications = orderBy(
        updatedNotifications,
        ['isRead', 'createdDate'],
        ['asc', 'desc']
      )
      return set('notifications', sortedNotifications, state)
    }),
    [apiActions.fetchTasks]: handleSuccess((state, action) => {
      // select open tasks only
      let incompleteTasks = action.payload.data
        .filter((task) => !task.completed)
        // shim for current api behavior (possible for assetStage = 'completed' while completed = false)
        .filter((task) => {
          const { isSubmitAsset } = mapTaskToType(task)
          if (isSubmitAsset) {
            return task.assetStage !== ADD_ASSET_STAGE.COMPLETED
          }
          return task
        })
      // manually update outdated assetStage values to match the correct route
      const updatedTasks = incompleteTasks
        .sort((a, b) => {
          return new Date(b.createDate) - new Date(a.createDate)
        })
        .map((task) => {
          if (task.assetStage === ADD_ASSET_STAGE.REQUIREMENT_DETAILS) {
            return {
              ...task,
              assetStage: ADD_ASSET_STAGE.PURCHASE_REQUIREMENTS,
            }
          }
          if (task.assetStage === ADD_ASSET_STAGE.DOCUMENT_UPLOAD) {
            return {
              ...task,
              assetStage: ADD_ASSET_STAGE.DOCUMENTS,
            }
          }
          const { isConfirmLiquidation } = mapTaskToType(task)
          if (isConfirmLiquidation) {
            if (
              task.subType ===
              LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_SECOND_STEP
            )
              return {
                ...task,
                subject: `${task.subject} Part 2`,
              }
          }
          return task
        })

      const uniqTaskTypes = uniq(
        updatedTasks
          .filter((task) => task.taskType)
          .map((task) => {
            if (task.taskType === TASK_TYPES.CONFIRMATION) {
              const { isConfirmLiquidation } = mapTaskToType(task)
              return isConfirmLiquidation
                ? TASK_TYPES.LIQUIDATION
                : task.subType
            } else {
              const { isValuation, isContactReview, isFunding } = mapTaskToType(
                task
              )
              return isValuation
                ? TASK_SUBTYPES.VALUATION
                : isContactReview
                ? TASK_SUBTYPES.CONTACT
                : isFunding
                ? TASK_SUBTYPES.FUNDING
                : task.taskType
            }
          })
      )

      const hasUrgentTasks = some(updatedTasks, {
        priority: PriorityLevels.URGENT,
      })

      if (hasUrgentTasks) {
        uniqTaskTypes.unshift(startCase(TaskPriorityFilters.URGENT))
      }

      return set(
        'tasks',
        { data: updatedTasks, meta: { uniqTaskTypes } },
        state
      )
    }),
    [apiActions.fetchTransactions]: handleSuccess((state, action) => {
      const sortedTransactions = orderBy(
        action.payload.data,
        [
          function(resultItem) {
            return resultItem.transactionDate !== null
          },
          'transactionDate',
        ],
        ['desc', 'desc']
      )
      return set('transactions', sortedTransactions, state)
    }),
    [apiActions.updateNotification]: handleSuccess((state, action) => {
      const notificationID = action.payload.data.instructionID
      const { notifications } = state
      return set(
        'notifications',
        updateNotificationInPlace(notifications, notificationID, {
          isRead: true,
        }),
        state
      )
    }),
    [apiActions.removeNotification]: handleSuccess((state, action) => {
      const notificationID = action.payload.data.instructionID
      const { notifications } = state
      return set(
        'notifications',
        updateNotificationInPlace(notifications, notificationID, {
          isRemoved: true,
        }),
        state
      )
    }),
  },
  initialState
)

const reducer = combineReducers({
  [homeReducerKey]: homeReducer,
  [addAssetReducerKey]: addAssetReducer,
  [confirmLiquidationReducerKey]: confirmLiquidationReducer,
  [documentsReducerKey]: documentsReducer,
  [assetCustodyReducerKey]: assetCustodyReducer,
  [capitalCommitmentsKey]: capitalCommitmentsReducer,
  [exchangeConfirmationsReducerKey]: exchangeConfirmationsReducer,
  [contactsManagementReducerKey]: contactsManagementReducer,
  [contactProfileReducerKey]: contactProfileReducer,
  [invitationReducerKey]: invitationReducer,
  [contactRoleReplacementsKey]: contactRoleReplacementsReducer,
  [bulkConfirmationsKey]: bulkConfirmationsReducer,
  [pendingPurchaseConfirmationsKey]: pendingPurchaseConfirmationsReducer,
  [resourcesKey]: resourcesReducer,
  [fundingInstructionsKey]: fundingInstructionsReducer,
  [onboardingKey]: onboardingReducer,
  global: globalReducer,
})

const select = selectorForSlice(slice)

const selectors = {
  isAuthenticated: select('isAuthenticated'),
  currentUser: select('currentUser'),
  assets: select('assets'),
  tasks: select('tasks.data'),
  notifications: select('notifications'),
  transactions: select('transactions'),
  asset: select('asset'),
  uniqTaskTypes: select('tasks.meta.uniqTaskTypes'),
  purchaseIDs: select('purchaseIDs'),
  fundingInstructionsPayload: select('fundingInstructionsPayload'),
  relatedEntities: select('relatedEntities'),
}

selectors.urgentTasks = createSelector([selectors.tasks], (tasks) => {
  if (!tasks) return []
  return tasks.sort(sortTasksByPriorityOrder)
})

selectors.permissions = createSelector(
  [selectors.currentUser],
  (currentUser) => {
    if (!currentUser) return {}
    return currentUser.permissions
  }
)

selectors.assetOptions = createSelector([selectors.assets], (assets) => {
  return assets?.map((asset) => ({
    key: asset.name,
    value: asset.assetID,
  }))
})

selectors.unreadNotifications = createSelector(
  [selectors.notifications],
  (notifications) => {
    return notifications.filter(({ isRead }) => !isRead)
  }
)

export { reducer, selectors, reducerKey }
